// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CalendarCreationModal Component rendering renders correctly 1`] = `
<div
  className="modal_body calendar"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="modal_title calendar"
  >
    Schedule new event
  </div>
  <section
    className="content"
  >
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Event type
        </div>
        <select
          className="calendar_select"
          id="calendarId"
          onChange={[Function]}
          value={1}
        >
          <option
            value={1}
          >
            name
          </option>
        </select>
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Name
        </div>
        <input
          id="name"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Location
        </div>
        <input
          id="location"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Details
        </div>
        <input
          id="body"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <section
      className="input-container calendar top"
    >
      <section>
        <div
          className="input-label"
        >
          Date
        </div>
        <div>
          <div
            className="tui-datepicker-input tui-datetime-input tui-has-focus"
          >
            <input
              aria-label="Date-Time"
              id="start-date-picker-input"
              type="text"
            />
            <span
              className="tui-ico-date"
            />
            <div
              id="start-date-picker"
            />
          </div>
          <span
            className="text"
          >
            to
          </span>
          <div
            className="tui-datepicker-input tui-datetime-input tui-has-focus"
          >
            <input
              aria-label="Date-Time"
              id="end-date-picker-input"
              type="text"
            />
            <span
              className="tui-ico-date"
            />
            <div
              id="end-date-picker"
            />
          </div>
        </div>
      </section>
    </section>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Lead name
        </div>
        <div
          className="user-search css-2b097c-container"
          onKeyDown={[Function]}
        >
          <div
            className="user-search__control css-yk16xz-control"
            onMouseDown={[Function]}
            onTouchEnd={[Function]}
          >
            <div
              className="user-search__value-container css-g1d714-ValueContainer"
            >
              <div
                className="user-search__placeholder css-1wa3eu0-placeholder"
              >
                Full name
              </div>
              <div
                className="css-b8ldur-Input"
              >
                <div
                  className="user-search__input"
                  style={
                    Object {
                      "display": "inline-block",
                    }
                  }
                >
                  <input
                    aria-autocomplete="list"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    disabled={false}
                    id="react-select-4-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    spellCheck="false"
                    style={
                      Object {
                        "background": 0,
                        "border": 0,
                        "boxSizing": "content-box",
                        "color": "inherit",
                        "fontSize": "inherit",
                        "label": "input",
                        "opacity": 1,
                        "outline": 0,
                        "padding": 0,
                        "width": "1px",
                      }
                    }
                    tabIndex="0"
                    type="text"
                    value=""
                  />
                  <div
                    style={
                      Object {
                        "height": 0,
                        "left": 0,
                        "overflow": "scroll",
                        "position": "absolute",
                        "top": 0,
                        "visibility": "hidden",
                        "whiteSpace": "pre",
                      }
                    }
                  >
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="user-search__indicators css-1hb7zxy-IndicatorsContainer"
            >
              <span
                className="user-search__indicator-separator css-1okebmr-indicatorSeparator"
              />
              <div
                aria-hidden="true"
                className="user-search__indicator user-search__dropdown-indicator css-tlfecz-indicatorContainer"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <i
                  className="material-icons"
                >
                  search
                </i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Attendees count
        </div>
        <input
          id="max_attendees"
          onChange={[Function]}
          value="5"
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          People to invite(
          0
          /
          5
          )
        </div>
        <div
          className="user-search calendar_modal css-2b097c-container"
          onKeyDown={[Function]}
        >
          <div
            className="user-search__control css-yk16xz-control"
            onMouseDown={[Function]}
            onTouchEnd={[Function]}
          >
            <div
              className="user-search__value-container css-g1d714-ValueContainer"
            >
              <div
                className="user-search__placeholder css-1wa3eu0-placeholder"
              >
                Full name
              </div>
              <div
                className="css-b8ldur-Input"
              >
                <div
                  className="user-search__input"
                  style={
                    Object {
                      "display": "inline-block",
                    }
                  }
                >
                  <input
                    aria-autocomplete="list"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    disabled={false}
                    id="react-select-5-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    spellCheck="false"
                    style={
                      Object {
                        "background": 0,
                        "border": 0,
                        "boxSizing": "content-box",
                        "color": "inherit",
                        "fontSize": "inherit",
                        "label": "input",
                        "opacity": 1,
                        "outline": 0,
                        "padding": 0,
                        "width": "1px",
                      }
                    }
                    tabIndex="0"
                    type="text"
                    value=""
                  />
                  <div
                    style={
                      Object {
                        "height": 0,
                        "left": 0,
                        "overflow": "scroll",
                        "position": "absolute",
                        "top": 0,
                        "visibility": "hidden",
                        "whiteSpace": "pre",
                      }
                    }
                  >
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="user-search__indicators css-1hb7zxy-IndicatorsContainer"
            >
              <span
                className="user-search__indicator-separator css-1okebmr-indicatorSeparator"
              />
              <div
                aria-hidden="true"
                className="user-search__indicator user-search__dropdown-indicator css-tlfecz-indicatorContainer"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <i
                  className="material-icons"
                >
                  search
                </i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      className="input-container calendar no_text_input"
    >
      <section>
        <div
          className="input-label"
        >
          Event type
        </div>
        <section>
          <div>
            <input
              checked={true}
              id="public"
              name="event_type"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="public"
            >
              Public event
            </label>
          </div>
          <div>
            <input
              checked={false}
              id="internal"
              name="event_type"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="internal"
            >
              Internal event
            </label>
          </div>
        </section>
      </section>
      <section>
        <div
          className="input-label"
        >
          Event visibility
        </div>
        <section>
          <div>
            <input
              checked={false}
              id="lead_only"
              name="event_visibility"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="lead_only"
            >
              Lead
            </label>
          </div>
          <div>
            <input
              checked={true}
              id="company_wide"
              name="event_visibility"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="company_wide"
            >
              Company Wide
            </label>
          </div>
        </section>
      </section>
    </div>
  </section>
  <div
    className="modal_button_container"
  >
    <button
      className="modal_button"
      onClick={[Function]}
      type="button"
    >
      Save
    </button>
  </div>
</div>
`;
