import React from 'react';
import Icon from '@material-ui/core/Icon';
import { cssTransition, ToastContainer } from 'react-toastify';

const Slide = cssTransition({
  enter: 'slideDown',
  exit: 'slideUp',
  duration: 500,
});

// eslint-disable-next-line react/prop-types
const Close = ({ closeToast }) => <button type="button" onClick={closeToast}><Icon>close</Icon></button>;

const Toast = () => (
  <ToastContainer
    closeButton={<Close />}
    hideProgressBar
    autoClose={false}
    draggable={false}
    enableMultiContainer={false}
    pauseOnFocusLoss={false}
    transition={Slide}
    className="toast"
    bodyClassName="toast-body"
    toastClassName="toast"
  />
);

export default Toast;
