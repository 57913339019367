// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SelectHospitals Component rendering renders correctly 1`] = `
Array [
  <div
    className="hospital-shipment_table-container"
  >
    <div
      className="box with-actions"
      id=""
    >
      <div
        className="table-actions"
      >
        <div
          className="add-new"
        >
          <div
            className="label"
          >
            Create New
          </div>
          <button
            onClick={[Function]}
            type="button"
          >
            +
          </button>
        </div>
        <div
          className="hs-filters"
        >
          <div
            className="checkbox-cell"
          >
            <input
              id="verified"
              onChange={[Function]}
              type="checkbox"
            />
            <label
              htmlFor="verified"
            >
              Address Verified
            </label>
          </div>
          <div
            className="checkbox-cell"
          >
            <input
              id="approved"
              onChange={[Function]}
              type="checkbox"
            />
            <label
              htmlFor="approved"
            >
              Approved for Shipping
            </label>
          </div>
        </div>
      </div>
      <div
        className="box box-default table-box table-responsive"
      >
        <table
          className="mdl-data-table table-selectable table-bordered"
        >
          <thead>
            <tr>
              <th
                className="mdl-data-table__cell--non-numeric"
              />
              <th
                className="mdl-data-table__cell--non-numeric"
              >
                #
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                ID
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Name
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Number of contacts
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Address
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Last Shipment
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Address Verified
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Approved for Shipping
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="mdl-data-table__cell--non-numeric"
              >
                <div
                  className="checkbox-cell"
                >
                  <input
                    id="hscb-0"
                    onChange={[Function]}
                    type="checkbox"
                  />
                  <label
                    htmlFor="hscb-0"
                  />
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                1
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  1
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  <button
                    className="link-btn"
                    onClick={[Function]}
                    type="button"
                  >
                    
                  </button>
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  0
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  City, ST
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  false
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  false
                </div>
              </td>
            </tr>
            <tr>
              <td
                className="mdl-data-table__cell--non-numeric"
              >
                <div
                  className="checkbox-cell"
                >
                  <input
                    id="hscb-1"
                    onChange={[Function]}
                    type="checkbox"
                  />
                  <label
                    htmlFor="hscb-1"
                  />
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                2
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  2
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  <button
                    className="link-btn"
                    onClick={[Function]}
                    type="button"
                  >
                    
                  </button>
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  0
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  City, ST
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  false
                </div>
              </td>
              <td
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
                onKeyDown={[Function]}
                role="gridcell"
              >
                <div
                  className=""
                >
                  false
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>,
  <button
    className="prepare-button"
    onClick={[MockFunction]}
    type="button"
  >
    Prepare shipment (
    0
    )
  </button>,
]
`;
