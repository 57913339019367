import React from 'react';
import PropTypes from 'prop-types';
import StateSelect from '../ui/StateSelect';
import CloseButton from '../ui/CloseButton';
import phoneFormatter from '../../services/phoneFormatter';

class HospitalAddNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: {
        name: '',
        short_name: '',
        phone: '',
        email: '',
        website: '',
        street1: '',
        street2: '',
        street1_short: '',
        street2_short: '',
        shipping_contact: '',
        city: '',
        state: 'AL',
        zip_code: '',
      },
      errors: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  hasErrors = () => {
    const { errors } = this.state;
    return Object.values(errors).some(item => item);
  };

  handleSubmit() {
    const { handleSave } = this.props;
    const { inputs } = this.state;
    Object.keys(inputs).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;
    handleSave(inputs);
  }

  validateField(fieldName) {
    const { errors, inputs } = this.state;
    const value = inputs[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errors[fieldName] = valid ? '' : 'Email is invalid';
        break;
      case 'street1':
      case 'name':
      case 'city':
        valid = !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        break;
      case 'phone':
        valid = !value || value.match(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\\-]?[\0-9]{3}[\s\\-]?[0-9]{4}$/g);
        errors[fieldName] = valid ? '' : 'Phone is invalid';
        break;
      case 'zip_code':
        valid = value.match(/^\d{5}(?:[-\s]\d{4})?$/g);
        errors[fieldName] = valid ? '' : 'Zip code is invalid';
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  handleChange(e) {
    const { inputs } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    if (name === 'phone') {
      inputs[name] = phoneFormatter(value);
      this.setState({ inputs }, () => this.validateField(name));
      return;
    }
    inputs[name] = value;
    this.setState({ inputs }, () => { this.validateField(name); });
  }

  render() {
    const { inputs, errors } = this.state;
    const { handleClose } = this.props;
    return (
      <div className="modal_body detail">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="title">Create new hospital</div>
        <div className="add-fields">
          <div className="input-container">
            <section>
              <div className="input-label">Name</div>
              <input id="name" value={inputs.name} onChange={this.handleChange} />
            </section>
            <div className="errors">{errors.name && errors.name}</div>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">Name (short)</div>
              <input id="short_name" value={inputs.short_name} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">Shipping contact</div>
              <input id="shipping_contact" value={inputs.shipping_contact} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label">Email</div>
              <input id="email" value={inputs.email} onChange={this.handleChange} />
            </section>
            <div className="errors">{errors.email && errors.email}</div>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label">Phone</div>
              <input id="phone" value={inputs.phone} onChange={this.handleChange} />
            </section>
            <div className="errors">{errors.phone && errors.phone}</div>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">Website</div>
              <input id="website" value={inputs.website} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label">Street 1</div>
              <input id="street1" value={inputs.street1} onChange={this.handleChange} />
            </section>
            <div className="errors">{errors.street1 && errors.street1}</div>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">Street 1 (short)</div>
              <input id="street1_short" value={inputs.street1_short} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">Street 2</div>
              <input id="street2" value={inputs.street2} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">Street 2 (short)</div>
              <input id="street2_short" value={inputs.street2_short} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label">City</div>
              <input id="city" value={inputs.city} onChange={this.handleChange} />
            </section>
            <div className="errors">{errors.city && errors.city}</div>
          </div>
          <div className="input-container no_error">
            <section>
              <div className="input-label">State</div>
              <StateSelect
                id="state"
                className="modal_select_state"
                onChange={this.handleChange}
                value={inputs.state}
              />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label">Zip Code</div>
              <input id="zip_code" value={inputs.zip_code} onChange={this.handleChange} />
            </section>
            <div className="errors">{errors.zip_code && errors.zip_code}</div>
          </div>
        </div>
        <div className="button_container">
          <button type="button" className="incoming-shipments-modal-btn no" onClick={handleClose}>Cancel</button>
          <button type="button" className="incoming-shipments-modal-btn yes" onClick={this.handleSubmit}>Save</button>
        </div>
      </div>
    );
  }
}

HospitalAddNew.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default HospitalAddNew;
