import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import IncomingShipments from '../IncomingShipments';

jest.mock('../../../services/api', () => ({
  acceptShipment: jest.fn(() => Promise.resolve({})),
  checkShipment: jest.fn(() => Promise.resolve({ donor: {}, shipment: {} })),
  createDonorShipment: jest.fn(() => Promise.resolve({ donor: {}, shipment: {} })),
  updateDonor: jest.fn(() => Promise.resolve({ donor: {} })),
}));

const refProps = {
  current: {
    focus: jest.fn(),
  },
};

describe('IncomingShipments Component', () => {
  let wrapper;
  const defaultProps = {
    withoutTNCount: 1,
  };
  const weightError = 'Weight must be a number greater than 0!';
  const trackingNumberError = 'The tracking number is required';
  beforeEach(() => {
    wrapper = mount(<IncomingShipments {...defaultProps} />);
    wrapper.instance().trackingNumberInput = refProps;
    wrapper.instance().weightInput = refProps;
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<IncomingShipments {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders with "showInfo" = false', () => {
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('.tables-container')).toHaveLength(1);
      expect(wrapper.find('.incoming-shipment_info')).toHaveLength(1);
      expect(wrapper.find('.table-title')).toHaveLength(1);
    });
    it('renders with "showInfo" = true', () => {
      wrapper.setState({ showInfo: true });
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('.tables-container')).toHaveLength(1);
      expect(wrapper.find('.incoming-shipment_info')).toHaveLength(2);
      expect(wrapper.find('.table-title')).toHaveLength(2);
    });
  });
  describe('validation', () => {
    describe('tracking number validation', () => {
      it('null tracking number', () => {
        wrapper.find('.blue').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(trackingNumberError);
      });
      it('not valid tracking number', () => {
        wrapper.find('input').at(0).simulate('change', { target: { id: 'trackingNumber', value: '' } });
        wrapper.find('.blue').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(trackingNumberError);
      });
      it('valid tracking number', () => {
        wrapper.find('input').at(0).simulate('change', { target: { id: 'trackingNumber', value: '1Z2FV9826194667252' } });
        wrapper.find('.blue').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(0);
        expect(wrapper.text()).not.toMatch(trackingNumberError);
      });
    });
    describe('weight validation', () => {
      beforeEach(() => {
        wrapper.setState({ showInfo: true });
      });

      it('weight = null', () => {
        wrapper.find('.orange').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(weightError);
      });
      it('weight = "sixteen"', () => {
        wrapper.find('input').at(1).simulate('change', { target: { id: 'weight', value: 'sixteen' } });
        wrapper.find('.orange').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(weightError);
      });
      it('weight = -12', () => {
        wrapper.find('input').at(1).simulate('change', { target: { id: 'weight', value: '-12' } });
        wrapper.find('.orange').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(weightError);
      });
      it('weight = "123.12"', () => {
        wrapper.find('input').at(1).simulate('change', { target: { id: 'weight', value: '1001' } });
        wrapper.find('.orange').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(0);
        expect(wrapper.text()).not.toMatch(weightError);
      });
      it('weight = "22"', () => {
        wrapper.find('input').at(1).simulate('change', { target: { id: 'weight', value: '22' } });
        wrapper.find('.orange').simulate('click');
        expect(wrapper.find('.error')).toHaveLength(0);
        expect(wrapper.text()).not.toMatch(weightError);
      });
    });

    describe('event handling', () => {
      it('handleCloseModal', () => {
        wrapper.instance().handleCloseModal();
        expect(wrapper.state('errors').trackingNumber).toEqual('');
      });

      it('handleModalYes', () => {
        wrapper.instance().handleModalYes();
        expect(wrapper.state('showCreateNew')).toEqual(true);
      });

      it('handleSave', () => {
        wrapper.instance().handleSave();
        expect(wrapper.state('showInfo')).toEqual(false);
        expect(wrapper.state('showCreateNew')).toEqual(false);
      });

      it('handleUpdate', () => {
        wrapper.instance().handleUpdate();
        expect(wrapper.state('readOnly')).toEqual(true);
      });

      it('handleChangeDonor', () => {
        wrapper.instance().handleChangeDonor({ target: { id: 'first_name', value: 'name' } });
        expect(wrapper.state('donor').first_name).toEqual('name');
      });
    });
  });
});
