// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`IncomingShipments Component rendering renders correctly 1`] = `
<div
  className="detail-container"
>
  <div
    className="Toastify"
  />
  <div
    className="detail-title"
  >
    Incoming Shipments
  </div>
  <div
    className="tables-container in-sh"
  >
    <div
      className="table-title"
    >
      Locate and Accept Shipment
    </div>
    <div
      className="information-container incoming-shipment"
      id="information"
      onKeyDown={[Function]}
      role="button"
      tabIndex={-1}
    >
      <div
        className="tn-container"
      >
        <div
          className="input-container incoming-shipment"
        >
          <div
            className="incoming-shipment_info"
          >
            <div
              className="input-label incoming-shipment tracking-number"
            >
              Tracking Number
            </div>
            <input
              autoFocus={true}
              className="tracking-number"
              id="trackingNumber"
              onChange={[Function]}
              value=""
            />
          </div>
          <button
            className="incoming-shipment_btn blue"
            onClick={[Function]}
            type="button"
          >
            Locate
          </button>
        </div>
        <button
          className="incoming-shipment_btn gray"
          onClick={[Function]}
          type="button"
        >
          Manual Entry
        </button>
        <button
          className="incoming-shipment_btn gray"
          onClick={[Function]}
          type="button"
        >
          Anonymous entry
        </button>
      </div>
    </div>
  </div>
</div>
`;
