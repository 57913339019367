import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import LocateAndAcceptShipment from '../LocateAndAcceptShipment';

describe('LocateAndAcceptShipment Component', () => {
  let wrapper;
  const defaultProps = {
    showWeight: true,
    locate: jest.fn(),
    accept: jest.fn(),
    errors: {},
    accepted: false,
    inputs: {
      trackingNumber: '',
      weight: '',
    },
    handleChange: jest.fn(),
    manualEntry: jest.fn(),
    shipmentInfo: {},
    tnInputRef: {},
    wInputRef: {},
  };

  beforeEach(() => {
    wrapper = mount(<LocateAndAcceptShipment {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<LocateAndAcceptShipment {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders with "showWeight" = false', () => {
      wrapper.setProps({ showWeight: false });
      expect(wrapper.find('.information-container')).toHaveLength(1);
      expect(wrapper.find('.input-container')).toHaveLength(1);
      expect(wrapper.find('.incoming-shipment_info')).toHaveLength(1);
    });
    it('renders with "showWeight" = true', () => {
      expect(wrapper.find('.information-container')).toHaveLength(1);
      expect(wrapper.find('.input-container')).toHaveLength(2);
      expect(wrapper.find('.incoming-shipment_info')).toHaveLength(2);
    });
    it('renders errors', () => {
      wrapper.setProps({ errors: { weight: 'err' } });
      expect(wrapper.find('.error')).toHaveLength(1);
    });
  });
  describe('methods', () => {
    it('locate call', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.locate).toHaveBeenCalled();
    });
    it('accept call', () => {
      wrapper.find('button').at(3).simulate('click');
      expect(defaultProps.accept).toHaveBeenCalled();
    });
  });
});
