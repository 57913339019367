// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Table Component rendering renders correctly 1`] = `
<div
  className="box with-actions"
  id="1"
>
  <div
    className="table-actions"
  >
    <div
      className="add-new"
    >
      <div
        className="label"
      >
        Create New
      </div>
      <button
        onClick={[Function]}
        type="button"
      >
        +
      </button>
    </div>
  </div>
  <div
    className="box box-default table-box table-responsive"
  >
    <table
      className="mdl-data-table table-selectable table-bordered"
    >
      <thead>
        <tr>
          <th
            className="mdl-data-table__cell--non-numeric"
          >
            #
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
          >
            ID
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 sort hidden"
            >
              arrow_upward
            </span>
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
          >
            Name
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 sort hidden"
            >
              arrow_upward
            </span>
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
          >
            Email
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 sort hidden"
            >
              arrow_upward
            </span>
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
          >
            Phone
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 sort hidden"
            >
              arrow_upward
            </span>
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
          >
            Shipment type
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 sort hidden"
            >
              arrow_upward
            </span>
          </th>
          <th
            className="mdl-data-table__cell--non-numeric editable"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            1
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              1
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test@test.com
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              12344567890
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            Normal
          </td>
          <td
            className="mdl-data-table__cell--non-numeric editable"
          >
            <section>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Edit"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  edit
                </span>
              </button>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Delete"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  delete
                </span>
              </button>
            </section>
          </td>
        </tr>
        <tr>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            2
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              2
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test2
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test2@test.com
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              12344562890
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            Normal
          </td>
          <td
            className="mdl-data-table__cell--non-numeric editable"
          >
            <section>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Edit"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  edit
                </span>
              </button>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Delete"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  delete
                </span>
              </button>
            </section>
          </td>
        </tr>
        <tr>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            3
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              3
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test3
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test3@test.com
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              12344562893
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            Normal
          </td>
          <td
            className="mdl-data-table__cell--non-numeric editable"
          >
            <section>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Edit"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  edit
                </span>
              </button>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Delete"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  delete
                </span>
              </button>
            </section>
          </td>
        </tr>
        <tr>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            4
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              4
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test4
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test4@test.com
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              12344562894
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            Schedule
          </td>
          <td
            className="mdl-data-table__cell--non-numeric editable"
          >
            <section />
          </td>
        </tr>
        <tr>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            5
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              5
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test5
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              test5@test.com
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className=""
            >
              12344562895
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric"
            onClick={[Function]}
            onKeyDown={[Function]}
            role="gridcell"
          >
            <div
              className="table-month-col"
            >
              <div
                className="month-item-active"
              >
                Jan
              </div>
              <div
                className="month-item-active"
              >
                Dec
              </div>
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric editable"
          >
            <section>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Edit"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  edit
                </span>
              </button>
              <button
                aria-describedby={null}
                className="label"
                onBlur={[Function]}
                onClick={[Function]}
                onFocus={[Function]}
                onMouseLeave={[Function]}
                onMouseOver={[Function]}
                onTouchEnd={[Function]}
                onTouchStart={[Function]}
                title="Delete"
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  delete
                </span>
              </button>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    className="table--view-more"
  >
    <button
      className="button"
      disabled={false}
      onClick={[MockFunction]}
      type="button"
    >
      View more ▼
    </button>
  </div>
</div>
`;
