import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import CalendarDetailModal from '../CalendarDetailModal';

describe('CalendarDetailModal Component', () => {
  let wrapper;
  const defaultProps = {
    event: {
      start: {
        toDate: jest.fn(() => new Date('12.12.12')),
      },
      end: {
        toDate: jest.fn(() => new Date('12.12.12')),
      },
      raw: {
        member: {},
        leader: {},
      },
      attendees: [1, 2],
    },
    handleClose: jest.fn(),
    handleDelete: jest.fn(),
    handleUpdate: jest.fn(),
    handleClick: jest.fn(),
    editable: true,
    signUp: true,
  };

  beforeEach(() => {
    wrapper = mount(<CalendarDetailModal {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<CalendarDetailModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('button')).toHaveLength(6);
      expect(wrapper.find('.detail-modal_info')).toHaveLength(3);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
    });
  });

  describe('event handling', () => {
    it('handle update', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleUpdate).toHaveBeenCalled();
    });

    it('handle delete', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.handleDelete).toHaveBeenCalled();
    });

    it('handle show confirmed', () => {
      wrapper.find('button').at(3).simulate('click');
    });

    it('handle show invited', () => {
      wrapper.find('button').at(4).simulate('click');
    });

    it('handle sign up', () => {
      wrapper.find('button').at(5).simulate('click');
      expect(defaultProps.handleClick).toHaveBeenCalled();
    });

    it('handle close', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });
  });
});
