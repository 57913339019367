// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CalendarUpdateModal Component rendering renders correctly 1`] = `
<div
  className="modal_body calendar"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="modal_title calendar"
  >
    Update event
  </div>
  <section
    className="content"
  >
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Event type
        </div>
        <input
          disabled={true}
          id="calendarId"
          readOnly={true}
          value="name"
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Name
        </div>
        <input
          id="name"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Location
        </div>
        <input
          id="location"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Details
        </div>
        <input
          id="body"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <section
      className="input-container calendar top"
    >
      <section>
        <div
          className="input-label"
        >
          Date
        </div>
        <div>
          <div
            className="tui-datepicker-input tui-datetime-input tui-has-focus"
          >
            <input
              aria-label="Date-Time"
              id="start-date-picker-input"
              type="text"
            />
            <span
              className="tui-ico-date"
            />
            <div
              id="start-date-picker"
            />
          </div>
          <span
            className="text"
          >
            to
          </span>
          <div
            className="tui-datepicker-input tui-datetime-input tui-has-focus"
          >
            <input
              aria-label="Date-Time"
              id="end-date-picker-input"
              type="text"
            />
            <span
              className="tui-ico-date"
            />
            <div
              id="end-date-picker"
            />
          </div>
        </div>
      </section>
    </section>
    <div
      className="input-container calendar no_text_input"
    >
      <section>
        <div
          className="input-label"
        >
          Join to this event:
        </div>
        <div>
          <label
            htmlFor="public"
          >
            Yes
          </label>
          <input
            checked={false}
            id="no"
            name="join"
            onChange={[Function]}
            type="radio"
          />
          <label
            htmlFor="private"
          >
            No
          </label>
          <input
            checked={true}
            id="yes"
            name="join"
            onChange={[Function]}
            type="radio"
          />
        </div>
      </section>
    </div>
    <div
      className="input-container calendar no_text_input"
    >
      <section>
        <div
          className="input-label"
        >
          Event type
        </div>
        <section>
          <div>
            <input
              checked={false}
              id="public"
              name="event_type"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="public"
            >
              Public event
            </label>
          </div>
          <div>
            <input
              checked={false}
              id="internal"
              name="event_type"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="internal"
            >
              Internal event
            </label>
          </div>
        </section>
      </section>
      <section>
        <div
          className="input-label"
        >
          Event visibility
        </div>
        <section>
          <div>
            <input
              checked={false}
              id="lead_only"
              name="event_visibility"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="lead_only"
            >
              Lead only
            </label>
          </div>
          <div>
            <input
              checked={false}
              id="company_wide"
              name="event_visibility"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="company_wide"
            >
              Company Wide
            </label>
          </div>
        </section>
      </section>
    </div>
  </section>
  <div
    className="modal_button_container"
  >
    <button
      className="modal_button"
      onClick={[Function]}
      type="button"
    >
      Save
    </button>
  </div>
</div>
`;
