// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CreateIncomingShipmentAnonymous Component rendering renders correctly 1`] = `
<div
  className="tables-container"
  onKeyDown={[Function]}
  role="button"
  tabIndex={-1}
>
  <div
    className="incoming_shipment_title"
  >
    Package Information
  </div>
  <div
    className="incoming-shipment-container tracking-number"
  >
    <div
      className="incoming-shipment-anonymous"
    >
      <div
        className="anonymous-package-prop"
      >
        <div
          className="input-label incoming_shipment_input-container tracking-number input-label-anonymous"
        >
          Tracking Number
        </div>
        <input
          className="shipment-input input-anonymous"
          id="trackingNumber"
          onChange={[Function]}
          placeholder="Leave empty if unknown"
          value="trn1"
        />
      </div>
      <div
        className="anonymous-package-prop"
      >
        <div
          className="input-label incoming_shipment_input-container tracking-number input-label-anonymous"
        >
          Weight
        </div>
        <input
          className="shipment-input input-anonymous"
          id="weight"
          onChange={[Function]}
          value=""
        />
        <span
          className="input-label-anonymous"
        >
           lbs
        </span>
      </div>
      <div
        className="anonymous-package-prop"
      >
        <div
          className="input-label incoming_shipment_input-container tracking-number input-label-anonymous"
        >
          Zip code
        </div>
        <input
          className="shipment-input input-anonymous"
          id="zipCode"
          onChange={[Function]}
          placeholder="94526"
          value=""
        />
      </div>
      <div
        className="anonymous-package-prop"
      >
        <div
          className="error"
        />
      </div>
    </div>
  </div>
  <button
    className="incoming-shipment_btn blue"
    onClick={[Function]}
    type="button"
  >
    Save
  </button>
</div>
`;
