// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HospitalInformation Component rendering renders correctly 1`] = `
<div
  className="information-container"
>
  <div
    className="full-width-box"
  >
    <div
      className="input-container"
    >
      <div
        className="input-label"
      >
        ID
      </div>
      <input
        disabled={true}
        readOnly={true}
        value="key"
      />
    </div>
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Name
    </div>
    <input
      id="name"
      onChange={[MockFunction]}
      readOnly={false}
      value="st. John"
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Name (short)
    </div>
    <input
      id="short_name"
      onChange={[MockFunction]}
      readOnly={false}
      value=""
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Shipping contact
    </div>
    <select
      className="select_state"
      disabled={false}
      id="shipping_contact"
      onChange={[MockFunction]}
    >
      <option
        disabled={true}
        hidden={true}
      />
    </select>
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Email
    </div>
    <input
      id="email"
      onChange={[MockFunction]}
      readOnly={false}
      value="test@test.com"
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Phone
    </div>
    <input
      id="phone"
      onChange={[MockFunction]}
      readOnly={false}
      value="21333456456"
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Website
    </div>
    <input
      id="website"
      onChange={[MockFunction]}
      readOnly={false}
      value="www.stjohn.com"
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Street 1
    </div>
    <input
      id="street1"
      onChange={[MockFunction]}
      readOnly={false}
      value=""
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Street 1 (short)
    </div>
    <input
      id="street1_short"
      onChange={[MockFunction]}
      readOnly={false}
      value=""
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Street 2
    </div>
    <input
      id="street2"
      onChange={[MockFunction]}
      readOnly={false}
      value=""
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      Street 2 (short)
    </div>
    <input
      id="street2_short"
      onChange={[MockFunction]}
      readOnly={false}
      value=""
    />
  </div>
  <div
    className="input-container"
  >
    <div
      className="input-label"
    >
      City
    </div>
    <input
      id="city"
      onChange={[MockFunction]}
      readOnly={false}
      value=""
    />
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Address Verified
      </div>
      <div
        className="checkbox-cell"
      >
        <input
          disabled={false}
          id="address_verified"
          onChange={[MockFunction]}
          type="checkbox"
        />
        <label
          htmlFor="address_verified"
        />
      </div>
    </section>
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        State
      </div>
      <select
        className="select_state"
        disabled={false}
        id="state"
        onChange={[Function]}
        value=""
      >
        <option
          disabled={true}
          hidden={true}
          value=""
        />
        <option
          value="AL"
        >
          AL - Alabama
        </option>
        <option
          value="AK"
        >
          AK - Alaska
        </option>
        <option
          value="AS"
        >
          AS - American Samoa
        </option>
        <option
          value="AZ"
        >
          AZ - Arizona
        </option>
        <option
          value="AR"
        >
          AR - Arkansas
        </option>
        <option
          value="CA"
        >
          CA - California
        </option>
        <option
          value="CO"
        >
          CO - Colorado
        </option>
        <option
          value="CT"
        >
          CT - Connecticut
        </option>
        <option
          value="DE"
        >
          DE - Delaware
        </option>
        <option
          value="DC"
        >
          DC - District Of Columbia
        </option>
        <option
          value="FM"
        >
          FM - Federated States Of Micronesia
        </option>
        <option
          value="FL"
        >
          FL - Florida
        </option>
        <option
          value="GA"
        >
          GA - Georgia
        </option>
        <option
          value="GU"
        >
          GU - Guam
        </option>
        <option
          value="HI"
        >
          HI - Hawaii
        </option>
        <option
          value="ID"
        >
          ID - Idaho
        </option>
        <option
          value="IL"
        >
          IL - Illinois
        </option>
        <option
          value="IN"
        >
          IN - Indiana
        </option>
        <option
          value="IA"
        >
          IA - Iowa
        </option>
        <option
          value="KS"
        >
          KS - Kansas
        </option>
        <option
          value="KY"
        >
          KY - Kentucky
        </option>
        <option
          value="LA"
        >
          LA - Louisiana
        </option>
        <option
          value="ME"
        >
          ME - Maine
        </option>
        <option
          value="MH"
        >
          MH - Marshall Islands
        </option>
        <option
          value="MD"
        >
          MD - Maryland
        </option>
        <option
          value="MA"
        >
          MA - Massachusetts
        </option>
        <option
          value="MI"
        >
          MI - Michigan
        </option>
        <option
          value="MN"
        >
          MN - Minnesota
        </option>
        <option
          value="MS"
        >
          MS - Mississippi
        </option>
        <option
          value="MO"
        >
          MO - Missouri
        </option>
        <option
          value="MT"
        >
          MT - Montana
        </option>
        <option
          value="NE"
        >
          NE - Nebraska
        </option>
        <option
          value="NV"
        >
          NV - Nevada
        </option>
        <option
          value="NH"
        >
          NH - New Hampshire
        </option>
        <option
          value="NJ"
        >
          NJ - New Jersey
        </option>
        <option
          value="NM"
        >
          NM - New Mexico
        </option>
        <option
          value="NY"
        >
          NY - New York
        </option>
        <option
          value="NC"
        >
          NC - North Carolina
        </option>
        <option
          value="ND"
        >
          ND - North Dakota
        </option>
        <option
          value="MP"
        >
          MP - Northern Mariana Islands
        </option>
        <option
          value="OH"
        >
          OH - Ohio
        </option>
        <option
          value="OK"
        >
          OK - Oklahoma
        </option>
        <option
          value="OR"
        >
          OR - Oregon
        </option>
        <option
          value="PW"
        >
          PW - Palau
        </option>
        <option
          value="PA"
        >
          PA - Pennsylvania
        </option>
        <option
          value="PR"
        >
          PR - Puerto Rico
        </option>
        <option
          value="RI"
        >
          RI - Rhode Island
        </option>
        <option
          value="SC"
        >
          SC - South Carolina
        </option>
        <option
          value="SD"
        >
          SD - South Dakota
        </option>
        <option
          value="TN"
        >
          TN - Tennessee
        </option>
        <option
          value="TX"
        >
          TX - Texas
        </option>
        <option
          value="UT"
        >
          UT - Utah
        </option>
        <option
          value="VT"
        >
          VT - Vermont
        </option>
        <option
          value="VI"
        >
          VI - Virgin Islands
        </option>
        <option
          value="VA"
        >
          VA - Virginia
        </option>
        <option
          value="WA"
        >
          WA - Washington
        </option>
        <option
          value="WV"
        >
          WV - West Virginia
        </option>
        <option
          value="WI"
        >
          WI - Wisconsin
        </option>
        <option
          value="WY"
        >
          WY - Wyoming
        </option>
      </select>
    </section>
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Approved for Shipping
      </div>
      <div
        className="checkbox-cell"
      >
        <input
          disabled={false}
          id="approved_for_shipping"
          onChange={[MockFunction]}
          type="checkbox"
        />
        <label
          htmlFor="approved_for_shipping"
        />
      </div>
    </section>
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Zip Code
      </div>
      <input
        className="short"
        id="zip_code"
        onChange={[MockFunction]}
        readOnly={false}
        value=""
      />
    </section>
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Position in a Queue
      </div>
      <input
        className="short"
        disabled={true}
        id="queue_number"
        readOnly={true}
        value=""
      />
    </section>
  </div>
  <div
    className="input-container-w-100"
  >
    <section>
      <div
        className="input-label"
      >
        Shipment type
      </div>
      <fieldset
        className="MuiFormControl-root-1"
      >
        <div
          aria-label="shipment_type"
          className="MuiFormGroup-root-5"
          role="radiogroup"
        >
          <label
            className="MuiFormControlLabel-root-7"
          >
            <span
              className="MuiButtonBase-root-26 MuiIconButton-root-20 MuiSwitchBase-root-16 MuiRadio-root-11 MuiRadio-colorSecondary-15 MuiSwitchBase-checked-17 MuiRadio-checked-12 radio-color"
              onBlur={[Function]}
              onFocus={[Function]}
              onKeyDown={[Function]}
              onKeyUp={[Function]}
              onMouseDown={[Function]}
              onMouseLeave={[Function]}
              onMouseUp={[Function]}
              onTouchEnd={[Function]}
              onTouchMove={[Function]}
              onTouchStart={[Function]}
              tabIndex={null}
            >
              <span
                className="MuiIconButton-label-25"
              >
                <svg
                  aria-hidden="true"
                  className="MuiSvgIcon-root-29"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                </svg>
                <input
                  checked={true}
                  className="MuiSwitchBase-input-19"
                  disabled={false}
                  name="shipment_type"
                  onChange={[Function]}
                  type="radio"
                  value="by_schedule"
                />
              </span>
              <span
                className="MuiTouchRipple-root-36"
              />
            </span>
            <span
              className="MuiTypography-root-43 MuiTypography-body1-52 MuiFormControlLabel-label-10"
            >
              Specific shipment - select months
            </span>
          </label>
          <label
            className="MuiFormControlLabel-root-7 radio-chose"
          >
            <span
              className="MuiButtonBase-root-26 MuiIconButton-root-20 MuiSwitchBase-root-16 MuiRadio-root-11 MuiRadio-colorSecondary-15 radio-color"
              onBlur={[Function]}
              onFocus={[Function]}
              onKeyDown={[Function]}
              onKeyUp={[Function]}
              onMouseDown={[Function]}
              onMouseLeave={[Function]}
              onMouseUp={[Function]}
              onTouchEnd={[Function]}
              onTouchMove={[Function]}
              onTouchStart={[Function]}
              tabIndex={null}
            >
              <span
                className="MuiIconButton-label-25"
              >
                <svg
                  aria-hidden="true"
                  className="MuiSvgIcon-root-29"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                </svg>
                <input
                  checked={false}
                  className="MuiSwitchBase-input-19"
                  disabled={false}
                  name="shipment_type"
                  onChange={[Function]}
                  type="radio"
                  value="by_normal"
                />
              </span>
              <span
                className="MuiTouchRipple-root-36"
              />
            </span>
            <span
              className="MuiTypography-root-43 MuiTypography-body1-52 MuiFormControlLabel-label-10"
            >
              Normal shipment
            </span>
          </label>
        </div>
      </fieldset>
    </section>
  </div>
  <div
    className="input-container-w-100"
  >
    <section>
      <div
        className="input-label"
      />
      <div
        className="monthes active"
      >
        <button
          className="month-item"
          id="Jan"
          onClick={[MockFunction]}
          type="submit"
        >
          Jan
        </button>
        <button
          className="month-item"
          id="Feb"
          onClick={[MockFunction]}
          type="submit"
        >
          Feb
        </button>
        <button
          className="month-item"
          id="Mar"
          onClick={[MockFunction]}
          type="submit"
        >
          Mar
        </button>
        <button
          className="month-item"
          id="Apr"
          onClick={[MockFunction]}
          type="submit"
        >
          Apr
        </button>
        <button
          className="month-item"
          id="May"
          onClick={[MockFunction]}
          type="submit"
        >
          May
        </button>
        <button
          className="month-item"
          id="Jun"
          onClick={[MockFunction]}
          type="submit"
        >
          Jun
        </button>
        <button
          className="month-item"
          id="Jul"
          onClick={[MockFunction]}
          type="submit"
        >
          Jul
        </button>
        <button
          className="month-item"
          id="Aug"
          onClick={[MockFunction]}
          type="submit"
        >
          Aug
        </button>
        <button
          className="month-item"
          id="Sep"
          onClick={[MockFunction]}
          type="submit"
        >
          Sep
        </button>
        <button
          className="month-item"
          id="Oct"
          onClick={[MockFunction]}
          type="submit"
        >
          Oct
        </button>
        <button
          className="month-item"
          id="Nov"
          onClick={[MockFunction]}
          type="submit"
        >
          Nov
        </button>
        <button
          className="month-item"
          id="Dec"
          onClick={[MockFunction]}
          type="submit"
        >
          Dec
        </button>
      </div>
    </section>
  </div>
  <div
    className="update-buttons--container end"
  >
    <button
      className="cancel"
      onClick={[MockFunction]}
      type="button"
    >
      Cancel
    </button>
    <button
      className="save"
      onClick={[MockFunction]}
      type="button"
    >
      Save
    </button>
  </div>
</div>
`;
