import React from 'react';
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const AddressAutocomplete = (props) => {
  const {
    ready, value, suggestions: { status, data }, setValue, clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us'] },
    },
  });

  const {
    classes,
    errors,
    setCity,
    setStateCode,
    setZipCode,
    setStreetAddress,
    streetAddress,
  } = props;

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
    setStreetAddress(e.target.value);
  };

  const handleSelect = params => () => {
    setValue(params.mainText, false);
    setStreetAddress(params.mainText, false);
    clearSuggestions();

    getGeocode({ address: params.description, componentRestrictions: { country: 'us' } })
      .then((results) => {
        setCity(results[0].address_components.find(component => component.types.includes('locality') && component.types.includes('political')).long_name);
        setStateCode(results[0].address_components.find(component => component.types.includes('administrative_area_level_1')).short_name);
        return getZipCode(results[0], false);
      })
      .then((zipCode) => {
        setZipCode(zipCode);
      })
      .catch(() => {
        setCity('');
        setStateCode('');
        setZipCode('');
      });
  };

  const renderSuggestions = () => data.map((suggestion) => {
    const {
      place_id: placeId,
      structured_formatting: { main_text: mainText, secondary_text: secondaryText },
    } = suggestion;

    return (
      <li className="address-prediction" key={placeId} onClick={handleSelect({ description: suggestion.description, mainText })} onKeyDown={handleSelect(suggestion)} role="presentation">
        <strong>{mainText}</strong>
        <span>&nbsp;&nbsp;</span>
        <small>{secondaryText}</small>
      </li>
    );
  });

  return (
    <div ref={ref}>
      <TextField
        id="address_line1"
        type="text"
        name="address_line1"
        classes={{
          root: classes.input,
        }}
        value={value || streetAddress}
        onChange={handleInput}
        error={Boolean(errors.address_line1)}
        helperText={errors.address_line1}
        FormHelperTextProps={{ className: 'errors' }}
        InputProps={{
          disableUnderline: true,
          classes: { input: classes.input1 },
        }}
        disabled={!ready}
      />

      {status === 'OK' && <ul className="address-prediction-container">{renderSuggestions()}</ul>}
    </div>
  );
};

AddressAutocomplete.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setCity: PropTypes.func.isRequired,
  setStateCode: PropTypes.func.isRequired,
  setZipCode: PropTypes.func.isRequired,
  setStreetAddress: PropTypes.func.isRequired,
};

export default AddressAutocomplete;
