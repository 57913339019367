// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AdminLogModal Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title"
  >
    title
  </div>
  <div
    className="add-fields"
  >
    <div
      className="input-container"
    >
      <section>
        <div
          className="input-label admin-log"
        >
          User
        </div>
        <input
          disabled={true}
        />
      </section>
    </div>
    <div
      className="input-container"
    >
      <section>
        <div
          className="input-label admin-log"
        >
          Object
        </div>
        <input
          disabled={true}
          value="type"
        />
      </section>
    </div>
    <div
      className="input-container"
    >
      <section>
        <div
          className="input-label admin-log"
        >
          Content
        </div>
        <textarea
          disabled={true}
          id="content-area"
          value="Triggered by undefined 

undefined"
        />
      </section>
    </div>
    <div
      className="input-container"
    >
      <section>
        <div
          className="input-label admin-log"
        >
          Date
        </div>
        <input
          disabled={true}
          size="30"
          value="1/1/2021 12:00:00 AM"
        />
      </section>
    </div>
  </div>
  <div
    className="button_container_ok"
  >
    <button
      className="incoming-shipments-modal-btn yes"
      onClick={[MockFunction]}
      type="button"
    >
      Ok
    </button>
  </div>
</div>
`;
