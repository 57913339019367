import React from 'react';
import PropTypes from 'prop-types';
import API from '../../services/api';
import SelectHospitals from './SelectHospitals';
import ConfirmHospitalShipment from './ConfirmHospitalShipment';
import sortHelper from '../../services/sortHelper';
import SelectHospitalsByCount from './SelectHospitalsByCount';
import { isSuperAdmin } from '../../services/permissions';

class HospitalShipmentsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shipment: {
        selectedHospitals: props.selectedHospitals,
        package: [{
          length: 19,
          width: 17,
          height: 11,
          weight: 35,
        }],
      },
      error: '',
      labels: [],
      status: '',
      confirm: props.confirm,
      numberOfCases: props.numberOfCases,
      hospitals: [],
      hospitalSortParams: {
        column: 'last_shipment',
        type: 'asc',
      },
      filter: {
        verified: true,
        approved: true,
      },
      changeSelection: false,
    };

    this.handleSort = this.handleSort.bind(this);
    this.loadHospitals = this.loadHospitals.bind(this);
    this.handleHospitalClick = this.handleHospitalClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.closeConfirmation = this.closeConfirmation.bind(this);
  }

  componentDidMount() {
    this.loadHospitals();
  }

  handleHospitalSelect = (value) => {
    const { shipment } = this.state;
    const sh = shipment.selectedHospitals.find(h => h.id === value.id);
    if (sh) {
      shipment.selectedHospitals.splice(shipment.selectedHospitals.indexOf(sh), 1);
    } else {
      shipment.selectedHospitals = [...shipment.selectedHospitals, value];
    }
    this.setState({ shipment });
  };

  handleHospitalSelectByCount = (value) => {
    const { shipment, hospitals } = this.state;
    const { user } = this.props;
    shipment.selectedHospitals = hospitals.slice(0, value);

    if (isSuperAdmin(user)) this.setState({ shipment, changeSelection: true });
    else this.setState({ shipment, confirm: true });
  };

  handleSubmit = () => {
    const { shipment } = this.state;
    this.setState({ status: 'loading' });
    API.hospitalShipment(shipment).then((res) => {
      this.loadHospitals();
      this.setState({
        labels: res.labels,
        error: '',
        status: 'success',
        numberOfCases: res.numberOfCases,
      });
    }).catch(res => this.setState({ error: res.errors[0].message, status: '' }));
  };

  handleFilter = (e) => {
    const { id, checked } = e.target;
    const { filter } = this.state;

    filter[id] = checked;

    this.setState({ filter }, () => this.loadHospitals());
  };

  renderSelect = () => {
    const {
      shipment, hospitals, hospitalSortParams, filter, changeSelection,
    } = this.state;

    return changeSelection ? (
      <SelectHospitals
        objects={hospitals}
        sortParams={hospitalSortParams}
        handleHospitalSelect={this.handleHospitalSelect}
        handlePrepare={() => this.setState({ confirm: true })}
        handleSort={this.handleSort}
        filter={this.handleFilter}
        filterParams={filter}
        clickHospital={this.handleHospitalClick}
        selectedHospitals={shipment.selectedHospitals}
      />
    ) : <SelectHospitalsByCount handleSelect={this.handleHospitalSelectByCount} />;
  };

  handleHospitalClick(id) {
    window.open(`/hospitals/${id}`);
  }

  handleSort(column) {
    const { hospitalSortParams } = this.state;
    this.setState({ hospitalSortParams: sortHelper(column, hospitalSortParams) }, () => this.loadHospitals());
  }

  loadHospitals() {
    const { hospitalSortParams, filter } = this.state;
    if (hospitalSortParams.column === 'address') return;
    API.loadAllHospitals(hospitalSortParams, filter).then((res) => {
      this.setState({ hospitals: res.hospitals });
    });
  }

  closeConfirmation() {
    const { shipment } = this.state;
    shipment.selectedHospitals = [];
    this.setState({
      confirm: false,
      shipment,
      error: '',
      labels: [],
      status: '',
    });
  }

  handleBack() {
    this.setState({
      confirm: false,
      error: '',
      labels: [],
      status: '',
    });
  }

  render() {
    const {
      shipment, confirm, error, labels, status, numberOfCases,
    } = this.state;
    return (
      <div className="detail-container hospital_shipment">
        <div className="detail-title">Hospital Shipments</div>
        <div className="detail-subtitle">{`Number of cases ready for shipment: ${numberOfCases}`}</div>
        <div className="tables-container">
          <div className="hospital-shipment-container">
            {confirm
              ? (
                <ConfirmHospitalShipment
                  labels={labels}
                  status={status}
                  error={error}
                  handleConfirm={this.handleSubmit}
                  handleClose={this.closeConfirmation}
                  handleBack={this.handleBack}
                  objects={shipment.selectedHospitals}
                />
              )
              : this.renderSelect()
            }
          </div>
        </div>
      </div>
    );
  }
}

HospitalShipmentsIndex.propTypes = {
  selectedHospitals: PropTypes.instanceOf(Array).isRequired,
  confirm: PropTypes.bool.isRequired,
  numberOfCases: PropTypes.number.isRequired,
  user: PropTypes.string.isRequired,
};

export default HospitalShipmentsIndex;
