import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import { ShippingFormComponent as ShippingForm } from '../ShippingForm';

const mockCreateToken = jest.fn(() => Promise.resolve({
  token: {
    id: 'token222',
    card: {},
  },
}));

describe('ShippingForm Component', () => {
  let wrapper;
  let defaultProps;

  beforeEach(() => {
    defaultProps = {
      classes: {},
      stripe: {
        createToken: mockCreateToken,
      },
      onReview: jest.fn(),
      getQuote: jest.fn(),
      shippingData: {
        shipping_address: {
          company_name: 'Test Company',
          contact: 'Test Contact',
          address_line1: '300 Riverside Dr.',
          address_line2: 'apt. 600',
          city: 'Austin',
          phone: '1657564665',
          email: 'tester@test.com',
          state: 'TX',
          zip_code: '55293',
          country: 'US',
        },
        billing_address: {
          billing_company_name: 'Test Billing Company',
          billing_contact: 'Test Billing Contact',
          billing_address_line1: '351 Billing Rd.',
          billing_address_line2: 'apt. 732',
          billing_city: 'Hawkins',
          billing_phone: '+12222222222',
          billing_email: 'billing@tester.com',
          billing_state: 'IN',
          billing_zip_code: '72495',
          billing_country: 'US',
        },
        pickup_address: {
          pickup_first_name: 'John',
          pickup_last_name: 'Doe',
          pickup_company_name: 'Test Pickup Company',
          pickup_address_line1: '351 Pickup Rd.',
          pickup_address_line2: 'apt. 732',
          pickup_city: 'Hawkins',
          pickup_phone: '+12222222222',
          pickup_email: 'pickup@tester.com',
          pickup_state: 'IN',
          pickup_zip_code: '72495',
          pickup_country: 'US',
        },
        pickup_date_time: {
          pickup_date: '12345612',
          close_time: '2100',
          ready_time: '2000',
        },
        token: '',
        numberOfPackages: 1,
        packages: [{
          length: '12',
          weight: '12',
          height: '12',
          width: '12',
          not_weight: false,
        }],
        useMyShipmentAddress: true,
        card_name: 'Tester',
        card: {},
      },
      status: '',
      quoteStatus: '',
      errors: {},
      error: '',
      paymentForm: jest.fn(() => ({
        build: jest.fn(),
        requestCardNonce: jest.fn(),
      })),
      appId: 'app-123213123123123',
    };
    wrapper = mount(<ShippingForm {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ShippingForm {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders ship from section', () => {
      wrapper.setState({ individual: false });
      expect(wrapper.find('TextField#company_name').prop('value')).toEqual('Test Company');
      // expect(wrapper.find('TextField#address_line1').prop('value')).toEqual('300 Riverside Dr.');
      expect(wrapper.find('TextField#address_line2').prop('value')).toEqual('apt. 600');
      expect(wrapper.find('TextField#city').prop('value')).toEqual('Austin');
      expect(wrapper.find('StateSelect#state').prop('value')).toEqual('TX');
      expect(wrapper.find('TextField#zip_code').prop('value')).toEqual('55293');
      expect(wrapper.find('input#country')).toHaveLength(1);
      expect(wrapper.find('TextField#phone').prop('value')).toEqual('1657564665');
      expect(wrapper.find('TextField#email').prop('value')).toEqual('tester@test.com');
    });

    describe('billing address section', () => {
      it('hides inputs with useMyShipmentAddress = true', () => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, useMyShipmentAddress: true } });
        expect(wrapper.find('input#my_shipping').prop('checked')).toBeTruthy();
        expect(wrapper.find('input#billing_company_name')).toHaveLength(0);
        expect(wrapper.find('input#billing_address_line1')).toHaveLength(0);
        expect(wrapper.find('input#billing_address_line2')).toHaveLength(0);
        expect(wrapper.find('input#billing_city')).toHaveLength(0);
        expect(wrapper.find('StateSelect#billing_state')).toHaveLength(0);
        expect(wrapper.find('TextField#billing_zip_code')).toHaveLength(0);
        expect(wrapper.find('input#billing_country')).toHaveLength(0);
        expect(wrapper.find('TextField#billing_phone')).toHaveLength(0);
        expect(wrapper.find('TextField#billing_email')).toHaveLength(0);
      });

      it('shows inputs with useMyShipmentAddress = false', () => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, useMyShipmentAddress: false }, individual: false });
        expect(wrapper.find('input#my_shipping').prop('checked')).toBeFalsy();
        expect(wrapper.find('input#billing_company_name').prop('value')).toEqual('Test Billing Company');
        expect(wrapper.find('input#billing_address_line1').prop('value')).toEqual('351 Billing Rd.');
        expect(wrapper.find('input#billing_address_line2').prop('value')).toEqual('apt. 732');
        expect(wrapper.find('input#billing_city').prop('value')).toEqual('Hawkins');
        expect(wrapper.find('StateSelect#billing_state').prop('value')).toEqual('IN');
        expect(wrapper.find('TextField#billing_zip_code').prop('value')).toEqual('72495');
        expect(wrapper.find('input#billing_country')).toHaveLength(1);
        expect(wrapper.find('TextField#billing_phone').prop('value')).toEqual('+12222222222');
        expect(wrapper.find('TextField#billing_email').prop('value')).toEqual('billing@tester.com');
      });
    });

    it('renders packages section', () => {
      const packages = [{
        length: '11',
        width: '12',
        height: '13',
        weight: '14',
        not_weight: false,
      }, {
        length: '21',
        width: '22',
        height: '23',
        weight: '',
        not_weight: true,
      }];

      wrapper.setState({
        shippingData: { ...defaultProps.shippingData, packages, numberOfPackages: packages.length },
      });

      expect(wrapper.find('select#package_number')).toHaveLength(1);

      expect(wrapper.find('input#length1').prop('value')).toEqual('11');
      expect(wrapper.find('input#width1').prop('value')).toEqual('12');
      expect(wrapper.find('input#height1').prop('value')).toEqual('13');
      expect(wrapper.find('input#weight1').prop('value')).toEqual('14');
      expect(wrapper.find('input#weight_check1').prop('checked')).toBeFalsy();

      expect(wrapper.find('input#length2').prop('value')).toEqual('21');
      expect(wrapper.find('input#width2').prop('value')).toEqual('22');
      expect(wrapper.find('input#height2').prop('value')).toEqual('23');
      expect(wrapper.find('input#weight2').prop('value')).toEqual('');
      expect(wrapper.find('input#weight_check2').prop('checked')).toBeTruthy();
    });

    describe('submit button', () => {
      it('has label "Review" with status = ""', () => {
        wrapper.setProps({ status: '' });
        expect(wrapper.find('button#review-button').text()).toEqual('Review');
      });

      it('has label "Processing..." with status = "loading"', () => {
        wrapper.setProps({ status: 'loading' });
        expect(wrapper.find('button#review-button').text()).toEqual('Processing...');
      });

      it('has label "Reviewed" with status = "success"', () => {
        wrapper.setProps({ status: 'success' });
        expect(wrapper.find('button#review-button').text()).toEqual('Reviewed');
      });
    });
  });

  describe('validation', () => {
    beforeEach(() => {
      wrapper.setState({ individual: false });
    });

    it('validates company_name', () => {
      wrapper.find('TextField#company_name input').simulate('change', { target: { id: 'company_name', value: '' } });
      expect(wrapper.find('TextField#company_name').text()).toContain('This field is required');
    });

    // it('validates address_line1', () => {
    //   wrapper.find('TextField#address_line1 input').simulate('change', { target: { id: 'address_line1', value: '' } });
    //   expect(wrapper.find('TextField#address_line1').text()).toContain('This field is required');
    // });

    it('validates address_line2', () => {
      wrapper.find('TextField#address_line2 input').simulate('change', { target: { id: 'address_line2', value: '' } });
      expect(wrapper.find('TextField#address_line2').text()).toContain('');
    });

    it('validates city', () => {
      wrapper.find('TextField#city input').simulate('change', { target: { id: 'city', value: '' } });
      expect(wrapper.find('TextField#city').text()).toContain('This field is required');
    });

    it('validates zip_code', () => {
      wrapper.find('TextField#zip_code input').simulate('change', { target: { id: 'zip_code', value: '' } });
      expect(wrapper.find('TextField#zip_code').text()).toContain('Zip code is invalid');
    });

    it('validates phone', () => {
      wrapper.find('TextField#phone input').simulate('change', { target: { id: 'phone', value: '' } });
      expect(wrapper.find('TextField#phone').text()).toContain('');
    });

    it('validates email', () => {
      wrapper.find('TextField#email input').simulate('change', { target: { id: 'email', value: '' } });
      expect(wrapper.find('TextField#email').text()).toContain('Email is invalid');
    });

    it('validates card_name', () => {
      wrapper.find('TextField#card_name input').simulate('change', { target: { id: 'card_name', value: '' } });
      expect(wrapper.find('TextField#card_name').text()).toContain('This field is required');
    });

    describe('validates with useMyShipmentAddress = false', () => {
      beforeEach(() => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, useMyShipmentAddress: false } });
      });

      it('validates billing_company_name', () => {
        wrapper.find('TextField#billing_company_name input').simulate('change', { target: { id: 'billing_company_name', value: '' } });
        expect(wrapper.find('TextField#billing_company_name').text()).toContain('This field is required');
      });

      it('validates billing_address_line1', () => {
        wrapper.find('TextField#billing_address_line1 input').simulate('change', { target: { id: 'billing_address_line1', value: '' } });
        expect(wrapper.find('TextField#billing_address_line1').text()).toContain('This field is required');
      });

      it('validates billing_address_line2', () => {
        wrapper.find('TextField#billing_address_line2 input').simulate('change', { target: { id: 'billing_address_line2', value: '' } });
        expect(wrapper.find('TextField#billing_address_line2').text()).toContain('');
      });

      it('validates billing_city', () => {
        wrapper.find('TextField#billing_city input').simulate('change', { target: { id: 'billing_city', value: '' } });
        expect(wrapper.find('TextField#billing_city').text()).toContain('This field is required');
      });

      it('validates billing_zip_code', () => {
        wrapper.find('TextField#billing_zip_code input').simulate('change', { target: { id: 'billing_zip_code', value: '' } });
        expect(wrapper.find('TextField#billing_zip_code').text()).toContain('Zip code is invalid');
      });

      it('validates billing_phone', () => {
        wrapper.find('TextField#billing_phone input').simulate('change', { target: { id: 'billing_phone', value: '' } });
        expect(wrapper.find('TextField#billing_phone').text()).toContain('');
      });

      it('validates billing_email', () => {
        wrapper.find('TextField#billing_email input').simulate('change', { target: { id: 'billing_email', value: '' } });
        expect(wrapper.find('TextField#billing_email').text()).toContain('Email is invalid');
      });
    });
  });

  // describe('click Review button', () => {
  //   it('onReview called', (cb) => {
  //     wrapper.find('button#review-button').simulate('click');
  //
  //     process.nextTick(() => { expect(defaultProps.paymentForm.requestCardNonce).toHaveBeenCalled(); cb(); });
  //   });
  // });
});
