// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ConfirmHospitalShipment Component rendering renders correctly 1`] = `
Array [
  <div
    className="shipment-package-container false"
  >
    <section
      style={
        Object {
          "marginBottom": "3rem",
        }
      }
    >
      <div
        className="package-header"
      >
        <div
          className="header"
        >
          1. name 1
        </div>
        <section>
          <div
            className="download-link"
          >
            <button
              className="print"
              onClick={[Function]}
              type="button"
            >
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 img"
              >
                file_download
              </span>
            </button>
          </div>
          <div
            className="download-link"
          >
            <button
              className="print"
              disabled={false}
              onClick={[Function]}
              type="button"
            >
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 img"
              >
                print
              </span>
            </button>
          </div>
        </section>
      </div>
      <span>
        addr 1, City 1, undefined undefined
      </span>
    </section>
    <section
      style={
        Object {
          "marginBottom": "3rem",
        }
      }
    >
      <div
        className="package-header"
      >
        <div
          className="header"
        >
          2. name 2
        </div>
        <section>
          <div
            className="download-link"
          >
            <button
              className="print"
              onClick={[Function]}
              type="button"
            >
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 img"
              >
                file_download
              </span>
            </button>
          </div>
          <div
            className="download-link"
          >
            <button
              className="print"
              disabled={false}
              onClick={[Function]}
              type="button"
            >
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 img"
              >
                print
              </span>
            </button>
          </div>
        </section>
      </div>
      <span>
        addr 2, City 2, undefined undefined
      </span>
    </section>
    <section
      style={
        Object {
          "marginBottom": "3rem",
        }
      }
    >
      <div
        className="package-header"
      >
        <div
          className="header"
        >
          3. name 3
        </div>
        <section>
          <div
            className="download-link"
          >
            <button
              className="print"
              onClick={[Function]}
              type="button"
            >
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 img"
              >
                file_download
              </span>
            </button>
          </div>
          <div
            className="download-link"
          >
            <button
              className="print"
              disabled={false}
              onClick={[Function]}
              type="button"
            >
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 img"
              >
                print
              </span>
            </button>
          </div>
        </section>
      </div>
      <span>
        addr 3, City 3, undefined undefined
      </span>
    </section>
  </div>,
  "",
  <div
    className="button-container"
  >
    <button
      className="prepare-button back"
      onClick={[MockFunction]}
      type="button"
    >
      Back
    </button>
    <button
      className="prepare-button"
      disabled={false}
      onClick={[MockFunction]}
      type="button"
    >
      Confirm
    </button>
  </div>,
  <div
    className="download-link all"
  >
    <span
      aria-hidden="true"
      className="material-icons MuiIcon-root-1 img"
    >
      print
    </span>
    <button
      className="print"
      disabled={false}
      onClick={[Function]}
      type="button"
    >
      Print all labels
    </button>
  </div>,
]
`;
