import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import HospitalsShow from '../HospitalsShow';

jest.mock('../../../services/api', () => ({
  loadHospitalContacts: jest.fn(() => Promise.resolve({
    contacts: [{
      id: '1',
      first_name: 'John',
      last_name: 'Wick',
      title: 'Director',
      email: 'once@gmail.com',
      phone: '437567563243523',
    }],
  })),

  createHospitalContact: jest.fn(() => Promise.resolve({
    contacts: [{
      id: '1',
      first_name: 'John',
      last_name: 'Wick',
      title: 'Director',
      email: 'once@gmail.com',
      phone: '437567563243523',
    },
    {
      id: '2',
      first_name: 'Terry',
      last_name: 'Wick',
      title: 'Doctor',
      email: 'fake@gmail.com',
      phone: '437567563243533',
    }],
  })),

  updateHospitalContact: jest.fn(() => Promise.resolve({
    contacts: [{
      id: '1',
      first_name: 'John',
      last_name: 'Wick',
      title: 'Director',
      email: 'once@gmail.com',
      phone: '437567563243523',
    },
    {
      id: '2',
      first_name: 'Terry-New',
      last_name: 'Wick',
      title: 'Doctor',
      email: 'fake@gmail.com',
      phone: '437567563243533',
    }],
  })),

  deleteHospitalContact: jest.fn(() => Promise.resolve({
    contacts: [{
      id: '2',
      first_name: 'Terry-New',
      last_name: 'Wick',
      title: 'Doctor',
      email: 'fake@gmail.com',
      phone: '437567563243533',
    }],
  })),

  updateHospital: jest.fn(() => Promise.resolve({
    hospital: {
      id: '1',
      first_name: 'John-New',
      last_name: 'Wick',
      title: 'Director',
      email: 'once@gmail.com',
      phone: '437567563243523',
      shipment_type: {
        shipment_schedule_type: 'by_normal',
      },
    },
  })),

  deleteHospital: jest.fn(() => Promise.resolve({
    ok: true,
  })),

  loadHospitalOrders: jest.fn(() => Promise.resolve({
    orders: [{
      id: '1',
      order_key: 'key',
      date: '2019-11-15T14:57:50.650488',
      number_of_boxes: '100',
      tracking_number: '1F234546256451000',
      status: 'Processing',
    }],
  })),

  loadHospitalNotes: jest.fn(() => Promise.resolve({
    notes: [{
      id: '1',
      message: 'Add new address',
      date: '2021-03-22T14:57:50.650488',
      user_full_name: 'Jacke Bee',
      hospital_id: '1',
    }],
  })),

  createHospitalNotes: jest.fn(() => Promise.resolve({
    notes: [{
      id: '1',
      message: 'Add new address',
      date: '2021-03-22T14:57:50.650488',
      user_full_name: 'Jacke Bee',
      hospital_id: '1',
    },
    {
      id: '2',
      message: 'Add new shipment type',
      date: '2021-04-22T14:57:50.650488',
      user_full_name: 'Tony Adams',
      hospital_id: '2',
    }],
  })),

  updateHospitalNotes: jest.fn(() => Promise.resolve({
    notes: [{
      id: '1',
      message: 'Add new address',
      date: '2021-03-22T14:57:50.650488',
      user_full_name: 'Jacke Bee',
      hospital_id: '1',
    },
    {
      id: '2',
      message: 'Add new shipment type New',
      date: '2021-04-22T14:57:50.650488',
      user_full_name: 'Tony Adams',
      hospital_id: '2',
    }],
  })),

  deleteHospitalNotes: jest.fn(() => Promise.resolve({
    notes: [{
      id: '2',
      message: 'Add new shipment type New',
      date: '2021-04-22T14:57:50.650488',
      user_full_name: 'Tony Adams',
      hospital_id: '2',
    }],
  })),
}));

describe('HospitalsShow Component', () => {
  let wrapper;
  const defaultProps = {
    hospital: {
      id: '1',
      hospital_key: 'key',
      name: 'St. John Wick',
      address: 'address',
      number_of_contacts: '1',
      shipment_type: {
        shipment_schedule_type: 'by_normal',
      },
    },
    contacts: [{
      id: '1',
      first_name: 'John',
      last_name: 'Wick',
      title: 'Director',
      email: 'once@gmail.com',
      phone: '437567563243523',
    }],
    hospitalInQueue: '',
    selectedNoteObject: {},
  };

  beforeEach(() => {
    wrapper = mount(<HospitalsShow {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalsShow {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(3);
      expect(wrapper.find('tr')).toHaveLength(6);
    });

    it('renders titles', () => {
      expect(wrapper.text()).toMatch('Information');
      expect(wrapper.text()).toMatch('Shipments');
      expect(wrapper.text()).toMatch('Contacts(1)');
      expect(wrapper.text()).toMatch('Notes');
    });

    it('renders titles', () => {
      expect(wrapper.find('input').at(1).props().value).toEqual(defaultProps.hospital.name);
      expect(wrapper.text()).toMatch('1F234546256451000');
      expect(wrapper.text()).toMatch('Add new address');
    });
  });

  describe('events', () => {
    const noteObj = {
      id: '1',
      message: 'Add new address',
      date: '2021-03-22T14:57:50.650488',
      user_full_name: 'Jacke Bee',
      hospital_id: '1',
    };

    const input = { message: 'Add new address' };

    it('handleCreateNewShipment', () => {
      global.open = jest.fn();
      wrapper.instance().handleCreateNewShipment();
      expect(global.open).toBeCalled();
    });

    it('handleEdit', () => {
      wrapper.instance().handleEdit();
      expect(wrapper.state().update).toEqual(true);
    });

    it('handleDeleteHospital', () => {
      wrapper.instance().handleDeleteHospital();
      process.nextTick(() => {
        expect(wrapper.state().showModalDeleteHospital).toEqual(false);
      });
    });

    it('handleCancel', () => {
      wrapper.instance().handleCancel();
      expect(wrapper.state().update).toEqual(false);
    });

    it('handleSave', () => {
      wrapper.instance().handleSave();
      expect(wrapper.state().update).toEqual(false);
    });

    it('handleCreateNew', () => {
      wrapper.instance().handleCreateNew();
      expect(wrapper.state().showModal).toEqual(true);
    });

    it('handleCreateNote', () => {
      wrapper.instance().handleCreateNote();
      expect(wrapper.state().showCreateNoteModal).toEqual(true);
    });

    it('handleCloseModal', () => {
      wrapper.instance().handleCloseModal();
      expect(wrapper.state().showModal).toEqual(false);
    });

    it('handleCloseNoteModal', () => {
      wrapper.instance().handleCloseNoteModal();
      expect(wrapper.state().showCreateNoteModal).toEqual(false);
    });

    it('handleCloseEditModal', () => {
      wrapper.instance().handleCloseEditModal();
      expect(wrapper.state().showEditContactModal).toEqual(false);
    });

    it('handleCloseEditNoteModal', () => {
      wrapper.instance().handleCloseEditNoteModal();
      expect(wrapper.state().showEditNoteModal).toEqual(false);
    });

    it('handleCloseDeleteModal', () => {
      wrapper.instance().handleCloseDeleteModal();
      expect(wrapper.state().showDeleteModal).toEqual(false);
    });

    it('handleCloseDeleteNoteModal', () => {
      wrapper.instance().handleCloseDeleteNoteModal();
      expect(wrapper.state().showDeleteNoteModal).toEqual(false);
    });

    it('handleSaveNewHospitalContact', () => {
      wrapper.instance().handleSaveNewHospitalContact();
      process.nextTick(() => { expect(wrapper.state().showModal).toEqual(false); });
    });

    it('handleSaveNewNote', () => {
      wrapper.instance().handleSaveNewNote(input);
      process.nextTick(() => { expect(wrapper.state().showCreateNoteModal).toEqual(false); });
    });

    it('handleUpdateHospitalContact', () => {
      wrapper.instance().handleUpdateHospitalContact();
      process.nextTick(() => { expect(wrapper.state().showEditContactModal).toEqual(false); });
    });

    it('handleEditNote', () => {
      wrapper.instance().handleEditNote(input);
      process.nextTick(() => { expect(wrapper.state().showEditNoteModal).toEqual(false); });
    });

    it('handleDeleteHospitalContact', () => {
      wrapper.instance().handleDeleteHospitalContact();
      process.nextTick(() => {
        expect(wrapper.state().showDeleteModal).toEqual(false);
      });
    });

    it('handleDeleteHospitalNote', () => {
      wrapper.instance().handleDeleteHospitalNote();
      process.nextTick(() => {
        expect(wrapper.state().showDeleteNoteModal).toEqual(false);
      });
    });

    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleNotePageChange', (cb) => {
      wrapper.instance().handleNotePageChange();
      process.nextTick(() => { expect(wrapper.state('currentNotePage')).toEqual(2); cb(); });
    });

    it('handleSortOrders', (cb) => {
      wrapper.instance().handleSortOrders('col');
      process.nextTick(() => { expect(wrapper.state('hospitalOrdersSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handleSortContacts', (cb) => {
      wrapper.instance().handleSortContacts('col');
      process.nextTick(() => { expect(wrapper.state('hospitalContactsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handleSortNotes', (cb) => {
      wrapper.instance().handleSortNotes('col');
      process.nextTick(() => { expect(wrapper.state('hospitalNotesSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('loadContacts', () => {
      wrapper.instance().loadContacts();
      expect(wrapper.state().contacts).toEqual([{
        id: '1',
        first_name: 'John',
        last_name: 'Wick',
        title: 'Director',
        email: 'once@gmail.com',
        phone: '437567563243523',
      }]);
    });

    it('loadNotes', () => {
      wrapper.instance().loadNotes();
      expect(wrapper.state().notes).toEqual([{
        id: '1',
        message: 'Add new address',
        date: '2021-03-22T14:57:50.650488',
        user_full_name: 'Jacke Bee',
        hospital_id: '1',
      }]);
    });

    it('loadPage', () => {
      wrapper.instance().loadPage();
      expect(wrapper.state().orders).toEqual([{
        id: '1',
        order_key: 'key',
        date: '2019-11-15T14:57:50.650488',
        number_of_boxes: '100',
        tracking_number: '1F234546256451000',
        status: 'Processing',
      }]);
    });

    it('handleChange', () => {
      wrapper.instance().handleEdit();
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(wrapper.state().hospital.email).toEqual('test1@test.com');
    });

    it('handleChange street address', () => {
      wrapper.instance().handleEdit();
      wrapper.find('input#street1').simulate('change', { target: { id: 'street1', value: '123 Park Ave' } });
      expect(wrapper.state().hospital.street1).toEqual('123 Park Ave');
    });

    it('handleRadioChange', () => { });

    it('handleOpenEditContactModal', () => {
      wrapper.instance().handleOpenEditContactModal();
      expect(wrapper.state().showEditContactModal).toEqual(true);
    });

    it('handleOpenEditNoteModal', () => {
      wrapper.instance().handleOpenEditNoteModal(noteObj);
      expect(wrapper.state().showEditNoteModal).toEqual(true);
    });

    it('handleOpenDeleteModal', () => {
      wrapper.instance().handleOpenDeleteModal();
      expect(wrapper.state().showDeleteModal).toEqual(true);
    });

    it('handleOpenDeleteNoteModal', () => {
      wrapper.instance().handleOpenDeleteNoteModal(noteObj);
      expect(wrapper.state().showDeleteNoteModal).toEqual(true);
    });

    // it('handleMonthChoose', () => {
    //   wrapper.instance().handleEdit();
    //   wrapper.find('input[name$="shipment_type"]').simulate('change', { target: { value: 'by_schedule' } });
    //   expect(wrapper.instance().handleMonthChoose()).toBeCalled();
    // });

    it('handleCloseModalDeleteHospital', () => {
      wrapper.instance().handleCloseModalDeleteHospital();
      expect(wrapper.state().showModalDeleteHospital).toEqual(false);
    });

    it('handleOpenModalDeleteHospital', () => {
      wrapper.instance().handleOpenModalDeleteHospital();
      expect(wrapper.state().showModalDeleteHospital).toEqual(true);
    });
  });
});
