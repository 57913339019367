import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../ui/CloseButton';

class NoteModify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: {
        message: props.message,
      },
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  hasErrors = () => {
    const { errors } = this.state;
    return Object.values(errors).some(item => item);
  };

  handleSubmit() {
    const { handleSave } = this.props;
    const { inputs } = this.state;
    Object.keys(inputs).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;
    handleSave(inputs);
  }

  validateField(fieldName) {
    const { errors, inputs } = this.state;
    const value = inputs[fieldName];
    let valid;

    switch (fieldName) {
      case 'message':
        valid = !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  handleChange(e) {
    const { inputs } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    inputs[name] = value;
    this.setState({ inputs }, () => { this.validateField(name); });
  }

  render() {
    const { inputs, errors } = this.state;
    const { handleClose, title, buttonTitle } = this.props;
    return (
      <div className="modal_body detail">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="title">{title}</div>
        <div className="input-container no_error">
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '10px', fontWeight: '800' }} className="input-label">Note</div>
            <textarea style={{ height: '171px', width: '100%' }} id="message" value={inputs.message} onChange={this.handleChange} />
          </section>
          <div className="errors">{errors.message && errors.message}</div>
        </div>
        <div className="button_container create-note-modal-btn-container">
          <button type="submit" className="create-note-modal-btn" onClick={this.handleSubmit}>{buttonTitle}</button>
        </div>
      </div>
    );
  }
}

NoteModify.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  message: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

NoteModify.defaultProps = {
  message: '',
};

export default NoteModify;
