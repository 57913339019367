import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../ui/CloseButton';

const CreateShipmentModal = ({ create, handleClose }) => (
  <div className="modal_body incoming-shipments">
    <div className="modal_button_container top">
      <CloseButton close={handleClose} />
    </div>
    <div className="title">Record not found</div>
    <div className="sub-title">Do you want to create a new entry manually?</div>
    <div className="button_container">
      <button type="button" className="incoming-shipments-modal-btn no" onClick={handleClose}>No</button>
      <button type="button" className="incoming-shipments-modal-btn yes" onClick={create}>Yes</button>
    </div>
  </div>
);

CreateShipmentModal.propTypes = {
  create: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CreateShipmentModal;
