import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import HospitalShipmentType from '../HospitalShipmentType';

describe('HospitalShipmentType Component', () => {
  let wrapper;
  let wrapperShow;
  const defaultProps = {
    hospital: {
      hospital_key: 'key',
      email: 'test@test.com',
      name: 'st. John',
      phone: '21333456456',
      address: 'address',
      website: 'www.stjohn.com',
      shipment_type: {
        shipment_schedule_type: 'by_schedule',
      },
    },
    handleChange: jest.fn(),
    handleMonthChoose: jest.fn(),
    update: true,
  };

  const defaultPropsShow = {
    hospital: {
      hospital_key: 'key',
      email: 'test@test.com',
      name: 'st. John',
      phone: '21333456456',
      address: 'address',
      website: 'www.stjohn.com',
      shipment_type: {
        shipment_schedule_type: 'by_schedule',
      },
    },
    handleChange: jest.fn(),
    handleMonthChoose: jest.fn(),
    update: false,
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<HospitalShipmentType {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalShipmentType {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container-w-100')).toHaveLength(2);
      expect(wrapper.find('.input-label')).toHaveLength(2);
      expect(wrapper.find('.monthes.active')).toHaveLength(1);
      expect(wrapper.find('.month-item')).toHaveLength(12);
    });
  });

  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<HospitalShipmentType {...defaultProps} />);
    });

    it('click month button', () => {
      wrapper.find('.month-item').at(0).simulate('click', { target: { id: 'Jan' } });
      expect(defaultProps.handleMonthChoose).toHaveBeenCalled();
    });
  });


  describe('rendering', () => {
    beforeEach(() => {
      wrapperShow = mount(<HospitalShipmentType {...defaultPropsShow} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalShipmentType {...defaultPropsShow} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapperShow.find('.input-container-w-100')).toHaveLength(1);
      expect(wrapperShow.find('.input-label')).toHaveLength(1);
      expect(wrapperShow.find('.monthes')).toHaveLength(1);
      expect(wrapperShow.find('.month-item')).toHaveLength(12);
    });
  });
});
