// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`InventoryTable Component rendering renders correctly 1`] = `
<div
  className="box padding with-actions"
>
  <div
    className="table-actions--supplies"
  >
    <div
      className="edit"
    >
      <button
        className="label"
        onClick={[MockFunction]}
        type="button"
      >
        <span
          aria-hidden="true"
          className="material-icons MuiIcon-root-1 img"
        >
          edit
        </span>
         Update inventory
      </button>
    </div>
  </div>
  <div
    className="box flex box-default table-box table-responsive"
  >
    <table
      className="mdl-data-table table-bordered with-margins"
    >
      <thead>
        <tr>
          <th
            className="mdl-data-table__cell--non-numeric"
          >
            Color
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
          >
            Number of Sleeves
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Red",
                  }
                }
              />
              Red
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ff0000",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={5}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Orange",
                  }
                }
              />
              Orange
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ed4a30",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={18}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Yellow",
                  }
                }
              />
              Yellow
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ec8185",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={29}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Green",
                  }
                }
              />
              Green
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ecb1b3",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={43}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Blue",
                  }
                }
              />
              Blue
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ffffff",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={50}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Purple",
                  }
                }
              />
              Purple
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ffffff",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={45}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Pink",
                  }
                }
              />
              Pink
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ffffff",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={87}
            />
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            <div
              className="color-cell"
            >
              <div
                className="color-box"
                style={
                  Object {
                    "backgroundColor": "Brown",
                  }
                }
              />
              Brown
            </div>
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
            style={
              Object {
                "backgroundColor": "#ffffff",
              }
            }
          >
            <input
              className="inventory-input"
              disabled={true}
              onChange={[Function]}
              value={67}
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      className="update-info"
    >
      <div>
        Updated by: 
        John Wick
      </div>
      <div>
        Updated on: 
        11/15/2019 2:57:50 PM
      </div>
    </div>
    
  </div>
</div>
`;
