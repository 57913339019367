import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../ui/CloseButton';

const PrintModeModal = ({ print, close }) => {
  const [type, setType] = useState('ups');

  return (
    <div className="modal_body detail">
      <div className="modal_button_container top">
        <CloseButton close={close} />
      </div>
      <div className="title print no-border">Print Settings</div>
      <div className="sub-title print">Please select paper size:</div>
      <section className="print-type">
        <input type="radio" id="ups" name="type" checked={type === 'ups'} onClick={() => setType('ups')} defaultChecked />
        <label htmlFor="ups">UPS label (4 x 6 in)</label>
      </section>
      <section className="print-type">
        <input type="radio" id="letter" name="type" checked={type === 'letter'} onClick={() => setType('letter')} />
        <label htmlFor="letter">Letter (8.5 x 11 in)</label>
      </section>
      <div className="print-button-container">
        <button type="button" className="save" onClick={() => print(type)}>Print</button>
      </div>
    </div>
  );
};

PrintModeModal.propTypes = {
  print: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default PrintModeModal;
