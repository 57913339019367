import React from 'react';
import PropTypes from 'prop-types';

class UsersShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
    };
  }


  render() {
    const { user } = this.state;
    return (
      <div className="box-body">
        <div className="box box-default table-box table-responsive mdl-shadow--2dp">
          <div>{`ID: ${user.id}`}</div>
          <div>{`Email: ${user.email}`}</div>
          <div>{`First Name: ${user.first_name}`}</div>
          <div>{`Last Name: ${user.last_name}`}</div>
          <div>{`Role: ${user.role}`}</div>
        </div>
      </div>
    );
  }
}

UsersShow.propTypes = {
  user: PropTypes.instanceOf(Object),
};

UsersShow.defaultProps = {
  user: {},
};

export default UsersShow;
