import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import API from '../../services/api';

const styles = {
  input: {
    'font-size': '1rem',
    height: '3rem',
    width: 'calc(100% - 2rem)',
    'border-radius': '10px',
    border: 'solid 1px #828282',
    padding: '0 1rem',
    color: '#828282',
    'font-family': 'Avenir Next Regular',
    outline: 'none',
  },
  input1: {
    height: '3rem',
  },
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    const defaults = {
      email: '',
      password: '',
    };

    this.state = { user: defaults, formErrors: { } };
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateField(fieldName) {
    const { formErrors, user } = this.state;
    const value = user[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        formErrors.email = valid ? '' : 'Email is invalid';
        break;
      case 'password':
        valid = value.length >= 6;
        formErrors.password = valid ? '' : 'Password is too short';
        break;
      default:
        break;
    }
    this.setState({ formErrors });
  }

  hasErrors() {
    const { formErrors } = this.state;
    return Object.values(formErrors).some(item => item);
  }

  handleSubmit() {
    const { user, formErrors } = this.state;
    const form = document.forms[0];

    Object.keys(user).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;

    API.validation({ type: 'sign_in', email: user.email, password: user.password }).then((res) => {
      if (res) {
        formErrors.email = res.err_msg;
        formErrors.password = res.err_msg;
      }
      this.setState({ formErrors }, () => {
        if (!this.hasErrors()) form.submit();
      });
    });
  }

  handleChange(event) {
    const { user } = this.state;
    const name = event.target.id;
    const { value } = event.target;
    user[name] = value;
    this.setState({ user }, () => { this.validateField(name); });
  }

  render() {
    const { user, formErrors } = this.state;
    const { classes } = this.props;
    return (
      <div className="auth-content">
        <div className="custom-logo-img" />
        <div className="auth-header">Sign In</div>
        <div className="custom-form-group">
          <label htmlFor="user[email]">
            Email Address
          </label>
          <div>
            <i className="material-icons">mail</i>
            <TextField
              id="email"
              type="email"
              name="user[email]"
              placeholder="Enter email"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.email}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="user[password]">
            Password
          </label>
          <div>
            <i className="material-icons">lock</i>
            <TextField
              type="password"
              name="user[password]"
              id="password"
              placeholder="Password"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.password}
              error={Boolean(formErrors.password)}
              helperText={formErrors.password}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-container">
            <div className="checkbox-cell">
              <input
                type="checkbox"
                name="user[remember_me]"
                id="user[remember_me]"
              />
              <label htmlFor="user[remember_me]" className="input-label">Remember Me</label>
            </div>
          </div>
          <a href="/users/password/new" className="forgot-password">Forgot your password?</a>
        </div>
        <button
          type="button"
          className="btn-main"
          onClick={this.handleSubmit}
        >
          Sign In
        </button>
        <div className="auth-footer-text">Don’t have an account? </div>
        <a href="/users/sign_up" className="btn-alt">Register Now</a>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SignIn);
