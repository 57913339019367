// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HospitalsIndex Component rendering renders correctly 1`] = `
<div
  className="detail-container index_page"
>
  <div
    className="Toastify"
  />
  <div
    className="details-header"
  >
    <div
      className="detail-title"
    >
      Crayon Hospitals (
      0
      )
    </div>
    <div
      className="search"
    >
      <input
        id="search"
        onChange={[Function]}
        value=""
      />
      <span>
        Search
      </span>
    </div>
  </div>
  <div
    className="box with-actions"
    id=""
  >
    <div
      className="table-actions"
    >
      <div
        className="add-new"
      >
        <div
          className="label"
        >
          Create New
        </div>
        <button
          onClick={[Function]}
          type="button"
        >
          +
        </button>
      </div>
      <div
        className="hs-filters"
      >
        <div
          className="checkbox-cell"
        >
          <input
            checked={true}
            id="verified"
            onChange={[Function]}
            type="checkbox"
          />
          <label
            htmlFor="verified"
          >
            Address Verified
          </label>
        </div>
        <div
          className="checkbox-cell"
        >
          <input
            checked={true}
            id="approved"
            onChange={[Function]}
            type="checkbox"
          />
          <label
            htmlFor="approved"
          >
            Approved for Shipping
          </label>
        </div>
      </div>
    </div>
    <div
      className="box box-default table-box table-responsive"
    >
      <table
        className="mdl-data-table table-selectable table-bordered"
      >
        <thead>
          <tr>
            <th
              className="mdl-data-table__cell--non-numeric"
            >
              #
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              ID
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort false"
              >
                arrow_upward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Name
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_upward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Number of Contacts
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_upward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Address
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_upward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Number of Shipments
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_upward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Shipped Crayons
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_upward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Last Shipment
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_upward
              </span>
            </th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
    <div
      className="table--view-more"
    >
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        View more ▼
      </button>
    </div>
  </div>
</div>
`;
