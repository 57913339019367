// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ProductionIndex Component rendering renders correctly 1`] = `
<div
  className="detail-container"
>
  <div
    className="Toastify"
  />
  <div
    className="detail-title"
  >
    Crayon Cases
  </div>
  <div
    className="tables-container"
  >
    <div
      className="table-title"
    >
      Number of completed cases of 100
    </div>
    <div
      className="information-container"
      id="information"
    >
      <div
        className="production-data-section"
      >
        <div
          className="input-container"
        >
          <div
            className="input-label"
          >
            Number of cases
          </div>
          <input
            onChange={[Function]}
            value=""
          />
        </div>
        <div
          className="button-container"
        >
          <button
            className="incoming-shipment_btn blue"
            onClick={[Function]}
            type="button"
          >
            Save
          </button>
        </div>
        
      </div>
    </div>
    <div
      className="table-title"
    >
      Recent Entries
    </div>
    <div
      className="box with-actions"
      id=""
    >
      <div
        className="table-actions"
      >
        <div
          className="add-new"
        >
          <div
            className="label"
          >
            Create New
          </div>
          <button
            onClick={[Function]}
            type="button"
          >
            +
          </button>
        </div>
      </div>
      <div
        className="box box-default table-box table-responsive"
      >
        <table
          className="mdl-data-table table-selectable table-bordered"
        >
          <thead>
            <tr>
              <th
                className="mdl-data-table__cell--non-numeric"
              >
                #
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Date
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Name
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Number of completed cases
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
      <div
        className="table--view-more"
      >
        <button
          className="button"
          disabled={true}
          onClick={[Function]}
          type="button"
        >
          View more ▼
        </button>
      </div>
    </div>
  </div>
</div>
`;
