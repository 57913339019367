import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DonorsShow from '../DonorsShow';

jest.mock('../../../services/api', () => ({
  updateDonor: jest.fn(() => Promise.resolve({
    donor: {},
  })),
  loadDonorOrders: jest.fn(() => Promise.resolve({
    orders: [{
      id: '1',
      order_key: 'key',
      date: '',
      amount: '100',
      number_of_items: '1000',
    }],
  })),
  loadDonorDonations: jest.fn(() => Promise.resolve({
    donations: [{
      id: '1',
      donation_key: 'key',
      date: '',
      amount: '100',
      donation_type: 'once',
      payment_type: 'check',
    }],
  })),
  loadDonorShipments: jest.fn(() => Promise.resolve({
    shipments: [{
      id: '1',
      shipment_key: 'key',
      date: '',
      amount: '100',
      weight: '500',
      tracking_number: 'tn475862938923',
    }],
  })),
}));

describe('DonorsShow Component', () => {
  let wrapper;
  const defaultProps = {
    donor: {
      id: '1',
      donor_key: 'key',
      email: 'test@test.com',
      first_name: 'John',
      last_name: 'Wick',
      address: 'address',
    },
  };

  beforeEach(() => {
    wrapper = mount(<DonorsShow {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<DonorsShow {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(2);
    });
    it('renders titles', () => {
      expect(wrapper.text()).toMatch('Information');
      // expect(wrapper.text()).toMatch('Orders');
      // expect(wrapper.text()).toMatch('Donations');
      expect(wrapper.text()).toMatch('Shipments');
    });
    it('renders titles', () => {
      expect(wrapper.find('input').at(1).props().value).toEqual(defaultProps.donor.email);
      expect(wrapper.text()).toMatch('tn475862938923');
      // expect(wrapper.text()).toMatch('check');
    });
  });

  describe('events', () => {
    it('handleEdit', () => {
      wrapper.instance().handleEdit();
      expect(wrapper.state().update).toEqual(true);
    });

    it('handleCancel', () => {
      wrapper.instance().handleCancel();
      expect(wrapper.state().update).toEqual(false);
    });

    it('handleSave', () => {
      wrapper.instance().handleSave({});
      expect(wrapper.state().update).toEqual(false);
    });

    it('handleChange', () => {
      wrapper.instance().handleEdit();
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(wrapper.state().donor.email).toEqual('test1@test.com');
    });

    it('handleChange street address', () => {
      wrapper.instance().handleEdit();
      wrapper.find('input#street1').simulate('change', { target: { id: 'street1', value: '123 Park Ave' } });
      expect(wrapper.state().donor.street1).toEqual('123 Park Ave');
    });

    it('handleOrdersPageChange', (cb) => {
      wrapper.instance().handleOrdersPageChange();
      process.nextTick(() => { expect(wrapper.state('ordersCurrentPage')).toEqual(2); cb(); });
    });

    it('handleDonationsPageChange', (cb) => {
      wrapper.instance().handleDonationsPageChange();
      process.nextTick(() => { expect(wrapper.state('donationsCurrentPage')).toEqual(2); cb(); });
    });

    it('handleShipmentsPageChange', (cb) => {
      wrapper.instance().handleShipmentsPageChange();
      process.nextTick(() => { expect(wrapper.state('shipmentsCurrentPage')).toEqual(2); cb(); });
    });

    it('handleSortOrders', (cb) => {
      wrapper.instance().handleSortOrders('col');
      process.nextTick(() => { expect(wrapper.state('donorOrdersSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handleSortDonations', (cb) => {
      wrapper.instance().handleSortDonations('col');
      process.nextTick(() => { expect(wrapper.state('donorDonationsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handleSortShipments', (cb) => {
      wrapper.instance().handleSortShipments('col');
      process.nextTick(() => { expect(wrapper.state('donorShipmentsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });
  });
});
