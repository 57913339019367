import React from 'react';


const OverviewIndex = () => (
  <div className="detail-container">
    <div className="detail-title">Overview</div>
    <div className="tables-container overview" />
  </div>
);

export default OverviewIndex;
