import React from 'react';
import PropTypes from 'prop-types';
import states from '../../services/states.json';


const StateSelect = ({
  id, value, className, onChange, readOnly,
}) => (
  <select id={id} value={value} className={className} disabled={readOnly} onChange={e => onChange(e)}>
    <option value="" disabled hidden />
    {states.map(item => (
      <option key={item.abbr} value={item.abbr}>
        {`${item.abbr} - ${item.name}`}
      </option>
    ))}
  </select>
);

StateSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
};

StateSelect.defaultProps = {
  readOnly: false,
  value: '',
};

export default StateSelect;
