import React from 'react';
import DatePicker from 'tui-date-picker';
import PropTypes from 'prop-types';
import CloseButton from '../ui/CloseButton';

class CalendarUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: {
        id: props.event.id,
        name: props.event.title,
        location: props.event.location,
        body: props.event.body,
        startDate: props.event.start.toDate(),
        endDate: props.event.end.toDate(),
        calendarId: props.event.calendarId,
        isAllDay: props.event.isAllDay,
        eventType: props.event.raw.class,
        eventVisibility: props.event.raw.visibility,
        attendees: props.event.attendees,
        max_attendees: props.event.raw.max_attendees,
        record_id: props.event.raw.record_id,
      },
      joinEvent: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
  }

  componentDidMount() {
    this.initDatePicker();
  }

  initDatePicker = () => {
    const { schedule } = this.state;

    const startDatePicker = new DatePicker('#start-date-picker', {
      date: schedule.startDate,
      timepicker: true,

      input: {
        element: '#start-date-picker-input',
        format: 'yyyy-MM-dd hh:mm',
      },
    });

    const endDatePicker = new DatePicker('#end-date-picker', {
      date: schedule.endDate,
      timepicker: true,

      input: {
        element: '#end-date-picker-input',
        format: 'yyyy-MM-dd hh:mm',
      },
    });

    startDatePicker.on('change', () => {
      this.setDate('startDate', startDatePicker.getDate());
    });

    endDatePicker.on('change', () => {
      this.setDate('endDate', endDatePicker.getDate());
    });
  };

  setDate = (type, date) => {
    const { schedule } = this.state;
    schedule[type] = date;
    this.setState({ schedule });
  };

  handleChange(e) {
    const { schedule } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    schedule[name] = value;
    this.setState({ schedule });
  }

  handleChangeType(e, type) {
    const { schedule } = this.state;
    schedule[type] = e.target.id;
    this.setState({ schedule });
  }

  render() {
    const { schedule, joinEvent } = this.state;
    const {
      handleUpdate, handleClose, isAttendee, calendars,
    } = this.props;

    return (
      <div className="modal_body calendar">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="modal_title calendar">Update event</div>
        <section className="content">
          <div className="input-container calendar">
            <section>
              <div className="input-label">Event type</div>
              <input id="calendarId" value={calendars.find(c => c.id === schedule.calendarId).name || ''} readOnly disabled />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Name</div>
              <input id="name" value={schedule.name || ''} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Location</div>
              <input id="location" value={schedule.location || ''} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Details</div>
              <input id="body" value={schedule.body || ''} onChange={this.handleChange} />
            </section>
          </div>
          <section className="input-container calendar top">
            <section>
              <div className="input-label">Date</div>
              <div>
                <div className="tui-datepicker-input tui-datetime-input tui-has-focus">
                  <input type="text" id="start-date-picker-input" aria-label="Date-Time" />
                  <span className="tui-ico-date" />
                  <div id="start-date-picker" />
                </div>
                <span className="text">to</span>
                <div className="tui-datepicker-input tui-datetime-input tui-has-focus">
                  <input type="text" id="end-date-picker-input" aria-label="Date-Time" />
                  <span className="tui-ico-date" />
                  <div id="end-date-picker" />
                </div>
              </div>
            </section>
          </section>
          {
            schedule.attendees.length < schedule.max_attendees && !isAttendee
              ? (
                <div className="input-container calendar no_text_input">
                  <section>
                    <div className="input-label">Join to this event:</div>
                    <div>
                      <label htmlFor="public">Yes</label>
                      <input type="radio" id="no" name="join" checked={joinEvent} onChange={() => this.setState({ joinEvent: !joinEvent })} />
                      <label htmlFor="private">No</label>
                      <input type="radio" id="yes" name="join" checked={!joinEvent} onChange={() => this.setState({ joinEvent: !joinEvent })} />
                    </div>
                  </section>
                </div>
              ) : (
                <div className="input-container calendar no_text_input">
                  <section>
                    <div className="input-label">Join to this event:</div>
                    <div className="input-label">{isAttendee ? 'You are already participating in an event' : 'Maximum'}</div>
                  </section>
                </div>
              )
          }
          <div className="input-container calendar no_text_input">
            <section>
              <div className="input-label">Event type</div>
              <section>
                <div>
                  <input
                    type="radio"
                    id="public"
                    name="event_type"
                    checked={schedule.eventType === 'public'}
                    onChange={e => this.handleChangeType(e, 'eventType')}
                  />
                  <label htmlFor="public">Public event</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="internal"
                    name="event_type"
                    checked={schedule.eventType === 'internal'}
                    onChange={e => this.handleChangeType(e, 'eventType')}
                  />
                  <label htmlFor="internal">Internal event</label>
                </div>
              </section>
            </section>
            <section>
              <div className="input-label">Event visibility</div>
              <section>
                <div>
                  <input
                    type="radio"
                    id="lead_only"
                    name="event_visibility"
                    checked={schedule.eventVisibility === 'lead_only'}
                    onChange={e => this.handleChangeType(e, 'eventVisibility')}
                  />
                  <label htmlFor="lead_only">Lead only</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="company_wide"
                    name="event_visibility"
                    checked={schedule.eventVisibility === 'company_wide'}
                    onChange={e => this.handleChangeType(e, 'eventVisibility')}
                  />
                  <label htmlFor="company_wide">Company Wide</label>
                </div>
              </section>
            </section>
          </div>
        </section>
        <div className="modal_button_container">
          <button type="button" className="modal_button" onClick={() => handleUpdate(schedule, joinEvent)}>Save</button>
        </div>
      </div>
    );
  }
}

CalendarUpdateModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  calendars: PropTypes.instanceOf(Array).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isAttendee: PropTypes.bool.isRequired,
};

export default CalendarUpdateModal;
