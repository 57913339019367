// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ConfirmShipment Component rendering renders correctly 1`] = `
<div
  className="ship-container"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="shipment-confirmation-content"
  >
    <div
      className="shipment-confirmation-title"
    >
      Ship From
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div>
        <section>
          <label>
            Company Name
          </label>
          <span>
            Test Company
          </span>
        </section>
        <section>
          <label>
            Address
          </label>
          <span>
            300 Riverside Dr.
            <br />
            apt. 600
            <br />
            Austin, 
                    TX
                    55293
            <br />
            United States
          </span>
        </section>
      </div>
      <div>
        <section>
          <label>
            Email
          </label>
          <span>
            tester@test.com
          </span>
        </section>
        <section>
          <label>
            Phone
          </label>
          <span>
            +11111111111
          </span>
        </section>
      </div>
    </div>
    <div
      className="shipment-confirmation-title"
    >
      Payment Information
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div>
        <div
          className="title"
        >
          Card Information
        </div>
        <section>
          <label>
            Name on Card
          </label>
          <span>
            Tester
          </span>
        </section>
        <section>
          <label>
            Card number
          </label>
          <span>
            **** **** **** 4242
          </span>
        </section>
        <section>
          <label>
            Valid Through
          </label>
          <span>
            12/22
          </span>
        </section>
      </div>
      <div>
        <div
          className="title"
        >
          Billing Address
        </div>
        <div>
          <section>
            <label>
              Company Name
            </label>
            <span>
              Test Billing Company
            </span>
          </section>
          <section>
            <label>
              Address
            </label>
            <span>
              <span>
                351 Billing Rd.
                <br />
                apt. 732
                <br />
                Hawkins, 
                            IN
                            72495
                <br />
                United States
              </span>
            </span>
          </section>
        </div>
        <div>
          <section>
            <label>
              Email
            </label>
            <span>
              billing@tester.com
            </span>
          </section>
          <section>
            <label>
              Phone
            </label>
            <span>
              +12222222222
            </span>
          </section>
        </div>
      </div>
    </div>
    <div
      className="shipment-confirmation-title"
    >
      Package Information
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div
        className="package-block false"
      >
        <section
          className="confirm-package"
        >
          <label>
            Package # 1
          </label>
          <section>
            <div
              className="download-link"
            >
              <button
                className="print"
                onClick={[Function]}
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  file_download
                </span>
              </button>
            </div>
            <div
              className="download-link"
            >
              <button
                className="print"
                onClick={[Function]}
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  print
                </span>
              </button>
            </div>
          </section>
        </section>
        <section>
          <label>
            Dimensions
          </label>
          <span>
            12" x 12" x 12"
          </span>
        </section>
        <section>
          <label>
            Weight
          </label>
          <span>
            12 lbs
          </span>
        </section>
      </div>
    </div>
    <div
      className="shipment-confirmation-ba"
    >
      <button
        className="shipment-confirmation-btn-back"
        onClick={[MockFunction]}
        type="button"
      >
        Back
      </button>
      <div
        className="amount"
      >
        Amount: $20
      </div>
    </div>
    <div
      className="action-buttons-container"
    >
      <div
        className="download-link"
      >
        <button
          className="shipment-confirmation-btn"
          onClick={[Function]}
          type="button"
        >
          <span
            aria-hidden="true"
            className="material-icons MuiIcon-root-1 img primary"
          >
            print
          </span>
          Print all labels
        </button>
      </div>
      <button
        className="shipment-confirmation-btn"
        disabled={false}
        onClick={[MockFunction]}
        type="button"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
`;

exports[`ConfirmShipment Component with pickup rendering renders correctly 1`] = `
<div
  className="ship-container"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="shipment-confirmation-content"
  >
    <div
      className="shipment-confirmation-title"
    >
      Ship From
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div>
        <section>
          <label>
            Company Name
          </label>
          <span>
            Test Company
          </span>
        </section>
        <section>
          <label>
            Address
          </label>
          <span>
            300 Riverside Dr.
            <br />
            apt. 600
            <br />
            Austin, 
                    TX
                    55293
            <br />
            United States
          </span>
        </section>
      </div>
      <div>
        <section>
          <label>
            Email
          </label>
          <span>
            tester@test.com
          </span>
        </section>
        <section>
          <label>
            Phone
          </label>
          <span>
            +11111111111
          </span>
        </section>
      </div>
    </div>
    <div
      className="shipment-confirmation-title"
    >
      Payment Information
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div>
        <div
          className="title"
        >
          Card Information
        </div>
        <section>
          <label>
            Name on Card
          </label>
          <span>
            Tester
          </span>
        </section>
        <section>
          <label>
            Card number
          </label>
          <span>
            **** **** **** 4242
          </span>
        </section>
        <section>
          <label>
            Valid Through
          </label>
          <span>
            12/22
          </span>
        </section>
      </div>
      <div>
        <div
          className="title"
        >
          Billing Address
        </div>
        <div>
          <section>
            <label>
              Company Name
            </label>
            <span>
              Test Billing Company
            </span>
          </section>
          <section>
            <label>
              Address
            </label>
            <span>
              <span>
                351 Billing Rd.
                <br />
                apt. 732
                <br />
                Hawkins, 
                            IN
                            72495
                <br />
                United States
              </span>
            </span>
          </section>
        </div>
        <div>
          <section>
            <label>
              Email
            </label>
            <span>
              billing@tester.com
            </span>
          </section>
          <section>
            <label>
              Phone
            </label>
            <span>
              +12222222222
            </span>
          </section>
        </div>
      </div>
    </div>
    <div
      className="shipment-confirmation-title"
    >
      Package Information
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div
        className="package-block false"
      >
        <section
          className="confirm-package"
        >
          <label>
            Package # 1
          </label>
          <section>
            <div
              className="download-link"
            >
              <button
                className="print"
                onClick={[Function]}
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  file_download
                </span>
              </button>
            </div>
            <div
              className="download-link"
            >
              <button
                className="print"
                onClick={[Function]}
                type="button"
              >
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 img"
                >
                  print
                </span>
              </button>
            </div>
          </section>
        </section>
        <section>
          <label>
            Dimensions
          </label>
          <span>
            12" x 12" x 12"
          </span>
        </section>
        <section>
          <label>
            Weight
          </label>
          <span>
            12 lbs
          </span>
        </section>
      </div>
    </div>
    <div
      className="shipment-confirmation-title"
    >
      Pickup information
    </div>
    <div
      className="shipment-confirmation-info"
    >
      <div>
        <div
          className="title"
        >
          Pickup Time
        </div>
        <section>
          <label>
            Pickup Date
          </label>
          <span>
            12345612
          </span>
        </section>
        <section>
          <label>
            Earliest Pickup Time
          </label>
          <span>
            2000
          </span>
        </section>
        <section>
          <label>
            Latest Pickup Time
          </label>
          <span>
            2100
          </span>
        </section>
      </div>
      <div>
        <div
          className="title"
        >
          Pickup Address
        </div>
        <div>
          <section>
            <label>
              Company Name
            </label>
            <span>
              Test Pickup Company
            </span>
          </section>
          <section>
            <label>
              Address
            </label>
            <span>
              <span>
                351 Pickup Rd.
                <br />
                apt. 732
                <br />
                Hawkins,
                                    IN
                                    72495
                <br />
                United States
              </span>
            </span>
          </section>
        </div>
        <div>
          <section>
            <label>
              Email
            </label>
            <span>
              pickup@tester.com
            </span>
          </section>
          <section>
            <label>
              Phone
            </label>
            <span>
              +12222222222
            </span>
          </section>
        </div>
      </div>
    </div>
    <div
      className="shipment-confirmation-ba"
    >
      <button
        className="shipment-confirmation-btn-back"
        onClick={[MockFunction]}
        type="button"
      >
        Back
      </button>
      <div
        className="amount"
      >
        Amount: $20
      </div>
    </div>
    <div
      className="action-buttons-container"
    >
      <div
        className="download-link"
      >
        <button
          className="shipment-confirmation-btn"
          onClick={[Function]}
          type="button"
        >
          <span
            aria-hidden="true"
            className="material-icons MuiIcon-root-1 img primary"
          >
            print
          </span>
          Print all labels
        </button>
      </div>
      <button
        className="shipment-confirmation-btn"
        disabled={false}
        onClick={[MockFunction]}
        type="button"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
`;
