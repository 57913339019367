import React from 'react';
import Scroll from 'react-scroll';
import PropTypes from 'prop-types';

const { Link } = Scroll;

const StickyBar = ({ tabs }) => (
  <div className="sticky-bar">
    <div className="items-container">
      {
        tabs.map((tab, index) => (
          <Link
            activeClass="sticky-bar--item active"
            to={tab.toLowerCase()}
            offset={-(11 * parseInt(getComputedStyle(document.documentElement).fontSize, 10))}
            hashSpy
            isDynamic
            spy
            smooth
            duration={500}
            className="sticky-bar--item"
            key={index.toString()}
          >
            {tab}
          </Link>
        ))
      }
    </div>
  </div>
);

StickyBar.propTypes = {
  tabs: PropTypes.instanceOf(Array),
};

StickyBar.defaultProps = {
  tabs: [],
};

export default StickyBar;
