import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ReactModal from 'react-modal';
import API from '../../services/api';
import TermsAndConditions from './TermsAndConditions';

const styles = {
  input: {
    'font-size': '1rem',
    height: '3rem',
    width: 'calc(100% - 2rem)',
    'border-radius': '10px',
    border: 'solid 1px #828282',
    padding: '0 1rem',
    color: '#828282',
    'font-family': 'Avenir Next Regular',
    outline: 'none',
  },
  input1: {
    height: '3rem',
  },
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    const defaults = {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      agree: false,
      toc: false,
    };

    this.state = {
      user: defaults,
      formErrors: { },
      showModal: false,
      stage: 'agree',
    };
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
  }

  validateField(fieldName) {
    const { formErrors, user } = this.state;
    const value = user[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        formErrors.email = valid ? '' : 'Email is invalid';
        break;
      case 'first_name':
      case 'last_name':
        valid = !!value;
        formErrors[fieldName] = valid ? '' : 'This field is required';
        break;
      case 'password':
        valid = value.length >= 6;
        formErrors.password = valid ? '' : 'Password is too short';
        break;
      case 'password_confirmation':
        valid = value === user.password;
        formErrors.password_confirmation = valid ? '' : 'Passwords don\'t match';
        break;
      case 'toc':
        valid = !!value;
        formErrors.toc = valid ? '' : 'err';
        break;
      default:
        break;
    }
    this.setState({ formErrors });
  }

  hasErrors() {
    const { formErrors } = this.state;
    return Object.values(formErrors).some(item => item);
  }

  handleSubmit() {
    const { user, formErrors } = this.state;
    const form = document.forms[0];

    if (!user.agree) {
      this.setState({ showModal: true, stage: 'confirm' });
      return;
    }

    Object.keys(user).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;

    API.validation({ type: 'sign_up', email: user.email }).then((res) => {
      if (res) formErrors.email = res.err_msg;
      this.setState({ formErrors }, () => {
        if (!this.hasErrors()) form.submit();
      });
    });
  }

  handleChange(event) {
    const { user } = this.state;
    const name = event.target.id;
    const { value } = event.target;
    if (name === 'toc') user[name] = !user[name];
    else user[name] = value;
    this.setState({ user }, () => { this.validateField(name); });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleAgree() {
    const { stage, user } = this.state;
    user.agree = true;
    if (stage === 'agree') this.setState({ showModal: false, user });
    else this.setState({ showModal: false, user }, () => this.handleSubmit());
  }

  render() {
    const { user, formErrors, showModal } = this.state;
    const { classes } = this.props;
    return (
      <div className="auth-content">
        <div className="custom-logo-img" />
        <div className="auth-header">Sign Up</div>
        <div className="custom-form-group">
          <label htmlFor="user[first_name]">
            First Name
          </label>
          <div>
            <i className="material-icons">person</i>
            <TextField
              id="first_name"
              type="text"
              placeholder="Enter first name"
              name="user[first_name]"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.first_name}
              error={Boolean(formErrors.first_name)}
              helperText={formErrors.first_name}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="user[last_name]">
            Last Name
          </label>
          <div>
            <i className="material-icons">person</i>
            <TextField
              id="last_name"
              type="text"
              placeholder="Enter last name"
              name="user[last_name]"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.last_name}
              error={Boolean(formErrors.last_name)}
              helperText={formErrors.last_name}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="user[email]">
            Email Address
          </label>
          <div>
            <i className="material-icons">mail</i>
            <TextField
              id="email"
              type="email"
              name="user[email]"
              placeholder="Enter email"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.email}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="user[password]">
            Password
          </label>
          <div>
            <i className="material-icons">lock</i>
            <TextField
              type="password"
              name="user[password]"
              id="password"
              placeholder="Password"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.password}
              error={Boolean(formErrors.password)}
              helperText={formErrors.password}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="user[password_confirmation]">
            Confirm Password
          </label>
          <div>
            <i className="material-icons">lock</i>
            <TextField
              type="password"
              name="user[password_confirmation]"
              id="password_confirmation"
              placeholder="Confirm password"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={user.password_confirmation}
              error={Boolean(formErrors.password_confirmation)}
              helperText={formErrors.password_confirmation}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="input-container">
          <div className={`checkbox-cell ${Boolean(formErrors.toc) && 'errorr'}`}>
            <input
              required
              type="checkbox"
              name="toc"
              id="toc"
              checked={user.toc}
              onChange={this.handleChange}
            />
            <label htmlFor="toc" className="input-label">I agree with Terms and Conditions</label>
          </div>
        </div>
        <div className="input-container">
          <div className="checkbox-cell">
            <input
              required
              type="checkbox"
              name="agree"
              id="agree"
              checked={user.agree}
              onChange={() => this.setState({ showModal: true, stage: 'agree' })}
            />
            <label htmlFor="agree" className="input-label">I agree with the Release and Waiver of Liability</label>
          </div>
        </div>
        <button
          type="button"
          className="btn-main"
          onClick={this.handleSubmit}
        >
          Create account
        </button>
        <div className="auth-footer-text">Have an account?</div>
        <a href="/users/sign_in" className="btn-alt">Sign In</a>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          className="modal toc"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <TermsAndConditions cancel={this.handleCloseModal} confirm={this.handleAgree} />
        </ReactModal>
      </div>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SignUp);
