import React from 'react';
import PropTypes from 'prop-types';
import PackageInformation from './PackageInformation';

const LocateAndAcceptShipment = ({
  showWeight, locate, accept, errors, accepted, inputs, handleChange, manualEntry, shipmentInfo, tnInputRef, wInputRef, anonymousEntry,
}) => (
  <div id="information" className="information-container incoming-shipment" tabIndex={-1} onKeyDown={e => (e.key === 'Enter' && accept(inputs))} role="button">
    <div className="tn-container">
      <div className="input-container incoming-shipment">
        <div className="incoming-shipment_info">
          <div className="input-label incoming-shipment tracking-number">Tracking Number</div>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <input autoFocus className="tracking-number" id="trackingNumber" ref={tnInputRef} onChange={handleChange} value={inputs.trackingNumber} />
        </div>
        <button type="button" className="incoming-shipment_btn blue" onClick={() => locate(inputs.trackingNumber)}>Locate</button>
      </div>
      <button type="button" className="incoming-shipment_btn gray" onClick={manualEntry}>Manual Entry</button>
      <button type="button" className="incoming-shipment_btn gray" onClick={anonymousEntry}>Anonymous entry</button>
    </div>
    {showWeight && (
    <div className="input-container incoming-shipment">
      <div className="incoming-shipment_info">
        <div className="input-label incoming-shipment tracking-number">Enter Actual Weight</div>
        <input className="weight" id="weight" ref={wInputRef} onChange={handleChange} />
        <span>lbs</span>
      </div>
      <button type="button" className="incoming-shipment_btn orange" onClick={() => accept(inputs)}>
        {accepted && 'Accepted'}
        {!accepted && 'Accept'}
      </button>
    </div>
    )}
    {errors.trackingNumber && <span className="error">{errors.trackingNumber}</span>}
    {errors.weight && <span className="error">{errors.weight}</span>}
    {showWeight && <PackageInformation shipment={shipmentInfo} />}
  </div>
);

LocateAndAcceptShipment.propTypes = {
  showWeight: PropTypes.bool.isRequired,
  locate: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  accepted: PropTypes.bool.isRequired,
  inputs: PropTypes.instanceOf(Object).isRequired,
  shipmentInfo: PropTypes.instanceOf(Object).isRequired,
  manualEntry: PropTypes.func.isRequired,
  tnInputRef: PropTypes.instanceOf(Object).isRequired,
  wInputRef: PropTypes.instanceOf(Object).isRequired,
  anonymousEntry: PropTypes.func.isRequired,
};

export default LocateAndAcceptShipment;
