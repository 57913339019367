import React from 'react';
import PropTypes from 'prop-types';
import ShippingForm from './ShippingForm';
import ConfirmShipping from './ConfirmShipment';
import API from '../../services/api';
import Toast from '../ui/Toast';
import { errorToast } from '../../services/toast';

class CreateShipment extends React.Component {
  constructor(props) {
    super(props);
    const defaults = {
      shipping_address: {
        first_name: props.donor.first_name || '',
        last_name: props.donor.last_name || '',
        company_name: props.donor.company || '',
        address_line1: props.donor.street1 || props.donor.location || '',
        address_line2: props.donor.street2 || '',
        city: props.donor.city || '',
        phone: props.donor.phone || '',
        email: props.donor.email || '',
        state: props.donor.state || 'AL',
        zip_code: props.donor.zip_code || '',
        country: props.donor.country || 'US',
      },
      billing_address: {
        billing_first_name: '',
        billing_last_name: '',
        billing_full_name: '',
        billing_company_name: '',
        billing_address_line1: '',
        billing_address_line2: '',
        billing_city: '',
        billing_phone: '',
        billing_email: '',
        billing_state: 'AL',
        billing_zip_code: '',
        billing_country: 'US',
      },
      pickup_address: {
        pickup_first_name: '',
        pickup_last_name: '',
        pickup_full_name: '',
        pickup_company_name: '',
        pickup_address_line1: '',
        pickup_address_line2: '',
        pickup_city: '',
        pickup_phone: '',
        pickup_email: '',
        pickup_state: 'AL',
        pickup_zip_code: '',
        pickup_country: 'US',
      },
      pickup_date_time: {
        pickup_date: '',
        close_time: '',
        ready_time: '',
      },
      token: '',
      numberOfPackages: 1,
      packages: [{
        length: '',
        width: '',
        height: '',
        weight: '',
        not_weight: false,
      }],
      card_name: props.donor.name_on_card,
      card: {},
      useMyShipmentAddress: true,
      useMyPickupShipmentAddress: true,
      usePickup: false,
      residentialAddress: true,
      driverInstructions: '',
      pickupNotifications: true,
    };

    this.state = {
      shippingData: defaults,
      confirm: false,
      status: '',
      labels: [],
      amount: null,
      error: '',
      quoteStatus: '',
    };
  }

  handleReview = (data) => {
    this.setState({ status: 'loading' });
    this.getQuote(data, true);
  };

  getQuote = (data, review = false) => {
    const statusField = review ? 'status' : 'quoteStatus';
    this.setState({ [statusField]: 'loading' });
    API.shipping({ shipping: data, packs: [] }).then((res) => {
      this.setState({
        amount: Number(res.amount).toFixed(2),
        confirm: review,
        [statusField]: '',
        status: '',
      });
    }).catch((res) => {
      this.setState({ [statusField]: '', amount: null });
      errorToast(res.errors[0].detail || res.errors[0].message);
    });
  };

  handleBack = () => {
    this.setState({
      confirm: false,
      labels: [],
      status: '',
      error: '',
    });
  };

  handleSubmit = () => {
    const { shippingData } = this.state;
    this.setState({ status: 'loading' });
    API.charge({ shipping: shippingData })
      .then(res => this.setState({
        labels: res.labels,
        status: 'success',
      }))
      .catch((res) => {
        let error = '';

        if (res.errors !== undefined) {
          error = res.errors[0].detail || res.errors[0].message;
        } else {
          error = 'Some error occurred!';
        }

        this.setState({ error, status: '' });
        errorToast(error);
      });
  };

  renderBody = () => {
    const {
      labels, status, error, confirm, shippingData, amount, quoteStatus,
    } = this.state;

    const { appId } = this.props;

    if (confirm) {
      return (
        <ConfirmShipping
          shipping={shippingData}
          labels={labels}
          handleConfirm={this.handleSubmit}
          handleBack={this.handleBack}
          status={status}
          amount={amount}
        />
      );
    }
    return (
      <ShippingForm
        onReview={this.handleReview}
        getQuote={this.getQuote}
        shippingData={shippingData}
        error={error}
        status={status}
        quoteStatus={quoteStatus}
        // paymentForm={window.SqPaymentForm}
        appId={appId}
        amount={amount}
      />
    );
  };

  render() {
    return (
      <>
        <Toast />
        {this.renderBody()}
      </>
    );
  }
}

CreateShipment.propTypes = {
  appId: PropTypes.string.isRequired,
  donor: PropTypes.instanceOf(Object).isRequired,
};

export default CreateShipment;
