// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PrintModeModal Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title print no-border"
  >
    Print Settings
  </div>
  <div
    className="sub-title print"
  >
    Please select paper size:
  </div>
  <section
    className="print-type"
  >
    <input
      checked={true}
      defaultChecked={true}
      id="ups"
      name="type"
      onClick={[Function]}
      type="radio"
    />
    <label
      htmlFor="ups"
    >
      UPS label (4 x 6 in)
    </label>
  </section>
  <section
    className="print-type"
  >
    <input
      checked={false}
      id="letter"
      name="type"
      onClick={[Function]}
      type="radio"
    />
    <label
      htmlFor="letter"
    >
      Letter (8.5 x 11 in)
    </label>
  </section>
  <div
    className="print-button-container"
  >
    <button
      className="save"
      onClick={[Function]}
      type="button"
    >
      Print
    </button>
  </div>
</div>
`;
