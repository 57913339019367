import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ConfirmShipment from '../ConfirmShipment';

jest.mock('../../../services/printHelper', () => ({
  printLabel: jest.fn(() => {}),
}));

describe('ConfirmShipment Component', () => {
  let wrapper;
  const defaultProps = {
    shipping: {
      shipping_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'Test Company',
        address_line1: '300 Riverside Dr.',
        address_line2: 'apt. 600',
        city: 'Austin',
        phone: '+11111111111',
        email: 'tester@test.com',
        state: 'TX',
        zip_code: '55293',
        country: 'US',
      },
      billing_address: {
        billing_first_name: 'John',
        billing_last_name: 'Doe',
        billing_company_name: 'Test Billing Company',
        billing_address_line1: '351 Billing Rd.',
        billing_address_line2: 'apt. 732',
        billing_city: 'Hawkins',
        billing_phone: '+12222222222',
        billing_email: 'billing@tester.com',
        billing_state: 'IN',
        billing_zip_code: '72495',
        billing_country: 'US',
      },
      pickup_address: {
        pickup_first_name: 'John',
        pickup_last_name: 'Doe',
        pickup_company_name: 'Test Pickup Company',
        pickup_address_line1: '351 Pickup Rd.',
        pickup_address_line2: 'apt. 732',
        pickup_city: 'Hawkins',
        pickup_phone: '+12222222222',
        pickup_email: 'pickup@tester.com',
        pickup_state: 'IN',
        pickup_zip_code: '72495',
        pickup_country: 'US',
      },
      pickup_date_time: {
        pickup_date: '12345612',
        close_time: '2100',
        ready_time: '2000',
      },
      packages: [{
        length: '12',
        width: '12',
        height: '12',
        weight: '12',
        not_weight: false,
      }],
      useMyShipmentAddress: false,
      usePickup: false,
      card_name: 'Tester',
      card: {
        last_4: '4242',
        exp_month: '12',
        exp_year: '22',
      },
    },
    handleConfirm: jest.fn(),
    handleBack: jest.fn(),
    labels: ['base64'],
    status: '',
    amount: '20',
  };

  beforeEach(() => {
    wrapper = mount(<ConfirmShipment {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ConfirmShipment {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.shipment-confirmation-title')).toHaveLength(3);
      expect(wrapper.find('.shipment-confirmation-info')).toHaveLength(3);
      expect(wrapper.find('button')).toHaveLength(5);
      expect(wrapper.text()).toMatch('Amount: $20');
    });

    it('renders Ship from', () => {
      expect(wrapper.text()).toMatch('Ship From');
      expect(wrapper.text()).toMatch(defaultProps.shipping.shipping_address.company_name);
      expect(wrapper.text()).toMatch(defaultProps.shipping.shipping_address.city);
    });

    it('renders Payment Information', () => {
      expect(wrapper.text()).toMatch(defaultProps.shipping.billing_address.billing_company_name);
      expect(wrapper.text()).toMatch(defaultProps.shipping.billing_address.billing_city);
      expect(wrapper.text()).toMatch('**** **** **** 4242');
      expect(wrapper.text()).toMatch('12/22');
    });

    it('renders packages', () => {
      expect(wrapper.find('.package-block')).toHaveLength(1);
      expect(wrapper.text()).toMatch('12" x 12" x 12"');
    });


    describe('submit button', () => {
      it('has label "Confirm" with status = ""', () => {
        wrapper.setProps({ status: '' });
        expect(wrapper.find('.shipment-confirmation-btn').at(1).text()).toEqual('Confirm');
      });

      it('has label "Confirming..." with status = "loading"', () => {
        wrapper.setProps({ status: 'loading' });
        expect(wrapper.find('.shipment-confirmation-btn').at(1).text()).toEqual('Processing...');
      });

      it('has label "Confirmed" with status = "success"', () => {
        wrapper.setProps({ status: 'success' });
        expect(wrapper.find('.shipment-confirmation-btn').at(1).text()).toEqual('Finish');
      });
    });
  });

  describe('event handling', () => {
    it('click back button', () => {
      wrapper.find('.shipment-confirmation-btn-back').simulate('click');
      expect(defaultProps.handleBack).toHaveBeenCalled();
    });

    it('click confirm button', () => {
      wrapper.find('.shipment-confirmation-btn').at(1).simulate('click');
      expect(defaultProps.handleConfirm).toHaveBeenCalled();
    });

    it('handleOpenModal', () => {
      wrapper.instance().handleOpenModal([]);
      expect(wrapper.state('showModal')).toEqual(true);
    });

    it('handleCloseModal', () => {
      wrapper.setState({ showModal: true });
      wrapper.instance().handleCloseModal();
      expect(wrapper.state('showModal')).toEqual(false);
    });

    it('handlePrint', () => {
      wrapper.find('.shipment-confirmation-btn').at(0).simulate('click');
      expect(wrapper.state('showModal')).toEqual(true);
    });
  });
});

describe('ConfirmShipment Component with pickup', () => {
  let wrapper;
  const defaultProps = {
    shipping: {
      shipping_address: {
        first_name: 'John',
        last_name: 'Doe',
        company_name: 'Test Company',
        address_line1: '300 Riverside Dr.',
        address_line2: 'apt. 600',
        city: 'Austin',
        phone: '+11111111111',
        email: 'tester@test.com',
        state: 'TX',
        zip_code: '55293',
        country: 'US',
      },
      billing_address: {
        billing_first_name: 'John',
        billing_last_name: 'Doe',
        billing_company_name: 'Test Billing Company',
        billing_address_line1: '351 Billing Rd.',
        billing_address_line2: 'apt. 732',
        billing_city: 'Hawkins',
        billing_phone: '+12222222222',
        billing_email: 'billing@tester.com',
        billing_state: 'IN',
        billing_zip_code: '72495',
        billing_country: 'US',
      },
      pickup_address: {
        pickup_first_name: 'John',
        pickup_last_name: 'Doe',
        pickup_company_name: 'Test Pickup Company',
        pickup_address_line1: '351 Pickup Rd.',
        pickup_address_line2: 'apt. 732',
        pickup_city: 'Hawkins',
        pickup_phone: '+12222222222',
        pickup_email: 'pickup@tester.com',
        pickup_state: 'IN',
        pickup_zip_code: '72495',
        pickup_country: 'US',
      },
      pickup_date_time: {
        pickup_date: '12345612',
        close_time: '2100',
        ready_time: '2000',
      },
      packages: [{
        length: '12',
        width: '12',
        height: '12',
        weight: '12',
        not_weight: false,
      }],
      useMyShipmentAddress: false,
      usePickup: true,
      card_name: 'Tester',
      card: {
        last_4: '4242',
        exp_month: '12',
        exp_year: '22',
      },
    },
    handleConfirm: jest.fn(),
    handleBack: jest.fn(),
    labels: ['base64'],
    status: '',
    amount: '20',
  };

  beforeEach(() => {
    wrapper = mount(<ConfirmShipment {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ConfirmShipment {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.shipment-confirmation-title')).toHaveLength(4);
      expect(wrapper.find('.shipment-confirmation-info')).toHaveLength(4);
      expect(wrapper.find('button')).toHaveLength(5);
      expect(wrapper.text()).toMatch('Amount: $20');
    });

    it('renders pickup info', () => {
      expect(wrapper.text()).toMatch('Pickup information');
      expect(wrapper.text()).toMatch('Pickup Time');
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_date_time.pickup_date);
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_date_time.ready_time);
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_date_time.close_time);
      expect(wrapper.text()).toMatch('Pickup Address');
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_address.pickup_address_line1);
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_address.pickup_address_line2);
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_address.pickup_email);
      expect(wrapper.text()).toMatch(defaultProps.shipping.pickup_address.pickup_phone);
    });
  });
});
