import React from 'react';

const NewPasswordEdit = () => (
  <div className="auth-content">
    <div className="custom-logo-img" />
    <div className="auth-header">Change your password</div>
    <div className="custom-form-group">
      <label htmlFor="user[password]">
        New password
      </label>
      <div>
        <input
          required
          type="password"
          name="user[password]"
          className="shipment-input"
          id="password"
          placeholder="New password"
        />
      </div>
      <label htmlFor="user[password_confirmation]">
        Confirm new password
      </label>
      <div>
        <input
          required
          type="password_confirmation"
          name="user[password_confirmation]"
          className="shipment-input"
          id="password_confirmation"
          placeholder="Confirm new password"
        />
      </div>
    </div>
    <button
      type="submit"
      className="btn-main"
    >
      Change my password
    </button>
    <a href="/users/sign_in" className="btn-alt">Sign In</a>
    <a href="/users/sign_up" className="btn-alt">Sign Up</a>
  </div>
);

export default NewPasswordEdit;
