import React from 'react';
import PropTypes from 'prop-types';
import CustomCalendar from '../ui/Calendar';


const CrayonCalendar = ({ user, calendars }) => (
  <div className="detail-container calendar">
    <div className="detail-title calendar">Events</div>
    <CustomCalendar user={user} calendars={calendars} />
  </div>
);

CrayonCalendar.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  calendars: PropTypes.instanceOf(Array).isRequired,
};

export default CrayonCalendar;
