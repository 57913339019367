import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const EventDetails = ({ event }) => (
  <div className="event-details">
    <div className="container">
      <div className="detail-modal_title">
        {event.title}
      </div>
      <div className="detail-modal_owner">
        {moment(event.start).format('dddd, MMMM D')}
        <div style={{ width: '2rem' }} />
        {moment(event.start).format('h.mm')}
        -
        {moment(event.end).format('h.mma')}
      </div>
      <div className="detail-modal_info">
        Location:&nbsp;
        {event.location}
      </div>
      <div className="detail-modal_info">
        Detail:&nbsp;
        {event.body}
      </div>
    </div>
  </div>
);

EventDetails.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
};

export default EventDetails;
