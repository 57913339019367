// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Table Component rendering renders correctly 1`] = `
<section>
  <button
    aria-describedby={null}
    className="label"
    onBlur={[Function]}
    onClick={[Function]}
    onFocus={[Function]}
    onMouseLeave={[Function]}
    onMouseOver={[Function]}
    onTouchEnd={[Function]}
    onTouchStart={[Function]}
    title="Edit"
    type="button"
  >
    <span
      aria-hidden="true"
      className="material-icons MuiIcon-root-8 img"
    >
      edit
    </span>
  </button>
  <button
    aria-describedby={null}
    className="label"
    onBlur={[Function]}
    onClick={[Function]}
    onFocus={[Function]}
    onMouseLeave={[Function]}
    onMouseOver={[Function]}
    onTouchEnd={[Function]}
    onTouchStart={[Function]}
    title="Delete"
    type="button"
  >
    <span
      aria-hidden="true"
      className="material-icons MuiIcon-root-8 img"
    >
      delete
    </span>
  </button>
  <button
    aria-describedby={null}
    className="label"
    onBlur={[Function]}
    onClick={[Function]}
    onFocus={[Function]}
    onMouseLeave={[Function]}
    onMouseOver={[Function]}
    onTouchEnd={[Function]}
    onTouchStart={[Function]}
    title="Skip this shipment"
    type="button"
  >
    <span
      aria-hidden="true"
      className="material-icons MuiIcon-root-8 img"
    >
      last_page
    </span>
  </button>
  <button
    aria-describedby={null}
    className="label"
    onBlur={[Function]}
    onClick={[Function]}
    onFocus={[Function]}
    onMouseLeave={[Function]}
    onMouseOver={[Function]}
    onTouchEnd={[Function]}
    onTouchStart={[Function]}
    title="Up this shipment"
    type="button"
  >
    <span
      aria-hidden="true"
      className="material-icons MuiIcon-root-8 img"
    >
      arrow_upward
    </span>
  </button>
  <button
    aria-describedby={null}
    className="label"
    onBlur={[Function]}
    onClick={[Function]}
    onFocus={[Function]}
    onMouseLeave={[Function]}
    onMouseOver={[Function]}
    onTouchEnd={[Function]}
    onTouchStart={[Function]}
    title="Down this shipment"
    type="button"
  >
    <span
      aria-hidden="true"
      className="material-icons MuiIcon-root-8 img"
    >
      arrow_downward
    </span>
  </button>
</section>
`;

exports[`Table Component rendering with new params renders correctly 1`] = `<section />`;
