import React from 'react';
import moment from 'moment';
import ProductionData from './ProductionData';
import API from '../../services/api';
import { errorToast, successToast } from '../../services/toast';
import Toast from '../ui/Toast';
import sortHelper from '../../services/sortHelper';
import Table from '../ui/Table';


class ProductionIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productions: [],
      numberOfCases: '',
      error: '',
      totalPages: 1,
      currentPage: 1,
      productionsSortParams: {
        column: 'id',
        type: 'asc',
      },
    };

    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleChangeNumberOfCases = this.handleChangeNumberOfCases.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { productionsSortParams } = this.state;
    this.setState({ productionsSortParams: sortHelper(column, productionsSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const { productions, productionsSortParams } = this.state;
    if (productionsSortParams.column === 'name') return;
    API.loadProductions(page, productionsSortParams).then((res) => {
      this.setState({ currentPage: page, totalPages: res.totalPages, productions: refresh ? res.productions : [...productions, ...res.productions] });
    });
  }

  handleChangeNumberOfCases(e) {
    if (e.target.value.match(/^([1-9][0-9]?)$/gm)) this.setState({ numberOfCases: e.target.value, error: '' });
    else this.setState({ numberOfCases: '', error: '' });
  }

  handleAddNew() {
    const { numberOfCases } = this.state;
    if (!numberOfCases) {
      this.setState({ error: 'Please enter a number of cases 1-99!' });
      return;
    }

    API.production({ number_of_cases: numberOfCases })
      .then(() => {
        this.setState({
          error: '',
          numberOfCases: '',
        });
        this.loadPage(1, true);
        successToast('Success! New productions added.');
      }).catch(() => {
        errorToast();
      });
  }

  render() {
    const {
      productions, error, numberOfCases, productionsSortParams, currentPage, totalPages,
    } = this.state;
    return (
      <div className="detail-container">
        <Toast />
        <div className="detail-title">Crayon Cases</div>
        <div className="tables-container">
          <div className="table-title">Number of completed cases of 100</div>
          <ProductionData error={error} value={numberOfCases} submit={this.handleAddNew} change={this.handleChangeNumberOfCases} />
          <div className="table-title">Recent Entries</div>
          <Table
            headers={['Date', 'Name', 'Number of completed cases']}
            keys={['updated_at', 'name', 'number_of_cases']}
            sortField={productionsSortParams}
            objects={productions}
            onPageChange={this.handlePageChange}
            paginationDisabled={currentPage >= totalPages}
            formatters={{ updated_at: item => moment(item).format('M/D/YYYY') }}
            handleSort={this.handleSort}
          />
        </div>
      </div>
    );
  }
}

export default ProductionIndex;
