import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import PrintModeModal from '../PrintModeModal';

describe('PrintModeModal Component', () => {
  let wrapper;
  const defaultProps = {
    print: jest.fn(),
    close: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<PrintModeModal {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<PrintModeModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('input')).toHaveLength(2);
      expect(wrapper.find('button')).toHaveLength(2);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
    });
  });

  describe('event handling', () => {
    it('handle change', () => {
      wrapper.find('input').at(0).simulate('click');
      expect(wrapper.find('input').at(0).prop('checked')).toBeTruthy();
    });

    it('handle change', () => {
      wrapper.find('input').at(1).simulate('click');
      expect(wrapper.find('input').at(1).prop('checked')).toBeTruthy();
    });

    it('handle cancel', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.close).toHaveBeenCalled();
    });

    it('handle confirm', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.print).toHaveBeenCalled();
    });
  });
});
