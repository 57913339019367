import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import HospitalInformation from '../HospitalInformation';

describe('HospitalInformation Component', () => {
  let wrapper;
  const defaultProps = {
    hospital: {
      hospital_key: 'key',
      email: 'test@test.com',
      name: 'st. John',
      phone: '21333456456',
      address: 'address',
      website: 'www.stjohn.com',
      shipment_type: {
        shipment_schedule_type: 'by_schedule',
      },
    },
    update: true,
    handleCancel: jest.fn(),
    handleSave: jest.fn(),
    handleChange: jest.fn(),
    handleCheck: jest.fn(),
    hospitalInQueue: '',
    handleRadioChange: jest.fn(),
    handleMonthChoose: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<HospitalInformation {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalInformation {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(17);
      expect(wrapper.find('.input-label')).toHaveLength(19);
      expect(wrapper.find('.information-container')).toHaveLength(1);
      expect(wrapper.find('input').at(4).props().value).toEqual(defaultProps.hospital.phone);
    });
  });

  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<HospitalInformation {...defaultProps} />);
    });

    it('change input value', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(defaultProps.handleChange).toHaveBeenCalled();
    });

    it('click "Cancel" button', () => {
      wrapper.find('.cancel').simulate('click');
      expect(defaultProps.handleCancel).toHaveBeenCalled();
    });

    it('click "Save" button', () => {
      wrapper.find('.save').simulate('click');
      expect(defaultProps.handleSave).toHaveBeenCalled();
    });

    it('click month button', () => {
      wrapper.find('.month-item').at(0).simulate('click', { target: { id: 'Jan' } });
      expect(defaultProps.handleMonthChoose).toHaveBeenCalled();
    });
  });
});
