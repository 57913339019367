import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import Attendee from '../Attendee';

describe('Attendee Component', () => {
  let wrapper;
  const defaultProps = {
    user: {},
    number: 1,
    id: 0,
    handleChange: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<Attendee {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<Attendee {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('input')).toHaveLength(3);
      expect(wrapper.find('.input-container')).toHaveLength(3);
      expect(wrapper.find('.input-label')).toHaveLength(3);
    });
  });

  describe('event handling', () => {
    it('handle change', () => {
      wrapper.find('input').at(0).simulate('change', { target: { name: 'first_name', value: 'new' } });
      wrapper.find('input').at(1).simulate('change', { target: { name: 'last_name', value: 'new' } });
      wrapper.find('input').at(2).simulate('change', { target: { name: 'email_name', value: 'new' } });
      expect(defaultProps.handleChange).toHaveBeenCalledTimes(3);
    });
  });
});
