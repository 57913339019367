// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PickupBlock Component rendering renders correctly 1`] = `
<div>
  <div
    className="custom-form-group-title"
  >
    Pickup
  </div>
  <div
    className="shipment-inputs-container"
  >
    <div
      className="check-container full"
    >
      <input
        checked={false}
        id="pickup"
        name="pickup"
        onChange={[Function]}
        type="checkbox"
      />
      <label
        htmlFor="pickup"
      >
        Do you want to pick up?
        <br />
        <div
          className="note"
        >
          (additional charges will be applied)
        </div>
      </label>
    </div>
  </div>
</div>
`;
