import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const PackageInformation = ({ shipment }) => (
  <div id="information" className="package-info">
    <div className="package-title">Package Information</div>
    <div className="package-container">
      <div className="label">Dimensions:</div>
      <span>{`${shipment.length}" x ${shipment.width}" x ${shipment.height}"`}</span>
    </div>
    <div className="package-container">
      <div className="label">Date:</div>
      <span>{shipment.created_at ? moment(shipment.created_at).format('M/D/YYYY') : ''}</span>
    </div>
    <div className="package-container">
      <div className="label">Weight:</div>
      <span>{shipment.weight ? `${shipment.weight} lbs` : ''}</span>
    </div>
  </div>
);

PackageInformation.propTypes = {
  shipment: PropTypes.instanceOf(Object),
};

PackageInformation.defaultProps = {
  shipment: {
    length: '1',
    width: '1',
    height: '1',
  },
};

export default PackageInformation;
