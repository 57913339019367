import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../ui/Table';
import sortHelper from '../../services/sortHelper';
import API from '../../services/api';
import CloseButton from '../ui/CloseButton';

class ProductionView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productions: [],
      totalPages: 1,
      currentPage: 1,
      productionsSortParams: {
        column: 'id',
        type: 'asc',
      },
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { productionsSortParams } = this.state;
    this.setState({ productionsSortParams: sortHelper(column, productionsSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const { productions, productionsSortParams } = this.state;
    if (productionsSortParams.column === 'name') return;
    API.loadProductions(page, productionsSortParams).then((res) => {
      this.setState({ currentPage: page, totalPages: res.totalPages, productions: refresh ? res.productions : [...productions, ...res.productions] });
    });
  }

  render() {
    const { handleClose } = this.props;
    const {
      productions, currentPage, totalPages, productionsSortParams,
    } = this.state;
    return (
      <div className="modal_body detail">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="title">Productions</div>
        <div className="modal_table_container">
          <Table
            headers={['Date', 'Name', 'Number of completed cases']}
            keys={['updated_at', 'name', 'number_of_cases']}
            sortField={productionsSortParams}
            objects={productions}
            onPageChange={this.handlePageChange}
            paginationDisabled={currentPage >= totalPages}
            formatters={{ updated_at: item => moment(item).format('M/D/YYYY') }}
            handleSort={this.handleSort}
          />
        </div>
      </div>
    );
  }
}

ProductionView.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ProductionView;
