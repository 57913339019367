// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DonorSignIn Component rendering renders correctly 1`] = `
<div
  className="auth-content"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="auth-header"
  >
    Sign In
  </div>
  <button
    className="btn-main"
    onClick={[Function]}
    type="button"
  >
    Sign In
  </button>
  <div
    className="auth-footer-text"
  >
    Don’t have an account? 
  </div>
  <a
    className="btn-alt"
    href="/donors/sign_up"
  >
    Register Now
  </a>
  <div
    className="auth-footer-text"
  >
    Or 
  </div>
  <button
    className="btn-alt black"
    onClick={[Function]}
    type="button"
  >
    Continue as a guest
  </button>
</div>
`;
