const printLabel = (value, type, mode) => {
  const url = type === 'donor'
    ? `/api/print/labels/donor?ids=${value};mode=${mode}`
    : `/api/print/labels/hospital?ids=${value};mode=ups`;

  $('<iframe hidden>')
    .attr('src', url)
    .appendTo('body');
};

export default printLabel;
