// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HospitalShipmentsIndex Component rendering renders correctly 1`] = `
<div
  className="detail-container hospital_shipment"
>
  <div
    className="detail-title"
  >
    Hospital Shipments
  </div>
  <div
    className="detail-subtitle"
  >
    Number of cases ready for shipment: 567
  </div>
  <div
    className="tables-container"
  >
    <div
      className="hospital-shipment-container"
    >
      <div
        className="hospital-shipment-container"
        id="information"
      >
        <div
          className="hospital-shipment_input-container"
        >
          <div
            className="input-label"
          >
            Number of hospitals
          </div>
          <input
            className="shipment-input"
            onChange={[Function]}
            value=""
          />
        </div>
        <button
          className="prepare-button with-input"
          onClick={[Function]}
          type="button"
        >
          Prepare shipment 
        </button>
      </div>
    </div>
  </div>
</div>
`;
