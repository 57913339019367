import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CloseButton from '../ui/CloseButton';
import Attendee from './Attendee';


const EventSignUpModal = ({
  event, users, handleClose, handleSignUp, handleChangeAttendee, handleChangeAttendeesNumber,
}) => (
  <div className="modal_body detail">
    <div className="modal_button_container top">
      <CloseButton close={handleClose} />
    </div>
    <div className="title event-sign_up">Sign up</div>
    <div className="container sign_up">
      <div className="detail-modal_title">{event.title}</div>
      <div className="detail-modal_owner">
        Owner:&nbsp;
        <b>{event.raw.member.name}</b>
      </div>
      <div className="detail-modal_owner">
        Event lead:&nbsp;
        <b>{event.raw.leader.name}</b>
      </div>
      <div className="detail-modal_info">
        Attendees:&nbsp;
        {event.attendees.length}
        <br />
        {event.attendees.map((a, index) => (
          <span key={index.toString()}>{`${index < event.attendees.length - 1 ? `${a.name}, ` : a.name}`}</span>
        ))}
      </div>
      <div className="detail-modal_info">
        Location:&nbsp;
        {event.location}
      </div>
      <div className="detail-modal_info gray">
        {event.body}
      </div>
    </div>
    <div className="inputs_container event-sign_up">
      <div className="input-container calendar w50">
        <section>
          <div className="input-label">Number of attendees</div>
          <Select
            styles={{
              option: (styles, { isDisabled, isSelected }) => ({
                ...styles,
                backgroundColor: 'transparent',
                color: 'black',
                fontSize: isSelected ? '.875rem' : '.75rem',
                fontWeight: isSelected ? 'bold' : 'normal',
                textDecoration: isSelected ? 'underline' : 'none',
                padding: 0,
                textAlign: 'center',
                lineHeight: '1.2rem',
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':hover': {
                  ...styles[':hover'],
                  fontSize: '.875rem',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                },
              }),
            }}
            onChange={e => handleChangeAttendeesNumber(e.value)}
            options={
                [...Array(event.raw.max_attendees - event.attendees.length)].map((_, item) => (
                  { value: item + 1, label: item + 1 }
                ))
              }
            placeholder=""
            className="user-search event-sign_up"
            classNamePrefix="user-search"
          />
        </section>
      </div>
    </div>
    <section className="attendees-container">
      { users.map((_, item) => (
        <Attendee key={item.toString()} user={users[item]} number={item + 1} handleChange={handleChangeAttendee} id={item} />
      ))}
    </section>
    <div className="inputs_container event-sign_up">
      <div className="input-container calendar comments">
        <section>
          <div className="input-label">Comments</div>
          <input id="comments" />
        </section>
      </div>
    </div>
    <div className="modal_button_container">
      <button type="button" className="modal_button" onClick={() => handleSignUp(event.raw.record_id)}>
        Sign up
      </button>
    </div>
  </div>
);

EventSignUpModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSignUp: PropTypes.func.isRequired,
  handleChangeAttendee: PropTypes.func.isRequired,
  handleChangeAttendeesNumber: PropTypes.func.isRequired,
};

export default EventSignUpModal;
