import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import HospitalContactsAddNew from '../HospitalContactsAddNew';

describe('HospitalContactsAddNew Component', () => {
  let wrapper;
  const defaultProps = {
    handleSave: jest.fn(),
    handleClose: jest.fn(),
    title: 'title',
  };

  beforeEach(() => {
    wrapper = mount(<HospitalContactsAddNew {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalContactsAddNew {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(5);
      expect(wrapper.find('.input-label')).toHaveLength(5);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.modal_button_container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(3);
    });
  });
  describe('event handling', () => {
    it('change input value', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(wrapper.state().inputs.email).toEqual('test1@test.com');
    });

    it('click "Save" button with empty fields', () => {
      wrapper.find('input#phone').simulate('change', { target: { id: 'phone', value: '145fghfg67' } });
      wrapper.find('button').at(2).simulate('click');
      expect(Object.keys(wrapper.state('errors')).length).toEqual(4);
    });

    it('click "Save" button', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      wrapper.find('input#first_name').simulate('change', { target: { id: 'first_name', value: 'street' } });
      wrapper.find('input#last_name').simulate('change', { target: { id: 'last_name', value: 'city' } });
      wrapper.find('input#phone').simulate('change', { target: { id: 'phone', value: '1456737867' } });
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.handleSave).toHaveBeenCalled();
    });
  });
});
