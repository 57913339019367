import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import TermsAndConditions from '../TermsAndConditions';

describe('TermsAndConditions Component', () => {
  let wrapper;
  const defaultProps = {
    confirm: jest.fn(),
    cancel: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<TermsAndConditions {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<TermsAndConditions {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.toc-subtitle')).toHaveLength(12);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
    });
  });

  describe('event handling', () => {
    it('handle confirm', () => {
      wrapper.setState({ disable: false });
      wrapper.find('input').simulate('change', { target: { checked: true } });
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.confirm).toHaveBeenCalled();
    });

    it('handle cancel', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.cancel).toHaveBeenCalled();
    });
  });
});
