import React from 'react';
import { shallow } from 'enzyme';
import renderer from 'react-test-renderer';
import NewPassword from '../newPassword';

describe('DonorSignIn Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = shallow(<NewPassword />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<NewPassword />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.auth-content')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(1);
    });
  });
});
