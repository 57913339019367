import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import CreateIncomingShipmentAnonymous from '../CreateIncomingShipmentAnonymous';

describe('CreateIncomingShipmentAnonymous Component', () => {
  let wrapper;
  const defaultProps = {
    trackingNumber: 'trn1',
    handleSave: jest.fn(),
    anonymousDonor: {},
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<CreateIncomingShipmentAnonymous {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<CreateIncomingShipmentAnonymous {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.incoming_shipment_input-container')).toHaveLength(3);
      expect(wrapper.find('.input-label-anonymous')).toHaveLength(4);
    });
  });
});
