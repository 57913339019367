import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import SendersInformation from '../SendersInformation';

describe('SendersInformation Component', () => {
  let wrapper;
  const defaultProps = {
    donor: {
      email: 'test@test.com',
      first_name: 'John',
      last_name: 'Wick',
      address: 'address',
    },
    errors: {},
    readOnly: false,
    handleChange: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<SendersInformation {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<SendersInformation {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.incoming_shipment_input-container')).toHaveLength(9);
      expect(wrapper.find('.input-label')).toHaveLength(9);
      expect(wrapper.find('.information-container')).toHaveLength(2);
      expect(wrapper.find('.incoming-shipment-container')).toHaveLength(2);
      expect(wrapper.find('input').at(2).props().value).toEqual(defaultProps.donor.email);
    });

    it('renders with errors', () => {
      wrapper.setProps({
        errors: {
          first_name: '1',
          company: '2',
          email: '3',
        },
      });
      expect(Object.keys(wrapper.props().errors).length).toEqual(3);
    });
  });
  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<SendersInformation {...defaultProps} />);
    });

    it('change input value', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(defaultProps.handleChange).toHaveBeenCalled();
    });
  });
});
