import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import TableActions from './TableActions';

function shipmentString(shipmentType) {
  const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (shipmentType.shipment_schedule_type === undefined
    || shipmentType.shipment_schedule_type === 'by_normal') {
    return 'Normal';
  }

  if (shipmentType.shipment_frequency === undefined
    || !shipmentType.shipment_frequency) {
    return 'Schedule';
  }

  return (
    <div className="table-month-col">
      {shipmentType.shipment_frequency && (
        monthsShort.map(item => (
          shipmentType.shipment_frequency.includes(item) && (
            <div key={item} className="month-item-active">{item}</div>
          )
        ))
      )}
    </div>
  );
}

function adminLogContent(content) {
  if (!content) return '';

  return `Incoming source: ${content.incoming_source}. Text: ${content.text}`;
}

function showObjectColumn(item, key) {
  if (key === 'shipment_type') {
    return shipmentString(item[key]);
  }

  if (key === 'content') {
    return adminLogContent(item[key]);
  }

  return '';
}

function showCellValue(formatters, item, key, whiteSpaceWrapper) {
  return (
    <div className={whiteSpaceWrapper ? 'cell-wrapper' : ''}>
      {formatters[key] ? formatters[key](item[key], item) : item[key]}
    </div>
  );
}

const Table = ({
  handleSelect, pagination, headers, keys, id, onCreateNew, withCheckBox, onPageChange,
  paginationDisabled, formatters, objects, handleSort, handleCheckBoxChange, editable,
  handleEdit, handleDelete, sortField, filters, checkedRows, actionKeys, handleSkipQueue,
  handleUpQueue, handleDownQueue, showCreateNew, whiteSpaceWrapper, withRowNumber,
}) => (
  <div id={id} className="box with-actions">
    <div className={showCreateNew ? 'table-actions' : 'table-without-actions'}>
      {showCreateNew && (
        <div className="add-new">
          <div className="label">Create New</div>
          <button type="button" onClick={onCreateNew}>+</button>
        </div>
      )}
      {filters ? filters() : (
        <div className="filters">
          <div className="label">
            <Icon className="img">filter_list</Icon>
          &nbsp;Filter
          </div>
        </div>
      )}
    </div>
    <div className="box box-default table-box table-responsive">
      <table className="mdl-data-table table-selectable table-bordered">
        <thead>
          <tr>
            {withCheckBox && <th className="mdl-data-table__cell--non-numeric" />}
            {withRowNumber && <th className="mdl-data-table__cell--non-numeric">#</th>}
            {headers.map((item, index) => (
              <th key={item} className="mdl-data-table__cell--non-numeric" onClick={() => handleSort(keys[index])}>
                {item}
                <Icon className={`sort ${!(sortField && (sortField.column === keys[index])) && 'hidden'}`}>{sortField.type === 'desc' ? 'arrow_downward' : 'arrow_upward'}</Icon>
              </th>
            ))}
            {objects.length > 0 && editable && <th className="mdl-data-table__cell--non-numeric editable">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {objects.map((item, index) => (
            <tr key={index.toString()}>
              {withCheckBox && (
              <td className="mdl-data-table__cell--non-numeric">
                <div className="checkbox-cell">
                  <input type="checkbox" id={`hscb-${index}`} checked={checkedRows.find(cr => cr.id === item.id)} onChange={() => handleCheckBoxChange(item)} />
                  <label htmlFor={`hscb-${index}`} />
                </div>
              </td>
              )}
              {withRowNumber && (
                <td
                  className="mdl-data-table__cell--non-numeric"
                  onClick={() => handleSelect(item.id)}
                  onKeyDown={() => handleSelect(item.id)}
                  role="gridcell"
                >
                  {index + 1}
                </td>
              )}
              {keys.map(key => (
                <td
                  key={key}
                  className="mdl-data-table__cell--non-numeric"
                  onClick={() => handleSelect(item.id)}
                  onKeyDown={() => handleSelect(item.id)}
                  role="gridcell"
                >
                  {(typeof (item[key]) === 'object' || item[key] === '{}') && showObjectColumn(item, key)}
                  {(typeof (item[key]) !== 'object' && item[key] !== '{}') && showCellValue(formatters, item, key, whiteSpaceWrapper)}
                </td>
              ))}
              {objects.length > 0 && editable && (
              <td className="mdl-data-table__cell--non-numeric editable">
                <TableActions
                  actionKeys={actionKeys}
                  handleSkipQueue={handleSkipQueue}
                  handleUpQueue={handleUpQueue}
                  handleDownQueue={handleDownQueue}
                  object={item}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {pagination && (
    <div className="table--view-more">
      <button disabled={paginationDisabled} type="button" className="button" onClick={onPageChange}>
        View more ▼
      </button>
    </div>
    )}
  </div>
);


Table.propTypes = {
  headers: PropTypes.instanceOf(Array),
  objects: PropTypes.instanceOf(Array),
  keys: PropTypes.instanceOf(Array),
  checkedRows: PropTypes.instanceOf(Array),
  sortField: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  handleSelect: PropTypes.func,
  pagination: PropTypes.bool,
  onCreateNew: PropTypes.func,
  onPageChange: PropTypes.func,
  handleSort: PropTypes.func,
  filters: PropTypes.func,
  paginationDisabled: PropTypes.bool,
  formatters: PropTypes.instanceOf(Object),
  withCheckBox: PropTypes.bool,
  handleCheckBoxChange: PropTypes.func,
  editable: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  actionKeys: PropTypes.instanceOf(Array),
  handleSkipQueue: PropTypes.func,
  handleUpQueue: PropTypes.func,
  handleDownQueue: PropTypes.func,
  showCreateNew: PropTypes.bool,
  whiteSpaceWrapper: PropTypes.bool,
  withRowNumber: PropTypes.bool,
};

Table.defaultProps = {
  headers: [],
  objects: [],
  keys: [],
  checkedRows: [],
  sortField: {},
  id: '',
  handleSelect: () => {},
  onCreateNew: () => {},
  pagination: true,
  onPageChange: () => {},
  handleSort: () => {},
  paginationDisabled: false,
  formatters: {},
  withCheckBox: false,
  handleCheckBoxChange: () => {},
  editable: false,
  filters: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  actionKeys: ['edit', 'delete'],
  handleSkipQueue: () => {},
  handleUpQueue: () => {},
  handleDownQueue: () => {},
  showCreateNew: true,
  whiteSpaceWrapper: false,
  withRowNumber: true,
};

export default Table;
