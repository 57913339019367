import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import HospitalsIndex from '../HospitalsIndex';

jest.mock('../../../services/api', () => ({
  createHospital: jest.fn(() => Promise.resolve({})),
  loadHospitals: jest.fn(() => Promise.resolve({
    hospitals: [
      {
        id: '1',
        hospital_key: 'key',
        name: 'St. John Wick',
        address: 'address',
        number_of_contacts: '2',
      },
      {
        id: '2',
        hospital_key: 'key',
        name: 'St. John Wick jr',
        address: 'address2',
        number_of_contacts: '2',
      },
    ],
    totalPages: 3,
  })),
}));

describe('HospitalsIndex Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(<HospitalsIndex />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalsIndex />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.text()).toMatch('Crayon Hospitals');
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(3);
    });
  });
  describe('events', () => {
    it('handleCreateNew', () => {
      wrapper.instance().handleCreateNew();
      expect(wrapper.state().showModal).toEqual(true);
    });

    it('handleCloseModal', () => {
      wrapper.instance().handleCloseModal();
      expect(wrapper.state().showModal).toEqual(false);
    });

    it('handleSaveNewHospital', () => {
      wrapper.instance().handleSaveNewHospital({});
      process.nextTick(() => { expect(wrapper.state().showModal).toEqual(false); });
    });

    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('hospitalsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handle searchHospitals', () => {
      wrapper.find('input#search').simulate('change', { target: { value: 'a' } });
      expect(wrapper.state('searchQuery')).toEqual('a');
    });

    it('handle filter', () => {
      wrapper.find('input#verified').simulate('change', { target: { id: 'verified', checked: false } });
      wrapper.find('input#approved').simulate('change', { target: { id: 'approved', checked: false } });
      expect(wrapper.state('filter')).toEqual({
        verified: false,
        approved: false,
      });
    });
  });
});
