// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AdminLogsIndex Component rendering renders correctly 1`] = `
<div
  className="detail-container index_page"
>
  <div
    className="details-header"
  >
    <div
      className="detail-title"
    >
      Admin Logs
    </div>
    <div
      className="search"
    >
      <input
        id="search"
        onChange={[Function]}
        value=""
      />
      <span>
        Search
      </span>
    </div>
  </div>
  <div
    className="box with-actions"
    id=""
  >
    <div
      className="table-without-actions"
    />
    <div
      className="box box-default table-box table-responsive"
    >
      <table
        className="mdl-data-table table-selectable table-bordered"
      >
        <thead>
          <tr>
            <th
              className="mdl-data-table__cell--non-numeric"
            >
              #
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Created Date
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort false"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Who caused the changes
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Object name
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Description about changes
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
    <div
      className="table--view-more"
    >
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        View more ▼
      </button>
    </div>
  </div>
</div>
`;
