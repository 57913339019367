import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import StickyBar from '../StickyBar';

jest.mock('react-scroll', () => ({ Link: () => <div /> }));


describe('StickyBar Component', () => {
  let wrapper;
  const defaultProps = {
    tabs: ['tab1', 'tab2', 'tab3', 'tab4', 'tab5'],
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<StickyBar {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<StickyBar {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.sticky-bar')).toHaveLength(1);
      expect(wrapper.find('.items-container')).toHaveLength(1);
      expect(wrapper.find('.sticky-bar--item')).toHaveLength(5);
    });
  });
});
