import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import scrollToError from '../../services/scrollHelper';
import defaultAddress from '../../helpers/constants';

class CreateIncomingShipmentAnonymous extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pack: {
        length: '',
        width: '',
        height: '',
        weight: '',
        trackingNumber: props.trackingNumber,
        zipCode: '',
      },
      selectedDonor: {
        first_name: '',
        last_name: '',
        email: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        company: '',
        anonymous: true,
      },
      errors: [],
    };
  }

  componentDidMount() {
    const { anonymousDonor } = this.props;
    this.setState({ selectedDonor: anonymousDonor });
  }

  hasErrors = () => {
    const { errors } = this.state;
    return Object.values(errors).some(item => item);
  };

  handleSubmit = () => {
    const { pack, selectedDonor } = this.state;
    selectedDonor.anonymous = true;

    if (pack.trackingNumber.length === 0) {
      pack.trackingNumber = `TCI-${moment().format('YYYY-DD-MM-mm-ss')}`;
    }

    if (pack.zipCode.length === 0) { pack.zipCode = defaultAddress.zipCode; }

    const { handleSave } = this.props;
    Object.keys(pack).forEach(field => this.validatePackage(field));

    if (this.hasErrors()) {
      scrollToError('.errors:not(:empty)');
      return;
    }

    handleSave({ pack, selectedDonor });
  };

  validatePackage = (fieldName) => {
    const { errors, pack } = this.state;
    const value = pack[fieldName];
    const weight = value;
    let valid;

    switch (fieldName) {
      case 'weight':
        valid = weight.match(/^([1-9]\d*|0)?(?:\.\d+)?$/gm) && parseFloat(weight) > 0;
        errors[fieldName] = valid ? '' : 'Weight must be a number greater than 0!';
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  handleChangePackage = (e) => {
    const { pack } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    pack[name] = value;
    this.setState({ pack }, () => this.validatePackage(name));
  };

  render() {
    const {
      pack, errors,
    } = this.state;

    return (
      <div className="tables-container" tabIndex={-1} onKeyDown={e => (e.key === 'Enter' && this.handleSubmit())} role="button">
        <div className="incoming_shipment_title">Package Information</div>
        <div className="incoming-shipment-container tracking-number">
          <div className="incoming-shipment-anonymous">
            <div className="anonymous-package-prop">
              <div className="input-label incoming_shipment_input-container tracking-number input-label-anonymous">Tracking Number</div>
              <input placeholder="Leave empty if unknown" id="trackingNumber" className="shipment-input input-anonymous" value={pack.trackingNumber} onChange={this.handleChangePackage} />
            </div>
            <div className="anonymous-package-prop">
              <div className="input-label incoming_shipment_input-container tracking-number input-label-anonymous">Weight</div>
              <input id="weight" className="shipment-input input-anonymous" value={pack.weight} onChange={this.handleChangePackage} />
              <span className="input-label-anonymous">&nbsp;lbs</span>
            </div>
            <div className="anonymous-package-prop">
              <div className="input-label incoming_shipment_input-container tracking-number input-label-anonymous">Zip code</div>
              <input placeholder="94526" id="zipCode" className="shipment-input input-anonymous" value={pack.zipCode} onChange={this.handleChangePackage} />
            </div>
            <div className="anonymous-package-prop">
              <div className="error">{errors.weight && errors.weight}</div>
            </div>
          </div>
        </div>
        <button type="button" className="incoming-shipment_btn blue" onClick={this.handleSubmit}>Save</button>
      </div>
    );
  }
}

CreateIncomingShipmentAnonymous.propTypes = {
  trackingNumber: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  anonymousDonor: PropTypes.instanceOf(Object),
};

CreateIncomingShipmentAnonymous.defaultProps = {
  anonymousDonor: null,
};

export default CreateIncomingShipmentAnonymous;
