import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import Table from '../Table';

describe('Table Component', () => {
  let wrapper;
  const defaultProps = {
    handleSelect: jest.fn(),
    onPageChange: jest.fn(),
    handleEdit: jest.fn(),
    handleDelete: jest.fn(),
    filters: jest.fn(),
    pagination: true,
    editable: true,
    headers: ['ID', 'Name', 'Email', 'Phone', 'Shipment type'],
    objects: [
      {
        id: '1',
        name: 'test',
        email: 'test@test.com',
        phone: '12344567890',
        shipment_type: {},
      },
      {
        id: '2',
        name: 'test2',
        email: 'test2@test.com',
        phone: '12344562890',
        shipment_type: {},
      },
      {
        id: '3',
        name: 'test3',
        email: 'test3@test.com',
        phone: '12344562893',
        shipment_type: {
          shipment_schedule_type: 'by_normal',
        },
      },
      {
        id: '4',
        name: 'test4',
        email: 'test4@test.com',
        phone: '12344562894',
        shipment_type: {
          shipment_schedule_type: 'by_schedule',
        },
        queue_number: null,
      },
      {
        id: '5',
        name: 'test5',
        email: 'test5@test.com',
        phone: '12344562895',
        shipment_type: {
          shipment_schedule_type: 'by_schedule',
          shipment_frequency: ['Jan', 'Dec'],
        },
      },
    ],
    keys: ['id', 'name', 'email', 'phone', 'shipment_type'],
    id: '1',
    showCreateNew: true,
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<Table {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<Table {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.with-actions').props().id).toEqual(defaultProps.id);
      expect(wrapper.find('.table-actions')).toHaveLength(1);
      expect(wrapper.find('.table-month-col')).toHaveLength(1);
      expect(wrapper.find('.month-item-active')).toHaveLength(2);
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('thead')).toHaveLength(1);
      expect(wrapper.find('tbody')).toHaveLength(1);
      expect(wrapper.text()).toMatch('View more ▼');
      expect(wrapper.find('button')).toHaveLength(10);
    });

    it('renders head', () => {
      expect(wrapper.find('thead')).toHaveLength(1);
      expect(wrapper.text()).toMatch('ID');
      expect(wrapper.text()).toMatch('Name');
      expect(wrapper.text()).toMatch('Email');
      expect(wrapper.text()).toMatch('Phone');
      expect(wrapper.text()).toMatch('Shipment type');
    });

    it('renders body', () => {
      expect(wrapper.find('tbody')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(6);
      expect(wrapper.text()).toMatch('test2@test.com');
      expect(wrapper.text()).toMatch('test');
      expect(wrapper.text()).toMatch('12344562890');
      expect(wrapper.text()).toMatch('Normal');
      expect(wrapper.text()).toMatch('Schedule');
      expect(wrapper.text()).toMatch('Dec');
    });

    it('renders no pagination', () => {
      wrapper.setProps({ pagination: false });
      expect(wrapper.text()).not.toMatch('View more ▼');
      expect(wrapper.find('button')).toHaveLength(9);
    });

    it('item select', () => {
      wrapper.find('td').at(1).simulate('click');
      expect(defaultProps.handleSelect).toHaveBeenCalled();
    });
  });

  describe('event handling', () => {
    it('change page', () => {
      wrapper.find('.button').simulate('click');
      expect(defaultProps.onPageChange).toHaveBeenCalled();
    });
    it('handle edit', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleEdit).toHaveBeenCalled();
    });
    it('handle delete', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.handleDelete).toHaveBeenCalled();
    });
  });
});
