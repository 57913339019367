import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const TableActions = ({
  object, actionKeys, handleSkipQueue, handleUpQueue, handleDownQueue, handleEdit,
  handleDelete,
}) => {
  const actions = actionKeys.map((action) => {
    let handler;
    let title;

    if (action === 'edit') {
      handler = () => handleEdit(object);
      title = 'Edit';
    } else if (action === 'delete') {
      handler = () => handleDelete(object);
      title = 'Delete';
    } else if (action === 'last_page') {
      handler = () => handleSkipQueue(object);
      title = 'Skip this shipment';
    } else if (action === 'arrow_upward') {
      handler = () => handleUpQueue(object);
      title = 'Up this shipment';
    } else if (action === 'arrow_downward') {
      handler = () => handleDownQueue(object);
      title = 'Down this shipment';
    }

    if (object.shipment_type !== undefined
      && object.shipment_type.shipment_schedule_type === 'by_schedule'
      && object.queue_number === null) {
      return null;
    }

    return (
      <Tooltip key={action} title={title}>
        <button type="button" className="label" onClick={handler}>
          <Icon className="img">{action}</Icon>
        </button>
      </Tooltip>
    );
  });

  return <section>{actions}</section>;
};

TableActions.propTypes = {
  actionKeys: PropTypes.instanceOf(Array),
  handleSkipQueue: PropTypes.func,
  handleUpQueue: PropTypes.func,
  handleDownQueue: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  object: PropTypes.instanceOf(Object),
};

TableActions.defaultProps = {
  actionKeys: ['edit', 'delete'],
  handleSkipQueue: () => {},
  handleUpQueue: () => {},
  handleDownQueue: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  object: {},
};

export default TableActions;
