import React from 'react';
import moment from 'moment';
import Table from '../ui/Table';
import API from '../../services/api';
import sortHelper from '../../services/sortHelper';
import Toast from '../ui/Toast';

class InTransitShipmentsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.numberFormat = new Intl.NumberFormat('en-US', {
      useGrouping: true,
    });

    this.state = {
      shipments: [],
      archivedShipments: [],
      sortParams: {
        column: 'id',
        type: 'desc',
      },
      sortParamsArchived: {
        column: 'id',
        type: 'desc',
      },
      currentPage: 1,
      totalPages: 1,
      archivedShipmentsCount: 0,
    };

    this.loadShipments = this.loadShipments.bind(this);
    this.loadArchivedShipments = this.loadArchivedShipments.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleSortArchived = this.handleSortArchived.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.loadShipments();
    this.loadArchivedShipments(1);
  }

  handleSort(column) {
    const { sortParams } = this.state;
    this.setState({ sortParams: sortHelper(column, sortParams) }, () => this.loadShipments());
  }

  handleSortArchived(column) {
    const { sortParamsArchived } = this.state;
    this.setState({ sortParamsArchived: sortHelper(column, sortParamsArchived) }, () => this.loadArchivedShipments(1, true));
  }

  loadShipments() {
    const { sortParams } = this.state;
    if (sortParams.column === 'donor') return;
    API.loadInTransitShipments(sortParams).then((res) => {
      this.setState({
        shipments: res.shipments,
      });
    });
  }

  loadArchivedShipments(page, refresh = false) {
    const { archivedShipments, sortParamsArchived } = this.state;

    API.loadInTransitArchivedShipments(page, sortParamsArchived).then((res) => {
      this.setState({
        currentPage: page,
        totalPages: res.totalPages,
        archivedShipmentsCount: res.archivedShipmentsCount,
        archivedShipments: refresh ? res.archivedShipments : [...archivedShipments, ...res.archivedShipments],
      });
    });
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadArchivedShipments(page);
  }

  render() {
    const {
      shipments, archivedShipments, sortParams, sortParamsArchived, currentPage, totalPages,
      archivedShipmentsCount,
    } = this.state;
    return (
      <div className="detail-container index_page">
        <Toast />
        <div className="details-header">
          <div className="detail-title">
            In-transit Shipments (
            { shipments.length }
            )
          </div>
        </div>
        <Table
          headers={['ID', 'Donor', 'Date', 'Amount, $', 'Weight', 'Tracking Number', 'Shipment Status', 'Mail Notification']}
          keys={['id', 'donor', 'created_at', 'amount', 'weight', 'tracking_number', 'shipment_status', 'notification_status']}
          formatters={{
            created_at: item => moment(item).format('M/D/YYYY h:mm:ss A'),
            tracking_number: item => <a target="_blank" rel="noopener noreferrer" href={`https://www.ups.com/track?loc=en_US&tracknum=${item}`} className="tn-link-btn">{item}</a>,
          }}
          sortField={sortParams}
          objects={shipments}
          handleSort={this.handleSort}
          pagination={false}
          showCreateNew={false}
        />
        <div className="details-header">
          <div className="detail-title">
            Archived (
            {this.numberFormat.format(archivedShipmentsCount)}
            )
          </div>
        </div>
        <Table
          headers={['ID', 'Donor', 'Date', 'Amount, $', 'Weight', 'Tracking Number', 'Shipment Status', 'Mail Notification']}
          keys={['id', 'donor_full_name', 'created_at', 'amount', 'weight', 'tracking_number', 'shipment_status', 'notification_status']}
          formatters={{
            created_at: item => moment(item).format('M/D/YYYY h:mm:ss A'),
            tracking_number: item => <a target="_blank" rel="noopener noreferrer" href={`https://www.ups.com/track?loc=en_US&tracknum=${item}`} className="tn-link-btn">{item}</a>,
          }}
          sortField={sortParamsArchived}
          objects={archivedShipments}
          handleSort={this.handleSortArchived}
          onPageChange={this.handlePageChange}
          paginationDisabled={currentPage >= totalPages}
          showCreateNew={false}
        />
      </div>
    );
  }
}

export default InTransitShipmentsIndex;
