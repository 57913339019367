import React from 'react';
import ReactModal from 'react-modal';
import Table from '../ui/Table';
import sortHelper from '../../services/sortHelper';
import API from '../../services/api';
import DeleteModal from '../ui/DeleteModal';
import { errorToast, successToast } from '../../services/toast';
import UserAddUpdateModal from './UserAddUpdateModal';
import Toast from '../ui/Toast';

class UsersIndex extends React.Component {
  constructor(props) {
    super(props);

    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleSaveNewUser = this.handleSaveNewUser.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.handleCreateNew = this.handleCreateNew.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.searchUsers = this.searchUsers.bind(this);

    this.state = {
      users: [],
      totalPages: 1,
      currentPage: 1,
      usersSortParams: {
        column: 'id',
        type: 'asc',
      },
      showDeleteModal: false,
      showAddUserModal: false,
      showEditUserModal: false,
      selectedObject: {},
      error: '',
      searchQuery: '',
      usersCount: 0,
    };

    this.numberFormat = new Intl.NumberFormat('en-US', {
      useGrouping: true,
    });
  }

  componentDidMount() {
    this.loadPage(1);
  }

  handleUserSelect(id) {
    window.open(`/users/${id}`);
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { usersSortParams } = this.state;
    this.setState({ usersSortParams: sortHelper(column, usersSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const { users, usersSortParams, searchQuery } = this.state;
    API.loadUsers(page, usersSortParams, searchQuery).then((res) => {
      this.setState({
        currentPage: page, totalPages: res.totalPages, usersCount: res.usersCount, users: refresh ? res.users : [...users, ...res.users],
      });
    });
  }

  handleDeleteUser() {
    const { selectedObject } = this.state;
    API.deleteUser(selectedObject.id).then(() => {
      this.setState({ showDeleteModal: false });
      this.loadPage(1, true);
      successToast('Success! Contact deleted.');
    }).catch(() => {
      this.setState({ showDeleteModal: false });
      errorToast();
    });
  }

  handleOpenEditModal(obj) {
    this.setState({ showEditUserModal: true, selectedObject: obj });
  }

  handleOpenDeleteModal(obj) {
    this.setState({ showDeleteModal: true, selectedObject: obj });
  }

  handleSaveNewUser(user) {
    API.createUser(user).then((res) => {
      if (res.error) {
        this.setState({ error: res.error });
      } else {
        this.setState({ showAddUserModal: false });
        this.loadPage(1, true);
        successToast('Success! New user added.');
      }
    }).catch(() => {
      this.setState({ showAddUserModal: false });
      errorToast();
    });
  }

  handleUpdateUser(contact) {
    const { selectedObject } = this.state;
    API.updateUser(selectedObject.id, contact).then((res) => {
      if (res.error) {
        this.setState({ error: res.error });
      } else {
        this.setState({ showEditUserModal: false });
        this.loadPage(1, true);
        successToast('Success! Contact updated.');
      }
    }).catch(() => {
      this.setState({ showEditUserModal: false });
      errorToast();
    });
  }

  handleCreateNew() {
    this.setState({ showAddUserModal: true });
  }

  handleCloseModal() {
    this.setState({ showAddUserModal: false });
  }

  handleCloseEditModal() {
    this.setState({ showEditUserModal: false });
  }

  handleCloseDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  searchUsers(e) {
    this.setState({ searchQuery: e.target.value }, () => this.loadPage(1, true));
  }

  render() {
    const {
      users, totalPages, currentPage, usersSortParams, showDeleteModal, showAddUserModal,
      selectedObject, showEditUserModal, error, usersCount, searchQuery,
    } = this.state;
    return (
      <div className="detail-container index_page">
        <Toast />
        <div className="details-header">
          <div className="detail-title">
            Users (
            {this.numberFormat.format(usersCount)}
            )
          </div>
          <div className="search">
            <input id="search" value={searchQuery || ''} onChange={this.searchUsers} />
            <span>Search</span>
          </div>
        </div>
        <Table
          headers={['ID', 'First Name', 'Last Name', 'Email', 'Role']}
          keys={['id', 'first_name', 'last_name', 'email', 'role']}
          sortField={usersSortParams}
          objects={users}
          onPageChange={this.handlePageChange}
          paginationDisabled={currentPage >= totalPages}
          // handleSelect={this.handleUserSelect}
          handleSort={this.handleSort}
          editable
          handleEdit={this.handleOpenEditModal}
          handleDelete={this.handleOpenDeleteModal}
          onCreateNew={this.handleCreateNew}
        />
        <ReactModal
          isOpen={showDeleteModal}
          onRequestClose={this.handleCloseDeleteModal}
          className="modal delete"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <DeleteModal
            title="Confirm delete user"
            subtitle="Are you sure you want to delete the user?"
            handleDelete={this.handleDeleteUser}
            handleClose={this.handleCloseDeleteModal}
          />
        </ReactModal>
        <ReactModal
          isOpen={showEditUserModal}
          onRequestClose={this.handleCloseEditModal}
          className="modal contact user"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <UserAddUpdateModal
            handleClose={this.handleCloseEditModal}
            handleSave={this.handleUpdateUser}
            user={selectedObject}
            error={error}
            title="Edit user"
          />
        </ReactModal>
        <ReactModal
          isOpen={showAddUserModal}
          onRequestClose={this.handleCloseModal}
          className="modal contact user"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <UserAddUpdateModal
            handleClose={this.handleCloseModal}
            handleSave={this.handleSaveNewUser}
            error={error}
            title="Add new user"
          />
        </ReactModal>
      </div>
    );
  }
}

export default UsersIndex;
