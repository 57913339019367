import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

class InventoryTable extends React.Component {
  cellBgColor = (count) => {
    const { minValues } = this.props;
    let color;

    switch (minValues.indexOf(count)) {
      case 0: color = '#ff0000'; break;
      case 1: color = '#ed4a30'; break;
      case 2: color = '#ec8185'; break;
      case 3: color = '#ecb1b3'; break;
      default: color = '#ffffff'; break;
    }

    return color;
  };

  render() {
    const {
      object, updateInfo, handleChange, onSave, onUpdate, onCancel, update, error,
    } = this.props;

    return (
      <div className="box padding with-actions">
        <div className="table-actions--supplies">
          <div className="edit">
            <button type="button" className="label" onClick={onUpdate}>
              <Icon className="img">edit</Icon>
              &nbsp;Update inventory
            </button>
          </div>
        </div>
        <div className="box flex box-default table-box table-responsive">
          <table className="mdl-data-table table-bordered with-margins">
            <thead>
              <tr>
                <th className="mdl-data-table__cell--non-numeric">Color</th>
                <th className="mdl-data-table__cell--non-numeric">Number of Sleeves</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(object).map((key, index) => (
                <tr className="no-selectable" key={index.toString()}>
                  <td className="mdl-data-table__cell--non-numeric">
                    <div className="color-cell">
                      <div style={{ backgroundColor: key }} className="color-box" />
                      {key}
                    </div>
                  </td>
                  <td
                    className="mdl-data-table__cell--non-numeric no-padding"
                    style={{ backgroundColor: this.cellBgColor(object[key] || 0) }}
                  >
                    <input
                      className="inventory-input"
                      value={object[key] || ''}
                      onChange={e => handleChange(key, e.target.value)}
                      disabled={!update}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!update ? (
            <div className="update-info">
              <div>
                Updated by:&nbsp;
                {updateInfo.updater}
              </div>
              <div>
                Updated on:&nbsp;
                {moment(updateInfo.update_date).format('M/D/YYYY h:mm:ss A')}
              </div>
            </div>
          ) : (
            <div className="update-buttons--container">
              <button type="button" className="cancel" onClick={onCancel}>Cancel</button>
              <button type="button" className="save" onClick={onSave}>Save</button>
            </div>
          )}
          {error && <span className="error">{error}</span>}
        </div>
      </div>
    );
  }
}

InventoryTable.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  updateInfo: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  update: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  minValues: PropTypes.instanceOf(Array).isRequired,
};

export default InventoryTable;
