const handleSort = (key, keySortType) => {
  let sort = keySortType;
  if (keySortType.column === key && keySortType.type === 'asc') {
    sort.type = 'desc';
  } else if (keySortType.column === key && keySortType.type === 'desc') {
    sort.type = 'asc';
  } else if (keySortType.column !== key) {
    sort = { column: key, type: 'asc' };
  }

  return sort;
};

export default handleSort;
