import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import SelectHospitalsByCount from '../SelectHospitalsByCount';

describe('SelectHospitalsByCount Component', () => {
  let wrapper;
  const defaultProps = {
    handleSelect: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<SelectHospitalsByCount {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<SelectHospitalsByCount {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.hospital-shipment-container')).toHaveLength(1);
      expect(wrapper.find('.input-label')).toHaveLength(1);
      expect(wrapper.find('.hospital-shipment_input-container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(1);
      expect(wrapper.find('input')).toHaveLength(1);
    });
  });
  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<SelectHospitalsByCount {...defaultProps} />);
    });

    it('change input value', () => {
      wrapper.find('input').simulate('change', { target: { value: '12' } });
      expect(wrapper.state().count).toEqual('12');
    });

    it('on select', () => {
      wrapper.find('button').simulate('click');
      expect(defaultProps.handleSelect).toHaveBeenCalled();
    });
  });
});
