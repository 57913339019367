// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PackageInformation Component rendering renders correctly 1`] = `
<div
  className="package-info"
  id="information"
>
  <div
    className="package-title"
  >
    Package Information
  </div>
  <div
    className="package-container"
  >
    <div
      className="label"
    >
      Dimensions:
    </div>
    <span>
      12" x 11" x 21"
    </span>
  </div>
  <div
    className="package-container"
  >
    <div
      className="label"
    >
      Date:
    </div>
    <span>
      11/8/2019
    </span>
  </div>
  <div
    className="package-container"
  >
    <div
      className="label"
    >
      Weight:
    </div>
    <span>
      22 lbs
    </span>
  </div>
</div>
`;
