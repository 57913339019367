import React from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import Table from '../ui/Table';
import API from '../../services/api';
import HospitalAddNew from './HospitalAddNew';
import sortHelper from '../../services/sortHelper';
import { successToast, errorToast } from '../../services/toast';
import Toast from '../ui/Toast';

class HospitalsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.numberFormat = new Intl.NumberFormat('en-US', {
      useGrouping: true,
    });

    this.state = {
      showModal: false,
      hospitals: [],
      totalPages: 1,
      currentPage: 1,
      searchQuery: '',
      hospitalsCount: 0,
      filter: {
        verified: true,
        approved: true,
      },
      hospitalsSortParams: {
        column: 'id',
        type: 'asc',
      },
    };

    this.handleCreateNew = this.handleCreateNew.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSaveNewHospital = this.handleSaveNewHospital.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.searchHospitals = this.searchHospitals.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
    if (sessionStorage.getItem('notification')) {
      successToast(sessionStorage.getItem('notification'));
      sessionStorage.removeItem('notification');
    }
  }

  handleFilter = (e) => {
    const { id, checked } = e.target;
    const { filter } = this.state;

    filter[id] = checked;

    this.setState({ filter }, () => this.loadPage(1, true));
  };

  handleHospitalSelect(id) {
    window.open(`/hospitals/${id}`, '_self');
  }

  handleCreateNew() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleSaveNewHospital(hospital) {
    API.createHospital({ hospital }).then(() => {
      this.setState({ showModal: false });
      successToast('Success! New hospital added.');
    }).catch(() => {
      this.setState({ showModal: false });
      errorToast();
    });
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { hospitalsSortParams } = this.state;
    this.setState({ hospitalsSortParams: sortHelper(column, hospitalsSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const {
      hospitals, hospitalsSortParams, searchQuery, filter,
    } = this.state;
    if (hospitalsSortParams.column === 'full_address') return;
    API.loadHospitals(page, hospitalsSortParams, searchQuery, filter).then((res) => {
      this.setState({
        currentPage: page, totalPages: res.totalPages, hospitalsCount: res.hospitalsCount, hospitals: refresh ? res.hospitals : [...hospitals, ...res.hospitals],
      });
    });
  }

  searchHospitals(e) {
    this.setState({ searchQuery: e.target.value }, () => this.loadPage(1, true));
  }

  render() {
    const {
      hospitals, showModal, totalPages, currentPage, hospitalsSortParams, searchQuery, hospitalsCount, filter,
    } = this.state;
    return (
      <div className="detail-container index_page">
        <Toast />
        <div className="details-header">
          <div className="detail-title">
            Crayon Hospitals (
            {this.numberFormat.format(hospitalsCount)}
            )
          </div>
          <div className="search">
            <input id="search" value={searchQuery || ''} onChange={this.searchHospitals} />
            <span>Search</span>
          </div>
        </div>
        <Table
          headers={['ID', 'Name', 'Number of Contacts', 'Address', 'Number of Shipments', 'Shipped Crayons', 'Last Shipment']}
          keys={['id', 'name', 'number_of_contacts', 'full_address', 'number_of_shipments', 'shipped_crayons', 'last_shipment']}
          sortField={hospitalsSortParams}
          objects={hospitals}
          onPageChange={this.handlePageChange}
          paginationDisabled={currentPage >= totalPages}
          handleSelect={this.handleHospitalSelect}
          filters={() => (
            <div className="hs-filters">
              <div className="checkbox-cell">
                <input type="checkbox" id="verified" checked={filter.verified} onChange={e => this.handleFilter(e)} />
                <label htmlFor="verified">Address Verified</label>
              </div>
              <div className="checkbox-cell">
                <input type="checkbox" id="approved" checked={filter.approved} onChange={e => this.handleFilter(e)} />
                <label htmlFor="approved">Approved for Shipping</label>
              </div>
            </div>
          )}
          onCreateNew={this.handleCreateNew}
          formatters={{
            last_shipment: item => item && moment(item).format('M/D/YYYY h:mm:ss A'),
            full_address: (value, item) => `${item.street1 ? `${item.street1},` : ''} ${item.city ? `${item.city},` : ''} ${item.state || ''} ${item.zip_code || ''}`,
          }}
          handleSort={this.handleSort}
        />
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          className="modal add-new"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <HospitalAddNew handleClose={this.handleCloseModal} handleSave={this.handleSaveNewHospital} />
        </ReactModal>
      </div>
    );
  }
}

export default HospitalsIndex;
