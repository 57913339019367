import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import PickupBlock from '../PickupBlock';

describe('PickupBlock Component', () => {
  let wrapper;
  let defaultProps;

  beforeEach(() => {
    defaultProps = {
      classes: {},
      shippingData: {
        shipping_address: {
          company_name: 'Test Company',
          contact: 'Test Contact',
          address_line1: '300 Riverside Dr.',
          address_line2: 'apt. 600',
          city: 'Austin',
          phone: '1657564665',
          email: 'tester@test.com',
          state: 'TX',
          zip_code: '55293',
          country: 'US',
        },
        pickup_address: {
          pickup_first_name: 'John',
          pickup_last_name: 'Doe',
          pickup_company_name: 'Test Pickup Company',
          pickup_address_line1: '351 Pickup Rd.',
          pickup_address_line2: 'apt. 732',
          pickup_city: 'Hawkins',
          pickup_phone: '+12222222222',
          pickup_email: 'pickup@tester.com',
          pickup_state: 'IN',
          pickup_zip_code: '72495',
          pickup_country: 'US',
        },
        pickup_date_time: {
          pickup_date: '12345612',
          close_time: '2100',
          ready_time: '2000',
        },
        useMyPickupShipmentAddress: true,
        usePickup: false,
      },
      errors: {},
      error: '',
    };
    wrapper = mount(<PickupBlock {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<PickupBlock {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    describe('pickup section', () => {
      it('hides inputs with usePickup = false', () => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, usePickup: false } });
        expect(wrapper.find('input#pickup').prop('checked')).toBeFalsy();
        expect(wrapper.find('input#pickup_date').exists()).toBeFalsy();
        expect(wrapper.find('input#ready_time').exists()).toBeFalsy();
        expect(wrapper.find('input#close_time').exists()).toBeFalsy();
        expect(wrapper.find('input#pickup_phone').exists()).toBeFalsy();
        expect(wrapper.find('textarea#instructions_area').exists()).toBeFalsy();
        expect(wrapper.find('input#my_pickup_shipping').exists()).toBeFalsy();
        expect(wrapper.find('input#pickup_notification').exists()).toBeFalsy();
      });

      it('shows inputs with usePickup = true', () => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, usePickup: true } });
        expect(wrapper.find('input#pickup').prop('checked')).toBeTruthy();
        expect(wrapper.find('input#pickup_date').exists()).toBeTruthy();
        expect(wrapper.find('input#ready_time').exists()).toBeTruthy();
        expect(wrapper.find('input#close_time').exists()).toBeTruthy();
        expect(wrapper.find('input#pickup_phone').exists()).toBeTruthy();
        expect(wrapper.find('textarea#instructions_area').exists()).toBeTruthy();
        expect(wrapper.find('input#my_pickup_shipping').exists()).toBeTruthy();
        expect(wrapper.find('input#pickup_notification').exists()).toBeTruthy();
      });
    });

    describe('pickup address section', () => {
      it('hides inputs with useMyPickupShipmentAddress = true', () => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, usePickup: true, useMyPickupShipmentAddress: true } });
        expect(wrapper.find('input#my_pickup_shipping').prop('checked')).toBeTruthy();
        expect(wrapper.find('input#pickup_company_name')).toHaveLength(0);
        expect(wrapper.find('input#pickup_address_line1')).toHaveLength(0);
        expect(wrapper.find('input#pickup_address_line2')).toHaveLength(0);
        expect(wrapper.find('input#pickup_city')).toHaveLength(0);
        expect(wrapper.find('StateSelect#pickup_state')).toHaveLength(0);
        expect(wrapper.find('TextField#pickup_zip_code')).toHaveLength(0);
        expect(wrapper.find('input#pickup_country')).toHaveLength(0);
        expect(wrapper.find('TextField#pickup_email')).toHaveLength(0);
      });

      it('shows inputs with useMyPickupShipmentAddress = false', () => {
        wrapper.setState({ shippingData: { ...defaultProps.shippingData, usePickup: true, useMyPickupShipmentAddress: false }, individual: false });
        expect(wrapper.find('input#my_pickup_shipping').prop('checked')).toBeFalsy();
        expect(wrapper.find('input#pickup_company_name').prop('value')).toEqual('Test Pickup Company');
        expect(wrapper.find('input#pickup_address_line1').prop('value')).toEqual('351 Pickup Rd.');
        expect(wrapper.find('input#pickup_address_line2').prop('value')).toEqual('apt. 732');
        expect(wrapper.find('input#pickup_city').prop('value')).toEqual('Hawkins');
        expect(wrapper.find('StateSelect#pickup_state').prop('value')).toEqual('IN');
        expect(wrapper.find('TextField#pickup_zip_code').prop('value')).toEqual('72495');
        expect(wrapper.find('TextField#pickup_phone').prop('value')).toEqual('+12222222222');
        expect(wrapper.find('TextField#pickup_email').prop('value')).toEqual('pickup@tester.com');
      });
    });
  });
});
