import React from 'react';
import PropTypes from 'prop-types';
import closeImg from '../../../assets/images/ei_close.svg';

const CloseButton = ({ close }) => (
  <button type="button" className="modal_close_button" onClick={close}>
    <img src={closeImg} alt="" />
  </button>
);

CloseButton.propTypes = {
  close: PropTypes.func.isRequired,
};

export default CloseButton;
