import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Select from 'react-select';
import moment from 'moment';

const supplies = [
  '96ct Sleeves',
  '8ct Cartons (unassembled)',
  '8ct Cartons (assembled)',
  '100ct Cases',
  'Case Pads',
  'Bulk Crayon Storage Boxes',
];


const SuppliesTable = ({
  object, updateInfo, handleChange, onSave, onUpdate, onCancel, update,
}) => (
  <div className="box padding with-actions">
    <div className="table-actions--supplies">
      <div className="edit">
        <button type="button" className="label" onClick={onUpdate}>
          <Icon className="img">edit</Icon>
          &nbsp;Update supplies
        </button>
      </div>
    </div>
    <div className="box flex box-default table-box table-responsive">
      <table className="mdl-data-table table-bordered with-margins">
        <thead>
          <tr>
            <th className="mdl-data-table__cell--non-numeric">Items</th>
            <th className="mdl-data-table__cell--non-numeric">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(object).map((key, index) => (
            <tr className="no-selectable" key={index.toString()}>
              <td className="mdl-data-table__cell--non-numeric">{supplies[index]}</td>
              <td className="mdl-data-table__cell--non-numeric no-padding">
                <Select
                  onChange={s => handleChange(key, s.value)}
                  options={[
                    { value: 'High', label: 'High' },
                    { value: 'Medium', label: 'Medium' },
                    { value: 'Low', label: 'Low' },
                    { value: 'None', label: 'None' },
                  ]}
                  value={{ value: object[key], label: object[key] }}
                  isSearchable={false}
                  isDisabled={!update}
                  className="quantity-select"
                  classNamePrefix="quantity-select"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!update ? (
        <div className="update-info">
          <div>
            Updated by:&nbsp;
            {updateInfo.updater}
          </div>
          <div>
            Updated on:&nbsp;
            {moment(updateInfo.update_date).format('M/D/YYYY h:mm:ss A')}
          </div>
        </div>
      )
        : (
          <div className="update-buttons--container">
            <button type="button" className="cancel" onClick={onCancel}>Cancel</button>
            <button type="button" className="save" onClick={onSave}>Save</button>
          </div>
        )}
    </div>
  </div>
);


SuppliesTable.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  updateInfo: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  update: PropTypes.bool.isRequired,
};

export default SuppliesTable;
