import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ConfirmHospitalShipment from '../ConfirmHospitalShipment';

describe('ConfirmHospitalShipment Component', () => {
  let wrapper;
  const defaultProps = {
    objects: [
      { name: 'name 1', street1: 'addr 1', city: 'City 1' },
      { name: 'name 2', street1: 'addr 2', city: 'City 2' },
      { name: 'name 3', street1: 'addr 3', city: 'City 3' },
    ],
    handleConfirm: jest.fn(),
    handleClose: jest.fn(),
    handleBack: jest.fn(),
    labels: ['base64', 'base64', 'base64'],
    status: '',
    error: '',
    allLabels: 'base64',
  };

  beforeEach(() => {
    wrapper = mount(<ConfirmHospitalShipment {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ConfirmHospitalShipment {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.shipment-package-container')).toHaveLength(1);
      expect(wrapper.find('.download-link')).toHaveLength(7);
      expect(wrapper.find('button')).toHaveLength(9);
    });

    it('renders titles', () => {
      expect(wrapper.text()).toMatch('name 1');
      expect(wrapper.text()).toMatch('addr 1');
      expect(wrapper.text()).toMatch('Print all labels');
    });

    it('renders hospitals', () => {
      expect(wrapper.find('section')).toHaveLength(6);
    });

    describe('submit button', () => {
      it('has label "Confirm" with status = ""', () => {
        wrapper.setProps({ status: '' });
        expect(wrapper.find('.prepare-button').at(1).text()).toEqual('Confirm');
      });

      it('has label "Confirming..." with status = "loading"', () => {
        wrapper.setProps({ status: 'loading' });
        expect(wrapper.find('.prepare-button').at(1).text()).toEqual('Processing');
      });
    });
  });

  describe('event handling', () => {
    it('click confirm button', () => {
      wrapper.find('.prepare-button').at(1).simulate('click');
      expect(defaultProps.handleConfirm).toHaveBeenCalled();
    });

    it('handlePrintLabels', () => {
      wrapper.instance().handlePrintLabels(1);
      expect(wrapper.state().disabledButton).toEqual(true);
    });
  });
});
