// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ProductionData Component rendering renders correctly 1`] = `
<div
  className="information-container"
  id="information"
>
  <div
    className="production-data-section"
  >
    <div
      className="input-container"
    >
      <div
        className="input-label"
      >
        Number of cases
      </div>
      <input
        onChange={[Function]}
        value=""
      />
    </div>
    <div
      className="button-container"
    >
      <button
        className="incoming-shipment_btn blue"
        onClick={[Function]}
        type="button"
      >
        Save
      </button>
    </div>
    
  </div>
</div>
`;
