import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import InTransitShipmentsIndex from '../InTransitShipmentsIndex';

jest.mock('../../../services/api', () => ({
  loadInTransitArchivedShipments: jest.fn(() => Promise.resolve({
    archivedShipments: [
      {
        id: '1',
        donor_full_name: 'Donor 1',
        created_at: '2021-04-23 16:13:34',
        amount: 10,
        weight: 3.4,
        tracking_number: 'TCI-2021-23-04',
        status: 'received',
        notification_status: '',
        shipment_status: 'Pre-Shipment',
      },
      {
        id: '2',
        donor_full_name: 'Donor 2',
        created_at: '2021-04-23 21:17:34',
        amount: 18.9,
        weight: 7.4,
        tracking_number: 'TCI-2021-23-04',
        status: 'received',
        notification_status: '',
        shipment_status: 'Pre-Shipment',
      },
      {
        id: '3',
        donor_full_name: 'Donor 1',
        created_at: '2021-03-23 08:00:14',
        amount: 12.9,
        weight: 5.41,
        tracking_number: 'TCI-2021-23-03',
        status: 'archived',
        notification_status: '',
        shipment_status: 'Pre-Shipment',
      },
    ],
    totalPages: 1,
    archivedShipmentsCount: 3,
  })),

  loadInTransitShipments: jest.fn(() => Promise.resolve({
    shipments: [
      {
        id: '4',
        donor: 'Donor 1',
        created_at: '2021-04-22 16:13:34',
        amount: 16.9,
        weight: 6.40,
        tracking_number: 'TCI-2021-22-04',
        status: 'paid',
        notification_status: '',
        shipment_status: 'Pre-Shipment',
      },
      {
        id: '5',
        donor: 'Donor 2',
        created_at: '2021-04-23 21:17:34',
        amount: 14.9,
        weight: 5.40,
        tracking_number: 'TCI-2021-23-04',
        status: 'paid',
        notification_status: '',
        shipment_status: 'Pre-Shipment',
      },
      {
        id: '6',
        donor: 'Donor 1',
        created_at: '2021-04-23 08:00:14',
        amount: 12.9,
        weight: 4.40,
        tracking_number: 'TCI-2021-23-04',
        status: 'paid',
        notification_status: '',
        shipment_status: 'Pre-Shipment',
      },
    ],
  })),
}));

describe('InTransitShipmentsIndex Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(<InTransitShipmentsIndex />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<InTransitShipmentsIndex />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(2);
      expect(wrapper.find('tr')).toHaveLength(8);
    });

    it('renders titles', () => {
      expect(wrapper.text()).toMatch('Archived (3)');
      expect(wrapper.text()).toMatch('In-transit Shipments (3)');
    });
  });

  describe('events', () => {
    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('sortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handleSortArchived', (cb) => {
      wrapper.instance().handleSortArchived('col');
      process.nextTick(() => { expect(wrapper.state('sortParamsArchived')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });
  });
});
