import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DonorsIndex from '../DonorsIndex';

jest.mock('../../../services/api', () => ({
  createDonor: jest.fn(() => Promise.resolve({})),
  loadDonors: jest.fn(() => Promise.resolve({
    donors: [
      {
        id: 1,
        donor_key: 'key',
        email: 'test@test.com',
        first_name: 'John',
        last_name: 'Wick',
        address: 'address',
      },
      {
        id: 2,
        donor_key: 'key1',
        email: 'test2@test.com',
        first_name: 'John',
        last_name: 'Wick',
        address: 'address2',
      },
    ],
    totalPages: 3,
  })),
}));

describe('DonorsIndex Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(<DonorsIndex />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<DonorsIndex />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.text()).toMatch('Crayon Donors');
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(3);
    });
  });
  describe('events', () => {
    it('handleCreateNew', () => {
      wrapper.instance().handleCreateNew();
      expect(wrapper.state().showModal).toEqual(true);
    });

    it('handleCloseModal', () => {
      wrapper.instance().handleCloseModal();
      expect(wrapper.state().showModal).toEqual(false);
    });

    it('handleSaveNewDonor', (cb) => {
      wrapper.setState({ showModal: true });
      wrapper.instance().handleSaveNewDonor({});
      process.nextTick(() => { expect(wrapper.state().showModal).toEqual(false); cb(); });
    });

    it('handlePageChange next', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('donorsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handle searchDonors', () => {
      wrapper.find('input#search').simulate('change', { target: { value: 'a' } });
      expect(wrapper.state('searchQuery')).toEqual('a');
    });
  });
});
