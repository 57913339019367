import React from 'react';
import { shallow } from 'enzyme';
import renderer from 'react-test-renderer';
import mockDate from 'mockdate';
import CrayonCalendar from '../CrayonCalendar';
import CustomCalendar from '../../ui/Calendar';

jest.mock('../../../services/api', () => ({
  loadCalendars: jest.fn(() => Promise.resolve({
    calendars: '',
  })),
  loadEvents: jest.fn(() => Promise.resolve({
    events: [],
  })),
}));

describe('CrayonCalendar Component', () => {
  let wrapper;
  const defaultProps = {
    user: {},
    calendars: [],
  };

  beforeEach(() => {
    mockDate.set(new Date('09/24/2020'));

    wrapper = shallow(<CrayonCalendar {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<CrayonCalendar {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('.detail-title')).toHaveLength(1);
      expect(wrapper.find(CustomCalendar)).toHaveLength(1);
    });
  });
});
