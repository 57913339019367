import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ProductionData from '../ProductionData';

describe('ProductionData Component', () => {
  let wrapper;
  const defaultProps = {
    submit: jest.fn(),
    change: jest.fn(),
    error: '',
    value: '',
  };

  beforeEach(() => {
    wrapper = mount(<ProductionData {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ProductionData {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(1);
    });
  });

  describe('button click', () => {
    it('click with empty input', () => {
      wrapper.find('button').simulate('click');
      expect(defaultProps.submit).toHaveBeenCalled();
    });
  });
});
