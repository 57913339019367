import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import InventoryTable from './InventoryTable';
import SuppliesTable from './SuppliesTable';
import API from '../../services/api';
import ProductionView from './ProductionView';
import { successToast, errorToast } from '../../services/toast';
import Toast from '../ui/Toast';

class InventoryIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      supplies: props.supplies,
      inventory: props.inventory,
      updateSuppliesInfo: props.updateSuppliesInfo,
      updateInventoriesInfo: props.updateInventoriesInfo,
      updateProductionsInfo: props.updateProductionsInfo,
      numberOfReadyCases: props.numberOfReadyCases,
      updateSupplies: false,
      updateInventories: false,
      error: '',
      showViewModal: false,
      showDeleteModal: false,
      showAddModal: false,
      numberOfCasesCreate: '',
      numberOfCasesDelete: '',
      casesError: '',
    };

    this.handleChangeNumberOfCasesCreate = this.handleChangeNumberOfCasesCreate.bind(this);
    this.handleChangeNumberOfCasesDelete = this.handleChangeNumberOfCasesDelete.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChangeSupply = (key, value) => {
    const { supplies } = this.state;
    supplies[key] = value;
    this.setState({ supplies });
  };

  handleChangeInventory = (key, value) => {
    const { inventory } = this.state;
    if (value.match(/^([1-9][0-9]{0,2}|1000)$/gm)) inventory[key] = value;
    else inventory[key] = '';
    this.setState({ inventory });
  };

  handleSaveSupply = () => {
    const { supplies } = this.state;
    API.suppliesUpdate({ updates: supplies })
      .then((res) => {
        this.setState({ updateSuppliesInfo: res.updateInfo, updateSupplies: false });
        successToast('Success! Supplies updated.');
      }).catch(() => errorToast());
  };

  handleSaveInventory = () => {
    const { inventory } = this.state;
    if (!inventory) return;
    if (Object.values(inventory).some(item => !item)) {
      this.setState({ error: 'All fields must be a number in range 1 - 1000!' });
    } else {
      API.inventoriesUpdate({ updates: inventory })
        .then((res) => {
          this.setState({
            error: '',
            updateInventoriesInfo: res.updateInfo,
            updateInventories: false,
            inventory: res.inventory,
          });
          successToast('Success! Inventories updated.');
        }).catch(() => errorToast());
    }
  };

  handleUpdateSupply = () => this.setState({ updateSupplies: true });

  handleCancelSupply = () => this.setState({ updateSupplies: false });

  handleUpdateInventory = () => this.setState({ updateInventories: true });

  handleCancelInventory = () => this.setState({ updateInventories: false });

  getMinValues = () => {
    const { inventory } = this.state;
    const values = [];
    Object.keys(inventory).forEach(key => (
      values.push(inventory[key])
    ));
    return values
      .sort((a, b) => a - b)
      .filter((item, pos, ary) => !pos || item !== ary[pos - 1])
      .slice(0, 4);
  };

  handleCloseAddModal = () => {
    this.setState({ showAddModal: false, casesError: '' });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, casesError: '' });
  };

  handleChangeNumberOfReadyCases = (number) => {
    this.setState({
      numberOfReadyCases: number,
    });
  };

  handleChangeNumberOfCasesCreate(e) {
    const number = e.target.value.match(/^([1-9][0-9]?)$/gm) ? e.target.value : '';
    this.setState({ numberOfCasesCreate: number });
  }

  handleChangeNumberOfCasesDelete(e) {
    const number = e.target.value.match(/^([1-9][0-9]?)$/gm) ? e.target.value : '';
    this.setState({ numberOfCasesDelete: number });
  }

  handleAddNew() {
    const { numberOfCasesCreate } = this.state;
    if (!numberOfCasesCreate) {
      this.setState({ casesError: 'Please enter a number of cases 1-99!' });
      return;
    }

    API.production({ number_of_cases: numberOfCasesCreate })
      .then((res) => {
        this.setState({
          casesError: '',
          numberOfCasesCreate: '',
          showAddModal: false,
          numberOfReadyCases: res.numberOfReadyCases,
          updateProductionsInfo: res.updateInfo,
        });
        successToast('Success! New productions added.');
      }).catch(() => {
        this.setState({ showAddModal: false });
        errorToast();
      });
  }

  handleDelete() {
    const { numberOfCasesDelete, numberOfReadyCases } = this.state;

    if (!numberOfCasesDelete) {
      this.setState({ casesError: 'Please enter a number of cases 1-99!' });
      return;
    }

    if (numberOfReadyCases < numberOfCasesDelete) {
      errorToast("You can't delete such amount of cases, because the number of ready cases less than the number of cases You want to delete");
    } else {
      API.production({ number_of_cases: -numberOfCasesDelete })
        .then((res) => {
          this.setState({
            casesError: '',
            numberOfCasesDelete: '',
            showDeleteModal: false,
            numberOfReadyCases: res.numberOfReadyCases,
            updateProductionsInfo: res.updateInfo,
          });
          successToast('Success! Cases deleted.');
        }).catch(() => {
          this.setState({ showDeleteModal: false });
          errorToast();
        });
    }
  }

  render() {
    const {
      supplies, updateSupplies, updateInventories, updateSuppliesInfo,
      updateInventoriesInfo, inventory, error, showViewModal, showAddModal,
      numberOfCasesCreate, numberOfCasesDelete, casesError, numberOfReadyCases, updateProductionsInfo, showDeleteModal,
    } = this.state;

    return (
      <div className="detail-container">
        <Toast />
        <div className="detail-title">Inventory</div>
        <div className="tables-container overview">
          <div className="row_container">
            <div className="overview-table-container start">
              <div className="table-title">Crayon Inventory</div>
              <InventoryTable
                object={inventory}
                updateInfo={updateInventoriesInfo}
                handleChange={this.handleChangeInventory}
                onSave={this.handleSaveInventory}
                onUpdate={this.handleUpdateInventory}
                onCancel={this.handleCancelInventory}
                update={updateInventories}
                error={error}
                numberOfReadyCases={numberOfCasesCreate}
                minValues={this.getMinValues()}
              />
            </div>
            <div className="overview-table-container end">
              <div className="table-title">Supplies</div>
              <SuppliesTable
                object={supplies}
                updateInfo={updateSuppliesInfo}
                handleChange={this.handleChangeSupply}
                onSave={this.handleSaveSupply}
                onUpdate={this.handleUpdateSupply}
                onCancel={this.handleCancelSupply}
                update={updateSupplies}
              />
            </div>
          </div>
          <div className="overview-table-container full">
            <div className="table-title">Cases Ready for Shipment</div>
            <div className="production-container">
              <div className="production-actions">
                <div className="add-new">
                  <div className="label">Add</div>
                  <button type="button" onClick={() => this.setState({ showAddModal: true, showDeleteModal: false })}>+</button>
                </div>
                <div className="delete-case">
                  <div className="label">Delete</div>
                  <button type="button" onClick={() => this.setState({ showDeleteModal: true, showAddModal: false })}>-</button>
                </div>
                <div className="edit">
                  <button type="button" onClick={() => this.setState({ showViewModal: true })}>
                    <div className="label">Recent Entries</div>
                    <Icon className="img">more_horiz</Icon>
                  </button>
                </div>
              </div>
              {showAddModal && (
              <div className="add-production-container">
                <div className="table-title">Add productions</div>
                <div className="production-input">
                  <div className="input-label">Number of cases</div>
                  <input value={numberOfCasesCreate} onChange={this.handleChangeNumberOfCasesCreate} />
                </div>
                <div className="error">{casesError}</div>
                <div className="update-buttons--container">
                  <button type="button" className="cancel" onClick={this.handleCloseAddModal}>Cancel</button>
                  <button type="button" className="save" onClick={this.handleAddNew}>Save</button>
                </div>
              </div>
              )}
              {showDeleteModal && (
                <div className="add-production-container">
                  <div className="table-title">Delete cases</div>
                  <div className="production-input">
                    <div className="input-label">Number of cases</div>
                    <input value={numberOfCasesDelete} onChange={this.handleChangeNumberOfCasesDelete} />
                  </div>
                  <div className="error">{casesError}</div>
                  <div className="update-buttons--container">
                    <button type="button" className="cancel" onClick={this.handleCloseDeleteModal}>Cancel</button>
                    <button type="button" className="save" onClick={this.handleDelete}>Delete</button>
                  </div>
                </div>
              )}
              <div className="production-input">
                <div className="input-label">{`Number of cases: ${numberOfReadyCases}`}</div>
              </div>
              <div className="update-info">
                <div>
                  Updated by:&nbsp;
                  {updateProductionsInfo.updater}
                </div>
                <div>
                  Updated on:&nbsp;
                  {moment(updateProductionsInfo.update_date).format('M/D/YYYY h:mm:ss A')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={showViewModal}
          onRequestClose={() => this.setState({ showViewModal: false })}
          className="modal production-view"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <ProductionView handleClose={() => this.setState({ showViewModal: false })} />
        </ReactModal>
      </div>
    );
  }
}

InventoryIndex.propTypes = {
  supplies: PropTypes.instanceOf(Object).isRequired,
  inventory: PropTypes.instanceOf(Object).isRequired,
  updateSuppliesInfo: PropTypes.instanceOf(Object).isRequired,
  updateInventoriesInfo: PropTypes.instanceOf(Object).isRequired,
  updateProductionsInfo: PropTypes.instanceOf(Object).isRequired,
  numberOfReadyCases: PropTypes.number.isRequired,
};

export default InventoryIndex;
