import React from 'react';
import { shallow } from 'enzyme';
import renderer from 'react-test-renderer';
import CreateShipment from '../CreateShipment';
import ShippingForm from '../ShippingForm';
import ConfirmShipping from '../ConfirmShipment';

jest.mock('../../../services/api', () => ({
  shipping: jest.fn(() => Promise.resolve({
    labels: [],
    amount: '100',
    identification_number: 'num',
    allLabels: 'dfghasdragdf',
  })),
  charge: jest.fn(() => Promise.resolve({})),
}));

describe('CreateShipment Component', () => {
  let wrapper;
  const defaultProps = {
    pkToken: 'token',
    donor: {},
  };

  beforeEach(() => {
    wrapper = shallow(<CreateShipment {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<CreateShipment {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders with confirm = false', () => {
      expect(wrapper.find(ShippingForm)).toHaveLength(1);
    });

    it('renders with confirm = true', () => {
      wrapper.setState({ amount: '0', confirm: true });
      expect(wrapper.find(ConfirmShipping)).toHaveLength(1);
    });
  });

  describe('event handling', () => {
    it('handle back', () => {
      wrapper.setState({ amount: '0', confirm: true });
      wrapper.instance().handleBack();
      expect(wrapper.state('confirm')).toEqual(false);
    });
    it('handle submit', (cb) => {
      wrapper.instance().handleSubmit();
      process.nextTick(() => { expect(wrapper.state('status')).toEqual('success'); cb(); });
    });
    it('handle review', (cb) => {
      wrapper.setState({ status: 'loading' });
      wrapper.instance().handleReview({});
      process.nextTick(() => { expect(wrapper.state('status')).toEqual(''); cb(); });
    });
  });
});
