// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ProductionView Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title"
  >
    Productions
  </div>
  <div
    className="modal_table_container"
  >
    <div
      className="box with-actions"
      id=""
    >
      <div
        className="table-actions"
      >
        <div
          className="add-new"
        >
          <div
            className="label"
          >
            Create New
          </div>
          <button
            onClick={[Function]}
            type="button"
          >
            +
          </button>
        </div>
      </div>
      <div
        className="box box-default table-box table-responsive"
      >
        <table
          className="mdl-data-table table-selectable table-bordered"
        >
          <thead>
            <tr>
              <th
                className="mdl-data-table__cell--non-numeric"
              >
                #
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Date
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Name
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
              <th
                className="mdl-data-table__cell--non-numeric"
                onClick={[Function]}
              >
                Number of completed cases
                <span
                  aria-hidden="true"
                  className="material-icons MuiIcon-root-1 sort hidden"
                >
                  arrow_upward
                </span>
              </th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
      <div
        className="table--view-more"
      >
        <button
          className="button"
          disabled={true}
          onClick={[Function]}
          type="button"
        >
          View more ▼
        </button>
      </div>
    </div>
  </div>
</div>
`;
