// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DeleteModal Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title"
  >
    title
  </div>
  <div
    className="sub-title"
  >
    subtitle
  </div>
  <div
    className="button_container"
  >
    <button
      className="incoming-shipments-modal-btn no"
      onClick={[MockFunction]}
      type="button"
    >
      No
    </button>
    <button
      className="incoming-shipments-modal-btn yes"
      onClick={[MockFunction]}
      type="button"
    >
      Yes
    </button>
  </div>
</div>
`;
