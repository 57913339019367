import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../ui/Table';

const SelectHospitals = ({
  handleHospitalSelect, handlePrepare, selectedHospitals, objects, handleSort, sortParams, filter, filterParams, clickHospital,
}) => (
  <React.Fragment>
    <div className="hospital-shipment_table-container">
      <Table
        headers={['ID', 'Name', 'Number of contacts', 'Address', 'Last Shipment', 'Address Verified', 'Approved for Shipping']}
        keys={['id', 'name', 'number_of_contacts', 'address', 'last_shipment', 'address_verified', 'approved_for_shipping']}
        sortField={sortParams}
        objects={objects}
        formatters={{
          last_shipment: item => (item ? moment(item).format('M/D/YYYY h:mm:ss A') : ''),
          name: (value, item) => <button type="button" className="link-btn" onClick={() => clickHospital(item.id)}>{value}</button>,
          address: (value, item) => `${item.city}, ${item.state}`,
          approved_for_shipping: (value, item) => `${item.approved_for_shipping ? 'true' : 'false'}`,
          address_verified: (value, item) => `${item.address_verified ? 'true' : 'false'}`,
        }}
        handleSort={handleSort}
        withCheckBox
        filters={() => (
          <div className="hs-filters">
            <div className="checkbox-cell">
              <input type="checkbox" id="verified" checked={filterParams.verified} onChange={e => filter(e)} />
              <label htmlFor="verified">Address Verified</label>
            </div>
            <div className="checkbox-cell">
              <input type="checkbox" id="approved" checked={filterParams.approved} onChange={e => filter(e)} />
              <label htmlFor="approved">Approved for Shipping</label>
            </div>
          </div>
        )}
        pagination={false}
        handleCheckBoxChange={handleHospitalSelect}
        checkedRows={selectedHospitals}
      />
    </div>
    <button type="button" className="prepare-button" onClick={handlePrepare}>
      Prepare shipment&nbsp;(
      {selectedHospitals.length}
      )
    </button>
  </React.Fragment>
);

SelectHospitals.propTypes = {
  objects: PropTypes.instanceOf(Array),
  selectedHospitals: PropTypes.instanceOf(Array),
  sortParams: PropTypes.instanceOf(Object),
  filterParams: PropTypes.instanceOf(Object).isRequired,
  handleHospitalSelect: PropTypes.func.isRequired,
  handlePrepare: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  clickHospital: PropTypes.func.isRequired,
};

SelectHospitals.defaultProps = {
  objects: [
    {
      id: '1',
      hospital_key: '',
      name: '',
      number_of_contacts: 0,
      city: 'City',
      state: 'ST',
      last_shipment: '',
    },
    {
      id: '2',
      hospital_key: '',
      name: '',
      number_of_contacts: 0,
      city: 'City',
      state: 'ST',
      last_shipment: '',
    },
  ],
  sortParams: {},
  selectedHospitals: [],
};

export default SelectHospitals;
