import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function normalShipment(hospital) {
  return hospital.shipment_type.shipment_schedule_type === undefined
    || hospital.shipment_type.shipment_schedule_type === 'by_normal';
}

function includeMonth(hospital, month) {
  if (hospital.shipment_type.shipment_frequency === undefined) {
    return false;
  }

  return hospital.shipment_type.shipment_frequency.includes(month);
}

function hospitalShipmentTypeInfo(hospital) {
  return normalShipment(hospital) ? 'Normal shipment' : 'Scheduled shipment';
}

const HospitalShipmentType = ({
  hospital, handleChange, handleMonthChoose, update,
}) => {
  const shipmentNormal = normalShipment(hospital);
  const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <>
      {!update && normalShipment(hospital) && (
        <div className="input-container">
          <section>
            <div className="input-label">Shipment type</div>
            <input id="shipment_type" value={hospitalShipmentTypeInfo(hospital)} readOnly disabled />
          </section>
        </div>
      )}
      {!update && !normalShipment(hospital) && (
        <SheduledShipmentType hospital={hospital} />
      )}
      {update && (
        <>
          <div className="input-container-w-100">
            <section>
              <div className="input-label">Shipment type</div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="shipment_type"
                  name="shipment_type"
                  value={hospital.shipment_type.shipment_schedule_type || 'by_normal'}
                  onChange={handleChange}
                >

                  <FormControlLabel
                    className={!shipmentNormal ? '' : 'radio-chose'}
                    value="by_schedule"
                    control={<Radio className="radio-color" />}
                    label="Specific shipment - select months"
                  />
                  <FormControlLabel
                    className={shipmentNormal ? '' : 'radio-chose'}
                    value="by_normal"
                    control={<Radio className="radio-color" />}
                    label="Normal shipment"
                  />
                </RadioGroup>
              </FormControl>
            </section>
          </div>
          <div className="input-container-w-100">
            <section>
              <div className="input-label" />
              <div className={!shipmentNormal ? 'monthes active' : 'monthes'}>
                {monthsShort.map(item => (
                  <button
                    type="submit"
                    key={item}
                    className={includeMonth(hospital, item) && !shipmentNormal ? 'month-item-active' : 'month-item'}
                    onClick={!shipmentNormal ? handleMonthChoose : null}
                    id={item}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

const SheduledShipmentType = ({
  hospital,
}) => {
  const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <div className="input-container-w-100">
      <section>
        <div className="input-label">Shipment type</div>
        <div className="monthes">
          {monthsShort.map(item => (
            <button
              key={item}
              className={includeMonth(hospital, item) ? 'month-item-active' : 'month-item'}
              type="submit"
            >
              {item}
            </button>
          ))}
        </div>
      </section>
    </div>
  );
};

SheduledShipmentType.propTypes = {
  hospital: PropTypes.instanceOf(Object),
};

SheduledShipmentType.defaultProps = {
  hospital: {},
};

HospitalShipmentType.propTypes = {
  hospital: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func.isRequired,
  handleMonthChoose: PropTypes.func.isRequired,
  update: PropTypes.bool.isRequired,
};

HospitalShipmentType.defaultProps = {
  hospital: {},
};

export default HospitalShipmentType;
