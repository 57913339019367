import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DonorSignUp from '../auth/DonorSignUp';

jest.mock('../../../services/api', () => ({
  donorValidation: jest.fn(() => Promise.resolve({
    ok: true,
    err_msg: 'Invalid email or password',
  })),
}));

describe('DonorSignIn Component', () => {
  let wrapper;
  const defaultProps = {
    classes: {
      input: 'str',
    },
  };

  beforeEach(() => {
    wrapper = mount(<DonorSignUp {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<DonorSignUp {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.auth-content')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(2);
    });
  });

  describe('validation', () => {
    it('validates first_name', () => {
      wrapper.find('TextField#first_name input').simulate('change', { target: { id: 'first_name', value: '' } });
      expect(wrapper.find('TextField#first_name').text()).toContain('This field is required');
    });

    it('validates last_name', () => {
      wrapper.find('TextField#last_name input').simulate('change', { target: { id: 'last_name', value: '' } });
      expect(wrapper.find('TextField#last_name').text()).toContain('This field is required');
    });

    it('validates email', () => {
      wrapper.find('TextField#email input').simulate('change', { target: { id: 'email', value: '' } });
      expect(wrapper.find('TextField#email').text()).toContain('Email is invalid');
    });

    it('validates password', () => {
      wrapper.find('TextField#password input').simulate('change', { target: { id: 'password', value: '' } });
      expect(wrapper.find('TextField#password').text()).toContain('Password is too short');
    });

    it('validates password_confirmation', () => {
      wrapper.find('TextField#password_confirmation input').simulate('change', { target: { id: 'password_confirmation', value: '1' } });
      expect(wrapper.find('TextField#password_confirmation').text()).toContain('Passwords don\'t match');
    });
  });

  describe('click Sign In button', () => {
    it('hasErrors called', (cb) => {
      wrapper.find('TextField#first_name input').simulate('change', { target: { id: 'first_name', value: 'John' } });
      wrapper.find('TextField#last_name input').simulate('change', { target: { id: 'last_name', value: 'Wick' } });
      wrapper.find('TextField#email input').simulate('change', { target: { id: 'email', value: 'test@test.te' } });
      wrapper.find('TextField#password input').simulate('change', { target: { id: 'password', value: '123456' } });
      wrapper.find('TextField#password_confirmation input').simulate('change', { target: { id: 'password_confirmation', value: '123456' } });
      wrapper.find('input#agree').simulate('change', { target: { id: 'agree', checked: true } });
      wrapper.find('button').at(0).simulate('click');
      process.nextTick(() => { expect(wrapper.text()).toContain('Invalid email or password'); cb(); });
    });
  });
});
