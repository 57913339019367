import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import API from '../../services/api';
import printLabel from '../../services/printHelper';

class ConfirmHospitalShipment extends React.Component {
  constructor(props) {
    super(props);
    this.refsArray = [];

    this.state = {
      disabledButton: false,
    };

    this.handlePrintLabels = this.handlePrintLabels.bind(this);
  }

  handlePrintLabels(labels) {
    this.setState({ disabledButton: true });
    printLabel(labels, 'hospital');
    setTimeout(() => {
      this.setState({ disabledButton: false });
    }, 3000);
  }

  render() {
    const {
      objects, labels, status, error, handleConfirm, handleClose, handleBack,
    } = this.props;
    const {
      disabledButton,
    } = this.state;

    return (
      <React.Fragment>
        <div className={`shipment-package-container ${objects && objects.length > 6 && 'overflow'}`}>
          {objects && objects.map((item, index) => (
            <section key={index.toString()} style={{ marginBottom: '3rem' }}>
              <div className="package-header">
                <div className="header">{`${index + 1}. ${item.name}`}</div>
                {labels[index] && (
                  <section>
                    <div className="download-link">
                      <button type="button" className="print" onClick={() => API.downloadHospitalLabel({ id: labels[index] })}><Icon className="img">file_download</Icon></button>
                    </div>
                    <div className="download-link">
                      <button
                        type="button"
                        className="print"
                        disabled={disabledButton}
                        onClick={() => this.handlePrintLabels(labels[index])}
                      >
                        <Icon className="img">print</Icon>
                      </button>
                    </div>
                  </section>
                )}
              </div>
              <span>
                {
              `${item.street1}, ${item.city}, ${item.state} ${item.zip_code}`
            }
              </span>
            </section>
          ))}
        </div>
        {error && <div className="error">{error}</div>}
        <div className="button-container">
          <button type="button" className="prepare-button back" onClick={handleBack}>Back</button>
          {
            status !== 'success'
              ? (
                <button disabled={(status === 'loading')} type="button" className="prepare-button" onClick={handleConfirm}>
                  {
                    !status && 'Confirm'
                  }
                  {
                    status === 'loading' && 'Processing'
                  }
                </button>
              )
              : <button type="button" className="prepare-button" onClick={handleClose}>Close</button>
          }
        </div>
        {
          labels.length > 0 && (
          <div className="download-link all">
            <Icon className="img">print</Icon>
            <button
              type="button"
              className="print"
              disabled={disabledButton}
              onClick={() => this.handlePrintLabels(labels)}
            >
              Print all labels
            </button>
          </div>
          )
         }
      </React.Fragment>
    );
  }
}

ConfirmHospitalShipment.propTypes = {
  objects: PropTypes.instanceOf(Array).isRequired,
  labels: PropTypes.instanceOf(Array).isRequired,
  status: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default ConfirmHospitalShipment;
