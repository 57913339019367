import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DonorInformation from '../DonorInformation';

describe('DonorInformation Component', () => {
  let wrapper;
  const defaultProps = {
    donor: {
      donor_key: 'key',
      email: 'test@test.com',
      first_name: 'John',
      last_name: 'Wick',
      address: 'address',
    },
    update: true,
    handleCancel: jest.fn(),
    handleSave: jest.fn(),
    handleChange: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<DonorInformation {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<DonorInformation {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(10);
      expect(wrapper.find('.input-label')).toHaveLength(10);
      expect(wrapper.find('.information-container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(2);
      expect(wrapper.find('input').at(1).props().value).toEqual(defaultProps.donor.email);
    });
  });
  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<DonorInformation {...defaultProps} />);
    });

    it('change input value', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(defaultProps.handleChange).toHaveBeenCalled();
    });

    it('click "Cancel" button', () => {
      wrapper.find('.cancel').simulate('click');
      expect(defaultProps.handleCancel).toHaveBeenCalled();
    });

    it('click "Save" button', () => {
      wrapper.find('.save').simulate('click');
      expect(defaultProps.handleSave).toHaveBeenCalled();
    });
  });
});
