import React from 'react';

const NewPassword = () => (
  <div className="auth-content">
    <div className="custom-logo-img" />
    <div className="auth-header">Reset Password</div>
    <div className="auth-reset-pass-info">
      Enter the
      <span> email address </span>
      associated with your account and we&apos;ll send you an email with instructions and a link to create a new password.
    </div>
    <div className="custom-form-group send">
      <label htmlFor="user[email]">
        Email Address
      </label>
      <div>
        <i className="material-icons">mail</i>
        <input
          required
          type="email"
          name="user[email]"
          className="shipment-input"
          id="email"
          placeholder="Enter email"
        />
      </div>
    </div>
    <button
      type="submit"
      className="btn-main"
    >
      Send
    </button>
    <a href="/users/sign_in" className="btn-link">Back to Sign in</a>
  </div>
);

export default NewPassword;
