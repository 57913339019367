const superAdmins = ['andrew.drach@yahoo.com', 'andrew@solwey.com', 'bryan@thecrayoninitiative.org'];

const isSuperAdmin = user => superAdmins.includes(user);
const isAdmin = user => user.role === 'Admin';
const isVolunteer = user => user.role === 'Volunteer';
const isMember = (user, event) => user.id === event.member.id;
const isLeader = (user, event) => user.id === event.leader.id;
const isAttendee = (user, event) => event.attendees.find(a => a.id === user.id);

export {
  isSuperAdmin, isAdmin, isVolunteer, isMember, isLeader, isAttendee,
};
