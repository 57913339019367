import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import CreateIncomingShipment from '../CreateIncomingShipment';

jest.mock('../../../services/api', () => ({
  searchDonor: jest.fn(() => Promise.resolve({
    donors: [{
      id: 1,
      first_name: 'John',
      last_name: 'Wick',
      city: 'city',
      state: 'state',
      zip_code: '11111',
    }],
  })),
}));

describe('CreateIncomingShipment Component', () => {
  let wrapper;
  const defaultProps = {
    trackingNumber: 'trn1',
    handleSave: jest.fn(),
    withoutTNCount: 1,
    status: '',
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<CreateIncomingShipment {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<CreateIncomingShipment {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.incoming_shipment_input-container')).toHaveLength(2);
      expect(wrapper.find('.input-label')).toHaveLength(3);
    });
  });
  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<CreateIncomingShipment {...defaultProps} />);
      wrapper.setState({ showInfo: true, readOnly: false });
      wrapper.find('input#first_name').simulate('change', { target: { id: 'first_name', value: 'John' } });
      wrapper.find('input#weight').simulate('change', { target: { id: 'weight', value: '12' } });
    });

    it('change input value', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleSave).toHaveBeenCalled();
    });

    it('search', (cb) => {
      wrapper.instance().handleSearch('John');
      process.nextTick(() => {
        const name = wrapper.state().selectedDonor.first_name;
        wrapper.instance().handleSelect(1);
        expect(name).toEqual('John');
        cb();
      });
    });

    it('search by zip code', (cb) => {
      wrapper.instance().handleSearch('11111');
      process.nextTick(() => {
        expect(wrapper.state().donors[0].zip_code).toEqual('11111');
        const zipCode = wrapper.state().donors[0].zip_code;
        expect(zipCode).toEqual('11111');
        cb();
      });
    });
  });
});
