import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DonorSignIn from '../auth/DonorSignIn';

jest.mock('../../../services/api', () => ({
  donorValidation: jest.fn(() => Promise.resolve({
    ok: true,
    err_msg: 'Invalid email or password',
  })),
}));

describe('DonorSignIn Component', () => {
  let wrapper;
  const defaultProps = {
    classes: {
      input: 'str',
    },
    setData: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<DonorSignIn {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<DonorSignIn {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.auth-content')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(2);
    });
  });

  describe('validation', () => {
    beforeEach(() => {
      wrapper.find('.btn-main').simulate('click');
    });

    it('validates email', () => {
      wrapper.find('TextField#email input').simulate('change', { target: { id: 'email', value: '' } });
      expect(wrapper.find('TextField#email').text()).toContain('Email is invalid');
    });

    it('validates password', () => {
      wrapper.find('TextField#password input').simulate('change', { target: { id: 'password', value: '' } });
      expect(wrapper.find('TextField#password').text()).toContain('Password is too short');
    });
  });

  describe('click Sign In button', () => {
    beforeEach(() => {
      wrapper.find('.btn-main').simulate('click');
    });

    it('setData called', (cb) => {
      wrapper.find('TextField#email input').simulate('change', { target: { id: 'email', value: 'test@test.te' } });
      wrapper.find('TextField#password input').simulate('change', { target: { id: 'password', value: '123456' } });
      wrapper.find('button').at(0).simulate('click');
      process.nextTick(() => { expect(wrapper.text()).toContain('Invalid email or password'); cb(); });
    });
  });
});
