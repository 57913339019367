// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Attendee Component rendering renders correctly 1`] = `
<section
  className="attendee"
>
  <div
    className="attendee-title"
  >
    Attendee #1
  </div>
  <div
    className="inputs_container event-sign_up"
  >
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          First name
        </div>
        <input
          name="first_name"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Last name
        </div>
        <input
          name="last_name"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
    <div
      className="input-container calendar"
    >
      <section>
        <div
          className="input-label"
        >
          Email
        </div>
        <input
          name="email"
          onChange={[Function]}
          value=""
        />
      </section>
    </div>
  </div>
</section>
`;
