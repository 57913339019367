// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HospitalShipmentType Component rendering renders correctly 1`] = `
Array [
  <div
    className="input-container-w-100"
  >
    <section>
      <div
        className="input-label"
      >
        Shipment type
      </div>
      <fieldset
        className="MuiFormControl-root-1"
      >
        <div
          aria-label="shipment_type"
          className="MuiFormGroup-root-5"
          role="radiogroup"
        >
          <label
            className="MuiFormControlLabel-root-7"
          >
            <span
              className="MuiButtonBase-root-26 MuiIconButton-root-20 MuiSwitchBase-root-16 MuiRadio-root-11 MuiRadio-colorSecondary-15 MuiSwitchBase-checked-17 MuiRadio-checked-12 radio-color"
              onBlur={[Function]}
              onFocus={[Function]}
              onKeyDown={[Function]}
              onKeyUp={[Function]}
              onMouseDown={[Function]}
              onMouseLeave={[Function]}
              onMouseUp={[Function]}
              onTouchEnd={[Function]}
              onTouchMove={[Function]}
              onTouchStart={[Function]}
              tabIndex={null}
            >
              <span
                className="MuiIconButton-label-25"
              >
                <svg
                  aria-hidden="true"
                  className="MuiSvgIcon-root-29"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                </svg>
                <input
                  checked={true}
                  className="MuiSwitchBase-input-19"
                  disabled={false}
                  name="shipment_type"
                  onChange={[Function]}
                  type="radio"
                  value="by_schedule"
                />
              </span>
              <span
                className="MuiTouchRipple-root-36"
              />
            </span>
            <span
              className="MuiTypography-root-43 MuiTypography-body1-52 MuiFormControlLabel-label-10"
            >
              Specific shipment - select months
            </span>
          </label>
          <label
            className="MuiFormControlLabel-root-7 radio-chose"
          >
            <span
              className="MuiButtonBase-root-26 MuiIconButton-root-20 MuiSwitchBase-root-16 MuiRadio-root-11 MuiRadio-colorSecondary-15 radio-color"
              onBlur={[Function]}
              onFocus={[Function]}
              onKeyDown={[Function]}
              onKeyUp={[Function]}
              onMouseDown={[Function]}
              onMouseLeave={[Function]}
              onMouseUp={[Function]}
              onTouchEnd={[Function]}
              onTouchMove={[Function]}
              onTouchStart={[Function]}
              tabIndex={null}
            >
              <span
                className="MuiIconButton-label-25"
              >
                <svg
                  aria-hidden="true"
                  className="MuiSvgIcon-root-29"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                </svg>
                <input
                  checked={false}
                  className="MuiSwitchBase-input-19"
                  disabled={false}
                  name="shipment_type"
                  onChange={[Function]}
                  type="radio"
                  value="by_normal"
                />
              </span>
              <span
                className="MuiTouchRipple-root-36"
              />
            </span>
            <span
              className="MuiTypography-root-43 MuiTypography-body1-52 MuiFormControlLabel-label-10"
            >
              Normal shipment
            </span>
          </label>
        </div>
      </fieldset>
    </section>
  </div>,
  <div
    className="input-container-w-100"
  >
    <section>
      <div
        className="input-label"
      />
      <div
        className="monthes active"
      >
        <button
          className="month-item"
          id="Jan"
          onClick={[MockFunction]}
          type="submit"
        >
          Jan
        </button>
        <button
          className="month-item"
          id="Feb"
          onClick={[MockFunction]}
          type="submit"
        >
          Feb
        </button>
        <button
          className="month-item"
          id="Mar"
          onClick={[MockFunction]}
          type="submit"
        >
          Mar
        </button>
        <button
          className="month-item"
          id="Apr"
          onClick={[MockFunction]}
          type="submit"
        >
          Apr
        </button>
        <button
          className="month-item"
          id="May"
          onClick={[MockFunction]}
          type="submit"
        >
          May
        </button>
        <button
          className="month-item"
          id="Jun"
          onClick={[MockFunction]}
          type="submit"
        >
          Jun
        </button>
        <button
          className="month-item"
          id="Jul"
          onClick={[MockFunction]}
          type="submit"
        >
          Jul
        </button>
        <button
          className="month-item"
          id="Aug"
          onClick={[MockFunction]}
          type="submit"
        >
          Aug
        </button>
        <button
          className="month-item"
          id="Sep"
          onClick={[MockFunction]}
          type="submit"
        >
          Sep
        </button>
        <button
          className="month-item"
          id="Oct"
          onClick={[MockFunction]}
          type="submit"
        >
          Oct
        </button>
        <button
          className="month-item"
          id="Nov"
          onClick={[MockFunction]}
          type="submit"
        >
          Nov
        </button>
        <button
          className="month-item"
          id="Dec"
          onClick={[MockFunction]}
          type="submit"
        >
          Dec
        </button>
      </div>
    </section>
  </div>,
]
`;

exports[`HospitalShipmentType Component rendering renders correctly 2`] = `
<div
  className="input-container-w-100"
>
  <section>
    <div
      className="input-label"
    >
      Shipment type
    </div>
    <div
      className="monthes"
    >
      <button
        className="month-item"
        type="submit"
      >
        Jan
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Feb
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Mar
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Apr
      </button>
      <button
        className="month-item"
        type="submit"
      >
        May
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Jun
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Jul
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Aug
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Sep
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Oct
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Nov
      </button>
      <button
        className="month-item"
        type="submit"
      >
        Dec
      </button>
    </div>
  </section>
</div>
`;
