import React from 'react';
import PropTypes from 'prop-types';

const ProductionData = ({
  error, submit, change, value,
}) => (
  <div id="information" className="information-container">
    <div className="production-data-section">
      <div className="input-container">
        <div className="input-label">Number of cases</div>
        <input value={value} onChange={e => change(e)} />
      </div>
      <div className="button-container">
        <button type="button" className="incoming-shipment_btn blue" onClick={() => submit()}>Save</button>
      </div>
      {error && <span className="error">{error}</span>}
    </div>
  </div>
);


ProductionData.propTypes = {
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ProductionData;
