import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import HospitalQueuesIndex from '../HospitalQueuesIndex';

jest.mock('../../../services/api', () => ({
  loadHospitalQueues: jest.fn(() => Promise.resolve({
    hospitalQueues: [
      {
        id: '1',
        hospital_id: '1',
        order: '1',
      },
      {
        id: '2',
        hospital_id: '2',
        order: '2',
      },
      {
        id: '3',
        hospital_id: '3',
        order: '3',
      },
    ],
  })),

  skipShipment: jest.fn(() => Promise.resolve({
    hospitalQueues: [
      {
        id: '2',
        hospital_id: '2',
        order: '2',
      },
    ],
  })),

  upShipmentQueue: jest.fn(() => Promise.resolve({
    hospitalQueues: [{
      id: '1',
      hospital_id: '1',
      order: '1',
    }],
  })),

  downShipmentQueue: jest.fn(() => Promise.resolve({
    hospitalQueues: [{
      id: '1',
      hospital_id: '1',
      order: '3',
    }],
  })),
}));

describe('HospitalQueuesIndex Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(<HospitalQueuesIndex />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalQueuesIndex />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(4);
    });

    it('renders titles', () => {
      expect(wrapper.text()).toMatch('Hospital Queues');
    });
  });

  describe('events', () => {
    const obj = { id: '1', hospital_id: '1', order: '2' };

    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('hospitalQueuesSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handle searchHospitalQueues', () => {
      wrapper.find('input#search').simulate('change', { target: { value: 'a' } });
      expect(wrapper.state('searchQuery')).toEqual('a');
    });

    it('handleOpenSkipModal', () => {
      wrapper.instance().handleOpenSkipModal();
      expect(wrapper.state().showSkipModal).toEqual(true);
    });

    it('handleCloseSkipModal', () => {
      wrapper.instance().handleCloseSkipModal();
      expect(wrapper.state().showSkipModal).toEqual(false);
    });

    it('handleSkipShipment', (cb) => {
      wrapper.instance().handleSkipShipment();
      process.nextTick(() => {
        expect(wrapper.state('hospitalQueues')).toEqual([{ id: '2', hospital_id: '2', order: '2' }]); cb();
      });
    });

    it('handleUpQueue', (cb) => {
      wrapper.instance().handleUpQueue(obj);
      process.nextTick(() => {
        expect(wrapper.state('hospitalQueues')).toEqual([{ id: '1', hospital_id: '1', order: '1' }]); cb();
      });
    });

    it('handleDownQueue', (cb) => {
      wrapper.instance().handleDownQueue(obj);
      process.nextTick(() => {
        expect(wrapper.state('hospitalQueues')).toEqual([{ id: '1', hospital_id: '1', order: '3' }]); cb();
      });
    });
  });
});
