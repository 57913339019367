// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`NoteModify Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title"
  >
    title
  </div>
  <div
    className="input-container no_error"
  >
    <section
      style={
        Object {
          "display": "flex",
          "flexDirection": "column",
        }
      }
    >
      <div
        className="input-label"
        style={
          Object {
            "fontWeight": "800",
            "marginBottom": "10px",
          }
        }
      >
        Note
      </div>
      <textarea
        id="message"
        onChange={[Function]}
        style={
          Object {
            "height": "171px",
            "width": "100%",
          }
        }
        value=""
      />
    </section>
    <div
      className="errors"
    />
  </div>
  <div
    className="button_container create-note-modal-btn-container"
  >
    <button
      className="create-note-modal-btn"
      onClick={[Function]}
      type="submit"
    >
      Create
    </button>
  </div>
</div>
`;
