import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import EventSignUpModal from '../EventSignUpModal';

describe('EventSignUpModal Component', () => {
  let wrapper;
  const defaultProps = {
    event: {
      raw: {
        max_attendees: 5,
        member: {},
        leader: {},
      },
      attendees: [1, 2],
    },
    users: [{}],
    handleClose: jest.fn(),
    handleSignUp: jest.fn(),
    handleChangeAttendee: jest.fn(),
    handleChangeAttendeesNumber: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<EventSignUpModal {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<EventSignUpModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('button')).toHaveLength(2);
      expect(wrapper.find('.detail-modal_info')).toHaveLength(3);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
    });
  });

  describe('event handling', () => {
    it('handle sign up', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleSignUp).toHaveBeenCalled();
    });

    it('handle close', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });
  });
});
