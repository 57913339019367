import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import CloseButton from '../ui/CloseButton';

const count = (arr, status) => {
  if (arr.length < 1) return 0;

  const res = arr.filter(a => a.status === status);
  return res.length;
};

const renderAttendees = (arr, status) => (
  arr.filter(a => a.status === status).map((a, index) => (
    <div key={index.toString()}>
      <span className="attendees-list-item" key={index.toString()}>{`-${a.name}`}</span>
      <br />
    </div>
  ))
);

const CalendarDetailModal = ({
  event, handleClose, handleUpdate, handleDelete, handleClick, editable, signUp,
}) => {
  const [confirmedShow, setConfirmedShow] = useState(false);
  const [invitedShow, setInvitedShow] = useState(false);

  return (
    <div className="modal_body calendar">
      <div className="modal_button_container top">
        <CloseButton close={handleClose} />
      </div>
      <div className="container">
        <div className="detail-modal_title">
          {event.title}
          {editable
          && (
            <section>
              <button type="button" className="modal_close_button no_border" onClick={() => handleUpdate(event)}>
                <Icon className="img">edit</Icon>
              </button>
              <button type="button" className="modal_close_button no_border no-margin" onClick={handleDelete}>
                <Icon className="img">delete</Icon>
              </button>
            </section>
          )
          }
        </div>
        <div className="detail-modal_owner">
          {moment(event.start.toDate()).format('dddd, MMMM D')}
          <div style={{ width: '2rem' }} />
          {moment(event.start.toDate()).format('H.mm')}
          -
          {moment(event.end.toDate()).format('H.mma')}
        </div>
        <div className="detail-modal_owner">
          <b>Owner:&nbsp;</b>
          <b>{event.raw.member.name}</b>
        </div>
        <div className="detail-modal_owner last">
          <b>Event lead:&nbsp;</b>
          <b>{event.raw.leader.name}</b>
        </div>
        <div className="detail-modal_info">
          <b>Attendees:&nbsp;</b>
          <br />
          <button
            type="button"
            onClick={() => setConfirmedShow(!confirmedShow)}
            className="show-attendees"
          >
            {`Confirmed ${count(event.attendees, 'confirmed')}`}
          </button>
          <br />
          {confirmedShow && renderAttendees(event.attendees, 'confirmed')}
          <button
            type="button"
            onClick={() => setInvitedShow(!invitedShow)}
            className="show-attendees"
          >
            {`Invited ${count(event.attendees, 'invited')}`}
          </button>
          <br />
          {invitedShow && renderAttendees(event.attendees, 'invited')}
          Available seats&nbsp;
          {event.raw.max_attendees - event.attendees.length}
        </div>
        <div className="detail-modal_info">
          Location:&nbsp;
          {event.location}
        </div>
        <div className="detail-modal_info gray">
          {event.body}
        </div>
      </div>
      {signUp && (
        <div className="modal_button_container">
          <button type="button" className="modal_button" onClick={handleClick}>Sign up</button>
        </div>
      )
      }
    </div>
  );
};
CalendarDetailModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  signUp: PropTypes.bool.isRequired,
};

export default CalendarDetailModal;
