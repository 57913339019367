import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import AdminLogModal from '../AdminLogModal';

describe('AdminLogModal Component', () => {
  let wrapper;
  const defaultProps = {
    handleClose: jest.fn(),
    subtitle: 'title',
    log: {
      id: '1',
      object_type: 'type',
      content: 'Lorem ipsum',
      created_at: '1/01/2021 0:00:00 AM',
    },
  };

  beforeEach(() => {
    wrapper = mount(<AdminLogModal {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<AdminLogModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(4);
      expect(wrapper.find('.input-label')).toHaveLength(4);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.modal_button_container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(2);
    });
  });
});
