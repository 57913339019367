import React from 'react';
import PropTypes from 'prop-types';

class SelectHospitalsByCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: '',
    };
  }

  render() {
    const { count } = this.state;
    const { handleSelect } = this.props;
    return (
      <div id="information" className="hospital-shipment-container">
        <div className="hospital-shipment_input-container">
          <div className="input-label">Number of hospitals</div>
          <input className="shipment-input" value={count} onChange={e => this.setState({ count: e.target.value })} />
        </div>
        <button type="button" className="prepare-button with-input" onClick={() => handleSelect(count)}>Prepare shipment </button>
      </div>
    );
  }
}

SelectHospitalsByCount.propTypes = {
  handleSelect: PropTypes.func.isRequired,
};

export default SelectHospitalsByCount;
