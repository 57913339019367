// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StickyBar Component rendering renders correctly 1`] = `
<div
  className="sticky-bar"
>
  <div
    className="items-container"
  >
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
</div>
`;
