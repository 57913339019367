// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CrayonCalendar Component rendering renders correctly 1`] = `
<div
  className="detail-container calendar"
>
  <div
    className="detail-title calendar"
  >
    Events
  </div>
  <div
    className="crayon_calendar_container"
  >
    <section
      className="full_calendar "
    >
      <div
        className="calendar_buttons_container false"
      >
        <div
          className="calendar_event_type"
        >
          <button
            disabled={false}
            onClick={[Function]}
            type="button"
          >
            My Events
          </button>
          <button
            disabled={true}
            onClick={[Function]}
            type="button"
          >
            All Events
          </button>
        </div>
        <section>
          <button
            disabled={false}
            onClick={[Function]}
            type="button"
          >
            Schedule
          </button>
          <button
            disabled={true}
            onClick={[Function]}
            type="button"
          >
            Day
          </button>
          <button
            disabled={false}
            onClick={[Function]}
            type="button"
          >
            Week
          </button>
          <button
            disabled={false}
            onClick={[Function]}
            type="button"
          >
            Month
          </button>
        </section>
      </div>
      <div
        className="calendar_actions_container"
      >
        <section
          className="date-nav"
        >
          <button
            className="today"
            onClick={[Function]}
            type="button"
          >
            Today
          </button>
          <button
            className="arrow"
            onClick={[Function]}
            type="button"
          >
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 img"
            >
              keyboard_arrow_left
            </span>
          </button>
          <button
            className="arrow"
            onClick={[Function]}
            type="button"
          >
            <span
              aria-hidden="true"
              className="material-icons MuiIcon-root-1 img"
            >
              keyboard_arrow_right
            </span>
          </button>
          <div
            className="current_date"
          >
            24 September 2020
          </div>
        </section>
      </div>
      <div
        hidden=""
        id="calendar"
      />
    </section>
    <section
      className="date_and_calendars-container"
    >
      <div
        className="date_picker"
        id="datepicker"
      />
      <div
        className="calendars"
      >
        <div
          className="title"
        >
          You categories
        </div>
      </div>
      <div
        className="calendars without_border"
      >
        <div
          className="title"
        >
          Events
        </div>
        <div
          className="table-actions"
        >
          <div
            className="add-new"
          >
            <button
              onClick={[Function]}
              type="button"
            >
              +
            </button>
            <div
              className="label"
            >
              Create new event
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
`;
