import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import SendersInformation from './SendersInformation';
import API from '../../services/api';
import addIcon from '../../../assets/images/add-outline.svg';
import scrollToError from '../../services/scrollHelper';
import CreateIncomingShipmentAnonymous from './CreateIncomingShipmentAnonymous';

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <i className="material-icons">search</i>
    </components.DropdownIndicator>
  )
);

class CreateIncomingShipment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pack: {
        len: '',
        width: '',
        height: '',
        weight: '',
        trackingNumber: props.trackingNumber,
      },
      donors: [],
      selectedDonor: {
        first_name: '',
        last_name: '',
        email: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip_code: '',
        company: '',
      },
      readOnly: true,
      showInfo: false,
      errors: [],
      tnDisabled: false,
    };
  }

  componentDidMount() {
    const { anonymousDonor } = this.props;

    if (anonymousDonor) {
      this.doNotKnowTrackingNumber();
      this.setState({ selectedDonor: anonymousDonor, showInfo: true });
    }
  }

  handleEdit = () => {
    const { readOnly } = this.state;
    this.setState({ readOnly: !readOnly });
  };

  handleCreate = () => {
    this.setState({ readOnly: false, showInfo: true });
  };

  handleSearch = (e) => {
    API.searchDonor(e).then(res => this.setState({ donors: res.donors }));
  };

  handleSelect = (e) => {
    const { donors } = this.state;
    this.setState({ selectedDonor: donors.find(d => d.id === e.value), showInfo: true, readOnly: true });
  };

  hasErrors = () => {
    const { errors } = this.state;
    return Object.values(errors).some(item => item);
  };

  handleSubmit = () => {
    const { pack, selectedDonor } = this.state;
    const { handleSave } = this.props;
    Object.keys(selectedDonor).forEach(field => this.validateField(field));
    Object.keys(pack).forEach(field => this.validatePackage(field));

    if (this.hasErrors()) {
      scrollToError('.errors:not(:empty)');
      return;
    }
    handleSave({ pack, selectedDonor });
  };

  validateField = (fieldName) => {
    const { errors, selectedDonor } = this.state;
    let value = selectedDonor[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        if (!value) value = '';
        valid = value === '' || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errors[fieldName] = valid ? '' : 'Email is invalid';
        break;
      case 'first_name':
        valid = selectedDonor.company ? true : !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        errors.company = valid ? '' : 'This field is required';
        break;
      case 'company':
        valid = selectedDonor.first_name ? true : !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        errors.first_name = valid ? '' : 'This field is required';
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  validatePackage = (fieldName) => {
    const { errors, pack } = this.state;
    const value = pack[fieldName];
    const weight = value;
    const width = value;
    const l = value;
    const height = value;
    const checkNumberValidation = (number) => {
      if (!number) {
        return true;
      }
      return number.match(/^([1-9]\d*|0)?(?:\.\d+)?$/gm) && parseFloat(number) > 0;
    };
    let valid;

    switch (fieldName) {
      case 'trackingNumber':
        valid = !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        break;
      case 'weight':
        valid = weight.match(/^([1-9]\d*|0)?(?:\.\d+)?$/gm) && parseFloat(weight) > 0;
        errors[fieldName] = valid ? '' : 'Weight must be a number greater than 0!';
        break;
      case 'width':
        valid = checkNumberValidation(width);
        errors[fieldName] = valid ? '' : 'Width must be a number greater than 0!';
        break;
      case 'len':
        valid = checkNumberValidation(l);
        errors[fieldName] = valid ? '' : 'Length must be a number greater than 0!';
        break;
      case 'height':
        valid = checkNumberValidation(height);
        errors[fieldName] = valid ? '' : 'Height must be a number greater than 0!';
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  handleChange = (e) => {
    const { selectedDonor } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    selectedDonor[name] = value;
    this.setState({ selectedDonor }, () => this.validateField(name));
  };

  handleChangePackage = (e) => {
    const { pack } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    pack[name] = value;
    this.setState({ pack }, () => this.validatePackage(name));
  };

  doNotKnowTrackingNumber = () => {
    const { pack, tnDisabled } = this.state;
    const { withoutTNCount } = this.props;
    pack.trackingNumber = `TCI-${moment().format('YYYY-DD-MM')}-${withoutTNCount > 9 ? withoutTNCount : `0${withoutTNCount}`}`;
    this.setState({ pack, tnDisabled: !tnDisabled });
  };

  render() {
    const {
      pack, donors, selectedDonor, readOnly, showInfo, errors, tnDisabled,
    } = this.state;
    const { status, anonymousDonor } = this.props;
    const { trackingNumber } = this.props;
    const { handleSave } = this.props;

    return (
      <div tabIndex={-1} onKeyDown={e => (e.key === 'Enter' && this.handleSubmit())} role="button">
        {anonymousDonor
          ? (
            <CreateIncomingShipmentAnonymous handleSave={handleSave} trackingNumber={trackingNumber} anonymousDonor={anonymousDonor} />
          )
          : (
            <div className="tables-container">
              <div className="incoming_shipment_title">Shipment</div>
              <div className="incoming-shipment-container tracking-number">
                <div className="incoming_shipment_input-container tracking-number">
                  <div className="input-label">Tracking Number</div>
                  <input id="trackingNumber" disabled={tnDisabled} className="shipment-input" value={pack.trackingNumber} onChange={this.handleChangePackage} />
                  <div className="errors pack">{errors.trackingNumber && errors.trackingNumber}</div>
                </div>
                <div className="input-container">
                  <section>
                    <div className="input-label">I do not know the tracking number</div>
                    <div className="checkbox-cell">
                      <input type="checkbox" id="tracking-number" checked={tnDisabled} onChange={this.doNotKnowTrackingNumber} />
                      <label htmlFor="tracking-number" />
                    </div>
                  </section>
                </div>
              </div>
              <div className="incoming_shipment_title">Sender’s Information</div>
              <div className="incoming_shipment_search_container">
                <div className="incoming_shipment_input-container">
                  <div className="input-label">Lookup sender</div>
                  <Select
                    components={{
                      DropdownIndicator,
                      MenuList: props => (
                        components.MenuList && (
                          <components.MenuList {...props}>
                            <div className="drop-add">
                              <button type="button" onClick={this.handleCreate}>
                                <img src={addIcon} alt="" />
                                &nbsp;Add New
                              </button>
                            </div>
                            {props.children}
                          </components.MenuList>
                        )
                      ),
                    }}
                    filterOption={option => option}
                    onChange={this.handleSelect}
                    onInputChange={this.handleSearch}
                    options={
                      donors.map(item => (
                        {
                          label: `${item.first_name || ''} ${item.last_name || ''}, ${item.city || ''} ${item.state || ''}`,
                          value: item.id,
                        }
                      ))
                    }
                    placeholder="Full name"
                    className="donor-search"
                    classNamePrefix="donor-search"
                  />
                </div>
              </div>
              {
                showInfo
                && (
                  <React.Fragment>
                    <>
                      <div className="incoming_shipment_title">
                        <button type="button" className="table-title label" onClick={this.handleEdit}>
                          {`Donor\`s Information ${readOnly ? '' : '(editing)'}`}
                          &nbsp;
                          <Icon className="img">{readOnly ? 'edit' : 'check'}</Icon>
                        </button>
                      </div>
                      <SendersInformation donor={selectedDonor} readOnly={readOnly} handleChange={this.handleChange} errors={errors} />
                    </>
                    <div className="incoming_shipment_title">Package information</div>
                    <div className="incoming-shipment-container">
                      <div className="incoming_shipment_input-container sh20">
                        <div className="input-label">Length</div>
                        <span>
                          <input id="len" className="shipment-package-input" value={pack.len} onChange={this.handleChangePackage} />
                          <span>&nbsp;in</span>
                        </span>
                        <div className="errors pack">{errors.len && errors.len}</div>
                      </div>
                      <div className="incoming_shipment_input-container sh20">
                        <div className="input-label">Width</div>
                        <span>
                          <input id="width" className="shipment-package-input" value={pack.width} onChange={this.handleChangePackage} />
                          <span>&nbsp;in</span>
                        </span>
                        <div className="errors pack">{errors.width && errors.width}</div>
                      </div>
                      <div className="incoming_shipment_input-container sh20">
                        <div className="input-label">Height</div>
                        <span>
                          <input id="height" className="shipment-package-input" value={pack.height} onChange={this.handleChangePackage} />
                          <span>&nbsp;in</span>
                        </span>
                        <div className="errors pack">{errors.height && errors.height}</div>
                      </div>
                      <div className="incoming_shipment_input-container sh20">
                        <div className="input-label">Weight</div>
                        <span>
                          <input id="weight" className="shipment-package-input" value={pack.weight} onChange={this.handleChangePackage} />
                          <span>&nbsp;lbs</span>
                        </span>
                        <div className="errors pack">{errors.weight && errors.weight}</div>
                      </div>
                    </div>
                    <button disabled={status === 'loading'} type="button" className="incoming-shipment_btn blue" onClick={this.handleSubmit}>
                      {
                        !status && 'Save'
                      }
                      {
                        status === 'loading' && 'Processing...'
                      }
                    </button>
                  </React.Fragment>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

CreateIncomingShipment.propTypes = {
  trackingNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  withoutTNCount: PropTypes.number.isRequired,
  anonymousDonor: PropTypes.instanceOf(Object),
};

CreateIncomingShipment.defaultProps = {
  anonymousDonor: null,
};

export default CreateIncomingShipment;
