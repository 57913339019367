import React from 'react';
import { shallow } from 'enzyme';
import renderer from 'react-test-renderer';
import StateSelect from '../StateSelect';

describe('StateSelect Component', () => {
  let wrapper;
  const defaultProps = {
    id: '2',
    className: 'class',
    value: 'TX',
    onChange: jest.fn(),
  };

  beforeEach(() => {
    wrapper = shallow(<StateSelect {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<StateSelect {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.class')).toHaveLength(1);
      expect(wrapper.find('option')).toHaveLength(60);
      expect(wrapper.find('select').props().value).toEqual('TX');
      expect(wrapper.find('select').props().id).toEqual('2');
      expect(defaultProps.onChange).not.toHaveBeenCalled();
      expect(wrapper.text()).toMatch('Texas');
    });
  });
  describe('onChange', () => {
    it('change', () => {
      wrapper.find('select').simulate('change', { target: { value: 'LA' } });
      expect(defaultProps.onChange).toHaveBeenCalled();
      expect(wrapper.text()).toMatch('Louisiana');
    });
  });
});
