import React from 'react';
import PropTypes from 'prop-types';
import StateSelect from '../ui/StateSelect';

const DonorInformation = ({
  donor, update, handleCancel, handleSave, handleChange,
}) => (
  <div className="information-container">
    <div className="input-container">
      <div className="input-label">ID</div>
      <input value={donor.donor_key || ''} readOnly disabled />
    </div>
    <div className="input-container">
      <div className="input-label">Email</div>
      <input id="email" value={donor.email || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">First Name</div>
      <input id="first_name" value={donor.first_name || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Last Name</div>
      <input id="last_name" value={donor.last_name || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Company</div>
      <input id="company" value={donor.company || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Street1</div>
      <input id="street1" value={donor.street1 || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container no_error">
      <div className="input-label">Street2</div>
      <input id="street2" value={donor.street2 || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">City</div>
      <input id="city" value={donor.city || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container no_error">
      <div className="input-label">State</div>
      <StateSelect
        id="state"
        className="select_state"
        onChange={handleChange}
        value={donor.state}
        readOnly={!update}
      />
    </div>
    <div className="input-container">
      <div className="input-label">Zip Code</div>
      <input id="zip_code" value={donor.zip_code || ''} readOnly={!update} onChange={handleChange} />
    </div>
    {update && (
    <div className="update-buttons--container end">
      <button type="button" className="cancel" onClick={handleCancel}>Cancel</button>
      <button type="button" className="save" onClick={handleSave}>Save</button>
    </div>
    )}
  </div>
);

DonorInformation.propTypes = {
  donor: PropTypes.instanceOf(Object),
  update: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  handleChange: PropTypes.func,
};

DonorInformation.defaultProps = {
  donor: {},
  update: false,
  handleCancel: () => {},
  handleSave: () => {},
  handleChange: () => {},
};

export default DonorInformation;
