import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import CalendarUpdateModal from '../CalendarUpdateModal';

jest.mock('tui-date-picker', () => jest.fn(() => ({
  on: jest.fn(),
})));

jest.mock('../../../services/api', () => ({
  searchUser: jest.fn(() => Promise.resolve({
    users: [
      { id: 1, first_name: 'name' },
      { id: 2, first_name: 'name2' },
    ],
  })),
}));

describe('CalendarUpdateModal Component', () => {
  let wrapper;
  const defaultProps = {
    event: {
      calendarId: 1,
      start: {
        toDate: jest.fn(),
      },
      end: {
        toDate: jest.fn(),
      },
      raw: {
        max_attendees: 5,
      },
      attendees: [1, 2],
    },
    calendars: [
      { id: 1, name: 'name' },
    ],
    isAttendee: false,
    handleClose: jest.fn(),
    handleUpdate: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<CalendarUpdateModal {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<CalendarUpdateModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('button')).toHaveLength(2);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.input-container')).toHaveLength(7);
      expect(wrapper.find('.input-label')).toHaveLength(8);
    });
  });

  describe('event handling', () => {
    it('handle update', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleUpdate).toHaveBeenCalled();
    });

    it('handle change type', () => {
      wrapper.find('#lead_only').simulate('change', { target: { id: 'lead_only', checked: true } });
      expect(wrapper.state().schedule.eventVisibility).toEqual('lead_only');
    });
    it('handle change type', () => {
      wrapper.find('#company_wide').simulate('change', { target: { id: 'company_wide', checked: true } });
      expect(wrapper.state().schedule.eventVisibility).toEqual('company_wide');
    });
    it('handle change type', () => {
      wrapper.find('#internal').simulate('change', { target: { id: 'internal', checked: true } });
      expect(wrapper.state().schedule.eventType).toEqual('internal');
    });
    it('handle change type', () => {
      wrapper.find('#public').simulate('change', { target: { id: 'public', checked: true } });
      expect(wrapper.state().schedule.eventType).toEqual('public');
    });
    it('handle change join', () => {
      wrapper.find('#yes').simulate('change', { target: { id: 'yes', checked: true } });
      expect(wrapper.state().joinEvent).toEqual(true);
    });
    it('handle change join', () => {
      wrapper.find('#yes').simulate('change', { target: { id: 'yes', checked: false } });
      wrapper.find('#no').simulate('change', { target: { id: 'no', checked: true } });
      expect(wrapper.state().joinEvent).toEqual(false);
    });

    it('handle change', () => {
      wrapper.find('#name').simulate('change', { target: { id: 'name', value: 'new' } });
      expect(wrapper.state().schedule.name).toEqual('new');
    });

    it('handle set date', () => {
      const date = new Date('12.12.12');
      wrapper.instance().setDate('startDate', date);
      expect(wrapper.state().schedule.startDate).toEqual(date);
    });

    it('handle close', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });
  });
});
