// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DonorSignIn Component rendering renders correctly 1`] = `
<div
  className="auth-content"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="auth-header"
  >
    Reset Password
  </div>
  <div
    className="auth-reset-pass-info"
  >
    Enter the
    <span>
       email address 
    </span>
    associated with your account and we'll send you an email with instructions and a link to create a new password.
  </div>
  <div
    className="custom-form-group send"
  >
    <label
      htmlFor="user[email]"
    >
      Email Address
    </label>
    <div>
      <i
        className="material-icons"
      >
        mail
      </i>
      <input
        className="shipment-input"
        id="email"
        name="user[email]"
        placeholder="Enter email"
        required={true}
        type="email"
      />
    </div>
  </div>
  <button
    className="btn-main"
    type="submit"
  >
    Send
  </button>
  <a
    className="btn-link"
    href="/users/sign_in"
  >
    Back to Sign in
  </a>
</div>
`;
