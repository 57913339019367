import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import TableActions from '../TableActions';

describe('Table Component', () => {
  let wrapper;
  let wrapperNew;

  const defaultProps = {
    object: {
      id: '1',
      name: 'New Hospital',
      queue_number: 1,
    },
    actionKeys: ['edit', 'delete', 'last_page', 'arrow_upward', 'arrow_downward'],
    handleSkipQueue: jest.fn(),
    handleUpQueue: jest.fn(),
    handleDownQueue: jest.fn(),
    handleEdit: jest.fn(),
    handleDelete: jest.fn(),
  };

  const defaultPropsNew = {
    object: {
      id: '2',
      name: 'Hospital New 2',
      queue_number: null,
      shipment_type: {
        shipment_schedule_type: 'by_schedule',
      },
    },
    actionKeys: ['last_page', 'arrow_upward', 'arrow_downward'],
    handleSkipQueue: jest.fn(),
    handleUpQueue: jest.fn(),
    handleDownQueue: jest.fn(),
    handleEdit: jest.fn(),
    handleDelete: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<TableActions {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<TableActions {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('section')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(5);
      expect(wrapper.find('span')).toHaveLength(5);
    });
  });

  describe('event handling', () => {
    it('handle edit', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleEdit).toHaveBeenCalled();
    });

    it('handle delete', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleDelete).toHaveBeenCalled();
    });

    it('handle skip', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.handleSkipQueue).toHaveBeenCalled();
    });

    it('handle up', () => {
      wrapper.find('button').at(3).simulate('click');
      expect(defaultProps.handleUpQueue).toHaveBeenCalled();
    });

    it('handle down', () => {
      wrapper.find('button').at(4).simulate('click');
      expect(defaultProps.handleDownQueue).toHaveBeenCalled();
    });

    it('show tooltip edit', () => {
      wrapper.find('button').at(0).simulate('focus');
      expect(wrapper.find('button').at(0).props().title).toMatch('Edit');
    });

    it('show tooltip delete', () => {
      wrapper.find('button').at(1).simulate('focus');
      expect(wrapper.find('button').at(1).props().title).toMatch('Delete');
    });

    it('show tooltip skip', () => {
      wrapper.find('button').at(2).simulate('focus');
      expect(wrapper.find('button').at(2).props().title).toMatch('Skip this shipment');
    });

    it('show tooltip down', () => {
      wrapper.find('button').at(3).simulate('focus');
      expect(wrapper.find('button').at(3).props().title).toMatch('Up this shipment');
    });

    it('show tooltip down', () => {
      wrapper.find('button').at(4).simulate('focus');
      expect(wrapper.find('button').at(4).props().title).toMatch('Down this shipment');
    });
  });

  describe('rendering with new params', () => {
    beforeEach(() => {
      wrapperNew = mount(<TableActions {...defaultPropsNew} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<TableActions {...defaultPropsNew} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapperNew.find('section')).toHaveLength(1);
      expect(wrapperNew.find('button')).toHaveLength(0);
      expect(wrapperNew.find('span')).toHaveLength(0);
    });
  });
});
