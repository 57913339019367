import React from 'react';
import { mount, shallow } from 'enzyme';
import renderer from 'react-test-renderer';
import SignUp from '../SignUp';

jest.mock('../../../services/api', () => ({
  validation: jest.fn(() => Promise.resolve({
    ok: true,
    err_msg: 'Invalid email or password',
  })),
}));

describe('SignUp Component', () => {
  let wrapper;
  const defaultProps = {
    classes: {
      input: 'str',
    },
  };

  beforeEach(() => {
    wrapper = mount(<SignUp {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<SignUp {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.auth-content')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(1);
    });
  });

  describe('validation', () => {
    it('validates first_name', () => {
      wrapper.find('TextField#first_name input').simulate('change', { target: { id: 'first_name', value: '' } });
      expect(wrapper.find('TextField#first_name').text()).toContain('This field is required');
    });

    it('validates last_name', () => {
      wrapper.find('TextField#last_name input').simulate('change', { target: { id: 'last_name', value: '' } });
      expect(wrapper.find('TextField#last_name').text()).toContain('This field is required');
    });

    it('validates email', () => {
      wrapper.find('TextField#email input').simulate('change', { target: { id: 'email', value: '' } });
      expect(wrapper.find('TextField#email').text()).toContain('Email is invalid');
    });

    it('validates password', () => {
      wrapper.find('TextField#password input').simulate('change', { target: { id: 'password', value: '' } });
      expect(wrapper.find('TextField#password').text()).toContain('Password is too short');
    });

    it('validates password_confirmation', () => {
      wrapper.find('TextField#password_confirmation input').simulate('change', { target: { id: 'password_confirmation', value: '1' } });
      expect(wrapper.find('TextField#password_confirmation').text()).toContain('Passwords don\'t match');
    });
  });

  describe('events', () => {
    beforeEach(() => {
      wrapper = shallow(<SignUp {...defaultProps} />).dive();
    });

    it('handleAgree', () => {
      wrapper.instance().handleAgree();
      expect(wrapper.state('user').agree).toEqual(true);
    });

    it('handleAgree', () => {
      wrapper.setState({ stage: 'confirm' });
      wrapper.instance().handleAgree();
      expect(wrapper.state('user').agree).toEqual(true);
    });

    it('handleCloseModal', () => {
      wrapper.setState({ showModal: true });
      wrapper.instance().handleCloseModal();
      expect(wrapper.state('showModal')).toEqual(false);
    });
  });
});
