import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import LocateAndAcceptShipment from './LocateAndAcceptShipment';
import API from '../../services/api';
import CreateShipmentModal from './CreateShipmentModal';
import CreateIncomingShipment from './CreateIncomingShipment';
import SendersInformation from './SendersInformation';
import Toast from '../ui/Toast';
import { errorToast, successToast } from '../../services/toast';

class IncomingShipments extends React.Component {
  constructor(props) {
    super(props);

    this.trackingNumberInput = React.createRef();
    this.weightInput = React.createRef();

    this.state = {
      inputs: {
        trackingNumber: '',
        weight: '',
      },
      showInfo: false,
      donor: {},
      shipmentInfo: {},
      errors: {},
      accepted: false,
      showCreateNew: false,
      withoutTNCount: props.withoutTNCount,
      readOnly: true,
      status: '',
      anonymousDonor: null,
    };
  }

  checkTrackingNumber = (value) => {
    const { errors } = this.state;
    this.validateTrackingNumber(value);
    if (errors.trackingNumber === 'The tracking number is required') return;

    API.checkShipment({ tracking_number: value }).then((res) => {
      if (res.error) {
        errors.trackingNumber = res.error;
        this.setState({ errors, showInfo: false });
      } else {
        errors.trackingNumber = '';
        this.setState({
          donor: res.donor, shipmentInfo: res.shipment, showInfo: true, errors,
        }, () => this.weightInput.current.focus());
      }
    });
  };

  handleChange = (e) => {
    const { inputs } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    inputs[name] = value;
    this.setState({ inputs });
    if (name === 'weight') this.validateWeight(value);
    if (name === 'trackingNumber') this.validateTrackingNumber(value);
  };

  handleEdit = () => {
    const { readOnly } = this.state;
    this.setState({ readOnly: !readOnly });
  };

  validateField = (fieldName) => {
    const { errors, donor } = this.state;
    const value = donor[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value === '' || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errors[fieldName] = valid ? '' : 'Email is invalid';
        break;
      case 'first_name':
        valid = donor.company ? true : !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        errors.company = valid ? '' : 'This field is required';
        break;
      case 'company':
        valid = donor.first_name ? true : !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        errors.first_name = valid ? '' : 'This field is required';
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  handleChangeDonor = (e) => {
    const { donor } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    donor[name] = value;
    this.setState({ donor }, () => this.validateField(name));
  };

  validateTrackingNumber = (value) => {
    const { errors } = this.state;
    if (value.trim().length > 0) {
      errors.trackingNumber = '';
      this.setState({ errors });
    } else {
      errors.trackingNumber = 'The tracking number is required';
      this.setState({ errors });
    }
  };

  validateWeight = (weight) => {
    const { errors } = this.state;
    if (weight.match(/^([1-9]\d*|0)?(?:\.\d+)?$/gm) && parseFloat(weight) > 0) {
      errors.weight = '';
      this.setState({ errors });
    } else {
      errors.weight = 'Weight must be a number greater than 0!';
      this.setState({ errors });
    }
  };

  acceptShipment = (data) => {
    const { errors } = this.state;
    this.validateWeight(data.weight);
    if (errors.weight) return;

    API.acceptShipment({ tracking_number: data.trackingNumber, weight: data.weight })
      .then(() => {
        successToast('Success! Shipment accepted.');
        this.setState({
          showInfo: false,
          inputs: {
            trackingNumber: '',
            weight: '',
          },
        }, () => this.trackingNumberInput.current.focus());
      }).catch(() => {
        errorToast();
        this.setState({
          showInfo: false,
          inputs: {
            trackingNumber: '',
            weight: '',
          },
        }, () => this.trackingNumberInput.current.focus());
      });
  };

  handleSave = (inputs) => {
    const { errors } = this.state;
    errors.trackingNumber = '';
    this.setState({ status: 'loading' });
    API.createDonorShipment(inputs).then((res) => {
      this.setState({
        donor: res.donor,
        shipmentInfo: res.shipment,
        inputs: {
          trackingNumber: '',
          weight: '',
        },
        showInfo: false,
        errors,
        showCreateNew: false,
        withoutTNCount: res.wtn_count,
        status: '',
      });
      successToast('Success! Shipment created.');
    }).catch(() => {
      this.setState({ status: '' });
      errorToast();
    });
  };

  handleUpdate = () => {
    const { donor } = this.state;
    API.updateDonor(donor).then((res) => {
      this.setState({ donor: res.donor, readOnly: true });
      successToast('Success! Donor updated.');
    }).catch(() => errorToast());
  };

  handleModalYes = () => {
    this.handleCloseModal();
    this.setState({ showCreateNew: true });
  };

  handleCloseModal = () => {
    const { errors } = this.state;
    errors.trackingNumber = '';
    this.setState({ errors });
  };

  render() {
    const {
      showInfo, errors, donor, shipmentInfo, accepted, showCreateNew, inputs, withoutTNCount, readOnly, status, anonymousDonor,
    } = this.state;
    const { anonymousDonorAccount } = this.props;
    return (
      <div className="detail-container">
        <Toast />
        <div className="detail-title">Incoming Shipments</div>
        { !showCreateNew
          ? (
            <div className="tables-container in-sh">
              <div className="table-title">Locate and Accept Shipment</div>
              <LocateAndAcceptShipment
                showWeight={showInfo}
                errors={errors}
                locate={this.checkTrackingNumber}
                accept={this.acceptShipment}
                accepted={accepted}
                handleSave={this.handleSave}
                handleChange={this.handleChange}
                inputs={inputs}
                manualEntry={() => this.setState({ showCreateNew: true })}
                anonymousEntry={() => this.setState({ showCreateNew: true, anonymousDonor: anonymousDonorAccount })}
                shipmentInfo={shipmentInfo}
                tnInputRef={this.trackingNumberInput}
                wInputRef={this.weightInput}
              />
              {showInfo && (
              <React.Fragment>
                <button type="button" className="table-title mb label" onClick={this.handleEdit}>
                  Donor`s Information&nbsp;
                  <Icon className="img">edit</Icon>
                </button>
                <SendersInformation
                  donor={donor}
                  readOnly={readOnly}
                  errors={errors}
                  handleChange={this.handleChangeDonor}
                  className="information-container new-incoming w92"
                />
                {!readOnly && <button type="button" className="incoming-shipment_btn blue" onClick={this.handleUpdate}>Save</button>}
              </React.Fragment>
              )}
            </div>
          )
          : <CreateIncomingShipment trackingNumber={inputs.trackingNumber} handleSave={this.handleSave} withoutTNCount={withoutTNCount} status={status} anonymousDonor={anonymousDonor} />}
        <ReactModal
          isOpen={errors.trackingNumber === 'Shipment with this tracking number not exist!'}
          onRequestClose={this.handleCloseModal}
          className="modal incoming-shipments"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <CreateShipmentModal handleClose={this.handleCloseModal} create={this.handleModalYes} />
        </ReactModal>
      </div>
    );
  }
}

IncomingShipments.propTypes = {
  withoutTNCount: PropTypes.number.isRequired,
  anonymousDonorAccount: PropTypes.instanceOf(Object).isRequired,
};

export default IncomingShipments;
