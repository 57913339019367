import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import SuppliesTable from '../SuppliesTable';

describe('SuppliesTable Component', () => {
  let wrapper;
  const defaultProps = {
    object: {
      item1: 'Low',
      item2: 'Low',
      item3: 'Low',
      item4: 'High',
      item5: 'Low',
      item6: 'Low',
    },
    updateInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15T14:57:50.650488',
    },
    handleChange: jest.fn(),
    onSave: jest.fn(),
    onUpdate: jest.fn(),
    onCancel: jest.fn(),
    update: false,
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<SuppliesTable {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<SuppliesTable {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('thead')).toHaveLength(1);
      expect(wrapper.find('tbody')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(1);
    });

    it('renders head', () => {
      expect(wrapper.find('thead')).toHaveLength(1);
      expect(wrapper.text()).toMatch('Items');
      expect(wrapper.text()).toMatch('Quantity');
    });

    it('renders body', () => {
      expect(wrapper.find('tbody')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(7);
      expect(wrapper.text()).toMatch('Low');
    });

    it('renders update info', () => {
      expect(wrapper.find('.update-info')).toHaveLength(1);
      expect(wrapper.text()).toMatch('Updated by: John Wick');
      expect(wrapper.text()).toMatch('Updated on: 11/15/2019 2:57:50 PM');
    });

    it('renders with update = true', () => {
      wrapper.setProps({ update: true });
      expect(wrapper.find('.update-info')).toHaveLength(0);
      expect(wrapper.find('.update-buttons--container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(3);
    });
  });

  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<SuppliesTable {...defaultProps} />);
      wrapper.setProps({ update: true });
    });

    it('click "Update supplies" button', () => {
      wrapper.setProps({ update: false });
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.onUpdate).toHaveBeenCalled();
    });

    it('click "Cancel" button', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.onCancel).toHaveBeenCalled();
    });

    it('click "Save" button', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.onSave).toHaveBeenCalled();
    });
  });
});
