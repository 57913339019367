import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import CreateShipmentModal from '../CreateShipmentModal';

describe('CreateShipmentModal Component', () => {
  let wrapper;
  const defaultProps = {
    create: jest.fn(),
    handleClose: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<CreateShipmentModal {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<CreateShipmentModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(3);
    });
  });
  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<CreateShipmentModal {...defaultProps} />);
    });

    it('create', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.create).toHaveBeenCalled();
    });

    it('close', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });
  });
});
