import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ProductionIndex from '../ProductionIndex';

jest.mock('../../../services/api', () => ({
  production: jest.fn(() => Promise.resolve({ production: [] })),
  loadProductions: jest.fn(() => Promise.resolve({
    productions: [
      {
        id: '1',
        name: 'test',
        date: '2019-12-12',
        number_of_cases: '45',
      },
    ],
  })),
}));

describe('ProductionIndex Component', () => {
  let wrapper;
  const defaultProps = {
    production: [
      {
        id: '1',
        name: 'test',
        date: '2019-12-12',
        number_of_sleeves: '123',
        color: 'Red',
      },
    ],
  };

  const error = 'Please enter a number of cases 1-99!';
  beforeEach(() => {
    wrapper = mount(<ProductionIndex {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ProductionIndex {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('.tables-container')).toHaveLength(1);
      expect(wrapper.find('.table-title')).toHaveLength(2);
    });
  });
  describe('validation', () => {
    describe('number of sleeves validation', () => {
      it('null number of sleeves', () => {
        wrapper.find('button').at(0).simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(error);
      });
      it('number of sleeves = "sixteen"', () => {
        wrapper.find('input').at(0).simulate('change', { target: { value: 'sixteen' } });
        wrapper.find('button').at(0).simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(error);
      });
      it('number of sleeves = -12', () => {
        wrapper.find('input').at(0).simulate('change', { target: { value: '-12' } });
        wrapper.find('button').at(0).simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(error);
      });
      it('number of sleeves = "101"', () => {
        wrapper.find('input').at(0).simulate('change', { target: { value: '101' } });
        wrapper.find('button').at(0).simulate('click');
        expect(wrapper.find('.error')).toHaveLength(1);
        expect(wrapper.text()).toMatch(error);
      });
      it('number of sleeves = "22"', () => {
        wrapper.find('input').at(0).simulate('change', { target: { value: '22' } });
        wrapper.find('button').at(0).simulate('click');
        expect(wrapper.find('.error')).toHaveLength(0);
        expect(wrapper.text()).not.toMatch(error);
      });
    });
  });

  describe('events', () => {
    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('productionsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });
  });
});
