import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CloseButton from '../ui/CloseButton';

class AdminLogModal extends React.Component {
  constructor(props) {
    super(props);

    this.convertDate = this.convertDate.bind(this);
    this.contentHelper = this.contentHelper.bind(this);
  }

  convertDate(date) {
    return moment(date).format('M/D/YYYY h:mm:ss A');
  }

  contentHelper(log) {
    const triggeredSource = log.user_full_name !== ' ' ? log.user_full_name : log.content.incoming_source;
    const source = `Triggered by ${triggeredSource} \n\n`;

    return source + log.content.text;
  }

  render() {
    const { handleClose, subtitle, log } = this.props;
    return (
      <div className="modal_body detail">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="title">{subtitle}</div>
        <div className="add-fields">
          <div className="input-container">
            <section>
              <div className="input-label admin-log">User</div>
              <input value={log.user_full_name} disabled />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label admin-log">Object</div>
              <input value={log.object_type} disabled />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label admin-log">Content</div>
              <textarea id="content-area" value={this.contentHelper(log)} disabled />
            </section>
          </div>
          <div className="input-container">
            <section>
              <div className="input-label admin-log">Date</div>
              <input value={this.convertDate(log.created_at)} size="30" disabled />
            </section>
          </div>
        </div>
        <div className="button_container_ok">
          <button type="button" className="incoming-shipments-modal-btn yes" onClick={handleClose}>Ok</button>
        </div>
      </div>
    );
  }
}

AdminLogModal.propTypes = {
  subtitle: PropTypes.string.isRequired,
  log: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AdminLogModal;
