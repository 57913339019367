import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import omit from 'lodash/omit';
import StateSelect from '../ui/StateSelect';

class PickupBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingData: props.shippingData,
      classes: props.classes,
      errors: props.errors,
      individual: true,
    };
  }

  handleUsePickupMyShipping = () => {
    const { shippingData, errors } = this.state;
    shippingData.useMyPickupShipmentAddress = !shippingData.useMyPickupShipmentAddress;
    const err = omit(errors, Object.keys(shippingData.pickup_address));
    this.setState({ shippingData, errors: err });
  };

  handleUsePickup = () => {
    const { shippingData } = this.state;
    shippingData.usePickup = !shippingData.usePickup;
    shippingData.pickup_date_time.ready_time = this.currentStringTime();
    shippingData.pickup_date_time.close_time = this.currentStringTime(1);
    this.setState({ shippingData });
  };

  currentStringTime = (hour = 0) => {
    const today = new Date();
    const minutes = today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`;
    const hours = hour > 0 ? parseInt(today.getHours(), 10) + hour : today.getHours();

    return `${hours}:${minutes}`;
  }

  render() {
    const {
      shippingData, classes, errors, individual,
    } = this.state;
    const {
      handleChange,
    } = this.props;

    return (
      <div>
        <div className="custom-form-group-title">Pickup</div>
        <div className="shipment-inputs-container">
          <div className="check-container full">
            <input
              id="pickup"
              name="pickup"
              type="checkbox"
              checked={shippingData.usePickup}
              onChange={() => this.handleUsePickup()}
            />
            <label htmlFor="pickup">
              Do you want to pick up?
              <br />
              <div className="note">(additional charges will be applied)</div>
            </label>
          </div>
        </div>
        {shippingData.usePickup
        && (
          <section>
            <label htmlFor="pickup_date">Pickup Date *</label>
            <TextField
              id="pickup_date"
              type="date"
              name="pickup_date"
              classes={{
                root: classes.input,
              }}
              onChange={e => handleChange(e, 'pickup_date_time')}
              value={shippingData.pickup_date_time.pickup_date}
              error={Boolean(errors.pickup_date)}
              helperText={errors.pickup_date}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
                inputProps: { min: new Date().toISOString().split('T')[0] },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <label htmlFor="ready_time">Earliest Pickup Time *</label>
            <TextField
              id="ready_time"
              type="time"
              name="ready_time"
              classes={{
                root: classes.input,
              }}
              onChange={e => handleChange(e, 'pickup_date_time')}
              value={shippingData.pickup_date_time.ready_time}
              error={Boolean(errors.ready_time)}
              helperText={errors.ready_time}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <label htmlFor="close_time">Latest Pickup Time *</label>
            <TextField
              id="close_time"
              type="time"
              name="close_time"
              classes={{
                root: classes.input,
              }}
              onChange={e => handleChange(e, 'pickup_date_time')}
              value={shippingData.pickup_date_time.close_time}
              error={Boolean(errors.close_time)}
              helperText={errors.close_time}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <label htmlFor="pickup_phone">
              Phone *
            </label>
            <TextField
              id="pickup_phone"
              type="text"
              name="pickup_phone"
              classes={{
                root: classes.input,
              }}
              onChange={e => handleChange(e, 'pickup_address')}
              value={shippingData.pickup_address.pickup_phone}
              error={Boolean(errors.pickup_phone)}
              helperText={errors.pickup_phone}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />

            <div className="shipment-input-container">
              <section id="instructions_area_section">
                <div className="input-label">Enter any special instructions to the UPS Driver:</div>
                <textarea
                  id="instructions_area"
                  value={shippingData.driverInstructions}
                  onChange={e => this.handleDriverInstructionsChange(e)}
                />
              </section>
            </div>
            <div className="shipment-inputs-container">
              <div className="check-container full">
                <input
                  id="my_pickup_shipping"
                  name="my_pickup_shipping"
                  type="checkbox"
                  checked={shippingData.useMyPickupShipmentAddress}
                  onChange={() => this.handleUsePickupMyShipping()}
                />
                <label htmlFor="my_pickup_shipping">
                  Use my shipping address
                </label>
              </div>
              <div className="check-container full">
                <input
                  id="pickup_notification"
                  name="pickup_notification"
                  type="checkbox"
                  checked={shippingData.pickupNotifications}
                  onChange={() => this.handlePickupNotifications()}
                />
                <label htmlFor="pickup_notification">
                  Send pickup notifications
                </label>
              </div>
            </div>
          </section>
        )
        }
        {!shippingData.useMyPickupShipmentAddress && shippingData.usePickup
        && (
          <section>
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="first_name">
                  First name *
                </label>
                <TextField
                  id="pickup_first_name"
                  type="text"
                  name="pickup_first_name"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => handleChange(e, 'pickup_address')}
                  value={shippingData.pickup_address.pickup_first_name}
                  error={Boolean(errors.pickup_first_name)}
                  helperText={errors.pickup_first_name}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="pickup_last_name">
                  Last name *
                </label>
                <TextField
                  id="pickup_last_name"
                  type="text"
                  name="pickup_last_name"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => handleChange(e, 'pickup_address')}
                  value={shippingData.pickup_address.pickup_last_name}
                  error={Boolean(errors.pickup_last_name)}
                  helperText={errors.pickup_last_name}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
            </div>
            {!individual
            && (
              <React.Fragment>
                <label htmlFor="pickup_company_name">
                  Company name *
                </label>
                <TextField
                  id="pickup_company_name"
                  type="text"
                  name="pickup_company_name"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => handleChange(e, 'pickup_address')}
                  value={shippingData.pickup_address.pickup_company_name}
                  error={Boolean(errors.pickup_company_name)}
                  helperText={errors.pickup_company_name}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </React.Fragment>
            )
            }
            <label htmlFor="pickup_address_line1">
              Street Address *
            </label>
            <TextField
              id="pickup_address_line1"
              type="text"
              name="pickup_address_line1"
              classes={{
                root: classes.input,
              }}
              onChange={e => handleChange(e, 'pickup_address')}
              value={shippingData.pickup_address.pickup_address_line1}
              error={Boolean(errors.pickup_address_line1)}
              helperText={errors.pickup_address_line1}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <label htmlFor="pickup_address_line2">
              Apt, Suite, Bldg, Gate Code
            </label>
            <TextField
              id="pickup_address_line2"
              type="text"
              name="pickup_address_line2"
              classes={{
                root: classes.input,
              }}
              onChange={e => handleChange(e, 'pickup_address')}
              value={shippingData.pickup_address.pickup_address_line2}
              error={Boolean(errors.pickup_address_line2)}
              helperText={errors.pickup_address_line2}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="pickup_city">
                  City *
                </label>
                <TextField
                  id="pickup_city"
                  type="text"
                  name="pickup_city"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => handleChange(e, 'pickup_address')}
                  value={shippingData.pickup_address.pickup_city}
                  error={Boolean(errors.pickup_city)}
                  helperText={errors.pickup_city}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="pickup_state">
                  State
                </label>
                <StateSelect
                  id="pickup_state"
                  className="shipment-input"
                  onChange={this.handleState}
                  value={shippingData.pickup_address.pickup_state}
                />
              </div>
            </div>
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="pickup_zip_code">
                  Zip Code *
                </label>
                <TextField
                  id="pickup_zip_code"
                  type="text"
                  name="pickup_zip_code"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => handleChange(e, 'pickup_address')}
                  value={shippingData.pickup_address.pickup_zip_code}
                  error={Boolean(errors.pickup_zip_code)}
                  helperText={errors.pickup_zip_code}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="pickup_country">
                  Country
                </label>
                <input
                  id="pickup_country"
                  name="pickup_country"
                  defaultValue="United States"
                  className="shipment-input read-only"
                  readOnly
                />
              </div>
            </div>
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="pickup_email">
                  Email *
                </label>
                <TextField
                  id="pickup_email"
                  type="text"
                  name="pickup_email"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => handleChange(e, 'pickup_address')}
                  value={shippingData.pickup_address.pickup_email}
                  error={Boolean(errors.pickup_email)}
                  helperText={errors.pickup_email}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
            </div>
            <div className="shipment-inputs-container">
              <div className="check-container full">
                <input
                  id="residential_address"
                  name="residential_address"
                  type="checkbox"
                  checked={shippingData.residentialAddress}
                  onChange={() => this.handleResidentialAddress()}
                />
                <label htmlFor="residential_address">
                  Residential Address
                </label>
              </div>
            </div>
          </section>
        )
        }
      </div>
    );
  }
}

PickupBlock.propTypes = {
  shippingData: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
};

PickupBlock.defaultProps = {
};

export default PickupBlock;
