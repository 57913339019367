// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SelectHospitalsByCount Component rendering renders correctly 1`] = `
<div
  className="hospital-shipment-container"
  id="information"
>
  <div
    className="hospital-shipment_input-container"
  >
    <div
      className="input-label"
    >
      Number of hospitals
    </div>
    <input
      className="shipment-input"
      onChange={[Function]}
      value=""
    />
  </div>
  <button
    className="prepare-button with-input"
    onClick={[Function]}
    type="button"
  >
    Prepare shipment 
  </button>
</div>
`;
