import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DonorAddNew from '../DonorAddNew';

describe('DonorAddNew Component', () => {
  let wrapper;
  const defaultProps = {
    handleSave: jest.fn(),
    handleClose: jest.fn(),
    error: '',
  };

  beforeEach(() => {
    wrapper = mount(<DonorAddNew {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<DonorAddNew {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(9);
      expect(wrapper.find('.input-label')).toHaveLength(9);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.modal_button_container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(3);
    });
    it('renders with error', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(wrapper.find('.errors')).toHaveLength(4);
    });
  });

  describe('event handling', () => {
    it('change input value', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      expect(wrapper.state().inputs.email).toEqual('test1@test.com');
    });

    it('click "Save" button', () => {
      wrapper.find('input#email').simulate('change', { target: { id: 'email', value: 'test1@test.com' } });
      wrapper.find('input#street1').simulate('change', { target: { id: 'street1', value: 'street' } });
      wrapper.find('input#city').simulate('change', { target: { id: 'city', value: 'city' } });
      wrapper.find('input#zip_code').simulate('change', { target: { id: 'zip_code', value: '45673' } });
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.handleSave).toHaveBeenCalled();
    });
  });
});
