import React from 'react';
import PropTypes from 'prop-types';
import StateSelect from '../ui/StateSelect';
import HospitalShipmentType from './HospitalShipmentType';

const HospitalInformation = ({
  hospital, update, handleCancel, handleSave, handleChange, contacts, handleCheck, handleRadioChange,
  hospitalInQueue, handleMonthChoose,
}) => (
  <div className="information-container">
    <div className="full-width-box">
      <div className="input-container">
        <div className="input-label">ID</div>
        <input value={hospital.hospital_key || ''} readOnly disabled />
      </div>
    </div>
    <div className="input-container">
      <div className="input-label">Name</div>
      <input id="name" value={hospital.name || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Name (short)</div>
      <input id="short_name" value={hospital.short_name || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Shipping contact</div>
      <select id="shipping_contact" className="select_state" value={hospital.shipping_contact} disabled={!update} onChange={handleChange}>
        <option value={hospital.shipping_contact} disabled hidden>{hospital.shipping_contact}</option>
        {contacts.map(item => (
          <option key={item.id} value={`${item.first_name} ${item.last_name}`}>
            {`${item.first_name} ${item.last_name}`}
          </option>
        ))}
      </select>
    </div>
      <div className="input-container">
          <div className="input-label">Contact Email</div>
          <select id="shipping_contact" className="select_state" value={hospital.shipping_contact} disabled={true}>
              {contacts.map(item => (
                  <option selected={`${item.first_name} ${item.last_name}` == hospital.shipping_contact} key={item.id} value={item.email} disabled hidden>
                      {item.email}
                  </option>
              ))}
          </select>
      </div>
    <div className="input-container">
      <div className="input-label">Email</div>
      <input id="email" value={hospital.email || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Phone</div>
      <input id="phone" value={hospital.phone || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Website</div>
      <input id="website" value={hospital.website || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Street 1</div>
      <input id="street1" value={hospital.street1 || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Street 1 (short)</div>
      <input id="street1_short" value={hospital.street1_short || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Street 2</div>
      <input id="street2" value={hospital.street2 || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">Street 2 (short)</div>
      <input id="street2_short" value={hospital.street2_short || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <div className="input-label">City</div>
      <input id="city" value={hospital.city || ''} readOnly={!update} onChange={handleChange} />
    </div>
    <div className="input-container">
      <section>
        <div className="input-label">Address Verified</div>
        <div className="checkbox-cell">
          <input type="checkbox" id="address_verified" checked={hospital.address_verified} disabled={!update} onChange={handleCheck} />
          <label htmlFor="address_verified" />
        </div>
      </section>
    </div>
    <div className="input-container">
      <section>
        <div className="input-label">State</div>
        <StateSelect
          id="state"
          className="select_state"
          onChange={handleChange}
          value={hospital.state}
          readOnly={!update}
        />
      </section>
    </div>
    <div className="input-container">
      <section>
        <div className="input-label">Approved for Shipping</div>
        <div className="checkbox-cell">
          <input type="checkbox" id="approved_for_shipping" checked={hospital.approved_for_shipping} disabled={!update} onChange={handleCheck} />
          <label htmlFor="approved_for_shipping" />
        </div>
      </section>
    </div>
    <div className="input-container">
      <section>
        <div className="input-label">Zip Code</div>
        <input id="zip_code" className="short" value={hospital.zip_code || ''} readOnly={!update} onChange={handleChange} />
      </section>
    </div>
    <div className="input-container">
      <section>
        <div className="input-label">Position in a Queue</div>
        <input id="queue_number" className="short" value={hospitalInQueue || ''} readOnly disabled />
      </section>
    </div>
    <HospitalShipmentType
      hospital={hospital}
      handleChange={handleRadioChange}
      handleMonthChoose={handleMonthChoose}
      update={update}
    />
    {update && (
      <div className="update-buttons--container end">
        <button type="button" className="cancel" onClick={handleCancel}>Cancel</button>
        <button type="button" className="save" onClick={handleSave}>Save</button>
      </div>
    )}
  </div>
);

HospitalInformation.propTypes = {
  hospital: PropTypes.instanceOf(Object),
  contacts: PropTypes.instanceOf(Array),
  hospitalInQueue: PropTypes.string,
  update: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleMonthChoose: PropTypes.func.isRequired,
};

HospitalInformation.defaultProps = {
  hospital: {},
  contacts: [],
  hospitalInQueue: '',
  update: false,
};

export default HospitalInformation;
