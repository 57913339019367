// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HospitalContactsAddNew Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title"
  >
    title
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        First Name
      </div>
      <input
        id="first_name"
        onChange={[Function]}
        value=""
      />
    </section>
    <div
      className="errors"
    />
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Last Name
      </div>
      <input
        id="last_name"
        onChange={[Function]}
        value=""
      />
    </section>
    <div
      className="errors"
    />
  </div>
  <div
    className="input-container no_error"
  >
    <section>
      <div
        className="input-label"
      >
        Title
      </div>
      <input
        id="title"
        onChange={[Function]}
        value=""
      />
    </section>
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Email
      </div>
      <input
        id="email"
        onChange={[Function]}
        value=""
      />
    </section>
    <div
      className="errors"
    />
  </div>
  <div
    className="input-container"
  >
    <section>
      <div
        className="input-label"
      >
        Phone
      </div>
      <input
        id="phone"
        onChange={[Function]}
        value=""
      />
    </section>
    <div
      className="errors"
    />
  </div>
  <div
    className="button_container"
  >
    <button
      className="incoming-shipments-modal-btn no"
      onClick={[MockFunction]}
      type="button"
    >
      Cancel
    </button>
    <button
      className="incoming-shipments-modal-btn yes"
      onClick={[Function]}
      type="button"
    >
      Save
    </button>
  </div>
</div>
`;
