import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import DonorInformation from './DonorInformation';
import Table from '../ui/Table';
import StickyBar from '../ui/StickyBar';
import API from '../../services/api';
import sortHelper from '../../services/sortHelper';
import { errorToast, successToast } from '../../services/toast';
import Toast from '../ui/Toast';

class DonorsShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      update: false,
      donor: props.donor,
      orders: [],
      donations: [],
      shipments: [],
      // ordersTotalPages: 1,
      // donationsTotalPages: 1,
      shipmentsTotalPages: 1,
      ordersCurrentPage: 1,
      donationsCurrentPage: 1,
      shipmentsCurrentPage: 1,
      donorOrdersSortParams: {
        column: 'id',
        type: 'asc',
      },
      donorDonationsSortParams: {
        column: 'id',
        type: 'asc',
      },
      donorShipmentsSortParams: {
        column: 'id',
        type: 'asc',
      },
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOrdersPageChange = this.handleOrdersPageChange.bind(this);
    this.handleDonationsPageChange = this.handleDonationsPageChange.bind(this);
    this.handleShipmentsPageChange = this.handleShipmentsPageChange.bind(this);
    this.handleSortOrders = this.handleSortOrders.bind(this);
    this.handleSortDonations = this.handleSortDonations.bind(this);
    this.handleSortShipments = this.handleSortShipments.bind(this);
    this.loadOrdersPage = this.loadOrdersPage.bind(this);
    this.loadDonationsPage = this.loadDonationsPage.bind(this);
    this.loadShipmentsPage = this.loadShipmentsPage.bind(this);
  }

  componentDidMount() {
    this.loadOrdersPage(1);
    this.loadDonationsPage(1);
    this.loadShipmentsPage(1);
  }

  handleEdit() {
    this.setState({ update: true });
  }

  handleCancel() {
    this.setState({ update: false });
  }

  handleSave() {
    const { donor } = this.state;
    API.updateDonor(donor).then((res) => {
      this.setState({ donor: res.donor, update: false });
      successToast('Success! Donor info updated.');
    }).catch(() => {
      this.setState({ update: false });
      errorToast();
    });
  }

  handleOrdersPageChange() {
    const { ordersCurrentPage } = this.state;
    const page = ordersCurrentPage + 1;
    this.loadOrdersPage(page);
  }

  handleDonationsPageChange() {
    const { donationsCurrentPage } = this.state;
    const page = donationsCurrentPage + 1;
    this.loadDonationsPage(page);
  }

  handleShipmentsPageChange() {
    const { shipmentsCurrentPage } = this.state;
    const page = shipmentsCurrentPage + 1;
    this.loadShipmentsPage(page);
  }

  handleSortOrders(column) {
    const { donorOrdersSortParams } = this.state;
    this.setState({ donorOrdersSortParams: sortHelper(column, donorOrdersSortParams) }, () => this.loadOrdersPage(1, true));
  }

  handleSortDonations(column) {
    const { donorDonationsSortParams } = this.state;
    this.setState({ donorDonationsSortParams: sortHelper(column, donorDonationsSortParams) }, () => this.loadDonationsPage(1, true));
  }

  handleSortShipments(column) {
    const { donorShipmentsSortParams } = this.state;
    this.setState({ donorShipmentsSortParams: sortHelper(column, donorShipmentsSortParams) }, () => this.loadShipmentsPage(1, true));
  }

  loadOrdersPage(page, refresh = false) {
    const { orders, donorOrdersSortParams, donor } = this.state;
    API.loadDonorOrders(donor.id, page, donorOrdersSortParams).then((res) => {
      this.setState({ ordersCurrentPage: page, orders: refresh ? res.orders : [...orders, ...res.orders] });
      // this.setState({ ordersCurrentPage: page, ordersTotalPages: res.totalPages, orders: refresh ? res.orders : [...orders, ...res.orders] });
    });
  }

  loadDonationsPage(page, refresh = false) {
    const { donations, donorDonationsSortParams, donor } = this.state;
    API.loadDonorDonations(donor.id, page, donorDonationsSortParams).then((res) => {
      this.setState({ donationsCurrentPage: page, donations: refresh ? res.donations : [...donations, ...res.donations] });
      // this.setState({ donationsCurrentPage: page, donationsTotalPages: res.totalPages, donations: refresh ? res.donations : [...donations, ...res.donations] });
    });
  }

  loadShipmentsPage(page, refresh = false) {
    const { shipments, donorShipmentsSortParams, donor } = this.state;
    API.loadDonorShipments(donor.id, page, donorShipmentsSortParams).then((res) => {
      this.setState({ shipmentsCurrentPage: page, shipmentsTotalPages: res.totalPages, shipments: refresh ? res.shipments : [...shipments, ...res.shipments] });
    });
  }

  handleChange(e) {
    const { donor } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    donor[name] = value;
    this.setState({ donor });
  }

  render() {
    const {
      donor, update, shipments, shipmentsTotalPages, shipmentsCurrentPage, donorShipmentsSortParams,
      // orders, donations, ordersTotalPages, donationsTotalPages, ordersCurrentPage, donationsCurrentPage,
      // donorOrdersSortParams, donorDonationsSortParams,
    } = this.state;
    return (
      <React.Fragment>
        <Toast />
        <StickyBar tabs={['Information', 'Shipments']} />
        <div className="detail-container">
          <div className="detail-title">{`Crayon Donor: ${donor.first_name || ''} ${donor.last_name || ''}`}</div>
          <div className="tables-container">
            <section id="information">
              <button type="button" className="table-title label" onClick={this.handleEdit}>
                Information&nbsp;
                <Icon className="img">edit</Icon>
              </button>
              <DonorInformation
                update={update}
                donor={donor}
                handleSave={this.handleSave}
                handleCancel={this.handleCancel}
                handleChange={this.handleChange}
              />
            </section>
            {/*
              <section id="orders">
                <div className="table-title">Orders</div>
                <Table
                  headers={['ID', 'Date', 'Amount, $', 'Number of Items']}
                  objects={orders}
                  keys={['id', 'created_at', 'amount', 'number_of_items']}
                  sortField={donorOrdersSortParams}
                  formatters={{ created_at: item => moment(item).format('M/D/YYYY') }}
                  onPageChange={this.handleOrdersPageChange}
                  paginationDisabled={ordersCurrentPage >= ordersTotalPages}
                  handleSort={this.handleSortOrders}
                />
              </section>
              <section id="donations">
                <div className="table-title">Donations</div>
                <Table
                  headers={['ID', 'Date', 'Amount, $', 'Type', 'Payment Type']}
                  objects={donations}
                  keys={['id', 'created_at', 'amount', 'donation_type', 'payment_type']}
                  sortField={donorDonationsSortParams}
                  formatters={{ created_at: item => moment(item).format('M/D/YYYY') }}
                  onPageChange={this.handleDonationsPageChange}
                  paginationDisabled={donationsCurrentPage >= donationsTotalPages}
                  handleSort={this.handleSortDonations}
                />
              </section>
            */}
            <section id="shipments">
              <div className="table-title">Shipments</div>
              <Table
                headers={['ID', 'Created', 'Received', 'Amount, $', 'Weight', 'Actual Weight', 'Tracking Number']}
                objects={shipments}
                keys={['id', 'created_at', 'received_date', 'amount', 'weight', 'actual_weight', 'tracking_number']}
                sortField={donorShipmentsSortParams}
                formatters={{
                  created_at: item => moment(item).format('M/D/YYYY h:mm:ss A'),
                  received_date: item => (item ? moment(item).format('M/D/YYYY h:mm:ss A') : ''),
                  tracking_number: item => <a target="_blank" rel="noopener noreferrer" href={`https://www.ups.com/track?loc=en_US&tracknum=${item}`} className="tn-link-btn">{item}</a>,
                }}
                onPageChange={this.handleShipmentsPageChange}
                paginationDisabled={shipmentsCurrentPage >= shipmentsTotalPages}
                handleSort={this.handleSortShipments}
              />
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DonorsShow.propTypes = {
  donor: PropTypes.instanceOf(Object),
};

DonorsShow.defaultProps = {
  donor: {},
};

export default DonorsShow;
