// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CalendarDetailModal Component rendering renders correctly 1`] = `
<div
  className="modal_body calendar"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="container"
  >
    <div
      className="detail-modal_title"
    >
      <section>
        <button
          className="modal_close_button no_border"
          onClick={[Function]}
          type="button"
        >
          <span
            aria-hidden="true"
            className="material-icons MuiIcon-root-1 img"
          >
            edit
          </span>
        </button>
        <button
          className="modal_close_button no_border no-margin"
          onClick={[MockFunction]}
          type="button"
        >
          <span
            aria-hidden="true"
            className="material-icons MuiIcon-root-1 img"
          >
            delete
          </span>
        </button>
      </section>
    </div>
    <div
      className="detail-modal_owner"
    >
      Wednesday, December 12
      <div
        style={
          Object {
            "width": "2rem",
          }
        }
      />
      0.00
      -
      0.00am
    </div>
    <div
      className="detail-modal_owner"
    >
      <b>
        Owner: 
      </b>
      <b />
    </div>
    <div
      className="detail-modal_owner last"
    >
      <b>
        Event lead: 
      </b>
      <b />
    </div>
    <div
      className="detail-modal_info"
    >
      <b>
        Attendees: 
      </b>
      <br />
      <button
        className="show-attendees"
        onClick={[Function]}
        type="button"
      >
        Confirmed 0
      </button>
      <br />
      <button
        className="show-attendees"
        onClick={[Function]}
        type="button"
      >
        Invited 0
      </button>
      <br />
      Available seats 
      NaN
    </div>
    <div
      className="detail-modal_info"
    >
      Location: 
    </div>
    <div
      className="detail-modal_info gray"
    />
  </div>
  <div
    className="modal_button_container"
  >
    <button
      className="modal_button"
      onClick={[MockFunction]}
      type="button"
    >
      Sign up
    </button>
  </div>
</div>
`;
