import React from 'react';
import Calendar from 'tui-calendar';
import DatePicker from 'tui-date-picker';
import moment from 'moment';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import API from '../../services/api';
import CalendarCreationModal from '../calendar/CalendarCreationModal';
import CalendarUpdateModal from '../calendar/CalendarUpdateModal';
import CalendarDetailModal from '../calendar/CalendarDetailModal';
import {
  isAdmin, isAttendee, isVolunteer, isMember,
} from '../../services/permissions';
import EventSignUpModal from '../calendar/EventSignUpModal';
import DeleteModal from './DeleteModal';

const DAYS_OF_WEEK = {
  Sun: 'Sunday',
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
};


DatePicker.localeTexts.sh = {
  titles: {
    // days
    DD: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    // daysShort
    D: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    // months
    MMMM: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ],
    // monthsShort
    MMM: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  titleFormat: 'MMMM yyyy',
  todayFormat: 'D, MMMM dd, yyyy',
  date: 'Date',
  time: 'Time',
};

const getTimeTemplate = (schedule, isAllDay) => {
  const html = [];

  if (!isAllDay) {
    html.push(`<strong>${moment(schedule.start.getTime()).format('HH:mm')}</strong> `);
  }
  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"/>');
    html.push(' Private');
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"/>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"/>');
    } else if (schedule.attendees.length) {
      html.push('<span class="calendar-font-icon ic-user-b"/>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"/>');
    }
    html.push(` ${schedule.title}`);
  }

  return html.join('');
};

const getGridTitleTemplate = (type) => {
  let title = '';

  switch (type) {
    case 'allday':
      title = '<span class="tui-full-calendar-left-content">ALL DAY</span>';
      break;
    default:
      break;
  }

  return title;
};

const getGridCategoryTemplate = (category, schedule) => {
  let tpl;

  switch (category) {
    case 'allday':
      tpl = getTimeTemplate(schedule, true);
      break;
    default:
      break;
  }

  return tpl;
};

const getPadStart = value => value.toString().padStart(2, '0');

class CustomCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendar: {},
      schedules: [],
      scheduledEvents: {},
      calendars: props.calendars,
      viewType: 'day',
      eventsType: 'all_events',
      showCreationModal: false,
      showUpdateModal: false,
      showDetailModal: false,
      showSignUpModal: false,
      showDeleteModal: false,
      event: {},
      signUp: false,
      editable: false,
      isInAttendee: false,
      newAttendees: [
        {
          first_name: props.user.first_name,
          last_name: props.user.last_name,
          email: props.user.email,
        },
      ],
    };
  }

  componentDidMount() {
    API.loadEvents().then(r => this.setState(
      { schedules: r.events, scheduledEvents: r.scheduled_events },
      () => {
        this.initCalendar();
        this.initDatePicker();
      },
    ));
  }

  initDatePicker = () => {
    const datePicker = new DatePicker('#datepicker', {
      date: new Date(),
      language: 'sh',
      showAlways: true,
      calendar: {
        showToday: false,
      },
    });

    datePicker.on('change', () => {
      this.changeDate(datePicker.getDate());
    });
  };

  changeDate = (date) => {
    const { calendar } = this.state;
    calendar.setDate(date);
  };

  initCalendar = () => {
    const { calendars, schedules, viewType } = this.state;
    const { user } = this.props;

    const calendar = new Calendar('#calendar', {
      defaultView: 'day',
      taskView: false,
      scheduleView: true,
      template: {
        weekDayname(model) {
          if (viewType === 'day') return `<span class="tui-full-calendar-dayname-name">${DAYS_OF_WEEK[model.dayName]}  ${model.date}</span>`;
          return `<span class="tui-full-calendar-dayname-name">${DAYS_OF_WEEK[model.dayName]}</span><span class="tui-full-calendar-dayname-date">${model.date}</span>`;
        },
        monthDayname(model) {
          return String(DAYS_OF_WEEK[model.label]);
        },
        weekGridFooterExceed(hiddenSchedules) {
          return `+${hiddenSchedules}`;
        },
        dayGridTitle(viewName) {
          return getGridTitleTemplate(viewName);
        },
        schedule(schedule) {
          return getGridCategoryTemplate(schedule.category, schedule);
        },
        collapseBtnTitle() {
          return '<span class="tui-full-calendar-icon tui-full-calendar-ic-arrow-solid-top"/>';
        },
        timezoneDisplayLabel(timezoneOffset, displayLabel) {
          let gmt; let hour; let minutes;
          let displayLabelOut = displayLabel;

          if (!displayLabel) {
            gmt = timezoneOffset < 0 ? '-' : '+';
            hour = Math.abs(parseInt(timezoneOffset / 60, 10));
            minutes = Math.abs(timezoneOffset % 60);
            displayLabelOut = `${gmt + getPadStart(hour)}:${getPadStart(minutes)}`;
          }

          return displayLabelOut;
        },
        timegridDisplayPrimayTime(time) {
          let { hour } = time;
          const meridiem = hour >= 12 ? 'pm' : 'am';

          if (hour > 12) {
            hour -= 12;
          }

          return `${hour} ${meridiem}`;
        },
        timegridDisplayPrimaryTime(time) {
          let { hour } = time;
          const meridiem = hour >= 12 ? 'pm' : 'am';

          if (hour > 12) {
            hour -= 12;
          }

          return `${hour} ${meridiem}`;
        },
        timegridDisplayTime(time) {
          return `${getPadStart(time.hour)}:${getPadStart(time.hour)}`;
        },
        timegridCurrentTime(timezone) {
          const templates = [];

          if (timezone.dateDifference) {
            templates.push(`[${timezone.dateDifferenceSign}${timezone.dateDifference}]<br>`);
          }

          templates.push(moment(timezone.hourmarker.toUTCString()).format('HH:mm'));
          return templates.join('');
        },
      },
      calendars,
      theme: {
        'week.dayname.backgroundColor': '#ceddf5',
        'month.dayname.height': '42px',
        'month.dayname.borderLeft': '0',
      },
      isReadOnly: isVolunteer(user),
      useCreationPopup: false,
      useDetailPopup: false,
    });

    calendar.createSchedules(this.modifySchedule(schedules));

    calendar.on('beforeUpdateSchedule', (event) => {
      if (event.triggerEventName && event.triggerEventName === 'click') this.setState({ showUpdateModal: true, event: event.schedule });
      else this.onDragUpdateEvent(event);
    });

    calendar.on('beforeCreateSchedule', (event) => {
      this.setState({ showCreationModal: true, event });
      event.guide.clearGuideElement();
    });

    calendar.on('beforeDeleteSchedule', (event) => {
      this.destroyEvent(event);
    });

    calendar.on('clickSchedule', (event) => {
      const { schedule } = event;
      this.setState({
        showDetailModal: true,
        event: schedule,
        signUp: isVolunteer(user) && (schedule.raw.max_attendees > schedule.attendees.length) && !isAttendee(user, schedule),
        editable: isAdmin(user) || isMember(user, schedule.raw),
        isInAttendee: isAttendee(user, schedule),
      });
    });

    this.setState({ calendar });
  };

  destroyEvent = (event) => {
    const { calendar } = this.state;
    API.destroyEvent(event.raw.record_id).then(() => {
      API.loadEvents().then((res) => {
        this.setState({ schedules: this.modifySchedule(res.events), showDeleteModal: false });
        calendar.clear();
        calendar.createSchedules(res.events);
        calendar.render();
      });
    });
  };

  modifySchedule = arr => arr.map((item) => {
    const newItem = item;
    newItem.raw = {
      max_attendees: item.max_attendees,
      record_id: item.record_id,
      class: item.class,
      member: item.member || item.raw.member,
      leader: item.leader || item.raw.leader,
      visibility: item.visibility,
    };

    newItem.attendees = item.attendees.map(a => (
      {
        id: a.id,
        name: a.name,
        status: a.status,
      }
    ));

    return newItem;
  });


  createEvent = (event) => {
    const { schedules, calendar } = this.state;
    const schedule = {
      id: Date.now(),
      calendarId: event.calendarId,
      title: event.name,
      body: event.body,
      start: event.startDate,
      category: event.isAllDay ? 'allday' : 'time',
      end: event.endDate,
      location: event.location,
      attendees: event.attendees,
      raw: { class: event.eventType },
      event_type: event.eventType,
      visibility: event.eventVisibility,
      leader: event.leader,
      max_attendees: event.max_attendees,
    };
    API.createEvent(schedule).then((res) => {
      schedule.record_id = res.id;
      schedule.member = res.member;
      schedule.leader = res.leader;
      schedule.attendees = res.attendees;

      const sh = this.modifySchedule([...schedules, schedule]);

      this.setState({ schedules: sh, showCreationModal: false });
      calendar.clear();
      calendar.createSchedules(sh);
      calendar.render();
    });
  };

  onDragUpdateEvent = (event) => {
    const { calendar } = this.state;
    const { schedule, changes } = event;

    calendar.updateSchedule(schedule.id, schedule.calendarId, changes);
    calendar.render();
    changes.start ? schedule.start = changes.start.toDate() : schedule.start = schedule.start.toDate();
    changes.end ? schedule.end = changes.end.toDate() : schedule.end = schedule.end.toDate();
    API.updateEvent(schedule.raw.record_id, schedule);
  };

  updateEvent = (event, newAttendee) => {
    const { schedules } = this.state;

    const schedule = {
      id: event.id,
      calendarId: event.calendarId,
      title: event.name,
      body: event.body,
      start: event.startDate,
      category: event.isAllDay ? 'allday' : 'time',
      end: event.endDate,
      location: event.location,
      new_attendee: newAttendee,
      raw: {
        class: event.eventType,
        visibility: event.eventVisibility,
      },
      event_type: event.eventType,
      visibility: event.eventVisibility,
      record_id: event.record_id,
    };
    API.updateEvent(schedule.record_id, schedule).then((res) => {
      schedule.attendees = res.attendees.map(a => (
        {
          id: a.id,
          name: `${a.first_name} ${a.last_name}`,
        }
      ));
      schedule.raw.member = res.member;
      schedule.raw.leader = res.leader;
      schedule.raw.record_id = res.record_id;

      const index = schedules.findIndex(s => s.id === schedule.id);
      schedules[index] = schedule;

      this.setState({ schedules, showUpdateModal: false }, () => this.updateCalendar());
    });
  };

  btnClickHandler = (type) => {
    const { calendar } = this.state;
    if (type !== 'schedule') calendar.changeView(type, true);
    if (type === 'day' || type === 'schedule') this.setState({ viewType: type }, () => this.initDatePicker());
    else this.setState({ viewType: type });
  };

  handleEventType = (type) => {
    API.loadEvents(type === 'all_events').then((r) => {
      this.setState({ schedules: this.modifySchedule(r.events), scheduledEvents: r.scheduled_events, eventsType: type },
        () => this.updateCalendar());
    });
  };

  updateCalendar = () => {
    const { schedules, calendar } = this.state;
    calendar.clear();
    calendar.createSchedules(schedules);
    calendar.render();
  };

  cbChangeHandler = (item) => {
    const { calendar, calendars } = this.state;
    calendar.toggleSchedules(item.id, item.checked, true);
    calendars.find(c => c.id === item.id).checked = !item.checked;
    this.setState({ calendars });
  };

  signUpEvent = (id) => {
    const { newAttendees } = this.state;
    API.eventSignUp({ id, attendees: newAttendees }).then(() => this.handleCloseSignUpModal());
  };

  handleCloseCreationModal = () => this.setState({ showCreationModal: false });

  handleCloseUpdateModal = () => this.setState({ showUpdateModal: false });

  handleCloseSignUpModal = () => this.setState({ showSignUpModal: false });

  handleCloseDetailModal = () => this.setState({ showDetailModal: false });

  handleCloseDeleteModal = () => this.setState({ showDeleteModal: false });

  handleOpenUpdateModal = event => this.setState({ showUpdateModal: true, showDetailModal: false, event });

  handleOpenDeleteModal = () => this.setState({ showDeleteModal: true, showDetailModal: false });

  handleDestroyEvent = event => this.destroyEvent(event);

  handleAttendeeChange = (e, index) => {
    const { newAttendees } = this.state;
    const { value, name } = e.target;

    newAttendees[index][name] = value;
    this.setState({ newAttendees });
  };

  handleAttendeesNumber = (value) => {
    const { newAttendees } = this.state;
    const { length } = newAttendees;
    let tmp = newAttendees;

    if (length < value) {
      tmp = tmp.concat([...Array(value - length)].map(() => ({
        first_name: '',
        last_name: '',
        email: '',
      })));
    } else if (length > value) {
      tmp = tmp.slice(0, -(length - value));
    }

    this.setState({ newAttendees: tmp });
  };

  render() {
    const {
      viewType, calendars, showCreationModal, showUpdateModal, event, eventsType,
      showDetailModal, calendar, scheduledEvents, showSignUpModal, signUp, showDeleteModal,
      editable, isInAttendee, newAttendees,
    } = this.state;
    const { user } = this.props;
    return (
      <div className="crayon_calendar_container">
        <section className={`full_calendar ${(viewType !== 'day' && viewType !== 'schedule') ? 'cl100' : ''}`}>
          <div className={`calendar_buttons_container ${isVolunteer(user) && 'flex-end'}`}>
            {
              !isVolunteer(user) && (
                <div className="calendar_event_type">
                  <button
                    type="button"
                    disabled={eventsType === 'my_events'}
                    onClick={() => this.handleEventType('my_events')}
                  >
                    My Events
                  </button>
                  <button
                    type="button"
                    disabled={eventsType === 'all_events'}
                    onClick={() => this.handleEventType('all_events')}
                  >
                    All Events
                  </button>
                </div>
              )
            }
            <section>
              <button
                type="button"
                disabled={viewType === 'schedule'}
                onClick={() => this.btnClickHandler('schedule')}
              >
                Schedule
              </button>
              <button
                type="button"
                disabled={viewType === 'day'}
                onClick={() => this.btnClickHandler('day')}
              >
                Day
              </button>
              <button
                type="button"
                disabled={viewType === 'week'}
                onClick={() => this.btnClickHandler('week')}
              >
                Week
              </button>
              <button
                type="button"
                disabled={viewType === 'month'}
                onClick={() => this.btnClickHandler('month')}
              >
                Month
              </button>
            </section>
          </div>
          {viewType !== 'schedule' && (
          <div className="calendar_actions_container">
            <section className="date-nav">
              <button
                type="button"
                className="today"
                onClick={() => calendar.today()}
              >
                Today
              </button>
              <button
                type="button"
                className="arrow"
                onClick={() => calendar.prev()}
              >
                <Icon className="img">keyboard_arrow_left</Icon>
              </button>
              <button
                type="button"
                className="arrow"
                onClick={() => calendar.next()}
              >
                <Icon className="img">keyboard_arrow_right</Icon>
              </button>
              <div className="current_date">{viewType !== 'week' ? moment().format('D MMMM YYYY') : moment().format('MMMM YYYY')}</div>
            </section>
            {(viewType !== 'day' && viewType !== 'schedule' && !isVolunteer(user)) && (
            <div className="table-actions">
              <div className="add-new">
                <div className="label">Create new event</div>
                <button type="button" onClick={() => this.setState({ showCreationModal: true })}>+</button>
              </div>
            </div>
            )}
          </div>
          )}
          {viewType === 'schedule' && (
          <div className="calendar_schedule">
            {Object.keys(scheduledEvents).map(item => (
              <div className="day">
                <div className="date">
                  {moment(scheduledEvents[item][0].start).format('D, MMM, ddd')}
                </div>
                <div className="schedules">
                  {scheduledEvents[item].map(e => (
                    <div className="schedule">
                      <div className="point"><span style={{ backgroundColor: calendars.find(c => c.id === e.calendarId).borderColor }} /></div>
                      <div className="time">
                        {moment(e.start).format('h')}
                        -
                        {moment(e.end).format('ha')}
                      </div>
                      <div className="title">{e.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          )}
          <div hidden={viewType === 'schedule' ? 'hidden' : ''} id="calendar" />
        </section>
        {(viewType === 'day' || viewType === 'schedule') && (
        <section className="date_and_calendars-container">
          <div className="date_picker" id="datepicker" />
          <div className="calendars">
            <div className="title">You categories</div>
            {
              calendars.map((item, i) => (
                <div key={i.toString()} className="lnb-calendars-item">
                  <label>
                    <input type="checkbox" className="tui-full-calendar-checkbox-round" checked={item.checked} onChange={() => this.cbChangeHandler(item)} />
                    <span style={{
                      borderColor: item.borderColor,
                      backgroundColor: item.checked ? item.borderColor : 'transparent',
                      color: item.checked ? 'white' : 'black',
                    }}
                    >
                      {item.name}
                    </span>
                  </label>
                </div>
              ))
            }
          </div>
          {
            !isVolunteer(user) && (
            <div className="calendars without_border">
              <div className="title">Events</div>
              <div className="table-actions">
                <div className="add-new">
                  <button type="button" onClick={() => this.setState({ showCreationModal: true })}>+</button>
                  <div className="label">Create new event</div>
                </div>
              </div>
            </div>
            )
          }
        </section>
        )}
        <ReactModal
          isOpen={showDetailModal}
          onRequestClose={this.handleCloseDetailModal}
          className={`modal calendar detail ${signUp && 'volunteer'}`}
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <CalendarDetailModal
            event={event}
            handleClose={this.handleCloseDetailModal}
            handleUpdate={this.handleOpenUpdateModal}
            handleDelete={this.handleOpenDeleteModal}
            handleClick={() => this.setState({ showDetailModal: false, showSignUpModal: true })}
            signUp={signUp}
            editable={editable}
          />
        </ReactModal>
        <ReactModal
          isOpen={showDeleteModal}
          onRequestClose={this.handleCloseDeleteModal}
          className="modal delete"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <DeleteModal
            title="Confirm delete event"
            subtitle="Are you sure you want to delete the event?"
            handleDelete={() => this.handleDestroyEvent(event)}
            handleClose={this.handleCloseDeleteModal}
          />
        </ReactModal>
        <ReactModal
          isOpen={showCreationModal}
          onRequestClose={this.handleCloseCreationModal}
          className="modal calendar"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <CalendarCreationModal event={event} handleCreate={this.createEvent} calendars={calendars} handleClose={this.handleCloseCreationModal} />
        </ReactModal>
        <ReactModal
          isOpen={showUpdateModal}
          onRequestClose={this.handleCloseUpdateModal}
          className="modal calendar"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <CalendarUpdateModal event={event} calendars={calendars} handleUpdate={this.updateEvent} isAttendee={isInAttendee} handleClose={this.handleCloseUpdateModal} />
        </ReactModal>
        <ReactModal
          isOpen={showSignUpModal}
          onRequestClose={this.handleCloseSignUpModal}
          className="modal calendar detail-sign-up"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <EventSignUpModal
            event={event}
            users={newAttendees}
            handleSignUp={this.signUpEvent}
            calendars={calendars}
            handleClose={this.handleCloseSignUpModal}
            handleChangeAttendee={this.handleAttendeeChange}
            handleChangeAttendeesNumber={this.handleAttendeesNumber}
          />
        </ReactModal>
      </div>
    );
  }
}

CustomCalendar.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  calendars: PropTypes.instanceOf(Array).isRequired,
};

export default CustomCalendar;
