// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CreateIncomingShipment Component rendering renders correctly 1`] = `
<div
  onKeyDown={[Function]}
  role="button"
  tabIndex={-1}
>
  <div
    className="tables-container"
  >
    <div
      className="incoming_shipment_title"
    >
      Shipment
    </div>
    <div
      className="incoming-shipment-container tracking-number"
    >
      <div
        className="incoming_shipment_input-container tracking-number"
      >
        <div
          className="input-label"
        >
          Tracking Number
        </div>
        <input
          className="shipment-input"
          disabled={false}
          id="trackingNumber"
          onChange={[Function]}
          value="trn1"
        />
        <div
          className="errors pack"
        />
      </div>
      <div
        className="input-container"
      >
        <section>
          <div
            className="input-label"
          >
            I do not know the tracking number
          </div>
          <div
            className="checkbox-cell"
          >
            <input
              checked={false}
              id="tracking-number"
              onChange={[Function]}
              type="checkbox"
            />
            <label
              htmlFor="tracking-number"
            />
          </div>
        </section>
      </div>
    </div>
    <div
      className="incoming_shipment_title"
    >
      Sender’s Information
    </div>
    <div
      className="incoming_shipment_search_container"
    >
      <div
        className="incoming_shipment_input-container"
      >
        <div
          className="input-label"
        >
          Lookup sender
        </div>
        <div
          className="donor-search css-2b097c-container"
          onKeyDown={[Function]}
        >
          <div
            className="donor-search__control css-yk16xz-control"
            onMouseDown={[Function]}
            onTouchEnd={[Function]}
          >
            <div
              className="donor-search__value-container css-g1d714-ValueContainer"
            >
              <div
                className="donor-search__placeholder css-1wa3eu0-placeholder"
              >
                Full name
              </div>
              <div
                className="css-b8ldur-Input"
              >
                <div
                  className="donor-search__input"
                  style={
                    Object {
                      "display": "inline-block",
                    }
                  }
                >
                  <input
                    aria-autocomplete="list"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    disabled={false}
                    id="react-select-3-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    spellCheck="false"
                    style={
                      Object {
                        "background": 0,
                        "border": 0,
                        "boxSizing": "content-box",
                        "color": "inherit",
                        "fontSize": "inherit",
                        "label": "input",
                        "opacity": 1,
                        "outline": 0,
                        "padding": 0,
                        "width": "1px",
                      }
                    }
                    tabIndex="0"
                    type="text"
                    value=""
                  />
                  <div
                    style={
                      Object {
                        "height": 0,
                        "left": 0,
                        "overflow": "scroll",
                        "position": "absolute",
                        "top": 0,
                        "visibility": "hidden",
                        "whiteSpace": "pre",
                      }
                    }
                  >
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="donor-search__indicators css-1hb7zxy-IndicatorsContainer"
            >
              <span
                className="donor-search__indicator-separator css-1okebmr-indicatorSeparator"
              />
              <div
                aria-hidden="true"
                className="donor-search__indicator donor-search__dropdown-indicator css-tlfecz-indicatorContainer"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <i
                  className="material-icons"
                >
                  search
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
