import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../ui/CloseButton';

class HospitalAddNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: {
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        email: props.user.email,
        role: props.user.role,
      },
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  hasErrors = () => {
    const { errors } = this.state;
    return Object.values(errors).some(item => item);
  };

  handleSubmit() {
    const { handleSave } = this.props;
    const { inputs } = this.state;
    Object.keys(inputs).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;
    handleSave(inputs);
  }

  validateField(fieldName) {
    const { errors, inputs } = this.state;
    const value = inputs[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errors[fieldName] = valid ? '' : 'Email is invalid';
        break;
      case 'first_name':
      case 'last_name':
        valid = !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  handleChange(e) {
    const { inputs } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    inputs[name] = value;
    this.setState({ inputs }, () => { this.validateField(name); });
  }

  render() {
    const { inputs, errors } = this.state;
    const { handleClose, title, error } = this.props;
    return (
      <div className="modal_body detail">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="title">{title}</div>
        <div className="input-container">
          <section>
            <div className="input-label">First Name</div>
            <input id="first_name" value={inputs.first_name} onChange={this.handleChange} />
          </section>
          <div className="errors">{errors.first_name && errors.first_name}</div>
        </div>
        <div className="input-container">
          <section>
            <div className="input-label">Last Name</div>
            <input id="last_name" value={inputs.last_name} onChange={this.handleChange} />
          </section>
          <div className="errors">{errors.last_name && errors.last_name}</div>
        </div>
        <div className="input-container">
          <section>
            <div className="input-label">Email</div>
            <input id="email" value={inputs.email} onChange={this.handleChange} />
          </section>
          <div className="errors">{(errors.email && errors.email) || (error && error)}</div>
        </div>
        <div className="input-container">
          <section>
            <div className="input-label">Role</div>
            <select id="role" className="select_state" value={inputs.role} onChange={this.handleChange}>
              <option value={inputs.role} disabled hidden>{inputs.role}</option>
              <option value="Volunteer">Volunteer</option>
              <option value="Employee">Employee</option>
              <option value="Admin">Admin</option>
              <option value="Donor">Donor</option>
            </select>
          </section>
        </div>
        <div className="button_container">
          <button type="button" className="incoming-shipments-modal-btn no" onClick={handleClose}>Cancel</button>
          <button type="button" className="incoming-shipments-modal-btn yes" onClick={this.handleSubmit}>Save</button>
        </div>
      </div>
    );
  }
}

HospitalAddNew.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

HospitalAddNew.defaultProps = {
  user: {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
  },
};

export default HospitalAddNew;
