// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`EventSignUpModal Component rendering renders correctly 1`] = `
<div
  className="modal_body detail"
>
  <div
    className="modal_button_container top"
  >
    <button
      className="modal_close_button"
      onClick={[MockFunction]}
      type="button"
    >
      <img
        alt=""
        src="test-file-stub"
      />
    </button>
  </div>
  <div
    className="title event-sign_up"
  >
    Sign up
  </div>
  <div
    className="container sign_up"
  >
    <div
      className="detail-modal_title"
    />
    <div
      className="detail-modal_owner"
    >
      Owner: 
      <b />
    </div>
    <div
      className="detail-modal_owner"
    >
      Event lead: 
      <b />
    </div>
    <div
      className="detail-modal_info"
    >
      Attendees: 
      2
      <br />
      <span>
        undefined, 
      </span>
      <span>
        undefined
      </span>
    </div>
    <div
      className="detail-modal_info"
    >
      Location: 
    </div>
    <div
      className="detail-modal_info gray"
    />
  </div>
  <div
    className="inputs_container event-sign_up"
  >
    <div
      className="input-container calendar w50"
    >
      <section>
        <div
          className="input-label"
        >
          Number of attendees
        </div>
        <div
          className="user-search event-sign_up css-2b097c-container"
          onKeyDown={[Function]}
        >
          <div
            className="user-search__control css-yk16xz-control"
            onMouseDown={[Function]}
            onTouchEnd={[Function]}
          >
            <div
              className="user-search__value-container css-g1d714-ValueContainer"
            >
              <div
                className="user-search__placeholder css-1wa3eu0-placeholder"
              >
                
              </div>
              <div
                className="css-b8ldur-Input"
              >
                <div
                  className="user-search__input"
                  style={
                    Object {
                      "display": "inline-block",
                    }
                  }
                >
                  <input
                    aria-autocomplete="list"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    disabled={false}
                    id="react-select-3-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    spellCheck="false"
                    style={
                      Object {
                        "background": 0,
                        "border": 0,
                        "boxSizing": "content-box",
                        "color": "inherit",
                        "fontSize": "inherit",
                        "label": "input",
                        "opacity": 1,
                        "outline": 0,
                        "padding": 0,
                        "width": "1px",
                      }
                    }
                    tabIndex="0"
                    type="text"
                    value=""
                  />
                  <div
                    style={
                      Object {
                        "height": 0,
                        "left": 0,
                        "overflow": "scroll",
                        "position": "absolute",
                        "top": 0,
                        "visibility": "hidden",
                        "whiteSpace": "pre",
                      }
                    }
                  >
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="user-search__indicators css-1hb7zxy-IndicatorsContainer"
            >
              <span
                className="user-search__indicator-separator css-1okebmr-indicatorSeparator"
              />
              <div
                aria-hidden="true"
                className="user-search__indicator user-search__dropdown-indicator css-tlfecz-indicatorContainer"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <svg
                  aria-hidden="true"
                  className="css-6q0nyr-Svg"
                  focusable="false"
                  height={20}
                  viewBox="0 0 20 20"
                  width={20}
                >
                  <path
                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <section
    className="attendees-container"
  >
    <section
      className="attendee"
    >
      <div
        className="attendee-title"
      >
        Attendee #1
      </div>
      <div
        className="inputs_container event-sign_up"
      >
        <div
          className="input-container calendar"
        >
          <section>
            <div
              className="input-label"
            >
              First name
            </div>
            <input
              name="first_name"
              onChange={[Function]}
              value=""
            />
          </section>
        </div>
        <div
          className="input-container calendar"
        >
          <section>
            <div
              className="input-label"
            >
              Last name
            </div>
            <input
              name="last_name"
              onChange={[Function]}
              value=""
            />
          </section>
        </div>
        <div
          className="input-container calendar"
        >
          <section>
            <div
              className="input-label"
            >
              Email
            </div>
            <input
              name="email"
              onChange={[Function]}
              value=""
            />
          </section>
        </div>
      </div>
    </section>
  </section>
  <div
    className="inputs_container event-sign_up"
  >
    <div
      className="input-container calendar comments"
    >
      <section>
        <div
          className="input-label"
        >
          Comments
        </div>
        <input
          id="comments"
        />
      </section>
    </div>
  </div>
  <div
    className="modal_button_container"
  >
    <button
      className="modal_button"
      onClick={[Function]}
      type="button"
    >
      Sign up
    </button>
  </div>
</div>
`;
