import React from 'react';
import ReactModal from 'react-modal';
import Table from '../ui/Table';
import API from '../../services/api';
import sortHelper from '../../services/sortHelper';
import Toast from '../ui/Toast';
import DeleteModal from '../ui/DeleteModal';
import { successToast, errorToast } from '../../services/toast';

class HospitalQueuesIndex extends React.Component {
  constructor(props) {
    super(props);

    this.searchHospitalQueues = this.searchHospitalQueues.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOpenSkipModal = this.handleOpenSkipModal.bind(this);
    this.handleCloseSkipModal = this.handleCloseSkipModal.bind(this);
    this.handleSkipShipment = this.handleSkipShipment.bind(this);
    this.handleUpQueue = this.handleUpQueue.bind(this);
    this.handleDownQueue = this.handleDownQueue.bind(this);
    this.handleHospitalQueueSelect = this.handleHospitalQueueSelect.bind(this);

    this.state = {
      hospitalQueues: [],
      totalPages: 1,
      currentPage: 1,
      hospitalQueuesSortParams: {
        column: 'row_number',
        type: 'asc',
      },
      searchQuery: '',
      actionKeys: ['last_page', 'arrow_upward', 'arrow_downward'],
      selectedObject: {},
      showSkipModal: false,
    };
  }

  componentDidMount() {
    this.loadPage(1);
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { hospitalQueuesSortParams } = this.state;

    if (column === 'shipment_type') {
      return this.loadPage(1, true);
    }

    return this.setState({ hospitalQueuesSortParams: sortHelper(column, hospitalQueuesSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const { hospitalQueues, hospitalQueuesSortParams, searchQuery } = this.state;

    API.loadHospitalQueues(page, hospitalQueuesSortParams, searchQuery).then((res) => {
      this.setState({
        currentPage: page,
        totalPages: res.totalPages,
        hospitalQueues: refresh ? res.hospitalQueues : [...hospitalQueues, ...res.hospitalQueues],
      });
    });
  }

  searchHospitalQueues(e) {
    this.setState({ searchQuery: e.target.value }, () => this.loadPage(1, true));
  }

  handleOpenSkipModal(obj) {
    this.setState({ showSkipModal: true, selectedObject: obj });
  }

  handleCloseSkipModal() {
    this.setState({ showSkipModal: false });
  }

  handleSkipShipment() {
    const {
      selectedObject, hospitalQueuesSortParams, searchQuery, currentPage,
    } = this.state;

    API.skipShipment(selectedObject.id, hospitalQueuesSortParams, searchQuery, currentPage).then((res) => {
      this.setState({ hospitalQueues: res.hospitalQueues, showSkipModal: false });
      successToast('Success! Shipment was skipped.');
    }).catch(() => {
      this.setState({ showSkipModal: false });
      errorToast();
    });
  }

  handleUpQueue(obj) {
    const { hospitalQueuesSortParams, searchQuery, currentPage } = this.state;

    API.upShipmentQueue(obj.id, hospitalQueuesSortParams, searchQuery, currentPage).then((res) => {
      this.setState({ hospitalQueues: res.hospitalQueues });
      successToast('Success! The hospital was changed in the queue');
    }).catch(() => {
      errorToast();
    });
  }

  handleDownQueue(obj) {
    const { hospitalQueuesSortParams, searchQuery, currentPage } = this.state;

    API.downShipmentQueue(obj.id, hospitalQueuesSortParams, searchQuery, currentPage).then((res) => {
      this.setState({ hospitalQueues: res.hospitalQueues });
      successToast('Success! The hospital was changed in the queue');
    }).catch(() => {
      errorToast();
    });
  }

  handleHospitalQueueSelect(id) {
    window.open(`/hospitals/${id}`, '_self');
  }

  render() {
    const {
      hospitalQueues, totalPages, currentPage, searchQuery, hospitalQueuesSortParams,
      actionKeys, showSkipModal,
    } = this.state;

    return (
      <div className="detail-container index_page table-sized">
        <Toast />
        <div className="details-header">
          <div className="detail-title">
            Hospital Queues
          </div>
          <div className="search">
            <input id="search" value={searchQuery || ''} onChange={this.searchHospitalQueues} />
            <span>Search</span>
          </div>
        </div>
        <Table
          headers={['Position', 'ID', 'Name', 'Shipment type']}
          keys={['row_number', 'id', 'name', 'shipment_type']}
          sortField={hospitalQueuesSortParams}
          objects={hospitalQueues}
          onPageChange={this.handlePageChange}
          paginationDisabled={currentPage >= totalPages}
          handleSort={this.handleSort}
          editable
          actionKeys={actionKeys}
          handleSkipQueue={this.handleOpenSkipModal}
          handleUpQueue={this.handleUpQueue}
          handleDownQueue={this.handleDownQueue}
          showCreateNew={false}
          whiteSpaceWrapper
          withRowNumber={false}
          handleSelect={this.handleHospitalQueueSelect}
        />
        <ReactModal
          isOpen={showSkipModal}
          onRequestClose={this.handleCloseSkipModal}
          className="modal delete"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <DeleteModal
            title="Confirm Skip Shipment"
            subtitle="Are you sure you want to skip this shipment?"
            handleDelete={this.handleSkipShipment}
            handleClose={this.handleCloseSkipModal}
          />
        </ReactModal>
      </div>
    );
  }
}

export default HospitalQueuesIndex;
