import React from 'react';
import PropTypes from 'prop-types';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.myDiv = React.createRef();
    this.state = {
      disable: true,
      error: false,
      agree: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleScroll() {
    if (this.myDiv.current.offsetHeight + this.myDiv.current.scrollTop >= this.myDiv.current.scrollHeight) {
      this.setState({ disable: false });
    }
  }

  handleAgree() {
    const { agree } = this.state;
    this.setState({ agree: !agree, error: false });
  }

  handleConfirm() {
    const { agree } = this.state;
    const { confirm } = this.props;
    if (!agree) this.setState({ error: true });
    else confirm();
  }

  render() {
    const { disable, error, agree } = this.state;
    const { cancel } = this.props;
    return (
      <div className="modal_body detail toc">
        <div className="title toc">Terms and Conditions</div>
        <div className="sub-title toc">
          Please read these Terms of Service carefully before accessing or using our website.
          By accessing or using any part of the site, you agree to be bound by these Terms of Service.
        </div>
        <div className="toc-container" ref={this.myDiv} onScroll={this.handleScroll}>
          <div className="toc-title">Release and Waiver of Liability</div>
          <div className="toc-subtitle">
            This Release and Waiver of Liability (the “Release”) is executed on the day of [date], by the signee below
            (the “Volunteer”) in favor of The Crayon Initiative, and their respective directors, officers, trustees,
            employees, volunteers and agents (collectively, the “Released Parties”).
          </div>
          <br />
          <div className="toc-subtitle">
            I, the Volunteer, desire to work as a volunteer for one or more of the Released Parties and engage in the
            activities related to being a volunteer for The Crayon Initiative (&quot;Activities&quot;). I understand that the
            Activities may include but are not limited to the following: working in The Crayon Initiative offices or
            production facilities; traveling to and from volunteering events, towns, cities or states; consuming food
            available or provided.
          </div>
          <br />
          <div className="toc-subtitle">
            I, the Volunteer, hereby freely, voluntarily and without duress execute this Release under the following terms:
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Release and Waiver</span>
            .&nbsp;I, the Volunteer, do hereby release and forever discharge and hold harmless the Released
            Parties and their successors and assigns from any and all liability, claims and demands which I or my heirs,
            assigns, next of kin or legal representatives may have or which may hereinafter accrue with respect to any
            bodily injury, personal injury, illness, death or property damage which arise or may hereafter arise from
            or is in any way related to my Activities with any of the Released Parties, whether caused wholly or in
            part by the simple negligence, fault or other misconduct, other than intentional or grossly negligent
            conduct, of any of the Released Parties.
          </div>
          <br />
          <div className="toc-subtitle">
            I understand and acknowledge that by this Release I knowingly assume the risk of injury, harm and loss
            associated with the Activities. I also understand that the Released Parties do not assume any responsibility
            for or obligation to provide financial assistance or other assistance, including but not limited to medical,
            health or disability insurance in the event of injury, illness, death or property damage.
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Medical Treatment</span>
            .&nbsp;I, the Volunteer, do hereby release and forever discharge the Released Parties from any claim or action
            whatsoever which arises or may hereafter arise on account of any first aid, treatment or service rendered
            in connection with my Activities with any of the Released Parties, whether administered by any Released
            Party or by third party medical or other personnel.
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Assumption of the Risk</span>
            .&nbsp;I, the Volunteer, understand that my Activities may include work that may be hazardous to me, including, but
            not limited to, the following: travel to and from the work sites; loading and unloading of heavy items;
            working with hot materials; and exposure to talc, asbestos, and bacteria, which may cause or worsen certain
            illnesses, especially if I do not wear protective equipment, am exposed for extended periods of time,
            or have a pre-existing immune system deficiency.
          </div>
          <br />
          <div className="toc-subtitle">
            I hereby expressly and specifically assume the risk of injury or harm in the Activities and release the
            Released Parties from all liability for any loss, cost, expense, injury, illness, death, or property damage
            resulting directly or indirectly from the Activities.
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Insurance</span>
            .&nbsp;I, the Volunteer, understand that, except as otherwise agreed to by the Released Parties in writing, the
            Released Parties are under no obligation to provide, carry or maintain health, medical, travel, disability
            or other insurance coverage for any Volunteer. Each Volunteer is expected and encouraged to obtain his or
            her own health, medical, travel, disability or other insurance coverage.
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Photographic Release</span>
            .&nbsp;I, the Volunteer, do hereby grant and convey unto Released Parties all right, title and interest in any and
            all photographs and video or audio recordings of or including my image or voice, made by any of the Released
            Parties during my Activities with the Released Parties, including, but not limited to, the permission to
            record, edit, transcribe, use, duplicate, modify, distribute, and/or publicly exhibit the Volunteer&apos;s
            presentation or appearance, and use of Volunteer&apos;s name, likeness, voice and biographical information in
            connection with the foregoing at the event, in any and all media now existing or hereafter developed,
            throughout the world, in perpetuity, without restrictions or limitation, including the right to use such
            photographs or recordings for any purpose and to any royalties, proceeds or other benefits derived from them.
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Release and Waiver</span>
            .&nbsp;I, the Volunteer, do hereby release and forever discharge and hold harmless the Released
            Parties and their successors and assigns from any and all liability, claims and demands which I or my heirs,
            assigns, next of kin or legal representatives may have or which may hereinafter accrue with respect to any
            bodily injury, personal injury, illness, death or property damage which arise or may hereafter arise from
            or is in any way related to my Activities with any of the Released Parties, whether caused wholly or in
            part by the simple negligence, fault or other misconduct, other than intentional or grossly negligent
            conduct, of any of the Released Parties.
          </div>
          <br />
          <div className="toc-subtitle">
            <span>Other</span>
            .&nbsp;I, the Volunteer, expressly agree that this Release is intended to be as broad and inclusive as permitted
            by the laws of the state where the Activities take place. I further agree that in the event any clause or
            provision of this Release shall be held to be invalid by any court of competent jurisdiction, the invalidity
            of such clause or provision shall not otherwise affect the remaining clauses or provisions of this Release,
            which shall continue to be enforceable. Further, a waiver of a right under this Release does not prevent the
            exercise of any other right.
          </div>
        </div>
        <div className="input-container">
          <div className={`checkbox-cell tos ${error && 'errorr'}`}>
            <input type="checkbox" name="ag" id="ag" checked={agree} disabled={disable} onChange={this.handleAgree} />
            <label htmlFor="ag" className={`input-label ${disable && 'disabled'}`}>I agree with Terms and Conditions</label>
          </div>
        </div>
        <div className="update-buttons--container">
          <button type="button" className="cancel" onClick={cancel}>Back</button>
          <button type="button" disabled={disable} className="save" onClick={this.handleConfirm}>Continue</button>
        </div>
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default TermsAndConditions;
