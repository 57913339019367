import React from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import Table from '../ui/Table';
import API from '../../services/api';
import AdminLogModal from './AdminLogModal';
import sortHelper from '../../services/sortHelper';

class AdminLogsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPages: 1,
      currentPage: 1,
      showLogModal: false,
      log: {},
      logs: [],
      searchQuery: '',
      adminLogsSortParams: {
        column: 'created_at',
        type: 'desc',
      },
    };

    this.handleAdminLogSelect = this.handleAdminLogSelect.bind(this);
    this.handleCloseLogModal = this.handleCloseLogModal.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.searchAdminLogs = this.searchAdminLogs.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
  }

  handleAdminLogSelect(id) {
    const { logs } = this.state;

    this.setState({ showLogModal: true });
    this.setState({ log: logs.find(o => o.id === id) });
  }

  handleCloseLogModal() {
    this.setState({ showLogModal: false });
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { adminLogsSortParams } = this.state;
    this.setState({ adminLogsSortParams: sortHelper(column, adminLogsSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const { logs, adminLogsSortParams, searchQuery } = this.state;
    API.loadAdminLogs(page, adminLogsSortParams, searchQuery).then((res) => {
      this.setState({
        currentPage: page, totalPages: res.totalPages, logs: refresh ? res.logs : [...logs, ...res.logs],
      });
    });
  }

  searchAdminLogs(e) {
    this.setState({ searchQuery: e.target.value }, () => this.loadPage(1, true));
  }

  render() {
    const {
      showLogModal, log, logs, adminLogsSortParams, currentPage, totalPages, searchQuery,
    } = this.state;
    return (
      <div className="detail-container index_page">
        <div className="details-header">
          <div className="detail-title">
            Admin Logs
          </div>
          <div className="search">
            <input id="search" value={searchQuery || ''} onChange={this.searchAdminLogs} />
            <span>Search</span>
          </div>
        </div>
        <Table
          headers={
            ['Created Date', 'Who caused the changes', 'Object name', 'Description about changes']
          }
          keys={['created_at', 'user_full_name', 'object_type', 'content']}
          sortField={adminLogsSortParams}
          objects={logs}
          onPageChange={this.handlePageChange}
          paginationDisabled={currentPage >= totalPages}
          handleSelect={this.handleAdminLogSelect}
          handleSort={this.handleSort}
          formatters={{ created_at: item => moment(item).format('M/D/YYYY h:mm:ss A') }}
          showCreateNew={false}
        />
        <ReactModal
          isOpen={showLogModal}
          onRequestClose={this.handleCloseLogModal}
          className="modal log"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <AdminLogModal
            subtitle="Admin log details"
            handleClose={this.handleCloseLogModal}
            log={log}
          />
        </ReactModal>
      </div>
    );
  }
}

export default AdminLogsIndex;
