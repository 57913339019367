import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import InventoryIndex from '../InventoryIndex';

jest.mock('../../../services/api', () => ({
  suppliesUpdate: jest.fn(() => Promise.resolve({
    updateInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15T14:59:50.650488',
    },
  })),
  inventoriesUpdate: jest.fn(() => Promise.resolve({
    updateInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15T14:59:50.650488',
    },
  })),
  loadProductions: jest.fn(() => Promise.resolve({
    totalPages: 2,
    productions: [],
  })),
}));

describe('InventoryIndex Component', () => {
  let wrapper;
  const defaultProps = {
    supplies: {
      item1: 'Low',
      item2: 'Low',
      item3: 'Low',
      item4: 'High',
      item5: 'Low',
      item6: 'Low',
    },
    updateSuppliesInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15 14:57:50.650488',
    },
    updateInventoriesInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15 14:57:50.650488',
    },
    updateProductionsInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15 14:57:50.650488',
    },
    inventory: {
      red: 5,
      orange: 18,
      yellow: 29,
      green: 43,
      blue: 50,
      purple: 45,
      pink: 87,
      brown: 67,
    },
    production: [],
    numberOfCases: 22,
    numberOfReadyCases: 100,
  };

  beforeEach(() => {
    wrapper = mount(<InventoryIndex {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<InventoryIndex {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('.tables-container')).toHaveLength(1);
      expect(wrapper.find('.table-title')).toHaveLength(3);
      expect(wrapper.find('table')).toHaveLength(2);
    });
  });

  describe('events', () => {
    it('handle update', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(wrapper.find('.update-info')).toHaveLength(2);
      expect(wrapper.find('.update-buttons--container')).toHaveLength(1);
    });

    it('click "Cancel" button', () => {
      beforeEach(() => {
        wrapper.find('button').at(0).simulate('click');
        wrapper.find('button').at(1).simulate('click');
      });

      expect(wrapper.find('.update-info')).toHaveLength(3);
      expect(wrapper.find('.update-buttons--container')).toHaveLength(0);
    });

    it('click "Save" inventory button', () => {
      beforeEach(() => {
        wrapper.find('button').at(0).simulate('click');
        wrapper.find('.save').at(0).simulate('click');
      });

      expect(wrapper.find('.update-info')).toHaveLength(3);
      expect(wrapper.find('.update-buttons--container')).toHaveLength(0);
    });

    it('click "Save" supplies button', () => {
      beforeEach(() => {
        wrapper.setState({ updateSupplies: false, updateInventories: true });
        wrapper.find('.save').at(0).simulate('click');
      });

      expect(wrapper.find('.update-info')).toHaveLength(3);
      expect(wrapper.find('.update-buttons--container')).toHaveLength(0);
    });

    it('click "Save" inventory button with errors', () => {
      wrapper.instance().handleChangeInventory('red', '3245');
      wrapper.instance().handleSaveInventory();
      expect(wrapper.text()).toMatch('All fields must be a number in range 1 - 1000!');
    });

    it('handle change', () => {
      wrapper.instance().handleChangeSupply('item1', 'High');
      wrapper.instance().handleChangeInventory('red', '66');
      expect(wrapper.state().supplies.item1).toEqual('High');
      expect(wrapper.state().inventory.red).toEqual('66');
    });

    it('click "Add" button', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(wrapper.state().showAddModal).toEqual(true);
    });

    it('click "Delete" button', () => {
      wrapper.find('button').at(3).simulate('click');
      expect(wrapper.state().showDeleteModal).toEqual(true);
    });

    it('click "View" button', () => {
      wrapper.find('button').at(4).simulate('click');
      expect(wrapper.state().showViewModal).toEqual(true);
    });

    it('handle change numberOfReadyCases', () => {
      wrapper.instance().handleChangeNumberOfReadyCases('1');
      expect(wrapper.state().numberOfReadyCases).toEqual('1');
    });
  });
});
