import qs from 'qs';
import { errorToast } from './toast';

const apiFetch = (url, method, body) => (
  fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  })
    .then(res => res.json())
    .then((res) => {
      if (!res.id && !res.ok) {
        errorToast(res.errors.join('\n'));
        window.console.log(res);
        throw res;
      }
      return res;
    })
);

const apiFetchFile = (url, method, body) => (
  fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  }).then((response) => {
    response.blob().then((blob) => {
      // eslint-disable-next-line no-shadow
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'label.pdf';
      a.click();
    });
  })
);

const get = url => apiFetch(url, 'GET');
const post = (url, data) => apiFetch(url, 'POST', data);
const postF = (url, data) => apiFetchFile(url, 'POST', data);
const put = (url, data) => apiFetch(url, 'PUT', data);
const destroy = url => apiFetch(url, 'DELETE');

const api = {
  validation: data => post('/api/validate', data),
  donorValidation: data => post('/api/validate_donor', data),
  shipping: data => post('/api/shipping', data),
  charge: data => post('/api/shipping/charge', data),
  checkShipment: data => post('/api/incoming_shipments/check', data),
  acceptShipment: data => post('/api/incoming_shipments/accept', data),
  production: data => post('/api/productions', data),
  suppliesUpdate: data => post('/api/supplies', data),
  inventoriesUpdate: data => post('/api/inventories', data),
  createDonor: data => post('/api/donors', data),
  updateDonor: data => put(`/api/donors/${data.id}`, data),
  createHospital: data => post('/api/hospitals', data),
  updateHospital: data => put(`/api/hospitals/${data.id}`, data),
  deleteHospital: id => destroy(`/api/hospitals/${id}`),
  createHospitalContact: data => post('/api/hospital_contacts', data),
  updateHospitalContact: (id, data) => put(`/api/hospital_contacts/${id}`, data),
  deleteHospitalContact: id => destroy(`/api/hospital_contacts/${id}`),
  hospitalShipment: data => post('/api/hospital_shipments', data),
  loadDonors: (page, sort, search) => get(`/api/donors?${qs.stringify({ page, sort, search })}`),
  loadUsers: (page, sort, search) => get(`/api/users?${qs.stringify({ page, sort, search })}`),
  createUser: data => post('/api/users', data),
  updateUser: (id, data) => put(`/api/users/${id}`, data),
  deleteUser: id => destroy(`/api/users/${id}`),
  loadHospitals: (page, sort, search, filters) => get(`/api/hospitals?${qs.stringify({
    page, sort, search, filters,
  })}`),
  loadAllHospitals: (sort, filters) => get(`/api/hospital_shipments?${qs.stringify({ sort, filters })}`),
  loadProductions: (page, sort) => get(`/api/productions?${qs.stringify({ page, sort })}`),
  loadHospitalOrders: (id, page, sort) => get(`/api/hospital_orders?${qs.stringify({ id, page, sort })}`),
  loadHospitalContacts: (id, sort) => get(`/api/hospital_contacts?${qs.stringify({ id, sort })}`),
  loadHospitalNotes: (id, page, sort) => get(`/api/hospitals/${id}/notes?${qs.stringify({ page, sort })}`),
  createHospitalNotes: (hospitalId, data) => post(`/api/hospitals/${hospitalId}/notes`, data),
  updateHospitalNotes: (hospitalId, id, data) => put(`/api/hospitals/${hospitalId}/notes/${id}`, data),
  deleteHospitalNotes: (hospitalId, id) => destroy(`/api/hospitals/${hospitalId}/notes/${id}`),
  loadDonorOrders: (id, page, sort) => get(`/api/donor_orders?${qs.stringify({ id, page, sort })}`),
  loadDonorDonations: (id, page, sort) => get(`/api/donor_donations?${qs.stringify({ id, page, sort })}`),
  loadDonorShipments: (id, page, sort) => get(`/api/donor_shipments?${qs.stringify({ id, page, sort })}`),
  createDonorShipment: data => post('/api/donor_shipments', data),
  loadCalendars: () => get('/api/calendars'),
  createCalendar: data => post('/api/calendars', data),
  loadEvents: (all = true) => get(`/api/events?${qs.stringify({ all })}`),
  createEvent: data => post('/api/events', data),
  updateEvent: (id, data) => put(`/api/events/${id}`, data),
  updateEventAttendee: (id, data) => put(`/api/event_attendees/${id}`, data),
  destroyEvent: id => destroy(`/api/events/${id}`),
  searchDonor: search => get(`/api/search_donors?${qs.stringify({ search })}`),
  searchUser: (search, roles) => get(`/api/search_users?${qs.stringify({ search, roles })}`),
  eventSignUp: data => post('api/events/sign_up', data),
  downloadDonorLabel: id => postF('api/downloads/labels/donor', id),
  downloadHospitalLabel: id => postF('api/downloads/labels/hospital', id),
  loadInTransitShipments: sort => get(`/api/in_transit_shipments?${qs.stringify({ sort })}`),
  loadInTransitArchivedShipments: (page, sort) => get(`/api/in_transit_shipments/archived?${qs.stringify({ page, sort })}`),
  loadHospitalQueues: (page, sort, search) => get(`/api/hospital_queues?${qs.stringify({ page, sort, search })}`),
  skipShipment: (id, sort, search, page) => get(`/api/hospital_queues/${id}/skip?${qs.stringify({ sort, search, page })}`),
  upShipmentQueue: (id, sort, search, page) => get(`/api/hospital_queues/${id}/order_up?${qs.stringify({ sort, search, page })}`),
  downShipmentQueue: (id, sort, search, page) => get(`/api/hospital_queues/${id}/order_down?${qs.stringify({ sort, search, page })}`),
  loadAdminLogs: (page, sort, search) => get(`api/admin_logs?${qs.stringify({ page, sort, search })}`),
};

export default api;
