// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SuppliesTable Component rendering renders correctly 1`] = `
<div
  className="box padding with-actions"
>
  <div
    className="table-actions--supplies"
  >
    <div
      className="edit"
    >
      <button
        className="label"
        onClick={[MockFunction]}
        type="button"
      >
        <span
          aria-hidden="true"
          className="material-icons MuiIcon-root-1 img"
        >
          edit
        </span>
         Update supplies
      </button>
    </div>
  </div>
  <div
    className="box flex box-default table-box table-responsive"
  >
    <table
      className="mdl-data-table table-bordered with-margins"
    >
      <thead>
        <tr>
          <th
            className="mdl-data-table__cell--non-numeric"
          >
            Items
          </th>
          <th
            className="mdl-data-table__cell--non-numeric"
          >
            Quantity
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            96ct Sleeves
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
          >
            <div
              className="quantity-select quantity-select--is-disabled css-14jk2my-container"
              onKeyDown={[Function]}
            >
              <div
                className="quantity-select__control quantity-select__control--is-disabled css-1fhf3k1-control"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <div
                  className="quantity-select__value-container quantity-select__value-container--has-value css-g1d714-ValueContainer"
                >
                  <div
                    className="quantity-select__single-value quantity-select__single-value--is-disabled css-107lb6w-singleValue"
                  >
                    Low
                  </div>
                  <input
                    aria-autocomplete="list"
                    className="css-62g3xt-dummyInput"
                    disabled={true}
                    id="react-select-8-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    readOnly={true}
                    tabIndex="0"
                    value=""
                  />
                </div>
                <div
                  className="quantity-select__indicators css-1hb7zxy-IndicatorsContainer"
                >
                  <span
                    className="quantity-select__indicator-separator css-109onse-indicatorSeparator"
                  />
                  <div
                    aria-hidden="true"
                    className="quantity-select__indicator quantity-select__dropdown-indicator css-tlfecz-indicatorContainer"
                    onMouseDown={[Function]}
                    onTouchEnd={[Function]}
                  >
                    <svg
                      aria-hidden="true"
                      className="css-6q0nyr-Svg"
                      focusable="false"
                      height={20}
                      viewBox="0 0 20 20"
                      width={20}
                    >
                      <path
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            8ct Cartons (unassembled)
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
          >
            <div
              className="quantity-select quantity-select--is-disabled css-14jk2my-container"
              onKeyDown={[Function]}
            >
              <div
                className="quantity-select__control quantity-select__control--is-disabled css-1fhf3k1-control"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <div
                  className="quantity-select__value-container quantity-select__value-container--has-value css-g1d714-ValueContainer"
                >
                  <div
                    className="quantity-select__single-value quantity-select__single-value--is-disabled css-107lb6w-singleValue"
                  >
                    Low
                  </div>
                  <input
                    aria-autocomplete="list"
                    className="css-62g3xt-dummyInput"
                    disabled={true}
                    id="react-select-9-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    readOnly={true}
                    tabIndex="0"
                    value=""
                  />
                </div>
                <div
                  className="quantity-select__indicators css-1hb7zxy-IndicatorsContainer"
                >
                  <span
                    className="quantity-select__indicator-separator css-109onse-indicatorSeparator"
                  />
                  <div
                    aria-hidden="true"
                    className="quantity-select__indicator quantity-select__dropdown-indicator css-tlfecz-indicatorContainer"
                    onMouseDown={[Function]}
                    onTouchEnd={[Function]}
                  >
                    <svg
                      aria-hidden="true"
                      className="css-6q0nyr-Svg"
                      focusable="false"
                      height={20}
                      viewBox="0 0 20 20"
                      width={20}
                    >
                      <path
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            8ct Cartons (assembled)
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
          >
            <div
              className="quantity-select quantity-select--is-disabled css-14jk2my-container"
              onKeyDown={[Function]}
            >
              <div
                className="quantity-select__control quantity-select__control--is-disabled css-1fhf3k1-control"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <div
                  className="quantity-select__value-container quantity-select__value-container--has-value css-g1d714-ValueContainer"
                >
                  <div
                    className="quantity-select__single-value quantity-select__single-value--is-disabled css-107lb6w-singleValue"
                  >
                    Low
                  </div>
                  <input
                    aria-autocomplete="list"
                    className="css-62g3xt-dummyInput"
                    disabled={true}
                    id="react-select-10-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    readOnly={true}
                    tabIndex="0"
                    value=""
                  />
                </div>
                <div
                  className="quantity-select__indicators css-1hb7zxy-IndicatorsContainer"
                >
                  <span
                    className="quantity-select__indicator-separator css-109onse-indicatorSeparator"
                  />
                  <div
                    aria-hidden="true"
                    className="quantity-select__indicator quantity-select__dropdown-indicator css-tlfecz-indicatorContainer"
                    onMouseDown={[Function]}
                    onTouchEnd={[Function]}
                  >
                    <svg
                      aria-hidden="true"
                      className="css-6q0nyr-Svg"
                      focusable="false"
                      height={20}
                      viewBox="0 0 20 20"
                      width={20}
                    >
                      <path
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            100ct Cases
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
          >
            <div
              className="quantity-select quantity-select--is-disabled css-14jk2my-container"
              onKeyDown={[Function]}
            >
              <div
                className="quantity-select__control quantity-select__control--is-disabled css-1fhf3k1-control"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <div
                  className="quantity-select__value-container quantity-select__value-container--has-value css-g1d714-ValueContainer"
                >
                  <div
                    className="quantity-select__single-value quantity-select__single-value--is-disabled css-107lb6w-singleValue"
                  >
                    High
                  </div>
                  <input
                    aria-autocomplete="list"
                    className="css-62g3xt-dummyInput"
                    disabled={true}
                    id="react-select-11-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    readOnly={true}
                    tabIndex="0"
                    value=""
                  />
                </div>
                <div
                  className="quantity-select__indicators css-1hb7zxy-IndicatorsContainer"
                >
                  <span
                    className="quantity-select__indicator-separator css-109onse-indicatorSeparator"
                  />
                  <div
                    aria-hidden="true"
                    className="quantity-select__indicator quantity-select__dropdown-indicator css-tlfecz-indicatorContainer"
                    onMouseDown={[Function]}
                    onTouchEnd={[Function]}
                  >
                    <svg
                      aria-hidden="true"
                      className="css-6q0nyr-Svg"
                      focusable="false"
                      height={20}
                      viewBox="0 0 20 20"
                      width={20}
                    >
                      <path
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            Case Pads
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
          >
            <div
              className="quantity-select quantity-select--is-disabled css-14jk2my-container"
              onKeyDown={[Function]}
            >
              <div
                className="quantity-select__control quantity-select__control--is-disabled css-1fhf3k1-control"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <div
                  className="quantity-select__value-container quantity-select__value-container--has-value css-g1d714-ValueContainer"
                >
                  <div
                    className="quantity-select__single-value quantity-select__single-value--is-disabled css-107lb6w-singleValue"
                  >
                    Low
                  </div>
                  <input
                    aria-autocomplete="list"
                    className="css-62g3xt-dummyInput"
                    disabled={true}
                    id="react-select-12-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    readOnly={true}
                    tabIndex="0"
                    value=""
                  />
                </div>
                <div
                  className="quantity-select__indicators css-1hb7zxy-IndicatorsContainer"
                >
                  <span
                    className="quantity-select__indicator-separator css-109onse-indicatorSeparator"
                  />
                  <div
                    aria-hidden="true"
                    className="quantity-select__indicator quantity-select__dropdown-indicator css-tlfecz-indicatorContainer"
                    onMouseDown={[Function]}
                    onTouchEnd={[Function]}
                  >
                    <svg
                      aria-hidden="true"
                      className="css-6q0nyr-Svg"
                      focusable="false"
                      height={20}
                      viewBox="0 0 20 20"
                      width={20}
                    >
                      <path
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          className="no-selectable"
        >
          <td
            className="mdl-data-table__cell--non-numeric"
          >
            Bulk Crayon Storage Boxes
          </td>
          <td
            className="mdl-data-table__cell--non-numeric no-padding"
          >
            <div
              className="quantity-select quantity-select--is-disabled css-14jk2my-container"
              onKeyDown={[Function]}
            >
              <div
                className="quantity-select__control quantity-select__control--is-disabled css-1fhf3k1-control"
                onMouseDown={[Function]}
                onTouchEnd={[Function]}
              >
                <div
                  className="quantity-select__value-container quantity-select__value-container--has-value css-g1d714-ValueContainer"
                >
                  <div
                    className="quantity-select__single-value quantity-select__single-value--is-disabled css-107lb6w-singleValue"
                  >
                    Low
                  </div>
                  <input
                    aria-autocomplete="list"
                    className="css-62g3xt-dummyInput"
                    disabled={true}
                    id="react-select-13-input"
                    onBlur={[Function]}
                    onChange={[Function]}
                    onFocus={[Function]}
                    readOnly={true}
                    tabIndex="0"
                    value=""
                  />
                </div>
                <div
                  className="quantity-select__indicators css-1hb7zxy-IndicatorsContainer"
                >
                  <span
                    className="quantity-select__indicator-separator css-109onse-indicatorSeparator"
                  />
                  <div
                    aria-hidden="true"
                    className="quantity-select__indicator quantity-select__dropdown-indicator css-tlfecz-indicatorContainer"
                    onMouseDown={[Function]}
                    onTouchEnd={[Function]}
                  >
                    <svg
                      aria-hidden="true"
                      className="css-6q0nyr-Svg"
                      focusable="false"
                      height={20}
                      viewBox="0 0 20 20"
                      width={20}
                    >
                      <path
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      className="update-info"
    >
      <div>
        Updated by: 
        John Wick
      </div>
      <div>
        Updated on: 
        11/15/2019 2:57:50 PM
      </div>
    </div>
  </div>
</div>
`;
