import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import mockDate from 'mockdate';
import Calendar from '../Calendar';

function mockCalendarCreationModal(props) { return <div {...props} />; }
function mockCalendarUpdateModal(props) { return <div {...props} />; }
function mockCalendarDetailModal(props) { return <div {...props} />; }
function mockEventSignUpModal(props) { return <div {...props} />; }

jest.mock('tui-date-picker', () => ({
  on: jest.fn(),
  localeTexts: {},
}));

jest.mock('tui-calendar', () => jest.fn(() => ({
  on: jest.fn(),
  createSchedules: jest.fn(),
  changeView: jest.fn(),
})));

jest.mock('../../../services/api', () => ({
  loadEvents: jest.fn(() => Promise.resolve({
    events: [],
    scheduled_events: [],
  })),
  eventSignUp: jest.fn(() => Promise.resolve()),
  destroyEvent: jest.fn(() => Promise.resolve()),
  updateEvent: jest.fn(() => Promise.resolve({
    attendees: [],
    member: {},
    leader: {},
    record_id: 1,
  })),
  createEvent: jest.fn(() => Promise.resolve({
    attendees: [],
    member: {},
    leader: {},
    id: 1,
  })),
}));

jest.mock('../../calendar/CalendarCreationModal', () => mockCalendarCreationModal);
jest.mock('../../calendar/CalendarUpdateModal', () => mockCalendarUpdateModal);
jest.mock('../../calendar/CalendarDetailModal', () => mockCalendarDetailModal);
jest.mock('../../calendar/EventSignUpModal', () => mockEventSignUpModal);

describe('Calendar Component', () => {
  let wrapper;
  const defaultProps = {
    calendars: [
      { id: 1, name: 'name', checked: true },
      { id: 2, name: 'name2', checked: true },
    ],
    user: {},
  };

  beforeEach(() => {
    mockDate.set(new Date('09/24/2020'));

    wrapper = mount(<Calendar {...defaultProps} />);
    wrapper.instance().initCalendar = jest.fn();
    wrapper.instance().initDatePicker = jest.fn();
    // wrapper.instance().updateCalendar = jest.fn();
    wrapper.update();
    wrapper.setState({
      calendar: {
        changeView: jest.fn(),
        toggleSchedules: jest.fn(),
        clear: jest.fn(),
        createSchedules: jest.fn(),
        render: jest.fn(),
        updateSchedule: jest.fn(),
      },
    });
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<Calendar {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.crayon_calendar_container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(10);
    });

    it('renders to Volunteer', () => {
      wrapper.setProps({ user: { role: 'Volunteer' } });
      expect(wrapper.find('.crayon_calendar_container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(7);
    });
  });

  describe('event handling', () => {
    it('handle change events type', (cb) => {
      wrapper.find('button').at(0).simulate('click');
      process.nextTick(() => { expect(wrapper.state().eventsType).toEqual('my_events'); cb(); });
    });

    it('handle change events type', (cb) => {
      wrapper.find('button').at(1).simulate('click');
      process.nextTick(() => { expect(wrapper.state().eventsType).toEqual('all_events'); cb(); });
    });

    it('handle change view type', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(wrapper.state().viewType).toEqual('schedule');
    });

    it('handle change view type', () => {
      wrapper.find('button').at(4).simulate('click');
      expect(wrapper.state().viewType).toEqual('week');
    });

    it('handle change view type', () => {
      wrapper.find('button').at(5).simulate('click');
      expect(wrapper.state().viewType).toEqual('month');
    });

    it('handle change view type', () => {
      wrapper.find('button').at(3).simulate('click');
      expect(wrapper.state().viewType).toEqual('day');
    });

    it('handle click create new', () => {
      wrapper.find('button').at(5).simulate('click');
      wrapper.find('button').at(9).simulate('click');
      expect(wrapper.state().showCreationModal).toEqual(true);
    });

    it('handle hide calendar', () => {
      wrapper.find('.tui-full-calendar-checkbox-round').at(0).simulate('change', { target: { checked: false } });
      expect(wrapper.state('calendars')[0].checked).toEqual(false);
    });

    it('delete event', (cb) => {
      wrapper.instance().destroyEvent({ raw: {} });
      process.nextTick(() => { expect(wrapper.state().showDeleteModal).toEqual(false); cb(); });
    });

    it('create event', (cb) => {
      wrapper.instance().createEvent({});
      process.nextTick(() => { expect(wrapper.state().showCreationModal).toEqual(false); cb(); });
    });

    it('update event', () => {
      wrapper.instance().onDragUpdateEvent({
        schedule: {
          raw: {},
        },
        changes: {
          start: {
            toDate: jest.fn(),
          },
          end: {
            toDate: jest.fn(),
          },
        },
      });
    });

    it('update event', (cb) => {
      wrapper.instance().updateEvent({});
      process.nextTick(() => { expect(wrapper.state().showUpdateModal).toEqual(false); cb(); });
    });

    it('sign up event', (cb) => {
      wrapper.instance().signUpEvent({});
      process.nextTick(() => { expect(wrapper.state().showSignUpModal).toEqual(false); cb(); });
    });

    it('handleCloseCreationModal', () => {
      wrapper.setState({ showCreationModal: true });
      wrapper.instance().handleCloseCreationModal();
      expect(wrapper.state().showCreationModal).toEqual(false);
    });

    it('handleCloseUpdateModal', () => {
      wrapper.setState({ showUpdateModal: true });
      wrapper.instance().handleCloseUpdateModal();
      expect(wrapper.state().showUpdateModal).toEqual(false);
    });

    it('handleCloseSignUpModal', () => {
      wrapper.setState({ showSignUpModal: true });
      wrapper.instance().handleCloseSignUpModal();
      expect(wrapper.state().showSignUpModal).toEqual(false);
    });

    it('handleCloseDetailModal', () => {
      wrapper.setState({ showDetailModal: true });
      wrapper.instance().handleCloseDetailModal();
      expect(wrapper.state().showDetailModal).toEqual(false);
    });

    it('handleCloseDeleteModal', () => {
      wrapper.setState({ showDeleteModal: true });
      wrapper.instance().handleCloseDeleteModal();
      expect(wrapper.state().showDeleteModal).toEqual(false);
    });

    it('handleOpenUpdateModal', () => {
      wrapper.setState({ showUpdateModal: false });
      wrapper.instance().handleOpenUpdateModal();
      expect(wrapper.state().showUpdateModal).toEqual(true);
      expect(wrapper.state().showDetailModal).toEqual(false);
    });

    it('handleOpenDeleteModal', () => {
      wrapper.setState({ showDeleteModal: false });
      wrapper.instance().handleOpenDeleteModal();
      expect(wrapper.state().showDeleteModal).toEqual(true);
      expect(wrapper.state().showDetailModal).toEqual(false);
    });

    it('handleDestroyEvent', (cb) => {
      wrapper.setState({ showDeleteModal: true });
      wrapper.instance().handleDestroyEvent({ raw: {} });
      process.nextTick(() => { expect(wrapper.state().showDeleteModal).toEqual(false); cb(); });
    });
  });
});
