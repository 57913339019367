import React from 'react';
import PropTypes from 'prop-types';


const Attendee = ({
  user, number, id, handleChange,
}) => (
  <section className="attendee">
    <div className="attendee-title">{`Attendee #${number}`}</div>
    <div className="inputs_container event-sign_up">
      <div className="input-container calendar">
        <section>
          <div className="input-label">First name</div>
          <input name="first_name" value={user.first_name || ''} onChange={e => handleChange(e, id)} />
        </section>
      </div>
      <div className="input-container calendar">
        <section>
          <div className="input-label">Last name</div>
          <input name="last_name" value={user.last_name || ''} onChange={e => handleChange(e, id)} />
        </section>
      </div>
      <div className="input-container calendar">
        <section>
          <div className="input-label">Email</div>
          <input name="email" value={user.email || ''} onChange={e => handleChange(e, id)} />
        </section>
      </div>
    </div>
  </section>
);

Attendee.propTypes = {
  user: PropTypes.instanceOf(Object),
  number: PropTypes.number,
  id: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

Attendee.defaultProps = {
  user: {},
  number: 1,
};

export default Attendee;
