import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from './CloseButton';

const DeleteModal = ({
  handleDelete, handleClose, title, subtitle,
}) => (
  <div className="modal_body detail">
    <div className="modal_button_container top">
      <CloseButton close={handleClose} />
    </div>
    <div className="title">{title}</div>
    <div className="sub-title">{subtitle}</div>
    <div className="button_container">
      <button type="button" className="incoming-shipments-modal-btn no" onClick={handleClose}>No</button>
      <button type="button" className="incoming-shipments-modal-btn yes" onClick={handleDelete}>Yes</button>
    </div>
  </div>
);

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeleteModal;
