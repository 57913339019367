// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DonorSignIn Component rendering renders correctly 1`] = `
<div
  className="auth-content"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="auth-header"
  >
    Sign In
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="user[email]"
    >
      Email Address
    </label>
    <div>
      <i
        className="material-icons"
      >
        mail
      </i>
      <div
        className="MuiFormControl-root-3 SignIn-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 SignIn-input1-2 MuiInput-inputType-18"
            disabled={false}
            id="email"
            name="user[email]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Enter email"
            required={false}
            type="email"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="user[password]"
    >
      Password
    </label>
    <div>
      <i
        className="material-icons"
      >
        lock
      </i>
      <div
        className="MuiFormControl-root-3 SignIn-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 SignIn-input1-2 MuiInput-inputType-18"
            disabled={false}
            id="password"
            name="user[password]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Password"
            required={false}
            type="password"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="input-container"
    >
      <div
        className="checkbox-cell"
      >
        <input
          id="user[remember_me]"
          name="user[remember_me]"
          type="checkbox"
        />
        <label
          className="input-label"
          htmlFor="user[remember_me]"
        >
          Remember Me
        </label>
      </div>
    </div>
    <a
      className="forgot-password"
      href="/users/password/new"
    >
      Forgot your password?
    </a>
  </div>
  <button
    className="btn-main"
    onClick={[Function]}
    type="button"
  >
    Sign In
  </button>
  <div
    className="auth-footer-text"
  >
    Don’t have an account? 
  </div>
  <a
    className="btn-alt"
    href="/users/sign_up"
  >
    Register Now
  </a>
</div>
`;
