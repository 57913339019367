import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import SelectHospitals from '../SelectHospitals';

jest.mock('../../../services/api', () => ({
  createHospital: jest.fn(() => Promise.resolve({})),
}));

describe('SelectHospitals Component', () => {
  let wrapper;
  const defaultProps = {
    objects: [
      {
        id: '1',
        hospital_key: '',
        name: '',
        number_of_contacts: 0,
        city: 'City',
        state: 'ST',
        last_shipment: '',
      },
      {
        id: '2',
        hospital_key: '',
        name: '',
        number_of_contacts: 0,
        city: 'City',
        state: 'ST',
        last_shipment: '',
      },
    ],
    filterParams: {},
    selectedCount: 0,
    handleHospitalSelect: jest.fn(),
    handlePrepare: jest.fn(),
    handleSort: jest.fn(),
    filter: jest.fn(),
    clickHospital: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<SelectHospitals {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<SelectHospitals {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(3);
    });
  });
  describe('events', () => {
    it('handleHospitalSelect', () => {
      wrapper.find('.checkbox-cell input').at(2).simulate('change', { target: { checked: true } });
      expect(defaultProps.handleHospitalSelect).toHaveBeenCalled();
    });

    it('handlePrepare', () => {
      wrapper.find('.prepare-button').simulate('click');
      expect(defaultProps.handlePrepare).toHaveBeenCalled();
    });

    it('handle filter', () => {
      wrapper.find('#verified').simulate('change', { target: { checked: true } });
      wrapper.find('#approved').simulate('change', { target: { checked: true } });
      expect(defaultProps.filter).toHaveBeenCalledTimes(2);
    });
  });
});
