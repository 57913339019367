// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ButtonContainer Component rendering renders correctly 1`] = `
<button
  className="MuiButtonBase-root-27 MuiButton-root-1 MuiButton-text-3 MuiButton-flat-6"
  disabled={false}
  href=""
  onBlur={[Function]}
  onFocus={[Function]}
  onKeyDown={[Function]}
  onKeyUp={[Function]}
  onMouseDown={[Function]}
  onMouseLeave={[Function]}
  onMouseUp={[Function]}
  onTouchEnd={[Function]}
  onTouchMove={[Function]}
  onTouchStart={[Function]}
  tabIndex="0"
  type="button"
>
  <span
    className="MuiButton-label-2"
  >
    <i
      className="nav-icon material-icons"
    >
      
    </i>
    <span
      className="nav-text"
    >
      click
    </span>
  </span>
  <span
    className="MuiTouchRipple-root-30"
  />
</button>
`;
