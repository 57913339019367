import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import NoteModify from '../HospitalNotesModify';

describe('NoteModify Component', () => {
  let wrapper;

  const defaultProps = {
    handleSave: jest.fn(),
    handleClose: jest.fn(),
    title: 'title',
    buttonTitle: 'Create',
  };

  beforeEach(() => {
    wrapper = mount(<NoteModify {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<NoteModify {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.input-container')).toHaveLength(1);
      expect(wrapper.find('.input-label')).toHaveLength(1);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.modal_button_container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(2);
    });

    it('renders titles', () => {
      expect(wrapper.text()).toMatch('title');
      expect(wrapper.text()).toMatch('Create');
    });
  });

  describe('events', () => {
    it('change text value', () => {
      wrapper.find('textarea#message').simulate('change', { target: { id: 'message', value: 'Some text' } });
      expect(wrapper.state().inputs.message).toEqual('Some text');
    });

    it('click "Save" button with empty fields', () => {
      wrapper.find('textarea#message').simulate('change', { target: { id: 'message', value: '' } });
      wrapper.find('button').at(1).simulate('click');
      expect(Object.keys(wrapper.state('errors')).length).toEqual(1);
    });

    it('click "Save" button', () => {
      wrapper.find('textarea#message').simulate('change', { target: { id: 'message', value: 'text some' } });
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleSave).toHaveBeenCalled();
    });
  });
});
