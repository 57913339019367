// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`InTransitShipmentsIndex Component rendering renders correctly 1`] = `
<div
  className="detail-container index_page"
>
  <div
    className="Toastify"
  />
  <div
    className="details-header"
  >
    <div
      className="detail-title"
    >
      In-transit Shipments (
      0
      )
    </div>
  </div>
  <div
    className="box with-actions"
    id=""
  >
    <div
      className="table-without-actions"
    />
    <div
      className="box box-default table-box table-responsive"
    >
      <table
        className="mdl-data-table table-selectable table-bordered"
      >
        <thead>
          <tr>
            <th
              className="mdl-data-table__cell--non-numeric"
            >
              #
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              ID
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort false"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Donor
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Date
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Amount, $
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Weight
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Tracking Number
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Shipment Status
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Mail Notification
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
  </div>
  <div
    className="details-header"
  >
    <div
      className="detail-title"
    >
      Archived (
      0
      )
    </div>
  </div>
  <div
    className="box with-actions"
    id=""
  >
    <div
      className="table-without-actions"
    />
    <div
      className="box box-default table-box table-responsive"
    >
      <table
        className="mdl-data-table table-selectable table-bordered"
      >
        <thead>
          <tr>
            <th
              className="mdl-data-table__cell--non-numeric"
            >
              #
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              ID
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort false"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Donor
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Date
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Amount, $
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Weight
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Tracking Number
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Shipment Status
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
            <th
              className="mdl-data-table__cell--non-numeric"
              onClick={[Function]}
            >
              Mail Notification
              <span
                aria-hidden="true"
                className="material-icons MuiIcon-root-1 sort hidden"
              >
                arrow_downward
              </span>
            </th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
    <div
      className="table--view-more"
    >
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        View more ▼
      </button>
    </div>
  </div>
</div>
`;
