import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ProductionView from '../ProductionView';

jest.mock('../../../services/api', () => ({
  loadProductions: jest.fn(() => Promise.resolve({
    productions: [
      {
        id: '1',
        name: 'test',
        date: '2019-12-12',
        number_of_cases: '45',
      },
    ],
  })),
}));

describe('ProductionView Component', () => {
  let wrapper;
  const defaultProps = {
    handleClose: jest.fn(),
  };


  beforeEach(() => {
    wrapper = mount(<ProductionView {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ProductionView {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.modal_button_container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(2);
    });
  });
  describe('events', () => {
    it('handleCloseModal', () => {
      wrapper.find('.modal_close_button').simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });

    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('productionsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });
  });
});
