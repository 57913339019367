import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ButtonContainer from '../Button';

describe('ButtonContainer Component', () => {
  let wrapper;
  const defaultProps = {
    label: 'click',
    path: '',
    icon: '',
  };

  beforeEach(() => {
    wrapper = mount(<ButtonContainer {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<ButtonContainer {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });


    it('renders', () => {
      expect(wrapper.find('.nav-icon')).toHaveLength(1);
      expect(wrapper.find('.nav-text')).toHaveLength(1);
      expect(wrapper.text()).toMatch('click');
    });
  });
});
