import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EventDetails from './EventDetails';
import API from '../../services/api';

class EventConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: '',
    };
  }

  handleUpdate = (id, status) => {
    API.updateEventAttendee(id, { status }).then(res => this.setState({ status: res.status }));
  };

  renderMessage = (status, event) => {
    if (status === 'confirmed') return <span>{`You confirmed that you are attending ${event.title} on ${moment(event.start).format('M/D/YYYY h:mm:ss A')}`}</span>;
    return <span>{`You declined the invititation to attend ${event.title} on ${moment(event.start).format('M/D/YYYY h:mm:ss A')}`}</span>;
  };

  render() {
    const { event } = this.props;
    const { status } = this.state;
    return (
      <div className="event-confirmation">
        <EventDetails event={event} />
        {
          status ? this.renderMessage(status, event)
            : (
              <section className="button-container">
                <button type="button" onClick={() => this.handleUpdate(event.id, 'confirmed')}>Confirm</button>
                <button type="button" onClick={() => this.handleUpdate(event.id, 'declined')}>Decline</button>
              </section>
            )
        }
      </div>
    );
  }
}

EventConfirmation.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
};

export default EventConfirmation;
