import React from 'react';
import PropTypes from 'prop-types';
import EventDetails from './EventDetails';

const EventConfirmationSignUp = ({ event }) => (
  <div className="event-confirmation">
    <EventDetails event={event} />
    <section className="button-container">
      <a href="/users/sign_in">Log in</a>
      <a href="/users/sign_up">Register</a>
    </section>
  </div>
);

EventConfirmationSignUp.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
};

export default EventConfirmationSignUp;
