// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DonorsShow Component rendering renders correctly 1`] = `
Array [
  <div
    className="Toastify"
  />,
  <div
    className="sticky-bar"
  >
    <div
      className="items-container"
    >
      <a
        className="sticky-bar--item"
        onClick={[Function]}
      >
        Information
      </a>
      <a
        className="sticky-bar--item"
        onClick={[Function]}
      >
        Shipments
      </a>
    </div>
  </div>,
  <div
    className="detail-container"
  >
    <div
      className="detail-title"
    >
      Crayon Donor: John Wick
    </div>
    <div
      className="tables-container"
    >
      <section
        id="information"
      >
        <button
          className="table-title label"
          onClick={[Function]}
          type="button"
        >
          Information 
          <span
            aria-hidden="true"
            className="material-icons MuiIcon-root-1 img"
          >
            edit
          </span>
        </button>
        <div
          className="information-container"
        >
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              ID
            </div>
            <input
              disabled={true}
              readOnly={true}
              value="key"
            />
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              Email
            </div>
            <input
              id="email"
              onChange={[Function]}
              readOnly={true}
              value="test@test.com"
            />
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              First Name
            </div>
            <input
              id="first_name"
              onChange={[Function]}
              readOnly={true}
              value="John"
            />
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              Last Name
            </div>
            <input
              id="last_name"
              onChange={[Function]}
              readOnly={true}
              value="Wick"
            />
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              Company
            </div>
            <input
              id="company"
              onChange={[Function]}
              readOnly={true}
              value=""
            />
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              Street1
            </div>
            <input
              id="street1"
              onChange={[Function]}
              readOnly={true}
              value=""
            />
          </div>
          <div
            className="input-container no_error"
          >
            <div
              className="input-label"
            >
              Street2
            </div>
            <input
              id="street2"
              onChange={[Function]}
              readOnly={true}
              value=""
            />
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              City
            </div>
            <input
              id="city"
              onChange={[Function]}
              readOnly={true}
              value=""
            />
          </div>
          <div
            className="input-container no_error"
          >
            <div
              className="input-label"
            >
              State
            </div>
            <select
              className="select_state"
              disabled={true}
              id="state"
              onChange={[Function]}
              value=""
            >
              <option
                disabled={true}
                hidden={true}
                value=""
              />
              <option
                value="AL"
              >
                AL - Alabama
              </option>
              <option
                value="AK"
              >
                AK - Alaska
              </option>
              <option
                value="AS"
              >
                AS - American Samoa
              </option>
              <option
                value="AZ"
              >
                AZ - Arizona
              </option>
              <option
                value="AR"
              >
                AR - Arkansas
              </option>
              <option
                value="CA"
              >
                CA - California
              </option>
              <option
                value="CO"
              >
                CO - Colorado
              </option>
              <option
                value="CT"
              >
                CT - Connecticut
              </option>
              <option
                value="DE"
              >
                DE - Delaware
              </option>
              <option
                value="DC"
              >
                DC - District Of Columbia
              </option>
              <option
                value="FM"
              >
                FM - Federated States Of Micronesia
              </option>
              <option
                value="FL"
              >
                FL - Florida
              </option>
              <option
                value="GA"
              >
                GA - Georgia
              </option>
              <option
                value="GU"
              >
                GU - Guam
              </option>
              <option
                value="HI"
              >
                HI - Hawaii
              </option>
              <option
                value="ID"
              >
                ID - Idaho
              </option>
              <option
                value="IL"
              >
                IL - Illinois
              </option>
              <option
                value="IN"
              >
                IN - Indiana
              </option>
              <option
                value="IA"
              >
                IA - Iowa
              </option>
              <option
                value="KS"
              >
                KS - Kansas
              </option>
              <option
                value="KY"
              >
                KY - Kentucky
              </option>
              <option
                value="LA"
              >
                LA - Louisiana
              </option>
              <option
                value="ME"
              >
                ME - Maine
              </option>
              <option
                value="MH"
              >
                MH - Marshall Islands
              </option>
              <option
                value="MD"
              >
                MD - Maryland
              </option>
              <option
                value="MA"
              >
                MA - Massachusetts
              </option>
              <option
                value="MI"
              >
                MI - Michigan
              </option>
              <option
                value="MN"
              >
                MN - Minnesota
              </option>
              <option
                value="MS"
              >
                MS - Mississippi
              </option>
              <option
                value="MO"
              >
                MO - Missouri
              </option>
              <option
                value="MT"
              >
                MT - Montana
              </option>
              <option
                value="NE"
              >
                NE - Nebraska
              </option>
              <option
                value="NV"
              >
                NV - Nevada
              </option>
              <option
                value="NH"
              >
                NH - New Hampshire
              </option>
              <option
                value="NJ"
              >
                NJ - New Jersey
              </option>
              <option
                value="NM"
              >
                NM - New Mexico
              </option>
              <option
                value="NY"
              >
                NY - New York
              </option>
              <option
                value="NC"
              >
                NC - North Carolina
              </option>
              <option
                value="ND"
              >
                ND - North Dakota
              </option>
              <option
                value="MP"
              >
                MP - Northern Mariana Islands
              </option>
              <option
                value="OH"
              >
                OH - Ohio
              </option>
              <option
                value="OK"
              >
                OK - Oklahoma
              </option>
              <option
                value="OR"
              >
                OR - Oregon
              </option>
              <option
                value="PW"
              >
                PW - Palau
              </option>
              <option
                value="PA"
              >
                PA - Pennsylvania
              </option>
              <option
                value="PR"
              >
                PR - Puerto Rico
              </option>
              <option
                value="RI"
              >
                RI - Rhode Island
              </option>
              <option
                value="SC"
              >
                SC - South Carolina
              </option>
              <option
                value="SD"
              >
                SD - South Dakota
              </option>
              <option
                value="TN"
              >
                TN - Tennessee
              </option>
              <option
                value="TX"
              >
                TX - Texas
              </option>
              <option
                value="UT"
              >
                UT - Utah
              </option>
              <option
                value="VT"
              >
                VT - Vermont
              </option>
              <option
                value="VI"
              >
                VI - Virgin Islands
              </option>
              <option
                value="VA"
              >
                VA - Virginia
              </option>
              <option
                value="WA"
              >
                WA - Washington
              </option>
              <option
                value="WV"
              >
                WV - West Virginia
              </option>
              <option
                value="WI"
              >
                WI - Wisconsin
              </option>
              <option
                value="WY"
              >
                WY - Wyoming
              </option>
            </select>
          </div>
          <div
            className="input-container"
          >
            <div
              className="input-label"
            >
              Zip Code
            </div>
            <input
              id="zip_code"
              onChange={[Function]}
              readOnly={true}
              value=""
            />
          </div>
        </div>
      </section>
      <section
        id="shipments"
      >
        <div
          className="table-title"
        >
          Shipments
        </div>
        <div
          className="box with-actions"
          id=""
        >
          <div
            className="table-actions"
          >
            <div
              className="add-new"
            >
              <div
                className="label"
              >
                Create New
              </div>
              <button
                onClick={[Function]}
                type="button"
              >
                +
              </button>
            </div>
          </div>
          <div
            className="box box-default table-box table-responsive"
          >
            <table
              className="mdl-data-table table-selectable table-bordered"
            >
              <thead>
                <tr>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                  >
                    #
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    ID
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort false"
                    >
                      arrow_upward
                    </span>
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    Created
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort hidden"
                    >
                      arrow_upward
                    </span>
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    Received
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort hidden"
                    >
                      arrow_upward
                    </span>
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    Amount, $
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort hidden"
                    >
                      arrow_upward
                    </span>
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    Weight
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort hidden"
                    >
                      arrow_upward
                    </span>
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    Actual Weight
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort hidden"
                    >
                      arrow_upward
                    </span>
                  </th>
                  <th
                    className="mdl-data-table__cell--non-numeric"
                    onClick={[Function]}
                  >
                    Tracking Number
                    <span
                      aria-hidden="true"
                      className="material-icons MuiIcon-root-1 sort hidden"
                    >
                      arrow_upward
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
          <div
            className="table--view-more"
          >
            <button
              className="button"
              disabled={true}
              onClick={[Function]}
              type="button"
            >
              View more ▼
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>,
]
`;
