import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import API from '../../../services/api';

const styles = {
  input: {
    'font-size': '1rem',
    height: '3rem',
    width: 'calc(100% - 2rem)',
    'border-radius': '10px',
    border: 'solid 1px #828282',
    padding: '0 1rem',
    color: '#828282',
    'font-family': 'Avenir Next Regular',
    outline: 'none',
  },
  input1: {
    height: '3rem',
  },
};

class DonorSignIn extends React.Component {
  constructor(props) {
    super(props);
    const defaults = {
      email: '',
      password: '',
    };

    this.state = { donor: defaults, formErrors: { }, signIn: false };
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateField(fieldName) {
    const { formErrors, donor } = this.state;
    const value = donor[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        formErrors.email = valid ? '' : 'Email is invalid';
        break;
      case 'password':
        valid = value.length >= 6;
        formErrors.password = valid ? '' : 'Password is too short';
        break;
      default:
        break;
    }
    this.setState({ formErrors });
  }

  hasErrors() {
    const { formErrors } = this.state;
    return Object.values(formErrors).some(item => item);
  }

  handleSubmit() {
    const { donor, formErrors, signIn } = this.state;
    if (!signIn) {
      this.setState({ signIn: true });
      return;
    }

    const form = document.forms[0];

    Object.keys(donor).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;

    API.donorValidation({ type: 'sign_in', email: donor.email, password: donor.password }).then((res) => {
      if (res) {
        formErrors.email = res.err_msg;
        formErrors.password = res.err_msg;
      }
      this.setState({ formErrors }, () => {
        if (!this.hasErrors()) form.submit();
      });
    });
  }

  handleChange(event) {
    const { donor } = this.state;
    const name = event.target.id;
    const { value } = event.target;
    donor[name] = value;
    this.setState({ donor }, () => { this.validateField(name); });
  }

  handleGuest() {
    window.location.assign('/shipping?guest=1');
  }

  render() {
    const { donor, formErrors, signIn } = this.state;
    const { classes } = this.props;
    return (
      <div className="auth-content">
        <div className="custom-logo-img" />
        <div className="auth-header">Sign In</div>
        {signIn && (
        <React.Fragment>
          <div className="custom-form-group">
            <label htmlFor="donor[email]">
              Email Address
            </label>
            <div>
              <i className="material-icons">mail</i>
              <TextField
                id="email"
                type="email"
                name="donor[email]"
                placeholder="Enter email"
                classes={{
                  root: classes.input,
                }}
                onChange={this.handleChange}
                value={donor.email}
                error={Boolean(formErrors.email)}
                helperText={formErrors.email}
                InputProps={{
                  disableUnderline: true,
                  classes: { input: classes.input1 },
                }}
              />
            </div>
          </div>
          <div className="custom-form-group">
            <label htmlFor="donor[password]">
              Password
            </label>
            <div>
              <i className="material-icons">lock</i>
              <TextField
                type="password"
                name="donor[password]"
                id="password"
                placeholder="Password"
                classes={{
                  root: classes.input,
                }}
                onChange={this.handleChange}
                value={donor.password}
                error={Boolean(formErrors.password)}
                helperText={formErrors.password}
                InputProps={{
                  disableUnderline: true,
                  classes: { input: classes.input1 },
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-container">
              <div className="checkbox-cell">
                <input
                  type="checkbox"
                  name="donor[remember_me]"
                  id="donor[remember_me]"
                />
                <label htmlFor="donor[remember_me]" className="input-label">Remember Me</label>
              </div>
            </div>
            <a href="/donors/password/new" className="forgot-password">Forgot your password?</a>
          </div>
        </React.Fragment>
        )}
        <button
          type="button"
          className="btn-main"
          onClick={this.handleSubmit}
        >
          Sign In
        </button>
        {!signIn && (
        <React.Fragment>
          <div className="auth-footer-text">Don’t have an account? </div>
          <a href="/donors/sign_up" className="btn-alt">Register Now</a>
          <div className="auth-footer-text">Or </div>
          <button type="button" onClick={this.handleGuest} className="btn-alt black">Continue as a guest</button>
        </React.Fragment>
        )}
      </div>
    );
  }
}

DonorSignIn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DonorSignIn);
