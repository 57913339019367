import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import ConfirmHospitalShipment from '../ConfirmHospitalShipment';
import SelectHospitals from '../SelectHospitals';
import SelectHospitalsByCount from '../SelectHospitalsByCount';
import HospitalShipmentsIndex from '../HospitalShipmentsIndex';

jest.mock('../../../services/api', () => ({
  hospitalShipment: jest.fn(() => Promise.resolve({
    labels: '',
  })),
  loadAllHospitals: jest.fn(() => Promise.resolve({
    hospitals: [
      {
        id: '1',
        hospital_key: '',
        name: '',
        number_of_contacts: 0,
        city: 'City',
        state: 'ST',
        last_shipment: '',
        address_verified: true,
        approved_for_shipping: false,
      },
      {
        id: '2',
        hospital_key: '',
        name: '',
        number_of_contacts: 0,
        city: 'City',
        state: 'ST',
        last_shipment: '',
        address_verified: false,
        approved_for_shipping: true,
      },
    ],
  })),
}));

jest.mock('../../../services/permissions', () => ({
  isSuperAdmin: jest.fn(u => (u === 'john.wick@yahoo.com')),
}));

describe('HospitalShipmentsIndex Component', () => {
  let wrapper;
  const defaultProps = {
    numberOfCases: 567,
    user: 'john1.wick@yahoo.com',
    selectedHospitals: [],
    confirm: false,
  };

  beforeEach(() => {
    wrapper = mount(<HospitalShipmentsIndex {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<HospitalShipmentsIndex {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders with confirm = false', () => {
      expect(wrapper.find(SelectHospitalsByCount)).toHaveLength(1);
    });

    it('renders with confirm = false and is SuperAdmin', () => {
      wrapper.setProps({ user: 'john.wick@yahoo.com' });
      expect(wrapper.find(SelectHospitalsByCount)).toHaveLength(1);
      wrapper.find('button').at(0).simulate('click');
      expect(wrapper.find(SelectHospitals)).toHaveLength(1);
    });

    it('renders with confirm = true', () => {
      wrapper.setState({ confirm: true });
      expect(wrapper.find(ConfirmHospitalShipment)).toHaveLength(1);
    });
  });

  describe('event handling', () => {
    it('handle submit', (cb) => {
      wrapper.instance().handleSubmit();
      process.nextTick(() => { expect(wrapper.state('status')).toEqual('success'); cb(); });
    });

    it('handle select', () => {
      wrapper.instance().handleHospitalSelect({ id: 1 });
      wrapper.instance().handleHospitalSelect({ id: 2 });
      wrapper.instance().handleHospitalSelect({ id: 3 });
      wrapper.instance().handleHospitalSelect({ id: 1 });
      expect(wrapper.state('shipment').selectedHospitals).toEqual([
        { id: 2 },
        { id: 3 },
      ]);
    });

    it('handle filter', () => {
      wrapper.setProps({ user: 'john.wick@yahoo.com' });
      wrapper.find('button').at(0).simulate('click');
      wrapper.find('.checkbox-cell input').at(0).simulate('change', { target: { id: 'verified', checked: false } });
      expect(wrapper.state('filter')).toEqual({
        verified: false,
        approved: true,
      });
    });

    it('handle filter', () => {
      wrapper.setProps({ user: 'john.wick@yahoo.com' });
      wrapper.find('button').at(0).simulate('click');
      wrapper.find('.checkbox-cell input').at(0).simulate('change', { target: { id: 'verified', checked: false } });
      wrapper.find('.checkbox-cell input').at(0).simulate('change', { target: { id: 'approved', checked: false } });
      expect(wrapper.state('hospitals').length).toEqual(2);
      expect(wrapper.state('filter')).toEqual({
        verified: false,
        approved: false,
      });
    });

    it('handle sort', () => {
      expect(wrapper.state('hospitalSortParams')).toEqual(
        {
          column: 'last_shipment',
          type: 'asc',
        },
      );
      wrapper.instance().handleSort('name');

      expect(wrapper.state('hospitalSortParams')).toEqual(
        {
          column: 'name',
          type: 'asc',
        },
      );
    });

    it('handle sort address', () => {
      expect(wrapper.state('hospitalSortParams')).toEqual(
        {
          column: 'last_shipment',
          type: 'asc',
        },
      );
      wrapper.instance().handleSort('address');

      expect(wrapper.state('hospitalSortParams')).toEqual(
        {
          column: 'address',
          type: 'asc',
        },
      );
    });

    it('handle prepare', () => {
      wrapper.find('.prepare-button').simulate('click');
      expect(wrapper.state('confirm')).toEqual(true);
    });
  });
});
