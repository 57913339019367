import React from 'react';
import DatePicker from 'tui-date-picker';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import API from '../../services/api';
import CloseButton from '../ui/CloseButton';

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <i className="material-icons">search</i>
    </components.DropdownIndicator>
  )
);

class CalendarCreationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: {
        name: '',
        location: '',
        body: '',
        startDate: props.event.start && props.event.start.toDate(),
        endDate: props.event.start && props.event.end.toDate(),
        calendarId: props.calendars[0] ? props.calendars[0].id : [],
        isAllDay: false,
        eventType: 'public',
        eventVisibility: 'company_wide',
        attendees: [],
        max_attendees: '5',
        leader: '',
      },
      users: [],
      calendars: props.calendars,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
  }

  componentDidMount() {
    this.initDatePicker();
    this.handleSearch('', 'leader');
  }

  handleSelect = (e) => {
    const { schedule } = this.state;
    const sh = schedule.attendees.find(h => h === e.value);
    if (!sh) {
      schedule.attendees = [...schedule.attendees, e.value];
    }

    this.setState({ schedule });
  };

  handleSelectLead = (e) => {
    const { schedule } = this.state;
    schedule.leader = e.value;
    this.setState({ schedule });
  };

  handleSearch = (e, type) => {
    const query = type === 'leader' ? "role != 'Donor' AND role != 'Volunteer'" : "role != 'Donor'";
    API.searchUser(e, query).then(res => this.setState({ users: res.users }));
  };

  initDatePicker = () => {
    const { schedule } = this.state;

    const startDatePicker = new DatePicker('#start-date-picker', {
      date: schedule.startDate,
      timepicker: true,

      input: {
        element: '#start-date-picker-input',
        format: 'yyyy-MM-dd hh:mm',
      },
    });

    const endDatePicker = new DatePicker('#end-date-picker', {
      date: schedule.endDate,
      timepicker: true,

      input: {
        element: '#end-date-picker-input',
        format: 'yyyy-MM-dd hh:mm',
      },
    });

    startDatePicker.on('change', () => {
      this.setDate('startDate', startDatePicker.getDate());
    });

    endDatePicker.on('change', () => {
      this.setDate('endDate', endDatePicker.getDate());
    });
  };

  setDate = (type, date) => {
    const { schedule } = this.state;
    schedule[type] = date;
    this.setState({ schedule });
  };

  handleChange(e) {
    const { schedule } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    schedule[name] = value;
    this.setState({ schedule });
  }

  handleChangeType(e, type) {
    const { schedule } = this.state;
    schedule[type] = e.target.id;
    this.setState({ schedule });
  }

  render() {
    const { schedule, users, calendars } = this.state;
    const { handleCreate, handleClose } = this.props;

    return (
      <div className="modal_body calendar">
        <div className="modal_button_container top">
          <CloseButton close={handleClose} />
        </div>
        <div className="modal_title calendar">Schedule new event</div>
        <section className="content">
          <div className="input-container calendar">
            <section>
              <div className="input-label">Event type</div>
              <select id="calendarId" className="calendar_select" value={schedule.calendarId} onChange={this.handleChange}>
                {calendars && calendars.map(item => (
                  <option key={item.id.toString()} value={item.id}>{item.name}</option>
                ))}
              </select>
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Name</div>
              <input id="name" value={schedule.name} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Location</div>
              <input id="location" value={schedule.location} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Details</div>
              <input id="body" value={schedule.body} onChange={this.handleChange} />
            </section>
          </div>
          <section className="input-container calendar top">
            <section>
              <div className="input-label">Date</div>
              <div>
                <div className="tui-datepicker-input tui-datetime-input tui-has-focus">
                  <input type="text" id="start-date-picker-input" aria-label="Date-Time" />
                  <span className="tui-ico-date" />
                  <div id="start-date-picker" />
                </div>
                <span className="text">to</span>
                <div className="tui-datepicker-input tui-datetime-input tui-has-focus">
                  <input type="text" id="end-date-picker-input" aria-label="Date-Time" />
                  <span className="tui-ico-date" />
                  <div id="end-date-picker" />
                </div>
              </div>
            </section>
          </section>
          <div className="input-container calendar">
            <section>
              <div className="input-label">
                Lead name
              </div>
              <Select
                components={{ DropdownIndicator }}
                onChange={this.handleSelectLead}
                onInputChange={e => this.handleSearch(e, 'leader')}
                options={
                  users.map(item => (
                    { value: item.id, label: `${item.first_name || ''} ${item.last_name || ''}` }
                  ))
                }
                placeholder="Full name"
                className="user-search"
                classNamePrefix="user-search"
              />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">Attendees count</div>
              <input id="max_attendees" value={schedule.max_attendees} onChange={this.handleChange} />
            </section>
          </div>
          <div className="input-container calendar">
            <section>
              <div className="input-label">
                People to invite(
                {schedule.attendees.length}
                /
                {schedule.max_attendees}
                )
              </div>
              <Select
                components={{ DropdownIndicator }}
                onChange={this.handleSelect}
                onInputChange={e => this.handleSearch(e, 'attendee')}
                options={
                  users.map(item => (
                    { value: item.id, label: `${item.first_name || ''} ${item.last_name || ''}` }
                  ))
                }
                isDisabled={schedule.attendees.length >= schedule.max_attendees}
                placeholder="Full name"
                className="user-search calendar_modal"
                classNamePrefix="user-search"
              />
              {/* eslint-disable-next-line array-callback-return,consistent-return */}
              {schedule.attendees.map((item, index) => {
                const usr = users.find(u => u.id === item);
                if (usr) return <span key={index.toString()} className="usr-email">{usr.email}</span>;
              })}
            </section>
          </div>
          <div className="input-container calendar no_text_input">
            <section>
              <div className="input-label">Event type</div>
              <section>
                <div>
                  <input
                    type="radio"
                    id="public"
                    name="event_type"
                    checked={schedule.eventType === 'public'}
                    onChange={e => this.handleChangeType(e, 'eventType')}
                  />
                  <label htmlFor="public">Public event</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="internal"
                    name="event_type"
                    checked={schedule.eventType === 'internal'}
                    onChange={e => this.handleChangeType(e, 'eventType')}
                  />
                  <label htmlFor="internal">Internal event</label>
                </div>
              </section>
            </section>
            <section>
              <div className="input-label">Event visibility</div>
              <section>
                <div>
                  <input
                    type="radio"
                    id="lead_only"
                    name="event_visibility"
                    checked={schedule.eventVisibility === 'lead_only'}
                    onChange={e => this.handleChangeType(e, 'eventVisibility')}
                  />
                  <label htmlFor="lead_only">Lead</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="company_wide"
                    name="event_visibility"
                    checked={schedule.eventVisibility === 'company_wide'}
                    onChange={e => this.handleChangeType(e, 'eventVisibility')}
                  />
                  <label htmlFor="company_wide">Company Wide</label>
                </div>
              </section>
            </section>
          </div>
        </section>
        <div className="modal_button_container">
          <button type="button" className="modal_button" onClick={() => handleCreate(schedule)}>Save</button>
        </div>
      </div>
    );
  }
}

CalendarCreationModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  calendars: PropTypes.instanceOf(Array).isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CalendarCreationModal;
