import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import UsersIndex from '../UsersIndex';

jest.mock('../../../services/api', () => ({
  createUser: jest.fn(() => Promise.resolve()),
  loadUsers: jest.fn(() => Promise.resolve({
    users: [],
  })),
  updateUser: jest.fn(() => Promise.resolve()),
  deleteUser: jest.fn(() => Promise.resolve()),
}));

describe('UsersIndex Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(<UsersIndex />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<UsersIndex />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(1);
    });
  });

  describe('events', () => {
    it('handleCreateNew', () => {
      wrapper.instance().handleCreateNew();
      expect(wrapper.state().showAddUserModal).toEqual(true);
    });

    it('handleOpenEditModal', () => {
      wrapper.instance().handleOpenEditModal();
      expect(wrapper.state().showEditUserModal).toEqual(true);
    });

    it('handleCloseEditModal', () => {
      wrapper.instance().handleCloseEditModal();
      expect(wrapper.state().showEditUserModal).toEqual(false);
    });

    it('handleOpenDeleteModal', () => {
      wrapper.instance().handleOpenDeleteModal();
      expect(wrapper.state().showDeleteModal).toEqual(true);
    });

    it('handleCloseDeleteModal', () => {
      wrapper.instance().handleCloseDeleteModal();
      expect(wrapper.state().showDeleteModal).toEqual(false);
    });

    it('handleCloseModal', () => {
      wrapper.instance().handleCloseModal();
      expect(wrapper.state().showAddUserModal).toEqual(false);
    });

    it('handleSaveNewUser', () => {
      wrapper.instance().handleSaveNewUser();
      process.nextTick(() => { expect(wrapper.state().showAddUserModal).toEqual(false); });
    });

    it('handleUpdateUser', () => {
      wrapper.instance().handleUpdateUser();
      process.nextTick(() => { expect(wrapper.state().showEditUserModal).toEqual(false); });
    });

    it('handleDeleteUser', () => {
      wrapper.instance().handleDeleteUser();
      process.nextTick(() => {
        expect(wrapper.state().showDeleteModal).toEqual(false);
      });
    });

    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('usersSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });

    it('handle searchDonors', () => {
      wrapper.find('input#search').simulate('change', { target: { value: 'a' } });
      expect(wrapper.state('searchQuery')).toEqual('a');
    });
  });
});
