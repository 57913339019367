import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import InventoryTable from '../InventoryTable';

jest.mock('../../../services/api', () => ({
  production: jest.fn(() => Promise.resolve({})),
  loadProductions: jest.fn(() => Promise.resolve({
    productions: [],
  })),
}));

describe('InventoryTable Component', () => {
  let wrapper;
  const defaultProps = {
    object: {
      Red: 5,
      Orange: 18,
      Yellow: 29,
      Green: 43,
      Blue: 50,
      Purple: 45,
      Pink: 87,
      Brown: 67,
    },
    updateInfo: {
      updater: 'John Wick',
      update_date: '2019-11-15 14:57:50.650488',
    },
    handleChange: jest.fn(),
    onSave: jest.fn(),
    onUpdate: jest.fn(),
    onCancel: jest.fn(),
    update: false,
    error: '',
    production: [],
    numberOfReadyCases: 22,
    minValues: [5, 18, 29, 43],
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<InventoryTable {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<InventoryTable {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('thead')).toHaveLength(1);
      expect(wrapper.find('tbody')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(1);
    });

    it('renders head', () => {
      expect(wrapper.find('thead')).toHaveLength(1);
      expect(wrapper.text()).toMatch('Color');
      expect(wrapper.text()).toMatch('Number of Sleeves');
    });

    it('renders body', () => {
      expect(wrapper.find('tbody')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(9);
    });

    it('renders update info', () => {
      expect(wrapper.find('.update-info')).toHaveLength(1);
      expect(wrapper.text()).toMatch('Updated by: John Wick');
      expect(wrapper.text()).toMatch('Updated on: 11/15/2019 2:57:50 PM');
    });

    it('renders with update = true', () => {
      wrapper.setProps({ update: true });
      expect(wrapper.find('.update-info')).toHaveLength(0);
      expect(wrapper.find('.update-buttons--container')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(3);
    });
  });

  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<InventoryTable {...defaultProps} />);
      wrapper.setProps({ update: true });
    });

    it('click "Update supplies" button', () => {
      wrapper.setProps({ update: false });
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.onUpdate).toHaveBeenCalled();
    });

    it('click "Cancel" button', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.onCancel).toHaveBeenCalled();
    });

    it('click "Save" button', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.onSave).toHaveBeenCalled();
    });
  });
});
