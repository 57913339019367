// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SendersInformation Component rendering renders correctly 1`] = `
<div
  className="information-container new-incoming"
>
  <div
    className="incoming-shipment-container vertical width30"
  >
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          First Name
        </div>
        <input
          className="shipment-input"
          id="first_name"
          onChange={[MockFunction]}
          value="John"
        />
      </section>
      <div
        className="errors"
      />
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          Last Name
        </div>
        <input
          className="shipment-input"
          id="last_name"
          onChange={[MockFunction]}
          value="Wick"
        />
      </section>
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          Email
        </div>
        <input
          className="shipment-input"
          id="email"
          onChange={[MockFunction]}
          value="test@test.com"
        />
      </section>
      <div
        className="errors"
      />
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          Company
        </div>
        <input
          className="shipment-input"
          id="company"
          onChange={[MockFunction]}
          value=""
        />
      </section>
      <div
        className="errors"
      />
    </div>
  </div>
  <div
    className="incoming-shipment-container vertical width30"
  >
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          Street1
        </div>
        <input
          className="shipment-input"
          id="street1"
          onChange={[MockFunction]}
          value=""
        />
      </section>
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          Street2
        </div>
        <input
          className="shipment-input"
          id="street2"
          onChange={[MockFunction]}
          value=""
        />
      </section>
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          City
        </div>
        <input
          className="shipment-input"
          id="city"
          onChange={[MockFunction]}
          value=""
        />
      </section>
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          State
        </div>
        <select
          className="select_state"
          disabled={false}
          id="state"
          onChange={[Function]}
          value=""
        >
          <option
            disabled={true}
            hidden={true}
            value=""
          />
          <option
            value="AL"
          >
            AL - Alabama
          </option>
          <option
            value="AK"
          >
            AK - Alaska
          </option>
          <option
            value="AS"
          >
            AS - American Samoa
          </option>
          <option
            value="AZ"
          >
            AZ - Arizona
          </option>
          <option
            value="AR"
          >
            AR - Arkansas
          </option>
          <option
            value="CA"
          >
            CA - California
          </option>
          <option
            value="CO"
          >
            CO - Colorado
          </option>
          <option
            value="CT"
          >
            CT - Connecticut
          </option>
          <option
            value="DE"
          >
            DE - Delaware
          </option>
          <option
            value="DC"
          >
            DC - District Of Columbia
          </option>
          <option
            value="FM"
          >
            FM - Federated States Of Micronesia
          </option>
          <option
            value="FL"
          >
            FL - Florida
          </option>
          <option
            value="GA"
          >
            GA - Georgia
          </option>
          <option
            value="GU"
          >
            GU - Guam
          </option>
          <option
            value="HI"
          >
            HI - Hawaii
          </option>
          <option
            value="ID"
          >
            ID - Idaho
          </option>
          <option
            value="IL"
          >
            IL - Illinois
          </option>
          <option
            value="IN"
          >
            IN - Indiana
          </option>
          <option
            value="IA"
          >
            IA - Iowa
          </option>
          <option
            value="KS"
          >
            KS - Kansas
          </option>
          <option
            value="KY"
          >
            KY - Kentucky
          </option>
          <option
            value="LA"
          >
            LA - Louisiana
          </option>
          <option
            value="ME"
          >
            ME - Maine
          </option>
          <option
            value="MH"
          >
            MH - Marshall Islands
          </option>
          <option
            value="MD"
          >
            MD - Maryland
          </option>
          <option
            value="MA"
          >
            MA - Massachusetts
          </option>
          <option
            value="MI"
          >
            MI - Michigan
          </option>
          <option
            value="MN"
          >
            MN - Minnesota
          </option>
          <option
            value="MS"
          >
            MS - Mississippi
          </option>
          <option
            value="MO"
          >
            MO - Missouri
          </option>
          <option
            value="MT"
          >
            MT - Montana
          </option>
          <option
            value="NE"
          >
            NE - Nebraska
          </option>
          <option
            value="NV"
          >
            NV - Nevada
          </option>
          <option
            value="NH"
          >
            NH - New Hampshire
          </option>
          <option
            value="NJ"
          >
            NJ - New Jersey
          </option>
          <option
            value="NM"
          >
            NM - New Mexico
          </option>
          <option
            value="NY"
          >
            NY - New York
          </option>
          <option
            value="NC"
          >
            NC - North Carolina
          </option>
          <option
            value="ND"
          >
            ND - North Dakota
          </option>
          <option
            value="MP"
          >
            MP - Northern Mariana Islands
          </option>
          <option
            value="OH"
          >
            OH - Ohio
          </option>
          <option
            value="OK"
          >
            OK - Oklahoma
          </option>
          <option
            value="OR"
          >
            OR - Oregon
          </option>
          <option
            value="PW"
          >
            PW - Palau
          </option>
          <option
            value="PA"
          >
            PA - Pennsylvania
          </option>
          <option
            value="PR"
          >
            PR - Puerto Rico
          </option>
          <option
            value="RI"
          >
            RI - Rhode Island
          </option>
          <option
            value="SC"
          >
            SC - South Carolina
          </option>
          <option
            value="SD"
          >
            SD - South Dakota
          </option>
          <option
            value="TN"
          >
            TN - Tennessee
          </option>
          <option
            value="TX"
          >
            TX - Texas
          </option>
          <option
            value="UT"
          >
            UT - Utah
          </option>
          <option
            value="VT"
          >
            VT - Vermont
          </option>
          <option
            value="VI"
          >
            VI - Virgin Islands
          </option>
          <option
            value="VA"
          >
            VA - Virginia
          </option>
          <option
            value="WA"
          >
            WA - Washington
          </option>
          <option
            value="WV"
          >
            WV - West Virginia
          </option>
          <option
            value="WI"
          >
            WI - Wisconsin
          </option>
          <option
            value="WY"
          >
            WY - Wyoming
          </option>
        </select>
      </section>
    </div>
    <div
      className="incoming_shipment_input-container"
    >
      <section>
        <div
          className="input-label"
        >
          Zip Code
        </div>
        <input
          className="shipment-input w35"
          id="zip_code"
          onChange={[MockFunction]}
          value=""
        />
      </section>
    </div>
  </div>
</div>
`;
