import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import ReactModal from 'react-modal';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import HospitalInformation from './HospitalInformation';
import StickyBar from '../ui/StickyBar';
import Table from '../ui/Table';
import API from '../../services/api';
import HospitalContactsAddNew from './HospitalContactsAddNew';
import sortHelper from '../../services/sortHelper';
import { successToast, errorToast } from '../../services/toast';
import Toast from '../ui/Toast';
import DeleteModal from '../ui/DeleteModal';
import phoneFormatter from '../../services/phoneFormatter';
import history from '../../helpers/history';
import NoteModify from './HospitalNotesModify';

class HospitalsShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      update: false,
      showModal: false,
      showCreateNoteModal: false,
      showEditContactModal: false,
      showEditNoteModal: false,
      showDeleteModal: false,
      showModalDeleteHospital: false,
      showDeleteNoteModal: false,
      hospital: props.hospital,
      contacts: props.contacts,
      hospitalInQueue: props.hospitalInQueue,
      notes: [],
      orders: [],
      selectedObject: {},
      selectedNoteObject: {},
      totalPages: 1,
      currentPage: 1,
      totalNotePages: 1,
      currentNotePage: 1,
      hospitalOrdersSortParams: {
        column: 'id',
        type: 'asc',
      },
      hospitalNotesSortParams: {
        column: 'created_at',
        type: 'desc',
      },
      hospitalContactsSortParams: {
        column: 'first_name',
        type: 'asc',
      },
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateNew = this.handleCreateNew.bind(this);
    this.handleCreateNote = this.handleCreateNote.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseNoteModal = this.handleCloseNoteModal.bind(this);
    this.handleEditNote = this.handleEditNote.bind(this);
    this.handleSaveNewHospitalContact = this.handleSaveNewHospitalContact.bind(this);
    this.handleSaveNewNote = this.handleSaveNewNote.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSortOrders = this.handleSortOrders.bind(this);
    this.handleSortContacts = this.handleSortContacts.bind(this);
    this.handleSortNotes = this.handleSortNotes.bind(this);
    this.loadContacts = this.loadContacts.bind(this);
    this.handleOpenEditContactModal = this.handleOpenEditContactModal.bind(this);
    this.handleOpenEditNoteModal = this.handleOpenEditNoteModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleOpenDeleteNoteModal = this.handleOpenDeleteNoteModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseEditNoteModal = this.handleCloseEditNoteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleCloseDeleteNoteModal = this.handleCloseDeleteNoteModal.bind(this);
    this.handleUpdateHospitalContact = this.handleUpdateHospitalContact.bind(this);
    this.handleDeleteHospitalContact = this.handleDeleteHospitalContact.bind(this);
    this.handleDeleteHospitalNote = this.handleDeleteHospitalNote.bind(this);
    this.loadNotes = this.loadNotes.bind(this);
    this.handleNotePageChange = this.handleNotePageChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleMonthChoose = this.handleMonthChoose.bind(this);
    this.handleCloseModalDeleteHospital = this.handleCloseModalDeleteHospital.bind(this);
    this.handleDeleteHospital = this.handleDeleteHospital.bind(this);
    this.handleOpenModalDeleteHospital = this.handleOpenModalDeleteHospital.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
    this.loadNotes(1);
  }

  handleCreateNewShipment = (id) => {
    window.open(`/hospital_orders?id=${id}`, '_self');
  };

  handleCheck = (e) => {
    const { hospital } = this.state;
    const name = e.target.id;
    hospital[name] = !hospital[name];
    this.setState({ hospital });
  };

  handleEdit() {
    this.setState({ update: true });
  }

  handleDeleteHospital() {
    const { hospital } = this.state;

    API.deleteHospital(hospital.id).then(() => {
      sessionStorage.setItem('notification', 'Success! Hospital deleted.');
      history.push('/hospitals');
      history.go(0);
      this.setState({ showModalDeleteHospital: false });
    }).catch(() => {
      errorToast();
      this.setState({ showModalDeleteHospital: false });
    });
  }

  handleCancel() {
    this.setState({ update: false });
  }

  handleSave() {
    const { hospital } = this.state;
    API.updateHospital(hospital).then((res) => {
      this.setState({ hospital: res.hospital, update: false });
      successToast('Success! Hospital info updated.');
    }).catch(() => {
      this.setState({ update: false });
      errorToast();
    });
  }

  handleCreateNew() {
    this.setState({ showModal: true });
  }

  handleCreateNote() {
    this.setState({ showCreateNoteModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseNoteModal() {
    this.setState({ showCreateNoteModal: false });
  }

  handleCloseEditModal() {
    this.setState({ showEditContactModal: false });
  }

  handleCloseEditNoteModal() {
    this.setState({ showEditNoteModal: false });
  }

  handleCloseDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  handleCloseDeleteNoteModal() {
    this.setState({ showDeleteNoteModal: false });
  }

  handleSaveNewHospitalContact(contact) {
    const { hospital } = this.state;
    API.createHospitalContact({ contact, id: hospital.id }).then((res) => {
      this.setState({ contacts: res.contacts, showModal: false });
      successToast('Success! New contact added.');
    }).catch(() => {
      this.setState({ showModal: false });
      errorToast();
    });
  }

  handleSaveNewNote(inputs) {
    const { hospital, hospitalNotesSortParams } = this.state;
    const data = {
      message: inputs.message,
      sort: hospitalNotesSortParams,
    };

    API.createHospitalNotes(hospital.id, data).then((res) => {
      this.setState({
        notes: res.notes,
        totalNotePages: res.totalNotePages,
        showCreateNoteModal: false,
      });
    });
  }

  handleUpdateHospitalContact(contact) {
    const { selectedObject } = this.state;

    API.updateHospitalContact(selectedObject.id, contact).then((res) => {
      this.setState({ contacts: res.contacts, showEditContactModal: false });
      successToast('Success! Contact updated.');
    }).catch(() => {
      this.setState({ showEditContactModal: false });
      errorToast();
    });
  }

  handleEditNote(inputs) {
    const { selectedNoteObject, hospitalNotesSortParams } = this.state;
    const data = {
      message: inputs.message,
      sort: hospitalNotesSortParams,
    };

    API.updateHospitalNotes(selectedNoteObject.hospital_id, selectedNoteObject.id, data).then((res) => {
      this.setState({ notes: res.notes, showEditNoteModal: false });
      successToast('Success! Note updated.');
    }).catch(() => {
      this.setState({ showEditNoteModal: false });
      errorToast();
    });
  }

  handleDeleteHospitalContact() {
    const { selectedObject } = this.state;

    API.deleteHospitalContact(selectedObject.id).then((res) => {
      this.setState({ contacts: res.contacts, showDeleteModal: false });
      successToast('Success! Contact deleted.');
    }).catch(() => {
      this.setState({ showDeleteModal: false });
      errorToast();
    });
  }

  handleDeleteHospitalNote() {
    const { selectedNoteObject } = this.state;

    API.deleteHospitalNotes(selectedNoteObject.hospital_id, selectedNoteObject.id).then(() => {
      this.setState({ showDeleteNoteModal: false }, () => this.loadNotes(1, true));
      successToast('Success! Note deleted.');
    }).catch(() => {
      this.setState({ showDeleteNoteModal: false }, () => this.loadNotes(1, true));
      errorToast();
    });
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleNotePageChange() {
    const { currentNotePage } = this.state;
    const page = currentNotePage + 1;
    this.loadNotes(page);
  }

  handleSortOrders(column) {
    const { hospitalOrdersSortParams } = this.state;
    this.setState({ hospitalOrdersSortParams: sortHelper(column, hospitalOrdersSortParams) }, () => this.loadPage(1, true));
  }

  handleSortContacts(column) {
    const { hospitalContactsSortParams } = this.state;
    this.setState({ hospitalContactsSortParams: sortHelper(column, hospitalContactsSortParams) }, () => this.loadContacts());
  }

  handleSortNotes(column) {
    const { hospitalNotesSortParams } = this.state;
    this.setState({ hospitalNotesSortParams: sortHelper(column, hospitalNotesSortParams) }, () => this.loadNotes(1, true));
  }

  loadContacts() {
    const { hospitalContactsSortParams, hospital } = this.state;

    API.loadHospitalContacts(hospital.id, hospitalContactsSortParams).then((res) => {
      this.setState({ contacts: res.contacts });
    });
  }

  loadNotes(page, refresh = false) {
    const {
      hospitalNotesSortParams,
      hospital,
      notes,
    } = this.state;

    API.loadHospitalNotes(hospital.id, page, hospitalNotesSortParams).then((res) => {
      this.setState({
        currentNotePage: page,
        totalNotePages: res.totalNotePages,
        notes: refresh ? res.notes : [...notes, ...res.notes],
      });
    });
  }

  loadPage(page, refresh = false) {
    const {
      orders,
      hospitalOrdersSortParams,
      hospital,
    } = this.state;

    API.loadHospitalOrders(hospital.id, page, hospitalOrdersSortParams).then((res) => {
      this.setState({
        currentPage: page,
        totalPages: res.totalPages,
        orders: refresh ? res.orders : [...orders, ...res.orders],
      });
    });
  }

  handleChange(e) {
    const { hospital } = this.state;
    const name = e.target.id;
    const { value } = e.target;

    if (name === 'phone') {
      hospital[name] = phoneFormatter(value);
      this.setState({ hospital });
      return;
    }

    hospital[name] = value;
    this.setState({ hospital });
  }

  handleRadioChange(e) {
    const { hospital } = this.state;
    const { name, value } = e.target;

    if (name === 'shipment_type') {
      hospital.shipment_type = { shipment_schedule_type: value };
    }

    this.setState({ hospital });
  }

  handleOpenEditContactModal(obj) {
    this.setState({ showEditContactModal: true, selectedObject: obj });
  }

  handleOpenEditNoteModal(obj) {
    this.setState({ showEditNoteModal: true, selectedNoteObject: obj });
  }

  handleOpenDeleteModal(obj) {
    this.setState({ showDeleteModal: true, selectedObject: obj });
  }

  handleOpenDeleteNoteModal(obj) {
    this.setState({ showDeleteNoteModal: true, selectedNoteObject: obj });
  }

  handleMonthChoose(e) {
    const { hospital } = this.state;
    const { id } = e.target;
    let monthArray = hospital.shipment_type.shipment_frequency;

    if (monthArray === undefined) {
      monthArray = [id];
    } else if (monthArray.includes(id)) {
      monthArray.splice(monthArray.indexOf(id), 1);
    } else {
      monthArray.push(id);
    }

    hospital.shipment_type.shipment_frequency = monthArray;
    this.setState({ hospital });
  }

  handleCloseModalDeleteHospital() {
    this.setState({ showModalDeleteHospital: false });
  }

  handleOpenModalDeleteHospital() {
    this.setState({ showModalDeleteHospital: true });
  }

  render() {
    const {
      hospital, update, showModal, contacts, notes, orders, totalPages, currentPage, showEditContactModal, showEditNoteModal,
      showCreateNoteModal, showDeleteModal, showDeleteNoteModal, selectedObject, hospitalContactsSortParams, hospitalNotesSortParams,
      hospitalOrdersSortParams, totalNotePages, currentNotePage, hospitalInQueue, showModalDeleteHospital, selectedNoteObject,
    } = this.state;
    return (
      <React.Fragment>
        <Toast />
        <StickyBar tabs={['Information', 'Contacts', 'Notes', 'Shipments']} />
        <div className="detail-container">
          <div className="detail-title">{`Hospital: ${hospital.name || ''}`}</div>
          <div className="tables-container">
            <section id="information">
              <div style={{ width: '90%', display: 'flex', justifyContent: 'space-between' }}>
                <button style={{ width: 'auto' }} type="button" className="table-title label" onClick={this.handleEdit}>
                  Information&nbsp;
                  <Icon className="img">edit</Icon>
                </button>
                <Tooltip title="Delete hospital">
                  <button style={{ width: 'auto' }} type="button" className="table-title label" onClick={this.handleOpenModalDeleteHospital}>
                    <Icon className="img">delete</Icon>
                  </button>
                </Tooltip>
              </div>
              <HospitalInformation
                hospital={hospital}
                contacts={contacts}
                hospitalInQueue={hospitalInQueue}
                update={update}
                handleSave={this.handleSave}
                handleCancel={this.handleCancel}
                handleChange={this.handleChange}
                handleCheck={this.handleCheck}
                handleRadioChange={this.handleRadioChange}
                handleMonthChoose={this.handleMonthChoose}
              />
            </section>
            <section id="contacts" className="contacts_section">
              <div className="table-title">
                Contacts(
                {contacts.length}
                )
              </div>
              <Table
                headers={['First Name', 'Last Name', 'Title', 'Email Address', 'Phone']}
                objects={contacts}
                keys={['first_name', 'last_name', 'title', 'email', 'phone']}
                sortField={hospitalContactsSortParams}
                onCreateNew={this.handleCreateNew}
                pagination={false}
                handleSort={this.handleSortContacts}
                editable
                handleEdit={this.handleOpenEditContactModal}
                handleDelete={this.handleOpenDeleteModal}
                formatters={{ phone: item => phoneFormatter(item) }}
              />
            </section>
            <section id="notes">
              <div className="table-title">Notes</div>
              <Table
                headers={['Date', 'Message', 'Created by']}
                keys={['created_at', 'message', 'user_full_name']}
                sortField={hospitalNotesSortParams}
                objects={notes}
                paginationDisabled={currentNotePage >= totalNotePages}
                onCreateNew={this.handleCreateNote}
                handleSort={this.handleSortNotes}
                editable
                handleEdit={this.handleOpenEditNoteModal}
                handleDelete={this.handleOpenDeleteNoteModal}
                onPageChange={this.handleNotePageChange}
                formatters={{
                  created_at: item => item && moment(item).format('M/D/YYYY h:mm:ss A'),
                }}
              />
            </section>
            <section id="shipments">
              <div className="table-title">Shipments</div>
              <Table
                headers={['ID', 'Date', 'Number of Boxes', 'Tracking Number', 'Status', 'Shipment Status']}
                objects={orders}
                onPageChange={this.handlePageChange}
                paginationDisabled={currentPage >= totalPages}
                keys={['id', 'created_at', 'number_of_boxes', 'tracking_number', 'status', 'shipment_status']}
                sortField={hospitalOrdersSortParams}
                formatters={{
                  created_at: item => moment(item).format('M/D/YYYY'),
                  tracking_number: item => <a target="_blank" rel="noopener noreferrer" href={`https://www.ups.com/track?loc=en_US&tracknum=${item}`} className="tn-link-btn">{item}</a>,
                }}
                handleSort={this.handleSortOrders}
                onCreateNew={() => this.handleCreateNewShipment(hospital.id)}
              />
            </section>
            <ReactModal
              isOpen={showModal}
              onRequestClose={this.handleCloseModal}
              className="modal contact"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <HospitalContactsAddNew
                handleClose={this.handleCloseModal}
                handleSave={this.handleSaveNewHospitalContact}
                title="Create new hospital contact"
              />
            </ReactModal>
            <ReactModal
              isOpen={showCreateNoteModal}
              onRequestClose={this.handleCloseNoteModal}
              className="modal contact"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <NoteModify
                handleClose={this.handleCloseNoteModal}
                handleSave={this.handleSaveNewNote}
                title="Add new note"
                buttonTitle="Create"
              />
            </ReactModal>
            <ReactModal
              isOpen={showDeleteModal}
              onRequestClose={this.handleCloseDeleteModal}
              className="modal delete"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <DeleteModal
                title="Confirm delete user"
                subtitle="Are you sure you want to delete the user?"
                handleDelete={this.handleDeleteHospitalContact}
                handleClose={this.handleCloseDeleteModal}
              />
            </ReactModal>
            <ReactModal
              isOpen={showDeleteNoteModal}
              onRequestClose={this.handleCloseDeleteNoteModal}
              className="modal delete"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <DeleteModal
                title="Confirm delete note"
                subtitle="Are you sure you want to delete the note?"
                handleDelete={this.handleDeleteHospitalNote}
                handleClose={this.handleCloseDeleteNoteModal}
              />
            </ReactModal>
            <ReactModal
              isOpen={showEditContactModal}
              onRequestClose={this.handleCloseEditModal}
              className="modal contact"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <HospitalContactsAddNew
                handleClose={this.handleCloseEditModal}
                handleSave={this.handleUpdateHospitalContact}
                contact={selectedObject}
                title="Edit hospital contact"
              />
            </ReactModal>
            <ReactModal
              isOpen={showModalDeleteHospital}
              onRequestClose={this.handleCloseModalDeleteHospital}
              className="modal delete"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <DeleteModal
                title="Confirm delete hospital"
                subtitle="Are you sure you want to delete this hospital?"
                handleClose={this.handleCloseModalDeleteHospital}
                handleDelete={this.handleDeleteHospital}
              />
            </ReactModal>
            <ReactModal
              isOpen={showEditNoteModal}
              onRequestClose={this.handleCloseEditNoteModal}
              className="modal contact"
              overlayClassName="overlay"
              ariaHideApp={false}
            >
              <NoteModify
                handleClose={this.handleCloseEditNoteModal}
                handleSave={this.handleEditNote}
                message={selectedNoteObject.message}
                title="Edit note"
                buttonTitle="Save"
              />
            </ReactModal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HospitalsShow.propTypes = {
  hospital: PropTypes.instanceOf(Object).isRequired,
  contacts: PropTypes.instanceOf(Array).isRequired,
  hospitalInQueue: PropTypes.string.isRequired,
};

export default HospitalsShow;
