import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import DeleteModal from '../DeleteModal';

describe('DeleteModal Component', () => {
  let wrapper;
  const defaultProps = {
    title: 'title',
    subtitle: 'subtitle',
    handleDelete: jest.fn(),
    handleClose: jest.fn(),
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<DeleteModal {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<DeleteModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('button')).toHaveLength(3);
    });
  });
  describe('event handling', () => {
    beforeEach(() => {
      wrapper = mount(<DeleteModal {...defaultProps} />);
    });

    it('create', () => {
      wrapper.find('button').at(2).simulate('click');
      expect(defaultProps.handleDelete).toHaveBeenCalled();
    });

    it('close', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });
  });
});
