import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import ReactModal from 'react-modal';
import API from '../../services/api';
import printLabel from '../../services/printHelper';
import PrintModeModal from './PrintModeModal';

class ConfirmShipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      value: [],
      finish: false,
    };
  }

  handleOpenModal = (value) => {
    this.setState({ showModal: true, value, finish: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handlePrint = (mode) => {
    const { value } = this.state;
    this.setState({ showModal: false }, () => printLabel(value, 'donor', mode));
  };

  render() {
    const { showModal, finish } = this.state;
    const {
      shipping, handleBack, handleConfirm, labels, status, amount,
    } = this.props;
    const {
      shipping_address: shippingAddress,
      billing_address: billingAddress,
      pickup_address: pickupAddress,
    } = shipping;
    const fullName = `${shippingAddress.first_name || ''} ${shippingAddress.last_name || ''}`;
    const billingFullName = `${billingAddress.billing_first_name || ''} ${billingAddress.billing_last_name || ''}`;
    const pickupFullName = `${pickupAddress.pickup_first_name || ''} ${pickupAddress.pickup_last_name || ''}`;
    return (
      <div className="ship-container">
        <div className="custom-logo-img" />
        <div className="shipment-confirmation-content">
          <div className="shipment-confirmation-title">
            Ship From
          </div>
          <div className="shipment-confirmation-info">
            <div>
              <section>
                <label>{shippingAddress.company_name ? 'Company Name' : 'Name'}</label>
                <span>{shippingAddress.company_name ? shippingAddress.company_name : fullName}</span>
              </section>
              <section>
                <label>Address</label>
                <span>
                  {
                    `${shippingAddress.address_line1}`
                  }
                  <br />
                  {
                    `${shippingAddress.address_line2}`
                  }
                  {shippingAddress.address_line2 && <br />}
                  {
                    `${shippingAddress.city}, 
                    ${shippingAddress.state}
                    ${shippingAddress.zip_code}`
                  }
                  <br />
                  United States
                </span>
              </section>
            </div>
            <div>
              <section>
                <label>Email</label>
                <span>{shippingAddress.email}</span>
              </section>
              <section>
                <label>Phone</label>
                <span>{shippingAddress.phone}</span>
              </section>
            </div>
          </div>
          <div className="shipment-confirmation-title">
            Payment Information
          </div>
          <div className="shipment-confirmation-info">
            <div>
              <div className="title">Card Information</div>
              <section>
                <label>Name on Card</label>
                <span>{shipping.card_name}</span>
              </section>
              <section>
                <label>Card number</label>
                <span>{`**** **** **** ${shipping.card.last_4}`}</span>
              </section>
              <section>
                <label>Valid Through</label>
                <span>{`${shipping.card.exp_month}/${shipping.card.exp_year}`}</span>
              </section>
            </div>
            <div>
              <div className="title">Billing Address</div>
              <div>
                {shipping.useMyShipmentAddress ? (
                  <section>
                    <label>{shippingAddress.company_name ? 'Company Name' : 'Name'}</label>
                    <span>{shippingAddress.company_name ? shippingAddress.company_name : fullName}</span>
                  </section>
                ) : (
                  <section>
                    <label>{billingAddress.billing_company_name ? 'Company Name' : 'Name'}</label>
                    <span>{billingAddress.billing_company_name ? billingAddress.billing_company_name : billingFullName}</span>
                  </section>
                )}
                <section>
                  <label>Address</label>
                  <span>
                    {shipping.useMyShipmentAddress
                      ? (
                        <span>
                          {
                            `${shippingAddress.address_line1}`
                          }
                          <br />
                          {
                            `${shippingAddress.address_line2}`
                          }
                          {shippingAddress.address_line2 && <br />}
                          {
                            `${shippingAddress.city}, 
                            ${shippingAddress.state}
                            ${shippingAddress.zip_code}`
                          }
                          <br />
                          United States
                        </span>
                      )
                      : (
                        <span>
                          {
                            `${billingAddress.billing_address_line1}`
                          }
                          <br />
                          {
                            `${billingAddress.billing_address_line2}`
                          }
                          {billingAddress.billing_address_line2 && <br />}
                          {
                            `${billingAddress.billing_city}, 
                            ${billingAddress.billing_state}
                            ${billingAddress.billing_zip_code}`
                          }
                          <br />
                          United States
                        </span>
                      )
                }
                  </span>
                </section>
              </div>
              <div>
                <section>
                  <label>Email</label>
                  <span>{shipping.useMyShipmentAddress ? shippingAddress.email : billingAddress.billing_email}</span>
                </section>
                <section>
                  <label>Phone</label>
                  <span>{shipping.useMyShipmentAddress ? shippingAddress.phone : billingAddress.billing_phone}</span>
                </section>
              </div>
            </div>
          </div>
          <div className="shipment-confirmation-title">
            Package Information
          </div>
          <div className="shipment-confirmation-info">
            {shipping.packages.map((_, index) => (
              <div key={index.toString()} className={`package-block ${shipping.numberOfPackages > 4 && 'margin'}`}>
                <section className="confirm-package">
                  <label>{`Package # ${index + 1}`}</label>
                  {labels && labels[index]
                  && (
                    <section>
                      <div className="download-link">
                        <button type="button" className="print" onClick={() => API.downloadDonorLabel({ id: labels[index] })}><Icon className="img">file_download</Icon></button>
                      </div>
                      <div className="download-link">
                        <button
                          type="button"
                          className="print"
                          onClick={() => this.handleOpenModal(labels[index])}
                        >
                          <Icon className="img">print</Icon>
                        </button>
                      </div>
                    </section>
                  )
                  }
                </section>
                <section>
                  <label>Dimensions</label>
                  <span>
                    {
                  `${shipping.packages[index].length}" x ${shipping.packages[index].width}" x ${shipping.packages[index].height}"`
                }
                  </span>
                </section>
                <section>
                  <label>Weight</label>
                  <span>{`${shipping.packages[index].weight} lbs`}</span>
                </section>
              </div>
            ))}
          </div>
          {shipping.usePickup
          && (
          <div className="shipment-confirmation-title">
            Pickup information
          </div>
          )}
          {shipping.usePickup
          && (
            <div className="shipment-confirmation-info">
              <div>
                <div className="title">Pickup Time</div>
                <section>
                  <label>Pickup Date</label>
                  <span>{shipping.pickup_date_time.pickup_date}</span>
                </section>
                <section>
                  <label>Earliest Pickup Time</label>
                  <span>{shipping.pickup_date_time.ready_time}</span>
                </section>
                <section>
                  <label>Latest Pickup Time</label>
                  <span>{shipping.pickup_date_time.close_time}</span>
                </section>
              </div>
              <div>
                <div className="title">Pickup Address</div>
                <div>
                  <section>
                    <label>{pickupAddress.pickup_company_name ? 'Company Name' : 'Name'}</label>
                    <span>{pickupAddress.pickup_company_name ? pickupAddress.pickup_company_name : pickupFullName}</span>
                  </section>
                  <section>
                    <label>Address</label>
                    <span>
                      <span>
                        {
                          `${pickupAddress.pickup_address_line1}`
                        }
                        <br />
                        {
                          `${pickupAddress.pickup_address_line2}`
                        }
                        {pickupAddress.pickup_address_line2 && <br />}
                        {
                          `${pickupAddress.pickup_city},
                                    ${pickupAddress.pickup_state}
                                    ${pickupAddress.pickup_zip_code}`
                        }
                        <br />
                        United States
                      </span>
                    </span>
                  </section>
                </div>
                <div>
                  <section>
                    <label>Email</label>
                    <span>{pickupAddress.pickup_email}</span>
                  </section>
                  <section>
                    <label>Phone</label>
                    <span>{pickupAddress.pickup_phone}</span>
                  </section>
                </div>
              </div>
            </div>
          )}
          <div className="shipment-confirmation-ba">
            <button type="button" onClick={handleBack} className="shipment-confirmation-btn-back">Back</button>
            <div className="amount">{`Amount: $${amount || 0}`}</div>
          </div>
          <div className="action-buttons-container">
            {
              labels.length > 0 && (
                <div className="download-link">
                  <button
                    type="button"
                    className="shipment-confirmation-btn"
                    onClick={() => this.handleOpenModal(labels)}
                  >
                    <Icon className="img primary">print</Icon>
                    Print all labels
                  </button>
                </div>
              )
            }
            {
              status !== 'success'
                ? (
                  <button disabled={(status === 'loading')} type="button" className="shipment-confirmation-btn" onClick={handleConfirm}>
                    {
                      !status && 'Confirm'
                    }
                    {
                      status === 'loading' && 'Processing...'
                    }
                  </button>
                )
                : <button disabled={!finish} type="button" className="shipment-confirmation-btn" onClick={() => window.location.replace('https://thecrayoninitiative.org/')}>Finish</button>
            }
          </div>
        </div>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          className="modal print"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <PrintModeModal close={this.handleCloseModal} print={this.handlePrint} />
        </ReactModal>
      </div>
    );
  }
}

ConfirmShipping.propTypes = {
  shipping: PropTypes.instanceOf(Object).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  labels: PropTypes.instanceOf(Array),
  status: PropTypes.string.isRequired,
  amount: PropTypes.string,
};

ConfirmShipping.defaultProps = {
  labels: [],
  amount: '',
};


export default ConfirmShipping;
