import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import API from '../../../services/api';

const styles = {
  input: {
    'font-size': '1rem',
    height: '3rem',
    width: 'calc(100% - 2rem)',
    'border-radius': '10px',
    border: 'solid 1px #828282',
    padding: '0 1rem',
    color: '#828282',
    'font-family': 'Avenir Next Regular',
    outline: 'none',
  },
  input1: {
    height: '3rem',
  },
};

class DonorSignUp extends React.Component {
  constructor(props) {
    super(props);
    const defaults = {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      agree: false,
    };

    this.state = { donor: defaults, formErrors: { } };
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateField(fieldName) {
    const { formErrors, donor } = this.state;
    const value = donor[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        formErrors.email = valid ? '' : 'Email is invalid';
        break;
      case 'first_name':
      case 'last_name':
        valid = !!value;
        formErrors[fieldName] = valid ? '' : 'This field is required';
        break;
      case 'password':
        valid = value.length >= 6;
        formErrors.password = valid ? '' : 'Password is too short';
        break;
      case 'password_confirmation':
        valid = value === donor.password;
        formErrors.password_confirmation = valid ? '' : 'Passwords don\'t match';
        break;
      case 'agree':
        valid = !!value;
        formErrors.agree = valid ? '' : 'err';
        break;
      default:
        break;
    }
    this.setState({ formErrors });
  }

  hasErrors() {
    const { formErrors } = this.state;
    return Object.values(formErrors).some(item => item);
  }

  handleSubmit() {
    const { donor, formErrors } = this.state;
    const form = document.forms[0];

    Object.keys(donor).forEach(field => this.validateField(field));
    if (this.hasErrors()) return;

    API.donorValidation({ type: 'sign_up', email: donor.email }).then((res) => {
      if (res) formErrors.email = res.err_msg;
      this.setState({ formErrors }, () => {
        if (!this.hasErrors()) form.submit();
      });
    });
  }

  handleChange(event) {
    const { donor } = this.state;
    const name = event.target.id;
    const { value } = event.target;
    if (name === 'agree') donor[name] = !donor[name];
    else donor[name] = value;
    this.setState({ donor }, () => { this.validateField(name); });
  }

  handleGuest() {
    window.location.assign('/shipping?guest=1');
  }

  render() {
    const { donor, formErrors } = this.state;
    const { classes } = this.props;
    return (
      <div className="auth-content">
        <div className="custom-logo-img" />
        <div className="auth-header">Sign Up</div>
        <div className="custom-form-group">
          <label htmlFor="donor[first_name]">
            First Name
          </label>
          <div>
            <i className="material-icons">person</i>
            <TextField
              id="first_name"
              type="text"
              placeholder="Enter first name"
              name="donor[first_name]"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={donor.first_name}
              error={Boolean(formErrors.first_name)}
              helperText={formErrors.first_name}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="donor[last_name]">
            Last Name
          </label>
          <div>
            <i className="material-icons">person</i>
            <TextField
              id="last_name"
              type="text"
              placeholder="Enter last name"
              name="donor[last_name]"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={donor.last_name}
              error={Boolean(formErrors.last_name)}
              helperText={formErrors.last_name}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="donor[email]">
            Email Address
          </label>
          <div>
            <i className="material-icons">mail</i>
            <TextField
              id="email"
              type="email"
              name="donor[email]"
              placeholder="Enter email"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={donor.email}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="donor[password]">
            Password
          </label>
          <div>
            <i className="material-icons">lock</i>
            <TextField
              type="password"
              name="donor[password]"
              id="password"
              placeholder="Password"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={donor.password}
              error={Boolean(formErrors.password)}
              helperText={formErrors.password}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="donor[password_confirmation]">
            Confirm Password
          </label>
          <div>
            <i className="material-icons">lock</i>
            <TextField
              type="password"
              name="donor[password_confirmation]"
              id="password_confirmation"
              placeholder="Confirm password"
              classes={{
                root: classes.input,
              }}
              onChange={this.handleChange}
              value={donor.password_confirmation}
              error={Boolean(formErrors.password_confirmation)}
              helperText={formErrors.password_confirmation}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
            />
          </div>
        </div>
        <div className="input-container">
          <div className={`checkbox-cell ${Boolean(formErrors.agree) && 'errorr'}`}>
            <input
              required
              type="checkbox"
              name="agree"
              id="agree"
              checked={donor.agree}
              onChange={this.handleChange}
            />
            <label htmlFor="agree" className="input-label">I agree with Terms and Conditions</label>
          </div>
        </div>
        <button
          type="button"
          className="btn-main"
          onClick={this.handleSubmit}
        >
          Create account
        </button>
        <div className="auth-footer-text">Have an account?</div>
        <a href="/donors/sign_in" className="btn-alt">Sign In</a>
        <div className="auth-footer-text">Or </div>
        <button type="button" onClick={this.handleGuest} className="btn-alt black">Continue as a guest</button>
      </div>
    );
  }
}

DonorSignUp.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DonorSignUp);
