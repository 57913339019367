import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import CalendarCreationModal from '../CalendarCreationModal';

jest.mock('tui-date-picker', () => jest.fn(() => ({
  on: jest.fn(),
})));

jest.mock('../../../services/api', () => ({
  searchUser: jest.fn(() => Promise.resolve({
    users: [
      { id: 1, first_name: 'name' },
      { id: 2, first_name: 'name2' },
    ],
  })),
}));

describe('CalendarCreationModal Component', () => {
  let wrapper;
  const defaultProps = {
    event: {
      start: {
        toDate: jest.fn(),
      },
      end: {
        toDate: jest.fn(),
      },
      raw: {},
      attendees: [],
    },
    calendars: [
      { id: 1, name: 'name' },
    ],
    handleClose: jest.fn(),
    handleCreate: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mount(<CalendarCreationModal {...defaultProps} />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<CalendarCreationModal {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      expect(wrapper.find('button')).toHaveLength(2);
      expect(wrapper.find('.modal_body')).toHaveLength(1);
      expect(wrapper.find('.input-container')).toHaveLength(9);
      expect(wrapper.find('.input-label')).toHaveLength(10);
    });
  });

  describe('event handling', () => {
    it('handle create', () => {
      wrapper.find('button').at(1).simulate('click');
      expect(defaultProps.handleCreate).toHaveBeenCalled();
    });

    it('handle change type', () => {
      wrapper.find('#lead_only').simulate('change', { target: { id: 'lead_only', checked: true } });
      expect(wrapper.state().schedule.eventVisibility).toEqual('lead_only');
    });
    it('handle change type', () => {
      wrapper.find('#company_wide').simulate('change', { target: { id: 'company_wide', checked: true } });
      expect(wrapper.state().schedule.eventVisibility).toEqual('company_wide');
    });
    it('handle change type', () => {
      wrapper.find('#internal').simulate('change', { target: { id: 'internal', checked: true } });
      expect(wrapper.state().schedule.eventType).toEqual('internal');
    });
    it('handle change type', () => {
      wrapper.find('#public').simulate('change', { target: { id: 'public', checked: true } });
      expect(wrapper.state().schedule.eventType).toEqual('public');
    });

    it('handle change', () => {
      wrapper.find('#calendarId').simulate('change', { target: { id: 'calendarId', value: 1 } });
      expect(wrapper.state().schedule.calendarId).toEqual(1);
    });

    it('handle select attendees', (cb) => {
      wrapper.instance().handleSearch('name');
      process.nextTick(() => {
        wrapper.instance().handleSelect({ value: 1 });
        wrapper.instance().handleSelect({ value: 2 });
        expect(wrapper.state().schedule.attendees.length).toEqual(2);
        cb();
      });
    });

    it('handle select lead', (cb) => {
      wrapper.instance().handleSearch('name');
      process.nextTick(() => {
        wrapper.instance().handleSelectLead({ value: 1 });
        expect(wrapper.state().schedule.leader).toEqual(1);
        cb();
      });
    });

    it('handle set date', () => {
      const date = new Date('12.12.12');
      wrapper.instance().setDate('startDate', date);
      expect(wrapper.state().schedule.startDate).toEqual(date);
    });

    it('handle close', () => {
      wrapper.find('button').at(0).simulate('click');
      expect(defaultProps.handleClose).toHaveBeenCalled();
    });
  });
});
