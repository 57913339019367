// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ShippingForm Component rendering renders correctly 1`] = `
<div
  className="ship-container"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="ship-content"
  >
    <div
      className="shipment-header"
    >
      Create a Shipment
    </div>
    <div
      className="div-64"
    >
      <div
        className="custom-form-group-title"
      >
        Ship from
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="radio-container"
        >
          <div>
            <input
              checked={true}
              id="individual"
              name="individual"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="individual"
            >
              Individual
            </label>
          </div>
          <div>
            <input
              checked={false}
              id="company"
              name="company"
              onChange={[Function]}
              type="radio"
            />
            <label
              htmlFor="company"
            >
              Company
            </label>
          </div>
        </div>
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="shi-44"
        >
          <label
            htmlFor="first_name"
          >
            First name *
          </label>
          <div
            className="MuiFormControl-root-1"
          >
            <div
              className="MuiInput-root-5 MuiInput-formControl-6"
            >
              <input
                aria-invalid={false}
                className="MuiInput-input-13"
                disabled={false}
                id="first_name"
                name="first_name"
                onBlur={[Function]}
                onChange={[Function]}
                onFocus={[Function]}
                required={false}
                type="text"
              />
            </div>
          </div>
        </div>
        <div
          className="shi-44"
        >
          <label
            htmlFor="last_name"
          >
            Last name *
          </label>
          <div
            className="MuiFormControl-root-1"
          >
            <div
              className="MuiInput-root-5 MuiInput-formControl-6"
            >
              <input
                aria-invalid={false}
                className="MuiInput-input-13"
                disabled={false}
                id="last_name"
                name="last_name"
                onBlur={[Function]}
                onChange={[Function]}
                onFocus={[Function]}
                required={false}
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <label
        htmlFor="address_line1"
      >
        Street Address *
      </label>
      <div>
        <div
          className="MuiFormControl-root-1"
        >
          <div
            className="MuiInput-root-5 MuiInput-disabled-8 MuiInput-formControl-6"
          >
            <input
              aria-invalid={false}
              className="MuiInput-input-13 MuiInput-disabled-8"
              disabled={true}
              id="address_line1"
              name="address_line1"
              onBlur={[Function]}
              onChange={[Function]}
              onFocus={[Function]}
              required={false}
              type="text"
              value=""
            />
          </div>
        </div>
      </div>
      <label
        htmlFor="address_line2"
      >
        Apt, Suite, Bldg, Gate Code
      </label>
      <div
        className="MuiFormControl-root-1"
      >
        <div
          className="MuiInput-root-5 MuiInput-formControl-6"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-13"
            disabled={false}
            id="address_line2"
            name="address_line2"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            required={false}
            type="text"
            value="apt. 600"
          />
        </div>
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="shi-44"
        >
          <label
            htmlFor="city"
          >
            City *
          </label>
          <div
            className="MuiFormControl-root-1"
          >
            <div
              className="MuiInput-root-5 MuiInput-formControl-6"
            >
              <input
                aria-invalid={false}
                className="MuiInput-input-13"
                disabled={false}
                id="city"
                name="city"
                onBlur={[Function]}
                onChange={[Function]}
                onFocus={[Function]}
                required={false}
                type="text"
                value="Austin"
              />
            </div>
          </div>
        </div>
        <div
          className="shi-44"
        >
          <label
            htmlFor="state"
          >
            State
          </label>
          <select
            className="shipment-input"
            disabled={false}
            id="state"
            onChange={[Function]}
            value="TX"
          >
            <option
              disabled={true}
              hidden={true}
              value=""
            />
            <option
              value="AL"
            >
              AL - Alabama
            </option>
            <option
              value="AK"
            >
              AK - Alaska
            </option>
            <option
              value="AS"
            >
              AS - American Samoa
            </option>
            <option
              value="AZ"
            >
              AZ - Arizona
            </option>
            <option
              value="AR"
            >
              AR - Arkansas
            </option>
            <option
              value="CA"
            >
              CA - California
            </option>
            <option
              value="CO"
            >
              CO - Colorado
            </option>
            <option
              value="CT"
            >
              CT - Connecticut
            </option>
            <option
              value="DE"
            >
              DE - Delaware
            </option>
            <option
              value="DC"
            >
              DC - District Of Columbia
            </option>
            <option
              value="FM"
            >
              FM - Federated States Of Micronesia
            </option>
            <option
              value="FL"
            >
              FL - Florida
            </option>
            <option
              value="GA"
            >
              GA - Georgia
            </option>
            <option
              value="GU"
            >
              GU - Guam
            </option>
            <option
              value="HI"
            >
              HI - Hawaii
            </option>
            <option
              value="ID"
            >
              ID - Idaho
            </option>
            <option
              value="IL"
            >
              IL - Illinois
            </option>
            <option
              value="IN"
            >
              IN - Indiana
            </option>
            <option
              value="IA"
            >
              IA - Iowa
            </option>
            <option
              value="KS"
            >
              KS - Kansas
            </option>
            <option
              value="KY"
            >
              KY - Kentucky
            </option>
            <option
              value="LA"
            >
              LA - Louisiana
            </option>
            <option
              value="ME"
            >
              ME - Maine
            </option>
            <option
              value="MH"
            >
              MH - Marshall Islands
            </option>
            <option
              value="MD"
            >
              MD - Maryland
            </option>
            <option
              value="MA"
            >
              MA - Massachusetts
            </option>
            <option
              value="MI"
            >
              MI - Michigan
            </option>
            <option
              value="MN"
            >
              MN - Minnesota
            </option>
            <option
              value="MS"
            >
              MS - Mississippi
            </option>
            <option
              value="MO"
            >
              MO - Missouri
            </option>
            <option
              value="MT"
            >
              MT - Montana
            </option>
            <option
              value="NE"
            >
              NE - Nebraska
            </option>
            <option
              value="NV"
            >
              NV - Nevada
            </option>
            <option
              value="NH"
            >
              NH - New Hampshire
            </option>
            <option
              value="NJ"
            >
              NJ - New Jersey
            </option>
            <option
              value="NM"
            >
              NM - New Mexico
            </option>
            <option
              value="NY"
            >
              NY - New York
            </option>
            <option
              value="NC"
            >
              NC - North Carolina
            </option>
            <option
              value="ND"
            >
              ND - North Dakota
            </option>
            <option
              value="MP"
            >
              MP - Northern Mariana Islands
            </option>
            <option
              value="OH"
            >
              OH - Ohio
            </option>
            <option
              value="OK"
            >
              OK - Oklahoma
            </option>
            <option
              value="OR"
            >
              OR - Oregon
            </option>
            <option
              value="PW"
            >
              PW - Palau
            </option>
            <option
              value="PA"
            >
              PA - Pennsylvania
            </option>
            <option
              value="PR"
            >
              PR - Puerto Rico
            </option>
            <option
              value="RI"
            >
              RI - Rhode Island
            </option>
            <option
              value="SC"
            >
              SC - South Carolina
            </option>
            <option
              value="SD"
            >
              SD - South Dakota
            </option>
            <option
              value="TN"
            >
              TN - Tennessee
            </option>
            <option
              value="TX"
            >
              TX - Texas
            </option>
            <option
              value="UT"
            >
              UT - Utah
            </option>
            <option
              value="VT"
            >
              VT - Vermont
            </option>
            <option
              value="VI"
            >
              VI - Virgin Islands
            </option>
            <option
              value="VA"
            >
              VA - Virginia
            </option>
            <option
              value="WA"
            >
              WA - Washington
            </option>
            <option
              value="WV"
            >
              WV - West Virginia
            </option>
            <option
              value="WI"
            >
              WI - Wisconsin
            </option>
            <option
              value="WY"
            >
              WY - Wyoming
            </option>
          </select>
        </div>
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="shi-44"
        >
          <label
            htmlFor="zip_code"
          >
            Zip Code *
          </label>
          <div
            className="MuiFormControl-root-1"
          >
            <div
              className="MuiInput-root-5 MuiInput-formControl-6"
            >
              <input
                aria-invalid={false}
                className="MuiInput-input-13"
                disabled={false}
                id="zip_code"
                name="zip_code"
                onBlur={[Function]}
                onChange={[Function]}
                onFocus={[Function]}
                required={false}
                type="text"
                value="55293"
              />
            </div>
          </div>
        </div>
        <div
          className="shi-44"
        >
          <label
            htmlFor="country"
          >
            Country
          </label>
          <input
            className="shipment-input read-only"
            defaultValue="United States"
            id="country"
            name="country"
            readOnly={true}
          />
        </div>
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="shi-44"
        >
          <label
            htmlFor="phone"
          >
            Phone
          </label>
          <div
            className="MuiFormControl-root-1"
          >
            <div
              className="MuiInput-root-5 MuiInput-formControl-6"
            >
              <input
                aria-invalid={false}
                className="MuiInput-input-13"
                disabled={false}
                id="phone"
                name="phone"
                onBlur={[Function]}
                onChange={[Function]}
                onFocus={[Function]}
                required={false}
                type="text"
                value="1657564665"
              />
            </div>
          </div>
        </div>
        <div
          className="shi-44"
        >
          <label
            htmlFor="email"
          >
            Email *
          </label>
          <div
            className="MuiFormControl-root-1"
          >
            <div
              className="MuiInput-root-5 MuiInput-formControl-6"
            >
              <input
                aria-invalid={false}
                className="MuiInput-input-13"
                disabled={false}
                id="email"
                name="email"
                onBlur={[Function]}
                onChange={[Function]}
                onFocus={[Function]}
                required={false}
                type="text"
                value="tester@test.com"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="check-container full"
        >
          <input
            id="residential_address"
            name="residential_address"
            onChange={[Function]}
            type="checkbox"
          />
          <label
            htmlFor="residential_address"
          >
            Residential Address
          </label>
        </div>
      </div>
      <div
        className="custom-form-group-title"
      >
        Package Information
      </div>
      <label
        htmlFor="package_number"
      >
        Total Number of Packages
      </label>
      <select
        id="package_number"
        name="package_number"
        onChange={[Function]}
      >
        <option>
          1
        </option>
        <option>
          2
        </option>
        <option>
          3
        </option>
        <option>
          4
        </option>
        <option>
          5
        </option>
        <option>
          6
        </option>
        <option>
          7
        </option>
        <option>
          8
        </option>
        <option>
          9
        </option>
      </select>
      <div
        className="shipment-package-container false"
      >
        <section>
          <div
            className="package-header"
          >
            Package #1
          </div>
          <div
            className="shipment-inputs-container"
          >
            <div
              className="shi-31"
            >
              <label
                htmlFor="length1"
              >
                Length
              </label>
              <span>
                <input
                  className="shipment-input"
                  id="length1"
                  name="length"
                  onChange={[Function]}
                  value="12"
                />
                <span>
                   in
                </span>
              </span>
            </div>
            <div
              className="shi-31"
            >
              <label
                htmlFor="width1"
              >
                Width
              </label>
              <span>
                <input
                  className="shipment-input"
                  id="width1"
                  name="width"
                  onChange={[Function]}
                  value="12"
                />
                <span>
                   in
                </span>
              </span>
            </div>
            <div
              className="shi-31"
            >
              <label
                htmlFor="height1"
              >
                Height
              </label>
              <span>
                <input
                  className="shipment-input"
                  id="height1"
                  name="height"
                  onChange={[Function]}
                  value="12"
                />
                <span>
                   in
                </span>
              </span>
            </div>
          </div>
          <div
            className="shipment-inputs-container"
          >
            <div
              className="shi-25"
            >
              <label
                htmlFor="weight1"
              >
                Weight
              </label>
              <span>
                <input
                  className="shipment-input"
                  disabled={false}
                  id="weight1"
                  name="weight"
                  onChange={[Function]}
                  value="12"
                />
                <span>
                   LBS
                </span>
              </span>
            </div>
            <div
              className="check-container"
            >
              <input
                checked={false}
                id="weight_check1"
                name="weight_check"
                onChange={[Function]}
                type="checkbox"
              />
              <label
                htmlFor="weight_check1"
              >
                I don’t know the weight
              </label>
            </div>
          </div>
        </section>
      </div>
      <div>
        <div
          className="custom-form-group-title"
        >
          Pickup
        </div>
        <div
          className="shipment-inputs-container"
        >
          <div
            className="check-container full"
          >
            <input
              id="pickup"
              name="pickup"
              onChange={[Function]}
              type="checkbox"
            />
            <label
              htmlFor="pickup"
            >
              Do you want to pick up?
              <br />
              <div
                className="note"
              >
                (additional charges will be applied)
              </div>
            </label>
          </div>
        </div>
      </div>
      <div
        className="custom-form-group-title"
      >
        Shipping Quote
      </div>
      <div
        className="shipping-quote"
      >
        <button
          className="btn-main medium"
          id="quote-button"
          onClick={[Function]}
          type="button"
        >
          Get Quote
        </button>
        <div
          className="amount"
          style={
            Object {
              "fontSize": "1.5rem",
            }
          }
        >
          Amount: $0.00
        </div>
      </div>
      <div
        className="custom-form-group-title"
      >
        Payment Information
      </div>
      <div
        style={
          Object {
            "fontSize": "1.0rem",
            "marginBottom": "2rem",
          }
        }
      >
        We need your credit card information to purchase UPS shipping label(s) using our discounted nonprofit rate.
      </div>
      <label
        htmlFor="card_name"
      >
        Name on card *
      </label>
      <div
        className="MuiFormControl-root-1"
      >
        <div
          className="MuiInput-root-5 MuiInput-formControl-6"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-13"
            disabled={false}
            id="card_name"
            name="card_name"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            required={false}
            type="text"
            value="Tester"
          />
        </div>
      </div>
      <div
        id="form-container"
      >
        <label>
          Card Number *
        </label>
        <div
          id="sq-card-number"
        />
        <div
          className="sq-fieldset"
        >
          <div
            className="third"
          >
            <label>
              Valid Through *
            </label>
            <div
              id="sq-expiration-date"
            />
          </div>
          <div
            className="third"
          >
            <label>
              CVC *
            </label>
            <div
              id="sq-cvv"
            />
          </div>
          <div
            className="third"
          >
            <label>
              Zip Code *
            </label>
            <div
              id="sq-postal-code"
            />
          </div>
        </div>
      </div>
      <div
        className="custom-form-group-title"
      >
        Billing Address
      </div>
      <div
        className="shipment-inputs-container"
      >
        <div
          className="check-container full"
        >
          <input
            checked={true}
            id="my_shipping"
            name="my_shipping"
            onChange={[Function]}
            type="checkbox"
          />
          <label
            htmlFor="my_shipping"
          >
            Use my shipping address
          </label>
        </div>
      </div>
    </div>
    
    <button
      className="btn-main medium"
      id="review-button"
      onClick={[Function]}
      type="button"
    >
      Review
    </button>
  </div>
</div>
`;
