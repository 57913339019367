import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import omit from 'lodash/omit';
import StateSelect from '../ui/StateSelect';
import phoneFormatter from '../../services/phoneFormatter';
import scrollToError from '../../services/scrollHelper';
import AddressAutocomplete from './AddressAutocomplete';
import PickupBlock from './PickupBlock';

const styles = {
  input: {
    'font-size': '1rem',
    height: '2rem',
    width: 'calc(100% - 2rem)',
    'border-radius': '5px',
    border: 'solid 1px #828282',
    padding: '0 1rem',
    color: '#828282',
    'font-family': 'Avenir Next Regular',
    outline: 'none',
    'margin-bottom': '2rem',
  },
  input1: {
    height: '1.5rem',
  },
};

class ShippingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingData: props.shippingData,
      errors: {},
      individual: true,
    };
    this.setZipCode = this.setZipCode.bind(this);
    this.setCity = this.setCity.bind(this);
    this.setStateCode = this.setStateCode.bind(this);
    this.setStreetAddress = this.setStreetAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // const { paymentForm, appId } = this.props;
    // const config = {
    //   applicationId: appId,
    //   inputClass: 'sq-input',
    //   autoBuild: false,
    //   inputStyles: [
    //     {
    //       fontSize: getComputedStyle(document.documentElement).fontSize,
    //       padding: '2px 16px',
    //       color: '#424770',
    //       backgroundColor: 'transparent',
    //       lineHeight: '2em',
    //       placeholderColor: '#aab7c4',
    //     },
    //   ],
    //   cardNumber: {
    //     elementId: 'sq-card-number',
    //     placeholder: '• • • •  • • • •  • • • •  • • • •',
    //   },
    //   cvv: {
    //     elementId: 'sq-cvv',
    //     placeholder: 'CVC',
    //   },
    //   expirationDate: {
    //     elementId: 'sq-expiration-date',
    //     placeholder: 'MM/YY',
    //   },
    //   postalCode: {
    //     elementId: 'sq-postal-code',
    //     placeholder: 'Zip',
    //   },
    //   callbacks: {
    //     cardNonceResponseReceived: this.cardNonceResponseReceived,
    //   },
    // };
    //
    // if (paymentForm) {
    //   // eslint-disable-next-line new-cap
    //   this.paymentForm = new paymentForm(config);
    //   this.paymentForm.build();
    // }
    //
    // this.setState({ config });
  }

  // componentDidUpdate(prevProps) {
    // const { paymentForm } = this.props;
    // const { config } = this.state;
    // if ((prevProps.paymentForm !== paymentForm)) {
    //   // eslint-disable-next-line new-cap
    //   this.paymentForm = new paymentForm(config);
    //   this.paymentForm.build();
    // }
  // }

  setZipCode(zipCode) {
    const { shippingData } = this.state;

    zipCode ? shippingData.shipping_address.zip_code = zipCode : shippingData.shipping_address.zip_code = '';

    this.setState({ shippingData });
  }

  setCity(city) {
    const { shippingData } = this.state;

    shippingData.shipping_address.city = city;

    this.setState({ shippingData });
  }

  setStateCode(state) {
    const { shippingData } = this.state;

    shippingData.shipping_address.state = state;

    this.setState({ shippingData });
  }

  setStreetAddress(address) {
    const { shippingData } = this.state;

    shippingData.shipping_address.address_line1 = address;

    this.setState({ shippingData });
  }

  hasErrors = () => {
    const { errors } = this.state;
    return Object.values(errors).some(item => item);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { shippingData } = this.state;

    Object.keys(shippingData.shipping_address).forEach(field => this.validateField(field, 'shipping_address'));
    if (!shippingData.useMyShipmentAddress) Object.keys(shippingData.billing_address).forEach(field => this.validateField(field, 'billing_address'));
    if (!shippingData.useMyPickupShipmentAddress) Object.keys(shippingData.pickup_address).forEach(field => this.validateField(field, 'pickup_address'));
    this.validateField('card_name');
    if (shippingData.usePickup) this.validateField('pickup_phone', 'pickup_address');


    // this.paymentForm.requestCardNonce();

    if (shippingData.useMyPickupShipmentAddress) {
      Object.keys(shippingData.shipping_address).forEach((k) => {
        if (k !== 'phone') {
          shippingData.pickup_address[`pickup_${k}`] = shippingData.shipping_address[k];
        }
      });
      this.setState({ shippingData });
    }
    await this.tokenize();

  };


   async tokenize() {
    const paymentMethod = window.card;
    const tokenResult = await paymentMethod.tokenize();
    console.log("tokenResult: ", tokenResult);

    if (tokenResult.status === 'OK') {
      const { onReview } = this.props;
      const { shippingData } = this.state;
      shippingData.token = tokenResult.token;
      shippingData.card = tokenResult.details.card;
      shippingData.card.last_4 = shippingData.card.last4;
      shippingData.card.exp_month = shippingData.card.expMonth;
      shippingData.card.exp_year = shippingData.card.expYear;

      this.setState({ shippingData });

      onReview(shippingData);
      console.log(tokenResult.token);
      // return tokenResult.token;
    } else {
      const { shippingData, errors } = this.state;
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      errors.token = errorMessage;
      this.setState({ errors });
      throw new Error(errorMessage);
    }


  };

  handleState = (e) => {
    const { shippingData } = this.state;
    const name = e.target.id;
    const { value } = e.target;
    if (name === 'state') {
      shippingData.shipping_address[name] = value;
    } else if (name === 'billing_state') {
      shippingData.billing_address[name] = value;
    } else {
      shippingData.pickup_address[name] = value;
    }
    this.setState({ shippingData });
  };


  handlePackageNumber = (e) => {
    const { shippingData } = this.state;
    const old = shippingData.numberOfPackages;
    shippingData.numberOfPackages = parseInt(e.target.value, 10);
    this.setState({ shippingData });

    if (old < shippingData.numberOfPackages) {
      shippingData.packages = [...shippingData.packages, ...[...Array(shippingData.numberOfPackages - old)].map(() => ({
        length: '',
        width: '',
        height: '',
        weight: '',
        not_weight: false,
      }))];
    } else if (old > shippingData.numberOfPackages) {
      shippingData.packages = shippingData.packages.slice(0, -(old - shippingData.numberOfPackages));
      this.setState({ shippingData });
    }
  };

  copyPackageInfo = (e, index) => {
    const { shippingData } = this.state;
    const { value } = e.target;
    shippingData.packages[index].length = shippingData.packages[value].length;
    shippingData.packages[index].height = shippingData.packages[value].height;
    shippingData.packages[index].width = shippingData.packages[value].width;
    shippingData.packages[index].weight = shippingData.packages[value].weight;
    shippingData.packages[index].not_weight = shippingData.packages[value].not_weight;
    this.setState({ shippingData });
  };

  handleUseMyShipping = () => {
    const { shippingData, errors } = this.state;
    shippingData.useMyShipmentAddress = !shippingData.useMyShipmentAddress;
    const err = omit(errors, Object.keys(shippingData.billing_address));
    this.setState({ shippingData, errors: err });
  };

  handleResidentialAddress = () => {
    const { shippingData } = this.state;
    shippingData.residentialAddress = !shippingData.residentialAddress;
    this.setState({ shippingData });
  };

  handlePickupNotifications = () => {
    const { shippingData } = this.state;
    shippingData.pickupNotifications = !shippingData.pickupNotifications;
    this.setState({ shippingData });
  };

  calculateWeight = (index) => {
    const { shippingData } = this.state;
    const shippingPackage = shippingData.packages[index];
    if (shippingPackage.not_weight) {
      shippingPackage.weight = parseFloat((shippingPackage.height * shippingPackage.width * shippingPackage.length * 0.025)
        .toFixed(2));
    }
    this.setState({ shippingData });
  };

  handlePackageChange = (e, index) => {
    const { shippingData } = this.state;
    const { value, name } = e.target;
    if (name === 'weight_check') {
      shippingData.packages[index].not_weight = !shippingData.packages[index].not_weight;
    }

    shippingData.packages[index][name] = value;
    this.setState({ shippingData }, () => this.calculateWeight(index));
  };

  handleDriverInstructionsChange = (e) => {
    const { shippingData } = this.state;
    const { value } = e.target;
    shippingData.driverInstructions = value;

    this.setState({ shippingData });
  };

  validateField(fieldName, blockName) {
    const { errors, shippingData, individual } = this.state;
    const value = blockName ? shippingData[blockName][fieldName] : shippingData[fieldName];
    let valid;

    switch (fieldName) {
      case 'email':
      case 'billing_email':
      case 'pickup_email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errors[fieldName] = valid ? '' : 'Email is invalid';
        break;
      case 'company_name':
      case 'billing_company_name':
      case 'pickup_company_name':
        // valid = individual ? true : !!value;
        // errors[fieldName] = valid ? '' : 'This field is required';
        // break;
      case 'address_line1':
      case 'city':
      case 'first_name':
      case 'last_name':
      case 'billing_first_name':
      case 'pickup_first_name':
      case 'billing_last_name':
      case 'pickup_last_name':
      case 'billing_address_line1':
      case 'pickup_address_line1':
      case 'billing_city':
      case 'pickup_city':
      case 'card_name':
        valid = !!value;
        errors[fieldName] = valid ? '' : 'This field is required';
        break;
      case 'phone':
      case 'billing_phone':
        errors[fieldName] = this.validatePhone(value);
        break;
      case 'pickup_phone':
        valid = !!value;
        if (valid) {
          errors[fieldName] = '';
        } else {
          errors[fieldName] = 'This field is required';
          break;
        }
        errors[fieldName] = this.validatePhone(value);
        break;
      case 'zip_code':
      case 'billing_zip_code':
      case 'pickup_zip_code':
        valid = value.match(/^\d{5}(?:[-\s]\d{4})?$/g);
        errors[fieldName] = valid ? '' : 'Zip code is invalid';
        break;
      case 'password':
        valid = value.length >= 6;
        errors[fieldName] = valid ? '' : 'Password is too short';
        break;
      case 'passwordConfirmation':
        valid = value === shippingData.password;
        errors[fieldName] = valid ? '' : 'Passwords don\'t match';
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  validatePhone(value) {
    const valid = !value || value.match(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\\-]?[\0-9]{3}[\s\\-]?[0-9]{4}$/g);
    return valid ? '' : 'Phone is invalid';
  }

  handleChange(event, blockName) {
    const { shippingData } = this.state;
    const name = event.target.id;
    const { value } = event.target;
    if (blockName) {
      if (name === 'phone' || name === 'pickup_phone' || name === 'billing_phone') {
        shippingData[blockName][name] = phoneFormatter(value);
        this.setState({ shippingData }, () => this.validateField(name, blockName));
        return;
      }
      shippingData[blockName][name] = value;
      this.setState({ shippingData }, () => { this.validateField(name, blockName); });
    } else {
      shippingData[name] = value;
      this.setState({ shippingData }, () => { this.validateField(name); });
    }
  }

  render() {
    const {
      shippingData, errors, individual,
    } = this.state;
    const {
      classes, error, status,
      getQuote, quoteStatus, amount,
    } = this.props;

    return (
      <div className="ship-container">
        <div className="custom-logo-img" />
        <div className="ship-content">
          <div className="shipment-header">Create a Shipment</div>
          <div className="div-64">
            {/* SHIP FROM */}
            <div className="custom-form-group-title">Ship from</div>
            <div className="shipment-inputs-container">
              {/*<div className="radio-container">*/}
              {/*  <div>*/}
              {/*    <input*/}
              {/*      type="radio"*/}
              {/*      id="individual"*/}
              {/*      name="individual"*/}
              {/*      checked={individual}*/}
              {/*      onChange={() => this.setState({ individual: true })}*/}
              {/*    />*/}
              {/*    <label htmlFor="individual">Individual</label>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <input*/}
              {/*      type="radio"*/}
              {/*      id="company"*/}
              {/*      name="company"*/}
              {/*      checked={!individual}*/}
              {/*      onChange={() => this.setState({ individual: false })}*/}
              {/*    />*/}
              {/*    <label htmlFor="company">Company</label>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="first_name">
                  First name *
                </label>
                <TextField
                  id="first_name"
                  type="text"
                  name="first_name"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.first_name}
                  error={Boolean(errors.first_name)}
                  helperText={errors.first_name}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="last_name">
                  Last name *
                </label>
                <TextField
                  id="last_name"
                  type="text"
                  name="last_name"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.last_name}
                  error={Boolean(errors.last_name)}
                  helperText={errors.last_name}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
            </div>
            {/*{!individual*/}
            {/*&& (*/}
            {/*  <React.Fragment>*/}
                <label htmlFor="company_name">
                  Company name
                </label>
                <TextField
                  id="company_name"
                  type="text"
                  name="company_name"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.company_name}
                  error={Boolean(errors.company_name)}
                  helperText={errors.company_name}
                  FormHelperTextProps={{ className: 'errors' }}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                />
            {/*  </React.Fragment>*/}
            {/*)*/}
            {/*}*/}
            <label htmlFor="address_line1">
              Street Address *
            </label>
            <AddressAutocomplete
              classes={classes}
              errors={errors}
              setZipCode={this.setZipCode}
              setCity={this.setCity}
              setStateCode={this.setStateCode}
              setStreetAddress={this.setStreetAddress}
              streetAddress={shippingData.shipping_address.address_line1}
            />
            <label htmlFor="address_line2">
              Apt, Suite, Bldg, Gate Code
            </label>
            <TextField
              id="address_line2"
              type="text"
              name="address_line2"
              classes={{
                root: classes.input,
              }}
              onChange={e => this.handleChange(e, 'shipping_address')}
              value={shippingData.shipping_address.address_line2}
              error={Boolean(errors.address_line2)}
              helperText={errors.address_line2}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="city">
                  City *
                </label>
                <TextField
                  id="city"
                  type="text"
                  name="city"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.city}
                  error={Boolean(errors.city)}
                  helperText={errors.city}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="state">
                  State
                </label>
                <StateSelect
                  id="state"
                  className="shipment-input"
                  onChange={this.handleState}
                  value={shippingData.shipping_address.state}
                />
              </div>
            </div>
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="zip_code">
                  Zip Code *
                </label>
                <TextField
                  id="zip_code"
                  type="text"
                  name="zip_code"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.zip_code}
                  error={Boolean(errors.zip_code)}
                  helperText={errors.zip_code}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="country">
                  Country
                </label>
                <input
                  id="country"
                  name="country"
                  defaultValue="United States"
                  className="shipment-input read-only"
                  readOnly
                />
              </div>
            </div>
            <div className="shipment-inputs-container">
              <div className="shi-44">
                <label htmlFor="phone">
                  Phone
                </label>
                <TextField
                  id="phone"
                  type="text"
                  name="phone"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.phone}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
              <div className="shi-44">
                <label htmlFor="email">
                  Email *
                </label>
                <TextField
                  id="email"
                  type="text"
                  name="email"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'shipping_address')}
                  value={shippingData.shipping_address.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
              </div>
            </div>
            <div className="shipment-inputs-container">
              <div className="check-container full">
                <input
                  id="residential_address"
                  name="residential_address"
                  type="checkbox"
                  checked={shippingData.residentialAddress}
                  onChange={() => this.handleResidentialAddress()}
                />
                <label htmlFor="residential_address">
                  Residential Address
                </label>
              </div>
            </div>

            {/* PACKAGE INFORMATION */}
            <div className="custom-form-group-title">Package Information</div>
            <label htmlFor="package_number">
              Total Number of Packages
            </label>
            <select
              id="package_number"
              name="package_number"
              onChange={e => this.handlePackageNumber(e)}
            >
              {
                [...Array(9)].map((_, item) => (
                  <option key={item.toString()}>
                    {item + 1}
                  </option>
                ))
              }
            </select>
            <div className={`shipment-package-container ${shippingData.numberOfPackages > 3 && 'overflow'}`}>
              {[...Array(shippingData.numberOfPackages)].map((_, item) => (
                <section key={item.toString()}>
                  <div className="package-header">{`Package #${item + 1}`}</div>
                  {shippingData.numberOfPackages > 1 && (
                  <div className="copy-package">
                    <span>Copy from</span>
                    <select onChange={e => this.copyPackageInfo(e, item)} defaultValue="">
                      <option value="" disabled hidden />
                      {
                        [...Array(shippingData.numberOfPackages)].map((__, index) => (
                          index !== item && <option key={index.toString()} value={index}>{index + 1}</option>
                        ))
                      }
                    </select>
                  </div>
                  )}
                  <div className="shipment-inputs-container">
                    <div className="shi-31">
                      <label htmlFor={`length${item + 1}`}>
                        Length
                      </label>
                      <span>
                        <input
                          id={`length${item + 1}`}
                          name="length"
                          className="shipment-input"
                          value={shippingData.packages[item].length}
                          onChange={e => this.handlePackageChange(e, item)}
                        />
                        <span>&nbsp;in</span>
                      </span>
                    </div>
                    <div className="shi-31">
                      <label htmlFor={`width${item + 1}`}>
                        Width
                      </label>
                      <span>
                        <input
                          id={`width${item + 1}`}
                          name="width"
                          className="shipment-input"
                          value={shippingData.packages[item].width}
                          onChange={e => this.handlePackageChange(e, item)}
                        />
                        <span>&nbsp;in</span>
                      </span>
                    </div>
                    <div className="shi-31">
                      <label htmlFor={`height${item + 1}`}>
                        Height
                      </label>
                      <span>
                        <input
                          id={`height${item + 1}`}
                          name="height"
                          className="shipment-input"
                          value={shippingData.packages[item].height}
                          onChange={e => this.handlePackageChange(e, item)}
                        />
                        <span>&nbsp;in</span>
                      </span>
                    </div>
                  </div>
                  <div className="shipment-inputs-container">
                    <div className="shi-25">
                      <label htmlFor={`weight${item + 1}`}>
                        Weight
                      </label>
                      <span>
                        <input
                          id={`weight${item + 1}`}
                          name="weight"
                          className="shipment-input"
                          value={shippingData.packages[item].weight}
                          onChange={e => this.handlePackageChange(e, item)}
                          disabled={shippingData.packages[item].not_weight}
                        />
                        <span>&nbsp;LBS</span>
                      </span>
                    </div>
                    <div className="check-container">
                      <input
                        id={`weight_check${item + 1}`}
                        name="weight_check"
                        type="checkbox"
                        checked={shippingData.packages[item].not_weight}
                        onChange={e => this.handlePackageChange(e, item)}
                      />
                      <label htmlFor={`weight_check${item + 1}`}>
                        I don’t know the weight
                      </label>
                    </div>
                  </div>
                </section>
              ))}
            </div>

            {/* PICKUP */}
            <PickupBlock
              shippingData={shippingData}
              classes={classes}
              errors={errors}
              handleChange={this.handleChange}
            />

            {/* SHIPPING QUOTE */}
            <div className="custom-form-group-title">Shipping Quote</div>
            <div className="shipping-quote">
              <button
                id="quote-button"
                type="button"
                className="btn-main medium"
                onClick={() => getQuote(shippingData)}
              >
                {
                  quoteStatus === 'loading' ? 'Getting Quote...' : 'Get Quote'
                }
              </button>
              <div className="amount" style={{ fontSize: '1.5rem' }}>{`Amount: $${amount || '0.00'}`}</div>
            </div>

            {/* PAYMENT INFORMATION */}
            <div className="custom-form-group-title">Payment Information</div>
            <div style={{ fontSize: '1.0rem', marginBottom: '2rem' }}>
              We need your credit card information to purchase UPS shipping label(s) using our discounted nonprofit rate.
            </div>
            <label htmlFor="card_name">
              Name on card *
            </label>
            <TextField
              id="card_name"
              type="text"
              name="card_name"
              classes={{
                root: classes.input,
              }}
              onChange={e => this.handleChange(e)}
              value={shippingData.card_name}
              error={Boolean(errors.card_name)}
              helperText={errors.card_name}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input1 },
              }}
              FormHelperTextProps={{ className: 'errors' }}
            />
            <form id="payment-form">
              <div id="card-container"></div>
            </form>
            <div id="payment-status-container"></div>


            {/* BILLING ADDRESS */}
            <div className="custom-form-group-title">Billing Address</div>
            <div className="shipment-inputs-container">
              <div className="check-container full">
                <input
                  id="my_shipping"
                  name="my_shipping"
                  type="checkbox"
                  checked={shippingData.useMyShipmentAddress}
                  onChange={() => this.handleUseMyShipping()}
                />
                <label htmlFor="my_shipping">
                  Use my shipping address
                </label>
              </div>
            </div>
            {!shippingData.useMyShipmentAddress
            && (
              <section>
                <div className="shipment-inputs-container">
                  <div className="shi-44">
                    <label htmlFor="billing_first_name">
                      First name
                    </label>
                    <TextField
                      id="billing_first_name"
                      type="text"
                      name="billing_first_name"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_first_name}
                      error={Boolean(errors.billing_first_name)}
                      helperText={errors.billing_first_name}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                  </div>
                  <div className="shi-44">
                    <label htmlFor="billing_last_name">
                      Last name
                    </label>
                    <TextField
                      id="billing_last_name"
                      type="text"
                      name="billing_last_name"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_last_name}
                      error={Boolean(errors.billing_last_name)}
                      helperText={errors.billing_last_name}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                  </div>
                </div>
                {/*{!individual*/}
                {/*&& (*/}
                {/*  <React.Fragment>*/}
                    <label htmlFor="billing_company_name">
                      Company name
                    </label>
                    <TextField
                      id="billing_company_name"
                      type="text"
                      name="company_name"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_company_name}
                      error={Boolean(errors.billing_company_name)}
                      helperText={errors.billing_company_name}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                {/*  </React.Fragment>*/}
                {/*)*/}
                {/*}*/}
                <label htmlFor="billing_address_line1">
                  Street Address
                </label>
                <TextField
                  id="billing_address_line1"
                  type="text"
                  name="address_line1"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'billing_address')}
                  value={shippingData.billing_address.billing_address_line1}
                  error={Boolean(errors.billing_address_line1)}
                  helperText={errors.billing_address_line1}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
                <label htmlFor="address_line2">
                  Apt, Suite, Bldg, Gate Code
                </label>
                <TextField
                  id="billing_address_line2"
                  type="text"
                  name="address_line2"
                  classes={{
                    root: classes.input,
                  }}
                  onChange={e => this.handleChange(e, 'billing_address')}
                  value={shippingData.billing_address.billing_address_line2}
                  error={Boolean(errors.billing_address_line2)}
                  helperText={errors.billing_address_line2}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input1 },
                  }}
                  FormHelperTextProps={{ className: 'errors' }}
                />
                <div className="shipment-inputs-container">
                  <div className="shi-44">
                    <label htmlFor="billing_city">
                      City
                    </label>
                    <TextField
                      id="billing_city"
                      type="text"
                      name="city"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_city}
                      error={Boolean(errors.billing_city)}
                      helperText={errors.billing_city}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                  </div>
                  <div className="shi-44">
                    <label htmlFor="billing_state">
                      State
                    </label>
                    <StateSelect
                      id="billing_state"
                      className="shipment-input"
                      onChange={this.handleState}
                      value={shippingData.billing_address.billing_state}
                    />
                  </div>
                </div>
                <div className="shipment-inputs-container">
                  <div className="shi-44">
                    <label htmlFor="billing_zip_code">
                      Zip Code
                    </label>
                    <TextField
                      id="billing_zip_code"
                      type="text"
                      name="zip_code"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_zip_code}
                      error={Boolean(errors.billing_zip_code)}
                      helperText={errors.billing_zip_code}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                  </div>
                  <div className="shi-44">
                    <label htmlFor="billing_country">
                      Country
                    </label>
                    <input
                      id="billing_country"
                      name="country"
                      defaultValue="United States"
                      className="shipment-input read-only"
                      readOnly
                    />
                  </div>
                </div>
                <div className="shipment-inputs-container">
                  <div className="shi-44">
                    <label htmlFor="billing_phone">
                      Phone
                    </label>
                    <TextField
                      id="billing_phone"
                      type="text"
                      name="phone"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_phone}
                      error={Boolean(errors.billing_phone)}
                      helperText={errors.billing_phone}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                  </div>
                  <div className="shi-44">
                    <label htmlFor="billing_email">
                      Email
                    </label>
                    <TextField
                      id="billing_email"
                      type="text"
                      name="email"
                      classes={{
                        root: classes.input,
                      }}
                      onChange={e => this.handleChange(e, 'billing_address')}
                      value={shippingData.billing_address.billing_email}
                      error={Boolean(errors.billing_email)}
                      helperText={errors.billing_email}
                      InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.input1 },
                      }}
                      FormHelperTextProps={{ className: 'errors' }}
                    />
                  </div>
                </div>
              </section>
            )
            }
          </div>
          {error && <div className="error">{error}</div>}
          <button
            id="review-button"
            type="button"
            className="btn-main medium"
            onClick={e => this.handleSubmit(e)}
          >
            {
              !status && 'Review'
            }
            {
              status === 'loading' && 'Processing...'
            }
            {
              status === 'success' && 'Reviewed'
            }
          </button>
        </div>
      </div>
    );
  }
}

ShippingForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  // paymentForm: PropTypes.func.isRequired,
  onReview: PropTypes.func.isRequired,
  getQuote: PropTypes.func.isRequired,
  shippingData: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  quoteStatus: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  amount: PropTypes.string,
};

ShippingForm.defaultProps = {
  amount: '',
};

export { ShippingForm as ShippingFormComponent };
export default withStyles(styles)(ShippingForm);
