import React from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import Table from '../ui/Table';
import DonorAddNew from './DonorAddNew';
import API from '../../services/api';
import sortHelper from '../../services/sortHelper';
import { successToast, errorToast } from '../../services/toast';
import Toast from '../ui/Toast';

class DonorsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.numberFormat = new Intl.NumberFormat('en-US', {
      useGrouping: true,
    });

    this.state = {
      showModal: false,
      donors: [],
      totalPages: 1,
      currentPage: 1,
      error: '',
      searchQuery: '',
      donorsCount: 0,
      donorsSortParams: {
        column: 'id',
        type: 'asc',
      },
    };

    this.handleCreateNew = this.handleCreateNew.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSaveNewDonor = this.handleSaveNewDonor.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.searchDonors = this.searchDonors.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
  }

  handleDonorSelect(id) {
    window.open(`/donors/${id}`, '_self');
  }

  handleCreateNew() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleSaveNewDonor(donor) {
    API.createDonor({ donor }).then(() => {
      this.setState({ showModal: false, error: '' });
      successToast('Success! New donor added.');
    }).catch(() => {
      this.setState({ showModal: false });
      errorToast();
    });
  }

  handlePageChange() {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.loadPage(page);
  }

  handleSort(column) {
    const { donorsSortParams } = this.state;
    this.setState({ donorsSortParams: sortHelper(column, donorsSortParams) }, () => this.loadPage(1, true));
  }

  loadPage(page, refresh = false) {
    const { donors, donorsSortParams, searchQuery } = this.state;
    API.loadDonors(page, donorsSortParams, searchQuery).then((res) => {
      this.setState({
        currentPage: page, totalPages: res.totalPages, donorsCount: res.donorsCount, donors: refresh ? res.donors : [...donors, ...res.donors],
      });
    });
  }

  searchDonors(e) {
    this.setState({ searchQuery: e.target.value }, () => this.loadPage(1, true));
  }

  render() {
    const {
      showModal, donors, error, totalPages, currentPage, donorsSortParams, searchQuery, donorsCount,
    } = this.state;
    return (
      <div className="detail-container index_page">
        <Toast />
        <div className="details-header">
          <div className="detail-title">
            Crayon Donors (
            {this.numberFormat.format(donorsCount)}
            )
          </div>
          <div className="search">
            <input id="search" value={searchQuery || ''} onChange={this.searchDonors} />
            <span>Search</span>
          </div>
        </div>
        <Table
          headers={['ID', 'First Name', 'Last Name', 'Company', 'Email Address', 'Number of Shipments', 'Crayons, lbs', 'Last Shipment']}
          keys={['id', 'first_name', 'last_name', 'company', 'email', 'number_of_shipments', 'crayons_weight', 'last_shipment']}
          sortField={donorsSortParams}
          objects={donors}
          onPageChange={this.handlePageChange}
          paginationDisabled={currentPage >= totalPages}
          handleSelect={this.handleDonorSelect}
          onCreateNew={this.handleCreateNew}
          handleSort={this.handleSort}
          formatters={{
            last_shipment: item => (item ? moment(item).format('M/D/YYYY h:mm:ss A') : ''),
          }}
        />
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          className="modal add-new donor"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <DonorAddNew handleClose={this.handleCloseModal} error={error} handleSave={this.handleSaveNewDonor} />
        </ReactModal>
      </div>
    );
  }
}

export default DonorsIndex;
