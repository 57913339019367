import React from 'react';
import PropTypes from 'prop-types';
import StateSelect from '../ui/StateSelect';

const SendersInformation = ({
  donor, readOnly, handleChange, errors, className,
}) => (
  <div className={className}>
    {!readOnly ? (
      <React.Fragment>
        <div className="incoming-shipment-container vertical width30">
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">First Name</div>
              <input id="first_name" className="shipment-input" value={donor.first_name || ''} onChange={handleChange} />
            </section>
            <div className="errors">{errors.first_name && errors.first_name}</div>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">Last Name</div>
              <input id="last_name" className="shipment-input" value={donor.last_name || ''} onChange={handleChange} />
            </section>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">Email</div>
              <input id="email" className="shipment-input" value={donor.email || ''} onChange={handleChange} />
            </section>
            <div className="errors">{errors.email && errors.email}</div>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">Company</div>
              <input id="company" className="shipment-input" value={donor.company || ''} onChange={handleChange} />
            </section>
            <div className="errors">{errors.company && errors.company}</div>
          </div>
        </div>
        <div className="incoming-shipment-container vertical width30">
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">Street1</div>
              <input id="street1" className="shipment-input" value={donor.street1 || ''} onChange={handleChange} />
            </section>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">Street2</div>
              <input id="street2" className="shipment-input" value={donor.street2 || ''} onChange={handleChange} />
            </section>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">City</div>
              <input id="city" className="shipment-input" value={donor.city || ''} onChange={handleChange} />
            </section>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">State</div>
              <StateSelect
                id="state"
                className="select_state"
                onChange={handleChange}
                value={donor.state || ''}
              />
            </section>
          </div>
          <div className="incoming_shipment_input-container">
            <section>
              <div className="input-label">Zip Code</div>
              <input id="zip_code" className="shipment-input w35" value={donor.zip_code || ''} onChange={handleChange} />
            </section>
          </div>
        </div>
      </React.Fragment>
    )
      : (
        <React.Fragment>
          <div className="package-info row">
            <div className="incoming-shipment-container vertical width30">
              <div className="package-container">
                <div className="label">First name:</div>
                <span>{donor.first_name || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">Last name:</div>
                <span>{donor.last_name || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">Email:</div>
                <span>{donor.email || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">Company:</div>
                <span>{donor.company || ''}</span>
              </div>
            </div>
            <div className="incoming-shipment-container vertical width30">
              <div className="package-container">
                <div className="label">Street1:</div>
                <span>{donor.street1 || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">Street2:</div>
                <span>{donor.street2 || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">City:</div>
                <span>{donor.city || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">State:</div>
                <span>{donor.state || ''}</span>
              </div>
              <div className="package-container">
                <div className="label">Zip code:</div>
                <span>{donor.zip_code || ''}</span>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
  </div>
);

SendersInformation.propTypes = {
  donor: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.string,
};

SendersInformation.defaultProps = {
  donor: {},
  errors: [],
  readOnly: false,
  handleChange: () => {},
  className: 'information-container new-incoming',
};

export default SendersInformation;
