import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const ButtonContainer = ({
  path, icon, label,
}) => (
  <Button href={path}>
    <i className="nav-icon material-icons">
      {icon}
    </i>
    <span className="nav-text">
      {label}
    </span>
  </Button>
);

ButtonContainer.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ButtonContainer;
