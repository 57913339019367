// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LocateAndAcceptShipment Component rendering renders correctly 1`] = `
<div
  className="information-container incoming-shipment"
  id="information"
  onKeyDown={[Function]}
  role="button"
  tabIndex={-1}
>
  <div
    className="tn-container"
  >
    <div
      className="input-container incoming-shipment"
    >
      <div
        className="incoming-shipment_info"
      >
        <div
          className="input-label incoming-shipment tracking-number"
        >
          Tracking Number
        </div>
        <input
          autoFocus={true}
          className="tracking-number"
          id="trackingNumber"
          onChange={[MockFunction]}
          value=""
        />
      </div>
      <button
        className="incoming-shipment_btn blue"
        onClick={[Function]}
        type="button"
      >
        Locate
      </button>
    </div>
    <button
      className="incoming-shipment_btn gray"
      onClick={[MockFunction]}
      type="button"
    >
      Manual Entry
    </button>
    <button
      className="incoming-shipment_btn gray"
      type="button"
    >
      Anonymous entry
    </button>
  </div>
  <div
    className="input-container incoming-shipment"
  >
    <div
      className="incoming-shipment_info"
    >
      <div
        className="input-label incoming-shipment tracking-number"
      >
        Enter Actual Weight
      </div>
      <input
        className="weight"
        id="weight"
        onChange={[MockFunction]}
      />
      <span>
        lbs
      </span>
    </div>
    <button
      className="incoming-shipment_btn orange"
      onClick={[Function]}
      type="button"
    >
      Accept
    </button>
  </div>
  <div
    className="package-info"
    id="information"
  >
    <div
      className="package-title"
    >
      Package Information
    </div>
    <div
      className="package-container"
    >
      <div
        className="label"
      >
        Dimensions:
      </div>
      <span>
        undefined" x undefined" x undefined"
      </span>
    </div>
    <div
      className="package-container"
    >
      <div
        className="label"
      >
        Date:
      </div>
      <span>
        
      </span>
    </div>
    <div
      className="package-container"
    >
      <div
        className="label"
      >
        Weight:
      </div>
      <span>
        
      </span>
    </div>
  </div>
</div>
`;
