// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DonorSignIn Component rendering renders correctly 1`] = `
<div
  className="auth-content"
>
  <div
    className="custom-logo-img"
  />
  <div
    className="auth-header"
  >
    Sign Up
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="donor[first_name]"
    >
      First Name
    </label>
    <div>
      <i
        className="material-icons"
      >
        person
      </i>
      <div
        className="MuiFormControl-root-3 DonorSignUp-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 DonorSignUp-input1-2"
            disabled={false}
            id="first_name"
            name="donor[first_name]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Enter first name"
            required={false}
            type="text"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="donor[last_name]"
    >
      Last Name
    </label>
    <div>
      <i
        className="material-icons"
      >
        person
      </i>
      <div
        className="MuiFormControl-root-3 DonorSignUp-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 DonorSignUp-input1-2"
            disabled={false}
            id="last_name"
            name="donor[last_name]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Enter last name"
            required={false}
            type="text"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="donor[email]"
    >
      Email Address
    </label>
    <div>
      <i
        className="material-icons"
      >
        mail
      </i>
      <div
        className="MuiFormControl-root-3 DonorSignUp-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 DonorSignUp-input1-2 MuiInput-inputType-18"
            disabled={false}
            id="email"
            name="donor[email]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Enter email"
            required={false}
            type="email"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="donor[password]"
    >
      Password
    </label>
    <div>
      <i
        className="material-icons"
      >
        lock
      </i>
      <div
        className="MuiFormControl-root-3 DonorSignUp-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 DonorSignUp-input1-2 MuiInput-inputType-18"
            disabled={false}
            id="password"
            name="donor[password]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Password"
            required={false}
            type="password"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="custom-form-group"
  >
    <label
      htmlFor="donor[password_confirmation]"
    >
      Confirm Password
    </label>
    <div>
      <i
        className="material-icons"
      >
        lock
      </i>
      <div
        className="MuiFormControl-root-3 DonorSignUp-input-1 str"
      >
        <div
          className="MuiInput-root-7 MuiInput-formControl-8"
        >
          <input
            aria-invalid={false}
            className="MuiInput-input-15 DonorSignUp-input1-2 MuiInput-inputType-18"
            disabled={false}
            id="password_confirmation"
            name="donor[password_confirmation]"
            onBlur={[Function]}
            onChange={[Function]}
            onFocus={[Function]}
            placeholder="Confirm password"
            required={false}
            type="password"
            value=""
          />
        </div>
      </div>
    </div>
  </div>
  <div
    className="input-container"
  >
    <div
      className="checkbox-cell false"
    >
      <input
        checked={false}
        id="agree"
        name="agree"
        onChange={[Function]}
        required={true}
        type="checkbox"
      />
      <label
        className="input-label"
        htmlFor="agree"
      >
        I agree with Terms and Conditions
      </label>
    </div>
  </div>
  <button
    className="btn-main"
    onClick={[Function]}
    type="button"
  >
    Create account
  </button>
  <div
    className="auth-footer-text"
  >
    Have an account?
  </div>
  <a
    className="btn-alt"
    href="/donors/sign_in"
  >
    Sign In
  </a>
  <div
    className="auth-footer-text"
  >
    Or 
  </div>
  <button
    className="btn-alt black"
    onClick={[Function]}
    type="button"
  >
    Continue as a guest
  </button>
</div>
`;
