import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import PackageInformation from '../PackageInformation';

describe('PackageInformation Component', () => {
  let wrapper;
  const defaultProps = {
    shipment: {
      length: '12',
      width: '11',
      height: '21',
      created_at: '2019-11-08T00:00:00',
      weight: '22',
    },
  };

  describe('rendering', () => {
    beforeEach(() => {
      wrapper = mount(<PackageInformation {...defaultProps} />);
    });

    it('renders correctly', () => {
      const tree = renderer
        .create(<PackageInformation {...defaultProps} />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });


    it('renders', () => {
      expect(wrapper.find('.package-container')).toHaveLength(3);
      expect(wrapper.find('.label')).toHaveLength(3);
      expect(wrapper.find('span').at(0).text()).toEqual('12" x 11" x 21"');
      expect(wrapper.find('span').at(1).text()).toEqual('11/8/2019');
    });
  });
});
