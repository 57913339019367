import React from 'react';
import { mount } from 'enzyme';
import renderer from 'react-test-renderer';
import AdminLogsIndex from '../AdminLogsIndex';

jest.mock('../../../services/api', () => ({
  loadAdminLogs: jest.fn(() => Promise.resolve({
    logs: [
      {
        id: '1',
        object_type: 'type',
        content: 'Lorem ipsum',
      },
    ],
  })),
}));

describe('AdminLogsIndex Component', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(<AdminLogsIndex />);
  });

  describe('rendering', () => {
    it('renders correctly', () => {
      const tree = renderer
        .create(<AdminLogsIndex />)
        .toJSON();

      expect(tree).toMatchSnapshot();
    });

    it('renders', () => {
      wrapper.update();
      expect(wrapper.find('.detail-container')).toHaveLength(1);
      expect(wrapper.find('table')).toHaveLength(1);
      expect(wrapper.find('tr')).toHaveLength(2);
    });
  });

  describe('events', () => {
    it('handleCloseLogModal', () => {
      wrapper.instance().handleCloseLogModal();
      expect(wrapper.state().showLogModal).toEqual(false);
    });

    it('handlePageChange', (cb) => {
      wrapper.instance().handlePageChange();
      process.nextTick(() => { expect(wrapper.state('currentPage')).toEqual(2); cb(); });
    });

    it('handle searchAdminLogs', () => {
      wrapper.find('input#search').simulate('change', { target: { value: 'a' } });
      expect(wrapper.state('searchQuery')).toEqual('a');
    });

    it('handleSort', (cb) => {
      wrapper.instance().handleSort('col');
      process.nextTick(() => { expect(wrapper.state('adminLogsSortParams')).toEqual({ column: 'col', type: 'asc' }); cb(); });
    });
  });
});
